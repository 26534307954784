
import React, {Fragment,useState,useRef,Suspense} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,
    Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import Modale from '../Modale.js';

//https://fullcalendar.io/demos utile per dimostrazioni e manuale

import FullCalendar, { joinSpans } from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import itLocale from '@fullcalendar/core/locales/it';//serve per lingua italiana
import timeGridPlugin from '@fullcalendar/timegrid';

import TendinaOrariAStep from '../TendinaOrariAStep.js';
import Clessidra from '../Clessidra.js';

import ReportPrenotazioniCorsi  from '../ReportPrenotazioniCorsi/Tabella';

const STATO_SCEGLI_ISCRITTO = 0;
const STATO_RICERCA_DATI_CORRETTA = 1;
const STATO_MEMORIZZAZIONE = 2;
const STATO_OPERAZIONE_FALLITA = 3;
const STATO_ERRORE_CONNESSIONE = 4;
const STATO_RICERCA_NUOVI_DATI = 5;

const STATO_VAI_A_REPORT_PRENOTAZIONI= 6;


function TablesPrenotazioneCorsi2(props) {

    const giorno   = useRef();

    const [bottoniNavigazione,setBottoniNavigazione]=useState("");

    const [statoPagina,setStatoPagina]=useState(STATO_SCEGLI_ISCRITTO);
    const [error,setError]=useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);
    const [variazioneCampiOrari,setVariazioneCampiOrari]=useState(0);

    const [eventiDisponibilita,setEventiDisponibilita]=useState([]);//json con i periodi permessi per poter prenotare
    const [eventiDisponibilitaPosti,setEventiDisponibilitaPosti]=useState([]);//json con tutti i periodo ed i relativi posti disponibili alla prenotazione
    const [prenotatiFullcalendar,setPrenotatiFullcalendar]=useState([]);//json con tutte le prenotazioni della persona (formato utile per il fullcalendar)
    const [prenotati,setPrenotati]=useState([]);//json con tutte le prenotazioni della persona
    
    const [statoTendinaCorso,setStatoTendinaCorso]=useState(false);
    const [statoRicercaInsolvenza,setStatoRicercaInsolvenza]=useState(false);
    const [statoTendinaIscritto,setStatoTendinaIscritto]=useState(false);

    const [risultatoServerDatiCorso,setRisultatoServerDatiCorso]=useState('');
    const [risultatoServerDatiPersona,setRisultatoServerDatiPersona]=useState('');

    const [risultatoServerInsolvenza,setRisultatoServerInsolvenza]=useState('');

    const [insCorsoEdi,setInsCorsoEdi]=useState('');//nome del corso
    const [insIdCorsoEdi,setInsIdCorsoEdi]=useState('');//id del corso
    //const [insDurataCorsoEdi,setInsDurataCorsoEdi]=useState('');//durata tipica del corso
    const [insDurataMinimaCorsoEdi,setInsDurataMinimaCorsoEdi]=useState('');//durata minima del corso
    const [classeAbbonamento,setClasseAbbonamento]=useState('');//
    const [insPersonaEdi,setInsPersonaEdi]=useState('');//estremi persona
    const [insIdPersonaEdi,setInsIdPersonaEdi]=useState('');//id persona
    
    const [modaleConfermaCanc, setModaleConfermaCanc] = React.useState(false);//per la finestra modale relativa all'inserimento dei dati dell'evento
    const [flagAttivaModaleInserisciModifica,setFlagAttivaModaleInserisciModifica]=React.useState(false);
    const [flagAttivaModaleCancellazione,setFlagAttivaModaleCancellazione]=React.useState(false);
    const [modaleInfoTendina,setModaleInfoTendina]=React.useState(false);

    const [idPrenotazione,setIdPrenotazione]=useState(0);
    const [operazione,setOperazione]=useState('');

    const [dataPartenza,setDataPartenza]=useState('');//questa verrà settata con il click sulla data: verrà suggerita poi come data di inizio evento
    const [inserisciNuovo,setInserisciNuovo]=useState(0);//verrà settata ad 1 quando l'utente clicca su un evento in background con groupId == "areaDisponibile"

    const [giornoEvento,setGiornoEvento]=useState(0);
    const [numeroPostiNelIntervallo,setNumeroPostiNelIntervallo]=useState(0);
    
    //settaggio degli eventuali arrori di compilazione delle date
    const [erroreCongruitaDate,setErroreCongruitaDate]=useState(0);//qui eventualmente ci sarà l'errore se data inizio>data fine
    const [erroreSovrapposizioneEvento,setErroreSovrapposizioneEvento]=useState(0);//qui eventualmente ci sarà l'errore se l'vento và a sovrapporsi ad un altro evento della persona
    
    const [orarioInizio,setOrarioInizio]=useState("");
    const [orarioFine,setOrarioFine]=useState("");

    const [dataSelezionata,setDataSelezionata]=useState("");

    //MODALI:
    const [flagAttivaModalePeriodoDisdettaNonRispettatoInserimentoFallito, setFlagAttivaModalePeriodoDisdettaNonRispettatoInserimentoFallito] = useState(0);
    const [flagAttivaModalePeriodoDisdettaNonRispettatoModificaFallita1, setFlagAttivaModalePeriodoDisdettaNonRispettatoModificaFallita1] = useState(0);
    const [flagAttivaModalePeriodoDisdettaNonRispettatoModificaFallita2, setFlagAttivaModalePeriodoDisdettaNonRispettatoModificaFallita2] = useState(0);
    const [flagAttivaModalePeriodoDisdettaNonRispettatoCancellazioneFallita, setFlagAttivaModalePeriodoDisdettaNonRispettatoCancellazioneFallita] = useState(0);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);

    const calendarRef = useRef();

    const [vista,setVista]=useState("timeGridWeek");

    var today = new Date();

    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno+'T00:00:00';

    //periodo considerato per fare la richiesta dati tramite fetch al backend:
    const [dataInizioPeriodo,setDataInizioPeriodo]=useState('');
    const [dataFinePeriodo,setDataFinePeriodo]=useState('');

    //periodo totale considerato per fare la richiesta dati tramite fetch al backend:
    const [dataInizioPeriodoTot,setDataInizioPeriodoTot]=useState('');
    const [dataFinePeriodoTot,setDataFinePeriodoTot]=useState('');
    //escursione massima ammessa di vibilità del calendario:
    let numeroAnniVisibiliDopoOggi=10;
    let numeroAnniVisibiliPrimaOggi=0;

    let annoInizioRange = today.getFullYear()-numeroAnniVisibiliPrimaOggi;
    let meseInizioRange = today.getMonth()+1;
    let giornoInizioRange = today.getDate();

    if(meseInizioRange<10) meseInizioRange="0"+meseInizioRange;
    if(giornoInizioRange<10) giornoInizioRange="0"+giornoInizioRange;

    let annoFineRange = today.getFullYear()+numeroAnniVisibiliDopoOggi;
    let meseFineRange = today.getMonth()+1;
    let giornoFineRange = today.getDate();

    if(meseFineRange<10) meseFineRange="0"+meseFineRange;
    if(giornoFineRange<10) giornoFineRange="0"+giornoFineRange;

    const dataInizioRange = annoInizioRange+'-'+meseInizioRange+'-'+giornoInizioRange;
    const dataFineRange = annoFineRange+'-'+meseFineRange+'-'+giornoFineRange;

    const [flagLoading,setFlagLoading]=useState(0);

    const [loading,setLoading]= useState(false);

    const [orarioInizioDispOdierna,setOrarioInizioDispOdierna]=useState('');
    const [orarioFineDispOdierna,setOrarioFineDispOdierna]=useState('');

    const [zoom,setZoom]=useState([]);
    const [chiaveZoom,setChiaveZoom]=useState(1);
    //const [stepOrariTendina,setStepOrariTendina]=useState(1);

    useEffect(() => {
        //al primo giro prendo 3 mesi come periodo di richiesta disponibilità e prenotazioni per cui:
        setDataInizioPeriodo(dataOggi);
        setDataInizioPeriodoTot(dataOggi);
        calcolaPeriodoAnalisiDisponibilita(dataOggi);
        setStatoTendinaIscritto(false);
        ricercaDatiPersona();
        setInserisciNuovo(0);
        setDataPartenza();
        setErroreCongruitaDate(0);
        setErroreSovrapposizioneEvento(0);
        setNumeroPostiNelIntervallo(0);
        setIdPrenotazione(0);
        setOrarioInizio("");
        setOrarioFine("");
        setFlagLoading(0);
        setDataSelezionata(dataOggi);//al primo giro la data selezionata è la data di oggi

        //possibili zoom:
        //0 - ora
        //1 - mezzora
        //2 - 15 minuti
        //3 - 5 minuti
        //4 - 1 minuti
        let arr=[];
        for (let i=0; i<5; i++) {
            if(i===0) arr[i]="01:00:00";
            if(i===1) arr[i]="00:30:00";
            if(i===2) arr[i]="00:15:00";
            if(i===3) arr[i]="00:05:00";
            if(i===4) arr[i]="00:01:00";
            setZoom(arr);
        }
        setChiaveZoom(1);
        //setStepOrariTendina(1);
    },[]);

    useEffect(() => {
        if(statoPagina  === STATO_RICERCA_NUOVI_DATI) setBottoniNavigazione('custom2');
        if(statoPagina  === STATO_RICERCA_DATI_CORRETTA) setBottoniNavigazione('custom1,prev,next');

        if(statoPagina  === STATO_RICERCA_DATI_CORRETTA || statoPagina  === STATO_RICERCA_NUOVI_DATI) evidenziaDataSelezionata();//utile per mantenere selezionato il giorno in seguito ad un inserimento, modifica o cancellazione
    
        if(statoPagina === STATO_SCEGLI_ISCRITTO){
            //ho resettato la persona per cui devo azzerare tutte la variabili eventualmente utilizzate ad ora (eccetto ciò che riguarda il corso eventualmente già selezionato):
            setEventiDisponibilita([]);//json con i periodi permessi per poter prenotare
            setEventiDisponibilitaPosti([]);//json con tutti i periodo ed i relativi posti disponibili alla prenotazione
            setPrenotatiFullcalendar([]);//json con tutte le prenotazioni della persona (formato utile per il fullcalendar)
            setPrenotati([]);//json con tutte le prenotazioni della persona
    
            setInsPersonaEdi('');
            setInsIdPersonaEdi('');

            setStatoTendinaCorso(false);
            setStatoRicercaInsolvenza(false);
        }
    
    },[statoPagina]);
 
    useEffect(() => {
        //ogni volta che cambi persona fai vedere in prima battuta tutte le prenotazioni senza però poter inserire/modificare/cancellare perchè non hai ancora le disponibilità
        if(statoTendinaIscritto === true && insPersonaEdi!==''){
            ricercaDatiCorsi();
            ricercaInsolvenza();
            //verificaDisponibilitaCorso("sostituisci");
        }
    },[insPersonaEdi]);

    useEffect(() => {
        //ogni volta che cambi persona fai vedere in prima battuta tutte le prenotazioni senza però poter inserire/modificare/cancellare perchè non hai ancora le disponibilità
        if(statoTendinaCorso === true && statoRicercaInsolvenza === true){
            verificaDisponibilitaCorso("sostituisci");
        }
    },[statoTendinaCorso,statoRicercaInsolvenza]);

    useEffect(() => {
        //ogni volta che cambi corso devi ricercare le disponibilità dello stesso nel calendario
        if(statoTendinaCorso=== true){
            setFlagLoading(1);        
            verificaDisponibilitaCorso("sostituisci");
            if(orarioInizio && orarioFine && orarioInizio!=="" && orarioFine!==""){
                verificaPostiPeriodoPrenotazione(orarioInizio,orarioFine);
            }
       }
    },[insCorsoEdi]);

    //useEffect per gestire l'inserimento di un nuovo evento:
    useEffect(() => {
        if(inserisciNuovo===1){
            setInserisciNuovo(0);
            nuovoEvento();
        }
    },[dataPartenza]);

    useEffect(() => {
        if(insPersonaEdi!=='') verificaDisponibilitaCorso();
    },[dataFinePeriodo]);

    useEffect(() => {
    
        if(orarioInizio && orarioFine 
            && orarioInizio!=="" && orarioFine!=="" 
            && orarioInizio!==undefined && orarioFine!==undefined 
            && eventiDisponibilitaPosti.length!==0){
//console.log("OK"+orarioInizio)
        //per evidenziare la giornata quando clicco su un evento:
        setDataSelezionata(orarioInizio+":00"); 
  
        evidenziaDataSelezionata();

        if(flagLoading===0){
            verificaPostiPeriodoPrenotazione(orarioInizio,orarioFine);
        } 
            if(operazione==='inserisci'){  
                if(controlloClickInAreaDisponibilita(orarioInizio) === 1){  
                    setFlagAttivaModaleInserisciModifica(true);
                }else{
                    //se non apro la modale di inserimento devo resettare la data di partenza in modo che alla prox selezione, se l'utente sceflie stessa ora/giorno 
                    //ci sarà un cambiamento di dataPartenza rispetto al reset appena fatto e scatterà lo useeffect innescato da dataPartenza
                    setDataPartenza();
                }
            } 
            if(operazione==='modifica' && idPrenotazione!==0){
                //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);//sblocco lo stato in modo che si aggiorni la tendina con il corso
                let periodoDisp = eventiDisponibilitaPosti[orarioInizio.substring(0, 10)];
                const arrDisp = periodoDisp.toString().split("-");
                setOrarioInizioDispOdierna(orarioInizio.toString().substring(0, 10)+"T"+arrDisp[0]);
                setOrarioFineDispOdierna(orarioInizio.toString().substring(0, 10)+"T"+arrDisp[1]);
                if(flagLoading===0){
                    setFlagAttivaModaleInserisciModifica(true);
                } 
            } 
        } 
    },[orarioInizio,orarioFine,eventiDisponibilitaPosti]);

    function controlloClickInAreaDisponibilita(inizio) { 
        //se il mio intervallo parte dentro disponibilita_fullcalendar -> "start" "end" -> sono in un'area di disponibilità
        let giornoSelezionato = (inizio.substr(0, 10));
      
        const minutoIniziale = parseInt(inizio.substr(11, 2))*60+parseInt(inizio.substr(14, 2));
    
        let s1, s2, s3, mi, mf;
        if(eventiDisponibilitaPosti[giornoSelezionato]){
            for (let id=0; id<eventiDisponibilitaPosti[giornoSelezionato].length; id++) {
                s1 = eventiDisponibilitaPosti[giornoSelezionato][id].split("-");
                s2 = s1[0].split(":");
                s3 = s1[1].split(":");
                mi = parseInt(s2[0])*60+parseInt(s2[1]);
                mf = parseInt(s3[0])*60+parseInt(s3[1]);
                if(mi<=minutoIniziale && mf>minutoIniziale){             
                    return 1;
                } 
                //console.log("minutoIniziale="+minutoIniziale+", s1="+s1+",s2="+s2+",s3="+s3+",mi="+mi+",mf="+mf);
            }  
        }   
        return 0;//non aprirò quindi la modale di inserimento
    }

    function tornaScheda() {
        setFlagAttivaModalePeriodoDisdettaNonRispettatoInserimentoFallito(0);
        setFlagAttivaModalePeriodoDisdettaNonRispettatoModificaFallita1(0);
        setFlagAttivaModalePeriodoDisdettaNonRispettatoModificaFallita2(0);
        setFlagAttivaModalePeriodoDisdettaNonRispettatoCancellazioneFallita(0);
    }

    function calcolaPeriodoAnalisiDisponibilita(dataPartenza,partenzaGiornoSuccessivo=0) {
        //calcolo la data fine periodo:
        let mesiDurataPeriodo = 1;//periodo sul quale viene eseguita la richiesta di disponibilità 

        let annoDataPartenza = (dataPartenza.substr(0, 4));
        let meseDataPartenza = (dataPartenza.substr(5, 2));

        let meseArrivo = parseInt(meseDataPartenza) + mesiDurataPeriodo +1;//arriverò al primo giorno del mese successivo al mese indicato in mesiDurataPeriodo
        let annoArrivo = annoDataPartenza;
        //casi di cambio anno:
        if(meseArrivo > 12){
            meseArrivo = meseArrivo - 12;
            annoArrivo = parseInt(annoArrivo) + 1;
        }

        //per cui èsetto la nuova la data di fine periodo da considerare:
        if(meseArrivo<10) meseArrivo="0"+meseArrivo;
        setDataFinePeriodo(annoArrivo+'-'+meseArrivo+'-01T'+"00:00:00");//arrivo al primo giorno del mese successivo 
        if(partenzaGiornoSuccessivo===1){
            setDataInizioPeriodo(annoDataPartenza+'-'+meseDataPartenza+'-02T'+"00:00:00");
        }else{
            setDataInizioPeriodo(dataPartenza);
        }
    }

    function ricercaDatiPersona() {
        //ricerco tra gli iscritti che mi servono per popolare la tendina delle persone che possono prenotare un corso:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoPersone",ridotto:1,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                  }
                  if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                  if(valoreDiRitorno.risultatoOperazione===1){
                    setRisultatoServerDatiPersona(valoreDiRitorno.risultatoRitorno);
                    setStatoTendinaIscritto(true);
                  }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaDatiCorsi() {
        //ricerco tra i corsi (attivi) che mi servono per popolare la tendina:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoCorsi",attivo:"1",idPersona:insIdPersonaEdi,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                  }
                  if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                  if(valoreDiRitorno.risultatoOperazione===1){
                        setRisultatoServerDatiCorso(valoreDiRitorno.risultatoRitorno);
                        setStatoTendinaCorso(true);
                  }

            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaInsolvenza() {
        //ricerco eventuale insolvenza della persona scelta:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"restituisciSituazioneInsolvente",id:insIdPersonaEdi,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                  }
                  if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                  if(valoreDiRitorno.risultatoOperazione===1){
                        setRisultatoServerInsolvenza(valoreDiRitorno.totali);
                        setStatoRicercaInsolvenza(true);
                  }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function tornaVisioneCalendario() {
        setFlagAttivaModaleInserisciModifica(false);
        setModaleConfermaCanc(false);
        setFlagAttivaModaleCancellazione(false);
        setModaleInfoTendina(false);
        setVariazioneCampi(0);
        setVariazioneCampiOrari(0);
        setInserisciNuovo(0);
        setDataPartenza("");
        setErroreCongruitaDate(0);
        setErroreSovrapposizioneEvento(0);
        setNumeroPostiNelIntervallo(0);
        setIdPrenotazione(0);
        setOperazione("");
        setOrarioInizio("");
        setOrarioFine("");
        
    }

    function callbackSetVariazioneCampi(variazioneCampi){
        setVariazioneCampi(variazioneCampi);
    }
    function callbackSetVariazioneCampiOrari(variazioneCampiOrari){
        setVariazioneCampiOrari(variazioneCampiOrari);
    }

    function callbackSetValoreTendEditabilePersona(valore,posizioneTendina,valoreNonVisibile){
        if(valoreNonVisibile !== undefined &&  valoreNonVisibile !== '' && valoreNonVisibile !== -1){
            setInsPersonaEdi(valore);
            setInsIdPersonaEdi(valoreNonVisibile);
        }
    }
    function callbackSetValoreTendEditabileCorso(valore,posizioneTendina,valoreNonVisibile){

        if(valoreNonVisibile !== undefined &&  valoreNonVisibile !== '' && valoreNonVisibile !== -1){
            setDataInizioPeriodoTot(today);
            setDataInizioPeriodo(today);
            setInsCorsoEdi(valore);
            setInsIdCorsoEdi(valoreNonVisibile);
            for (let i=0; i<risultatoServerDatiCorso.length ; i++) {
                if(risultatoServerDatiCorso[i].ID===valoreNonVisibile){
                    //ho trovato il mio corso: ne recupero la durata tipica:
                    //setInsDurataCorsoEdi(risultatoServerDatiCorso[i].DURATA_TIPICA);
                    setInsDurataMinimaCorsoEdi(risultatoServerDatiCorso[i].DURATA_MINIMA);
                    setClasseAbbonamento(risultatoServerDatiCorso[i].CLASSE_ABBONAMENTO);
                    
                }
            }
            setOrarioInizio("");
            setOrarioFine("");
        }       
    }

    function vaiDataOggi(){
        let calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(dataOggi); // va al giorno di oggi
        setDataSelezionata(dataOggi);
    }
    function vaiDataSelezionata(){
        let calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(dataSelezionata); // va alla data che riuslta adesso selezionata
    }

    function settaDataPartenza(arg){
        let calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(arg.dateStr); // va al giorno
        setGiornoEvento(arg.dateStr.substr(0, 10)); //YYYY-MM-DDThh:mm:ss
        setInserisciNuovo(1);
        
        setDataSelezionata(arg.dateStr);

        let periodoDisp = eventiDisponibilitaPosti[arg.dateStr.substr(0, 10)];
        const arrDisp = periodoDisp.toString().split("-");
        setOrarioInizioDispOdierna(arg.dateStr.toString().substr(0, 10)+"T"+arrDisp[0]);
        setOrarioFineDispOdierna(arg.dateStr.toString().substr(0, 10)+"T"+arrDisp[1]);
        
        //console.log("S_Data Partenza"+arg.dateStr.toString().substr(0, 16));
        

        const arrPartenzaDisp = arrDisp[0].toString().split(":");
        let minutiPartenzaDisp = parseInt(arrPartenzaDisp[0]) * 60 + parseInt(arrPartenzaDisp[1]);
        //console.log("parto da"+minutiPartenzaDisp);
        const arrArrivoDisp = arrDisp[1].toString().split(":");
        let minutiArrivoDisp = parseInt(arrArrivoDisp[0]) * 60 + parseInt(arrArrivoDisp[1]);
        //console.log("arrivo a"+minutiArrivoDisp);

        let blocca = 0;
        let inizioStep = minutiPartenzaDisp;
        let fineStep = minutiPartenzaDisp+ parseInt(insDurataMinimaCorsoEdi);
        let fineDisponibilità = minutiArrivoDisp;
        
        let stepPrecedente = 0;
        let contatoreSicurezza = 0;
        let dataSelezionataInMinuti = parseInt(arg.dateStr.toString().substring(11, 13)) * 60 + parseInt(arg.dateStr.toString().substring(14, 16));
        
        while (blocca === 0 && contatoreSicurezza < 1500) {
            stepPrecedente = inizioStep;
            //console.log("inizioStep"+inizioStep)
            //console.log("fineStep"+fineStep)
            //console.log("dataSelezionataInMinuti"+dataSelezionataInMinuti)
            if(inizioStep<=dataSelezionataInMinuti && fineStep>dataSelezionataInMinuti) blocca = 1;
            inizioStep = inizioStep + parseInt(insDurataMinimaCorsoEdi);//incremento di tenti minuti quanti sono quelli della durata minima
            fineStep = fineStep + parseInt(insDurataMinimaCorsoEdi);//incremento di tenti minuti quanti sono quelli della durata minima
            //condizione di uscita: se sono arrivato mel mio intervallo blocco!
            contatoreSicurezza = contatoreSicurezza + 1;
        }
        //setDataPartenza(arg.dateStr.substr(0, 16)); 
        //trasformo i minuti in hh:mm
        let stepPrecedenteOre = Math.floor(stepPrecedente/60);
        let stepPrecedenteMinuti = stepPrecedente - stepPrecedenteOre * 60;
        let stringaOre ='';
        let stringaMinuti = '';
        if (stepPrecedenteOre<=9) stringaOre = "0"+stepPrecedenteOre.toString();
        else stringaOre = stepPrecedenteOre.toString();
        if (stepPrecedenteMinuti<=9) stringaMinuti = "0"+stepPrecedenteMinuti.toString();
        else stringaMinuti = stepPrecedenteMinuti.toString();

        setDataPartenza(arg.dateStr.toString().substr(0, 10)+"T"+stringaOre+":"+stringaMinuti);
        //console.log("P_Data Partenza"+arg.dateStr.toString().substr(0, 10)+"T"+stringaOre+":"+stringaMinuti);
        //setDataPartenza(arg.dateStr.substr(0, 16)); 

    }

    function evidenziaDataSelezionata() {
        if (dataSelezionata==="" || dataSelezionata===undefined || dataSelezionata==='') return;     
        if (calendarRef==="" || calendarRef===undefined  || calendarRef.current==='') return;
        if (calendarRef.current===undefined || calendarRef.current===null) return;
        let calendarApi = calendarRef.current.getApi();
        
        //let vista = calendarApi.getView("name"); // recupera la vista attuale
        //console.log("la vista vale"+calendarApi.getView("name"));
        calendarApi.select(dataSelezionata.substring(0, 10)+"T00:00:00"); // colora di azzurro il giorno                                              
    }
    function nuovoEvento(){
        setOperazione("inserisci");
        //con i limiti e la data scelta dall'utente procedo con la presentazione della pagina modale:
        let d1 = '';
        let ore = 0;
        let minuti = 0;
        let partenza = 0;
        let arrivo = 0;
        let durataEvento = 0;

        if(insDurataMinimaCorsoEdi && insDurataMinimaCorsoEdi!=='0' && insDurataMinimaCorsoEdi!==''){
            //se ho recuperato da back una durata tipica per questo evento la considero
            durataEvento = insDurataMinimaCorsoEdi;
        }else{
            //altrimenti dò una durata tipica dell'evento di default di 30 minuti
            durataEvento = 30;
        }
        //cerco intervallo utile più vicino alla data di partenza del periodo che ho cliccato:
       //console.log("N_OrarioInizio"+dataPartenza)
        setOrarioInizio(dataPartenza);
        d1 = dataPartenza.substring(0, 10);//prima parte della data priva di orario
        ore = dataPartenza.substring(11, 13);
        minuti = dataPartenza.substring(14, 16);

        //ed aggiungo la durata minima:
        //trasformo tutto in minuti che è più facile:
        partenza = parseInt(ore)*60 + parseInt(minuti);
        //aggiungo il gap della durata evento:
        arrivo = partenza + parseInt(durataEvento);
        //controllo di non aver passato la giornata:
        if(arrivo>=3600){

        }else{
            //sono rimasto all'interno della stessa giornata per cui:
            ore = Math.floor(arrivo/60);
            minuti = Math.round((arrivo/60 - ore)*60);
        }

        //compongo la data:
        if(ore<10) ore="0"+ore;
        if(minuti<10) minuti="0"+minuti;
        d1 = d1+"T"+ore+":"+minuti;

        //aggiorno la data di fine:
        //console.log("N_OrarioInizio"+dataPartenza)
        setOrarioFine(d1);
    }

    function modificaEvento(arg){ // bind with an arrow function

        if(arg.view.type!=="dayGridMonth"){
            setVista(arg.view.type);
            setGiornoEvento(arg.event.startStr.substr(0, 10)); //YYYY-MM-DDThh:mm:ss

            if(arg.event.groupId === "areaDisponibile"){
                //si tratta di un evento in background quindi devo fare un inserimento:
            }else{
                //if(arg.event.title == insCorsoEdi){
                    //posso modificare solo le prenotazioni relative al corso selezionato 
                    //-> commentato così modifico anche se non corrisponde al mio corso ma basta che clicco su una prenotazione affinchè quel corso diventi il mio
                    //ed aggiungo (in modo che il corso cliccato diventi il mio):
                    //e riacchiappo tutti i dati relativi al corso cui hai cliccato la prenotazione:
                    //setStatoPagina(STATO_MEMORIZZAZIONE);
                    
                    for (let i=0; i<risultatoServerDatiCorso.length ; i++) {
                        if(risultatoServerDatiCorso[i].NOME===arg.event.title){
                            //ho trovato il mio corso: ne recupero i dati:
                            setInsIdCorsoEdi(risultatoServerDatiCorso[i].ID);
                            //setInsDurataCorsoEdi(risultatoServerDatiCorso[i].DURATA_TIPICA);
                            setInsDurataMinimaCorsoEdi(risultatoServerDatiCorso[i].DURATA_MINIMA);
                            setInsCorsoEdi(arg.event.title);
                            break;
                        }
                    } 

                    setOperazione("modifica");
                    setIdPrenotazione(arg.event.id);
                    setOrarioInizio(arg.event.startStr.substr(0, 16));
                    setOrarioFine(arg.event.endStr.substr(0, 16));
            
            }
        }
        
    } 

    function closeModal() {
        setFlagAttivaModaleInserisciModifica(false);
    }

    function verificaPeriodoVisualizzazione(dataF) {
        //controllo che la data inizio e fine del periodo di visualizzazione del calendario stiano nel periodo richiesco delle disponibilità:
        //se così non fosse faccio nuova fetch:
        let dFineVisual= dataF.substr(0,19);

        if(dFineVisual>dataFinePeriodo){
            //mi sono spostato oltre al periodo che avevo chiesto al backend per cui calcolo la fine del nuovo periodo:
            setStatoPagina(STATO_RICERCA_NUOVI_DATI);
            calcolaPeriodoAnalisiDisponibilita(dataFinePeriodo,1);
        } 
    }

    function verificaDisponibilitaCorso(modalita="",periodo=0){
        //ricerco le prenotazioni già in calendario per la persona in esame:
        //con periodo = 0 eseguo la ricerca solo sul periodo dataInizioPeriodo,dataFinePeriodo
        //invece con periodo = 1 estendo al ricerca su tutto il periodo dataInizioPeriodoTot,dataFinePeriodoTot (utilizzato in caaso di modifica di un evento: devo rifare la fetch completa perchè potrei non essere nell'ultimo periodo fetchato)
        // nota:
        // dataInizio nella forma YYYY-MM-DDThh:mm:ss (dove ore, minuti e secondi sono ininfluenti)
        // dataFine nella forma YYYY-MM-DDThh:mm:ss (dove ore, minuti e secondi sono ininfluenti)
        let dIni='';
        let dFine='';

        if(periodo===1){
            dIni=dataInizioPeriodoTot;
            dFine=dataFinePeriodoTot;
        }else{
            dIni=dataInizioPeriodo;
            dFine=dataFinePeriodo;
        }

        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"verificaDisponibilitaCorso",
            outputDisponibilita:"fullcalendar",
            idPersona:insIdPersonaEdi,
            idCorso:insIdCorsoEdi,
            dataInizio:dIni,
            dataFine:dFine,
            emak: props.emak,
            sessionId:props.sessionId})
        };
          
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then( 
                (valoreDiRitorno) => {
                    setLoading(false);
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                        //torno al calendario con i dati aggiornati all'ultima modifica:
                        if (modalita==="sostituisci") {
                            setEventiDisponibilita(valoreDiRitorno.disponibilita_fullcalendar);
                            setEventiDisponibilitaPosti(valoreDiRitorno.disponibilita_posti);
                            setPrenotatiFullcalendar(valoreDiRitorno.prenotati_fullcalendar);
                            setPrenotati(valoreDiRitorno.prenotati);
                            setDataFinePeriodoTot(dataFinePeriodo);
                            
                        }else{

                            if(dataFinePeriodo>dataFinePeriodoTot) setDataFinePeriodoTot(dataFinePeriodo);
                            setEventiDisponibilita([...eventiDisponibilita ,...valoreDiRitorno.disponibilita_fullcalendar]);
                            setEventiDisponibilitaPosti({...eventiDisponibilitaPosti,...valoreDiRitorno.disponibilita_posti});
                            setPrenotatiFullcalendar([...prenotatiFullcalendar ,...valoreDiRitorno.prenotati_fullcalendar]);
                            setPrenotati({...prenotati,...valoreDiRitorno.prenotati});
                        }
                        setFlagLoading(0);
                        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){ 
                        setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    }
                },
                (error) => {setLoading(false);;setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
    } 

    function fetchOperazioneServer(operazione) {
        setModaleConfermaCanc(false);
        setFlagAttivaModaleCancellazione(false);
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        
        if(operazione==='modifica' && variazioneCampiOrari===0){
            //in realtà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
            tornaVisioneCalendario();
        }else{
            // parametri per inviare i dati al server:
            setLoading(true);
           /*console.log("per"+insIdPersonaEdi);
            console.log("cor"+insIdCorsoEdi);
            console.log("ini"+orarioInizio);
            console.log("fin"+orarioFine);*/
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: operazione!=='cancella' ?  
                    JSON.stringify({op:operazione,
                    idPersona:insIdPersonaEdi,
                    idCorso:insIdCorsoEdi,
                    dataInizio:orarioInizio,
                    dataFine:orarioFine,
                    idElemento:idPrenotazione,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
                : 
                    JSON.stringify({op:operazione,
                    idElemento:idPrenotazione,
                    emak: props.emak,
                    sessionId:props.sessionId})
            };
            
            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                  if(valoreDiRitorno.risultatoOperazione===1){
                        //torno al calendario con i dati aggiornati all'ultima modifica:

                        verificaDisponibilitaCorso("sostituisci",1);
                        setFlagAttivaModaleInserisciModifica(false);
                        setVariazioneCampi(0);
                        setVariazioneCampiOrari(0);
                        setInserisciNuovo(0);
                        setDataPartenza();
                        setErroreCongruitaDate(0);
                        setErroreSovrapposizioneEvento(0);
                        setNumeroPostiNelIntervallo(0);
                        setIdPrenotazione(0);
                        setOrarioInizio("");
                        setOrarioFine("");
                    }

                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        if(valoreDiRitorno.mess !== undefined){
                            if(valoreDiRitorno.mess.indexOf("Prenotazione non inserita in quanto rientrerebbe nel periodo di disdetta non consentito") !== -1){
                                setFlagAttivaModalePeriodoDisdettaNonRispettatoInserimentoFallito(1);
                            }   
                            if(valoreDiRitorno.mess.indexOf("Prenotazione non modificabile in quanto oramai entrata nel periodo di disdetta non consentito") !== -1){
                                setFlagAttivaModalePeriodoDisdettaNonRispettatoModificaFallita1(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Prenotazione non modificata in quanto rientrerebbe nel periodo di disdetta non consentito") !== -1){
                                setFlagAttivaModalePeriodoDisdettaNonRispettatoModificaFallita2(1);
                            }
                            if(valoreDiRitorno.mess.indexOf("Prenotazione non eliminabile in quanto oramai entrata nel periodo di disdetta non consentito") !== -1){
                                setFlagAttivaModalePeriodoDisdettaNonRispettatoCancellazioneFallita(1);
                            }
                            if(valoreDiRitorno.mess.indexOf("Nessun posto disponibile") !== -1){
                                alert("Prenotazione NON INSERITA: i posti che erano disponibili nell'intervallo scelto sono stati nel frattempo prenotati da altri");                                
                            }
                        }else{
                            //altrimenti vado in errore generico
                            setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        }
                    } 
                },
                (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
        }
    }
    function verificaPostiPeriodoPrenotazione(dataInizialeImpostata,dataFinaleImpostata){
        //controllo in eventiDisponibilitaPosti il mio periodo se esiste e nel caso lo sia e coinvolga microperiodi con diverse disponibilità di posti,
        //prendo il minimo dei posti disponibili da presentare all'utente: "2022-01-04":["15:21-15:22-56"],

        const minutoIniziale = parseInt(dataInizialeImpostata.substr(11, 2))*60+parseInt(dataInizialeImpostata.substr(14, 2));
        const minutoFinale = parseInt(dataFinaleImpostata.substr(11, 2))*60+parseInt(dataFinaleImpostata.substr(14, 2));

        if (minutoFinale - minutoIniziale > 0) {
    
            let p=-1;
            let s1, s2, s3, mi, mf ,idPren;
            let postiMinuti=[];
            for (let i=minutoIniziale; i<=minutoFinale; i++) postiMinuti[i]=0;
            for (let id=0; id<eventiDisponibilitaPosti[giornoEvento].length; id++) {

                s1 = eventiDisponibilitaPosti[giornoEvento][id].split("-");
                s2 = s1[0].split(":");
                s3 = s1[1].split(":");
                mi = parseInt(s2[0])*60+parseInt(s2[1]);
                mf = parseInt(s3[0])*60+parseInt(s3[1]);
//console.log("minutoIniziale="+minutoIniziale+"minutoFinale="+minutoFinale+", s1="+s1+",s2="+s2+",s3="+s3+",mi="+mi+",mf="+mf);
                for (let i=mi; i<=mf; i++) postiMinuti[i]=parseInt(s1[2]);
            }
            for (let i=minutoIniziale; i<minutoFinale; i++) {
                if (p<0 || postiMinuti[i]<p) {
                  p=postiMinuti[i];
                }
              }
            setNumeroPostiNelIntervallo(p);

            //verifico eventuali sovrapposizioni con prenotazioni della stessa persona nella stessa giornata: (escludendo la prenotazione in esame nel caso di modifica)

            let controlloSovrapposizione = 0;
            if(prenotati[giornoEvento]){
                //console.log("Ci sono prenotazioni nel giorno!");
                for (let id=0; id<prenotati[giornoEvento].length; id++) {
                    s1 = prenotati[giornoEvento][id].split("-");
                    s2 = s1[0].split(":");
                    s3 = s1[1].split(":");
                    idPren = s1[3].split(":");
                    mi = parseInt(s2[0])*60+parseInt(s2[1]);
                    mf = parseInt(s3[0])*60+parseInt(s3[1]);

                    if(parseInt(idPren)!==parseInt(idPrenotazione)){
                        if((mi<minutoIniziale && minutoIniziale<mf) 
                        || (mi<minutoFinale && minutoFinale<mf) 
                        || (mi<=minutoIniziale && minutoFinale<=mf) 
                        || (mi>=minutoIniziale && minutoFinale>=mf)){
                            controlloSovrapposizione = 1;
                        }
                    }
                }
            }
            if(controlloSovrapposizione === 1) setErroreSovrapposizioneEvento(1);
            else setErroreSovrapposizioneEvento(0);

            setErroreCongruitaDate(0);
        } else {
            setErroreCongruitaDate(1);
            setErroreSovrapposizioneEvento(0);
            setNumeroPostiNelIntervallo(0);
        } 
       
    }

    //funzioni di ritorno del componente TendinaOrariAStep:
    function callbacksetOrarioSelezionatoInizio(valore){
        setOrarioInizio(giornoEvento+"T"+valore);
    }
    function callbacksetOrarioSelezionatoFine(valore){
        setOrarioFine(giornoEvento+"T"+valore);
    }

    function cambiamentoGiorno(event) {
        setGiornoEvento(giorno.current.value);
        setOrarioInizio(giorno.current.value+"T"+orarioInizio.substring(11,16));
        setOrarioFine(giorno.current.value+"T"+orarioFine.substring(11,16));
        setVariazioneCampiOrari(1);
    }

    function chiudiModaleConfermaCanc(){
        setModaleConfermaCanc(false);
        setFlagAttivaModaleCancellazione(false);
    }

    function apriModaleConfermaCanc(){
        setModaleConfermaCanc(true);
        setFlagAttivaModaleCancellazione(true);
    }
    function regolaZoom (direzione){
        //direzione === 1 -> aumenta zoom
        //direzione === 0 -> diminuisci zoom
        //cinque step di zoom a seconda del valore della chiave:
        //0 - ora
        //1 - mezzora
        //2 - 15 minuti
        //3 - 5 minuti
        //4 - 1 minuti
        if(direzione === 1 && chiaveZoom<4) setChiaveZoom(parseInt(chiaveZoom)+1);
        if(direzione === 0 && chiaveZoom>1) setChiaveZoom(parseInt(chiaveZoom)-1);
    } 
    function callbackTermineEscursioneInReport(){
        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    }

    return(
        <Fragment>
      
        <div className="app-main">
            {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
            {statoPagina === STATO_ERRORE_CONNESSIONE ? <div className="app-main__outer">Errore: 1PrenCor {error.message}</div> :""}
            {statoPagina === STATO_OPERAZIONE_FALLITA ? <div className="app-main__outer">Errore per operazione errata</div> :""}

            {statoPagina === STATO_VAI_A_REPORT_PRENOTAZIONI ? 
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                <Suspense fallback={
                <div className="loader-container">
                <div className="loader-container-inner"><h6 className="mt-5">Caricamento report prenotazioni in corso...</h6></div>
                </div>
            }>
            <ReportPrenotazioniCorsi 
            {...props} 
            callbackTermineEscursioneInReport={callbackTermineEscursioneInReport} 
            provvenienza={"tabellaPrenotazioniCorsi"} ></ReportPrenotazioniCorsi>
            {/*<FormGridFormRowAbb 
                {...props}
                ope={"inserisci"} 
                callbackTermineModifica={callbackTermineModificaQuote} 
                sessionId={props.sessionId} 
                idIscritto={props.id} 
                cognome={cognomeDefault} 
                nome={nomeDefault} 
                tessera={tesseraNumeroDefault}>
        </FormGridFormRowAbb>*/}
            </Suspense>
            </div>
            :""}

            {statoPagina === STATO_SCEGLI_ISCRITTO ?
                <div className="app-main__outer">
                <Clessidra loading={loading}/>
                <Card className="main-card mb-3"> 
                            <CardBody>
                                 {/*<Label>vale{insPersonaEdi}</Label>*/} 
                                <Col md={12}>    
                                <CardTitle>Iscritto</CardTitle>       
                                <TendinaEditabile
                                    //titolo={"Iscritto"}
                                    nomeCampo="COGNOME"
                                    nomeCampo2="NOME"
                                    nomeCampoParentesi="NUMERO_TESSERA"
                                    nomeCampoNonVisibile="ID"
                                    bloccoInserimento = "1"
                                    valoreDefault={insPersonaEdi}
                                    arrayValori={risultatoServerDatiPersona}
                                    callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                    callbackSetValoreTendEditabile={callbackSetValoreTendEditabilePersona}
                                ></TendinaEditabile>
                                </Col>      
                            </CardBody>
                        </Card>
                </div>
            :""}
                                        
            {statoPagina === STATO_RICERCA_DATI_CORRETTA  || statoPagina === STATO_RICERCA_NUOVI_DATI ?

            <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>

                <div> 
                <Clessidra loading={loading}/>

                {modaleInfoTendina===true ?
                    <Modale 
                        titolo="Informativa dati in tendina Prestazione / Attrezzatura Prenotabili"
                        flagErrore={false}
                        contenuto={<div>La tendina viene popolata con tutte le Prestazioni / Attrezzature Prenotabili che siano svincolate dal possesso di un abbonamento oppure che siano vincolate ad abbonamenti attivi.</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);setModaleInfoTendina(false)}
                                }    
                            ]}
                        />
                :""}


                {flagAttivaModalePeriodoDisdettaNonRispettatoInserimentoFallito=== 1 ?
                    <Modale 
                            titolo="Informativa inserimento bloccato"
                            flagErrore={true}
                            contenuto={<div>La prenotazione in esame rientra nel periodo nel quale non è più possibile annullarla, pertanto non è permesso neppure l'inserimento.<br></br><br></br>
                                Il tempo di annullamento delle prenotazioni è modificabile da menu principale, Impostazioni, Società e Layout Tessere
                            </div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda(1)}
                                }    
                            ]}
                        />
                :""}

                {flagAttivaModalePeriodoDisdettaNonRispettatoModificaFallita1=== 1 ?
                    <Modale 
                            titolo="Informativa modifica bloccata"
                            flagErrore={true}
                            contenuto={<div>La prenotazione in esame rientra nel periodo nel quale non è più possibile annullarla, pertanto non è permessa neppure la modifica.<br></br><br></br>
                            Il tempo di annullamento delle prenotazioni è modificabile da menu principale, Impostazioni, Società e Layout Tessere</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda(1)}
                                }    
                            ]}
                        />
                :""}

                {flagAttivaModalePeriodoDisdettaNonRispettatoModificaFallita2=== 1 ?
                    <Modale 
                        titolo="Informativa modifica bloccata"
                        flagErrore={true}
                        contenuto={<div>Modificando la prenotazione in esame con i dati inseriti, questa rientrerebbe nel periodo nel quale non sarebbe più possibile annullarla, non è permessa una modifica di questo tipo.<br></br><br></br>
                        Il tempo di annullamento delle prenotazioni è modificabile da menu principale, Impostazioni, Società e Layout Tessere</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda(1)}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModalePeriodoDisdettaNonRispettatoCancellazioneFallita=== 1 ?
                    <Modale 
                    titolo="Informativa cancellazione bloccata"
                    flagErrore={true}
                    contenuto={<div>La prenotazione in esame rientra nel periodo nel quale non è più possibile annullarla.<br></br><br></br>
                    Il tempo di annullamento delle prenotazioni è modificabile da menu principale, Impostazioni, Società e Layout Tessere</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setDisabilitaBottoni(false);tornaScheda(1)}
                        }    
                    ]}
                />
                :""}
                                             
                            <div>
                                {risultatoServerDatiCorso.length=== 0 ?
                                <Modale 
                                    titolo="Prestazioni / Attrezzature Prenotabili non presenti"
                                    flagErrore={true}
                                    contenuto={<div>E' necessario popolare l'elenco di Prestazioni / Attrezzature Prenotabili recandosi nel menù principale sezione Anagrafiche, Prestazioni / Attrezzature Prenotabili</div>}
                                    bottoni={[
                                        {
                                            "etichetta":"OK",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(false);setStatoPagina(STATO_SCEGLI_ISCRITTO)}
                                        }    
                                    ]}
                                />
                                :""}
                              
                                <CardTitle>Prenotazioni di {insPersonaEdi} <Button color="primary" onClick={() => setStatoPagina(STATO_SCEGLI_ISCRITTO)} >Seleziona un altro Iscritto</Button></CardTitle>
                                
                                {Number(risultatoServerInsolvenza[1].IMPORTO) < 0 ? 
                                    <div><Label><b>L'iscritto selezionato risulta insolvente per le quote abbonamento con debito di {Math.abs(Number(risultatoServerInsolvenza[1].IMPORTO))} euro</b></Label></div>  
                                :""}

                                <div style={{display:"flex", flexDirection:"row"}}>  
                                <div style={{flex:5}}>
                                <Label>Prestazione / Attrezzatura Prenotabili attualmente selezionata:<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoTendina(true)}/></Label>    
                                <TendinaEditabile
                                    //titolo={"Corso"}
                                    nomeCampo="NOME"
                                    nomeCampoNonVisibile="ID"
                                    bloccoInserimento = "1"
                             
                                    valoreDefault={insCorsoEdi}
                                    arrayValori={risultatoServerDatiCorso}
                                    callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                    callbackSetValoreTendEditabile={callbackSetValoreTendEditabileCorso}
                                ></TendinaEditabile>

                                

                                </div>
                                {insIdCorsoEdi === '' ?<div style={{flex:4,marginLeft: 7}}><Label>Per poter visualizzare le disponibilità ed eseguire operazioni (inserimento, modifica o eliminazione) sulle prenotazioni è necessario scegliere preventivamente una singola prestazione / attrezzatura tramite tendina</Label></div>
                                :<div style={{flex:4}}><Label></Label></div>}
                                
                                <div style={{marginLeft: 7}}>
                                <Button color="primary" className="mt-3" onClick={() => {regolaZoom(1)}}>Zoom +</Button>
                                </div>
                                <div style={{marginLeft: 7}}>
                                <Button color="primary" className="mt-3" onClick={() => {regolaZoom(0)}}>Zoom -</Button>
                                </div>
                                <div style={{marginLeft: 7}}>
                                <Button color="success" className="mt-3" onClick={() => setStatoPagina(STATO_VAI_A_REPORT_PRENOTAZIONI)}>Report Prenotazioni</Button>
                                </div>

                                </div>
                                
                                {Number(risultatoServerInsolvenza[1].IMPORTO) < 0 ? (
                                    <Row form><Col md={12}>
                                    <div>
                                        {insIdCorsoEdi === '' ? "" 
                                            :(
                                            classeAbbonamento == ''? (
                                                <div>
                                                    <Label><b>Prestazione / Attrezzatura Prenotabile svincolata dal possesso di un abbonamento e prenotabile anche da chi risulta insolvente per le quote abbonamento</b></Label>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Label><b>Prestazione / Attrezzatura Prenotabile vincolata al possesso di un abbonamento valido della classe {classeAbbonamento}, NON prenotabile da chi risulta insolvente per le quote abbonamento</b></Label>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    </Col>
                                    </Row>
                                ) : (
                                    ""
                                )}


                                <Row form><Col md={12}><Label>La visione delle disponibilità e la possibilità di eseguire operazioni (inserimento, modifica o eliminazione) sulle prenotazioni sono riservate alle viste 'Settimana' e 'Giorno'</Label></Col></Row>
                                    
                                <Row form>
                                    <Col md={12}>
                                        <FullCalendar
                                           
                                           customButtons={{
                                            custom1: {
                                              text: 'Oggi',
                                              click: function() {
                                                vaiDataOggi();
                                              }
                                            },
                                            custom2: {
                                                text: 'Attendere...',
                                            },
                                            custom3: {
                                                text: 'Giorno Selezionato',
                                                click: function() {
                                                  vaiDataSelezionata();
                                                }
                                              },
                                          }}
                                            slotDuration={zoom[chiaveZoom]}//The slotDuration changes the grid display to 30 minutes. 
                                            snapDuration={zoom[chiaveZoom]}//
                                            //slotDuration={'00:01:00'}//The slotDuration changes the grid display to 30 minutes. 
                                            //snapDuration={"00:01:00"}//The snapDuration is more interesting: it changes the start and end times in intervals of 5 minutes while you're dragging the event. 
                                            ref ={calendarRef}
                                            height={props.dimensioniApp.yMain}//determina l'altezza della pagine
                                            initialDate={dataSelezionata}
                                            //firstDay={3}//se metti 0 parte da domenica, 1 da lunedì ecc.. nella vista week
                                           // weekNumbers={true}//ti indica nel giorno il numero della settimana
                                           scrollTimeReset={false}//serve per evitare che vada alle 6:00:00 ad ogni click
             

                                            plugins={[ dayGridPlugin, interactionPlugin ,timeGridPlugin ]}
                                            validRange= {{
                                                start: dataInizioRange,
                                                end: dataFineRange,
                                            }}//escursione massima permessa di visibilità del calendario: da oggi a 10 anni
                                            
                                            //https://fullcalendar.io/docs/datesSet dice:
                                            //datesSet is called after the new date range has been rendered. 
                                            //However, you should avoid relying on this callback to manipulate rendered dates, 
                                            //because some dates might still be in view from the previous render, 
                                            //and you don’t want to “double render” them. 
                                            //It’s much better to rely on hooks that manipulate the rendering of individual dates when they change. 
                                            //See the day header, day cell, or slot render hooks.

                                            //https://fullcalendar.io/docs/datesSet
                                            datesSet={ (data => {
                                                //console.log("dates set-> data selezionata "+dataSelezionata);
                                                //settaDataPartenza();
                                                //settaDataGiorno(data.start);
                                                if(data.startStr>dataSelezionata || dataSelezionata>data.endStr) setDataSelezionata();//serve per eliminare la 
                                                //selezione della data selezionata se passo in un range nel quale la data non è più compresa

                                                evidenziaDataSelezionata();//quando scommento questo -> funge l'evidenziare la selezione ma non funza più  eventClick
                                                verificaPeriodoVisualizzazione(data.endStr);})
                                                
                                            }

                                        
                                            allDaySlot={false} //togli la cella iniziale che individua tutto il giorno

                                            //disegno la toolbar:
                                            headerToolbar={{
                                              start: 'title', // will normally be on the left. if RTL, will be on the right
                                              center: 'dayGridMonth,timeGridWeek,timeGridDay',//nel caso di mese non fà vedere gli eventi in background
                                              //center: 'today,timeGridWeek,timeGridDay',
                                              //end: 'custom1,prev,next', // will normally be on the right. if RTL, will be on the left
                                              end: bottoniNavigazione, // will normally be on the right. if RTL, will be on the left
                                            }}
                                            //a seconda di che vista ho scelto tramite bottoni la propongo:
                                            initialView={vista}//'timeGridWeek'//ti parte facendoti vedere la settimana e le ore a sx se metti ad es 'timeGridWeek'
                                            locale={itLocale} //serve per lingua italiana
                                            editable={false}//permette di trascinare l'evento se true (ma poi ci sarebbe il problema della memorizzazione degli orari variati)
                                            eventOverlap={true} //true-> eventi sovrapponibili, false -> eventi non sovrapponibili
                                            selectable={false}//rende il giorno selezionabile
                                            dragScroll={true}//rende l'evento trascinabile
                                            eventMinHeight="15"//altezza minima di ogni evento (default = 15)
                                            weekends={true}//inserisce anche i weekends nel calendario
                                            displayEventEnd={true}//fà vedere l'ora di fine evento
                                            events={eventiDisponibilita.concat(prenotatiFullcalendar)}//inserisco i dati degli eventi attualmente già presenti (rangeValidita.concat(prenotati))
                                            
                                            eventClick={modificaEvento}//gestisce il click sull'evento (per modifica ad esempio)
                                            //dateClick={settaDataPartenza}
                                            selectOverlap={true}//di default è cmq true
                                            //selectMinDistance="1" // seguendo quanto detto in https://github.com/fullcalendar/fullcalendar/issues/4590 ma non funza
                                            //unselectAuto={true}

                                            //eventClick={modificaEvento}//gestisce il click sull'evento (per modifica ad esempio)
                                            
                                            //eventClick={ (data => {modificaEvento(data);})}

                                            //dayClick={evidenziaDataSelezionata()}
                                            //dayClick={ (data => {
                                            //    controllaClickSuEventoBackground(jsEvent);})
                                            //}
                                            //slotLabelFormat={[
                                            //    {
                                            //        hour: '2-digit',
                                            //        minute: '2-digit',
                                            //        hour12:false
                                            //    }
                                            //    ]}

                                            slotLabelFormat={{
                                                    hour: 'numeric',
                                                    minute: '2-digit',
                                                    omitZeroMinute: false,
                                                    meridiem: 'short'
                                            }}
                                           
                                            dateClick={settaDataPartenza}
                                        />

                                    {flagAttivaModaleInserisciModifica === true && orarioInizio.substring(11,16)!==undefined && orarioFine.substring(11,16)!==undefined?
                                        <Modale 
                                            titolo=
                                            {operazione === "modifica"? "Modifica Prenotazione Prestazione / Attrezzatura" :
                                             "Inserisci Prenotazione Prestazione / Attrezzatura"}
                                            flagErrore={false}
                                            contenuto={<div>
                                            Prestazione / Attrezzatura : <b>{insCorsoEdi}</b><br/>
                                            {erroreCongruitaDate === 1 ? <b><br/>La data di fine prenotazione risulta antecedente o sovrapposta rispetto alla data di inizio prenotazione</b>  :""}
                                            {erroreSovrapposizioneEvento === 1 ? <b><br/>La prenotazione và a sovrapporsi con una prenotazione già esistente</b>  :""}
                                        
                                            {(erroreCongruitaDate === 1 || erroreSovrapposizioneEvento === 1)?
                                                <b><br/>Correggere la prenotazione per poter procedere con la modifica<br/></b>
                                            :""}

                                            <br/><FormGroup>Giorno prenotazione:
                                            <Input maxLength={10} type="date" name="giorno" id="giorno"
                                            innerRef={giorno} placeholder="" defaultValue={giornoEvento} onInput={(e)=>{cambiamentoGiorno(e);}}/></FormGroup> 
                              
                                            <Row><Col><FormGroup>Posti disponibili: {numeroPostiNelIntervallo}</FormGroup></Col></Row>
                                      
                                            <Row><Col>Orario Inizio&nbsp;

                                                {orarioInizio && orarioInizio!=="" && orarioInizio.substring(11,16)!==undefined  ?
                                                <TendinaOrariAStep
                                                    step = {insDurataMinimaCorsoEdi} //step espresso in minuti tra un orario ed il successivo
                                                    orarioDefault= {orarioInizio.substring(11,16)}
                                                    orarioInizio = {orarioInizioDispOdierna.toString().substring(11, 16)}
                                                    orarioFine= {orarioFineDispOdierna.toString().substring(11, 16)}
                                                    callbackSetVariazioneCampi={callbackSetVariazioneCampiOrari}
                                                    callbacksetOrarioSelezionato={callbacksetOrarioSelezionatoInizio} 
                                                ></TendinaOrariAStep>
                                                :""}
                                   
                                            </Col><Col>Orario Fine&nbsp;
                                                {orarioFine && orarioFine!=="" && orarioFine.substring(11,16)!==undefined ?
                                                <TendinaOrariAStep
                                                    step = {insDurataMinimaCorsoEdi} //step espresso in minuti tra un orario ed il successivo
                                                    orarioDefault= {orarioFine.substring(11,16)}
                                                    orarioInizio = {orarioInizioDispOdierna.toString().substring(11, 16)}
                                                    orarioFine= {orarioFineDispOdierna.toString().substring(11, 16)}
                                                    callbackSetVariazioneCampi={callbackSetVariazioneCampiOrari}
                                                    callbacksetOrarioSelezionato={callbacksetOrarioSelezionatoFine} 
                                                ></TendinaOrariAStep>
                                                :""}
                                            </Col></Row>

                                            </div>}
                                                bottoni={
                                                    operazione === "modifica" ?
                                                        variazioneCampiOrari === 1
                                                        && erroreSovrapposizioneEvento === 0
                                                        && erroreCongruitaDate === 0 
                                                        && numeroPostiNelIntervallo > 0
                                                        ? 
                                                        [
                                                            {
                                                                "etichetta":"Memorizza",
                                                                "tipo":"success",
                                                                callback:() => {setDisabilitaBottoni(false);fetchOperazioneServer("prenotazioneCorsiMod")}
                                                            },
                                                            {
                                                                "etichetta":"Torna al calendario",
                                                                "tipo":"primary",
                                                                callback:() => {setDisabilitaBottoni(false);tornaVisioneCalendario()}
                                                            },
                                                            {
                                                            "etichetta":"Elimina Prenotazione",
                                                            "tipo":"danger",
                                                            callback:() => {setDisabilitaBottoni(false);apriModaleConfermaCanc()}
                                                            }  
                                                        ]
                                                        :
                                                        [
                                                        {
                                                            "etichetta":"Torna al calendario",
                                                            "tipo":"primary",
                                                            callback:() => {setDisabilitaBottoni(false);tornaVisioneCalendario()}
                                                        },
                                                        {
                                                        "etichetta":"Elimina Prenotazione",
                                                        "tipo":"danger",
                                                        callback:() => {setDisabilitaBottoni(false);apriModaleConfermaCanc()}
                                                        }  
                                                        ]
                                                :
                                                erroreSovrapposizioneEvento === 0 && erroreCongruitaDate === 0 && numeroPostiNelIntervallo > 0 ?
                                                    [
                                                    {
                                                        "etichetta":"Inserisci",
                                                        "tipo":"success",
                                                        callback:() => {setDisabilitaBottoni(false);fetchOperazioneServer("prenotazioneCorsiIns")}
                                                    },  
                                                    {
                                                        "etichetta":"Annulla",
                                                        "tipo":"primary",
                                                        callback:() => {setDisabilitaBottoni(false);tornaVisioneCalendario()}
                                                    }]
                                                    :
                                                    [{
                                                    "etichetta":"Annulla",
                                                    "tipo":"primary",
                                                    callback:() => {setDisabilitaBottoni(false);tornaVisioneCalendario()}
                                                    }]
                                                }
                                            />
                                        :""}

                                        {flagAttivaModaleCancellazione === true ?
                                            <Modale 
                                                titolo="Conferma Cancellazione"
                                                flagErrore={true}
                                                contenuto={<div>Vuoi davvero cancellare la prenotazione selezionata?</div>}
                                                bottoni={[
                                                    {
                                                        "etichetta":"Ok",
                                                        "tipo":"primary",
                                                        callback:() => {setFlagAttivaModaleInserisciModifica(false);fetchOperazioneServer("prenotazioneCorsiCanc")}
                                                    },
                                                    {
                                                        "etichetta":"Annulla",
                                                        "tipo":"primary",
                                                        callback:() => {chiudiModaleConfermaCanc()}
                                                    }    
                                                ]}
                                            />
                                        :""}
                                        </Col>
                                    </Row> 
                                               
                            </div>
                        </div>
                {/*<AppFooter/>*/}
            </div>
            :""}
        </div>
    </Fragment>
    );
};

export default TablesPrenotazioneCorsi2;