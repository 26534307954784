/* VisualizzaAllegato.js 1.1 */

import React, {useState} from 'react';
import { Button } from 'reactstrap';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'

/*
Nota: il bottone Scarica deve essere disabilitato in caso di modifica del contenuto, perché in caso di PDF il file
appena caricato non è in forma di URI (scaricabile) ma è ancora un oggetto (che poi viene mandato aggiungendolo con
formData.append() al body della fetch al backend, che lo riceve nella $_FILES e non nella $_POST), quindi provando
a scaricarlo non funzionerebbe. Per le immagini invece funzionerebbe.
Il flag che memorizza se il contenuto è stato modificato e quindi disabilita il bottone deve stare nel js che usa
il componente e non qui nel componente, perché in seguito alla modifica del contenuto c'è un nuovo rendering e il 
flag tornerebbe sempre allo stato iniziale, se stesse qui nel componente.
*/

function VisualizzaAllegato(props) {
    const [chiave, setChiave] = useState(0); // chiave autoincrementante ogni volta che preme Elimina, per forzare rendering del componente input e vedere l'onChange dello stesso file passato due volte

    return (
        <>
        <div style={{position:"relative"}}> {/* div per gestire il click e far partire la richiesta di upload file, position:relative serve per questo */}
            <div className={props.cssContenitore}>
                {props.contenutoFile==="" ? "" :
                    props.tipoFile.substring(0, 5) === "image" ?
                        <img className={props.cssImmagine} src={props.contenutoFile} />
                    :  
                        props.tipoFile === "application/pdf" ?
                            <div className={props.cssPdf}>
                                <Document file={props.contenutoFile}>
                                    <Page scale={props.scalaPdf} pageNumber={1}/>
                                </Document>
                            </div>
                        :
                            <div className={props.cssTestoEstensione}>
                                {props.estensioneFile}
                            </div>
                }                                            
            </div>
            <input type='file' className='bottone' key={chiave} accept={props.estensioniAccettate}
                onChange={(e) => { if (e.target.files[0].size>10000000) {alert("Seleziona un file che non sia più grande di 10MB"); return }; props.callbackAggiornaFile(e)}}/>
        </div>
        {props.contenutoFile!=='' ?
            <div style={{display:"flex", flexDirection: "row"}}>                                            
                <Button 
                    disabled={props.flagContenutoModificato} 
                    style={{flex:1, border: "1px solid grey", marginRight:1, marginTop:2}} 
                    onClick={() => { if (!props.flagContenutoModificato) props.callbackScarica()}}
                >
                    Scarica
                </Button>
                <Button 
                    style={{flex:1, border: "1px solid grey", marginLeft:1, marginTop:2}}
                    onClick={() => { setChiave(chiave+1); props.callbackElimina()}}
                >
                    Elimina
                </Button>
            </div>
        :
            <></>
        }
        </>
    )    
}

export default VisualizzaAllegato;