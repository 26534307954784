
import React, {Fragment,useState} from 'react';
import { useEffect } from 'react';

import {
    Table,Row,Col, Card, CardBody,
    CardTitle, Button, FormGroup, Label, 
    Modal,
} from 'reactstrap';
import Parametri from '../../parametri';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';

const STATO_SCEGLI_IMPORTAZIONE = 0;
const STATO_IMPOSTA_LAYOUT = 1;
const STATO_SELEZIONA_FILE = 2;

const STATO_MEMORIZZAZIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_ERRORE_CONNESSIONE = 5;
const STATO_PRESENTA_REPORT_FINALE = 6;




function ImportazioneMassiva(props) {

    const [statoPagina,setStatoPagina]=useState(STATO_SCEGLI_IMPORTAZIONE);

    //valori personalizzati
    const [risultatoServerLayoutPersonaPosizioni,setRisultatoServerLayoutPersonaPosizioni]=useState('');    
    const [risultatoServerLayoutPersonaDescrizioni,setRisultatoServerLayoutPersonaDescrizioni]=useState('');  
    const [risultatoServerLayoutPersonaTipi,setRisultatoServerLayoutPersonaTipi]=useState('');  
    const [risultatoServerLayoutPersonaMaxCaratteri,setRisultatoServerLayoutPersonaMaxCaratteri]=useState('');  
    const [risultatoServerLayoutPersonaObbligatorio,setRisultatoServerLayoutPersonaObbligatorio]=useState('');  
    const [risultatoServerLayoutPersonaUnivoco,setRisultatoServerLayoutPersonaUnivoco]=useState('');  
    //valori standard
    const [risultatoServerLayoutPersonaPosizioniSTD,setRisultatoServerLayoutPersonaPosizioniSTD]=useState('');    
    const [risultatoServerLayoutPersonaDescrizioniSTD,setRisultatoServerLayoutPersonaDescrizioniSTD]=useState('');  
    const [risultatoServerLayoutPersonaTipiSTD,setRisultatoServerLayoutPersonaTipiSTD]=useState('');  
    const [risultatoServerLayoutPersonaMaxCaratteriSTD,setRisultatoServerLayoutPersonaMaxCaratteriSTD]=useState('');  
    const [risultatoServerLayoutPersonaObbligatorioSTD,setRisultatoServerLayoutPersonaObbligatorioSTD]=useState('');  
    const [risultatoServerLayoutPersonaUnivocoSTD,setRisultatoServerLayoutPersonaUnivocoSTD]=useState('');  
    const [risultatoServerLayoutPersonaColonneSTD,setRisultatoServerLayoutPersonaColonneSTD]=useState('');  

    const [risultatoServerCampiCustom,setRisultatoServerCampiCustom]=useState(''); 

    const [risultatoServerCampiObbligatori,setRisultatoServerCampiObbligatori]=useState('');

    const [risultatoServerLayoutPersonaColonne,setRisultatoServerLayoutPersonaColonne]=useState(''); 

    const [statoCaricamentoLayoutPersona,setStatoCaricamentoLayoutPersona]=useState(false);

    const [contenutoFile,setContenutoFile]=useState([]);

    const [tipologiaImportazioneDefault,setTipologiaImportazioneDefault]=useState('');

    const [verificaCampiObbligatori,setVerificaCampiObbligatori]=useState(0);
    const [verificaCampi,setVerificaCampi]=useState(0);
    const [campiObbligatoriMancanti,setCampiObbligatoriMancanti]=useState([]);
    const [campiMancanti,setCampiMancanti]=useState([]);
    
    const [visualizzaCampiMancanti,setVisualizzaCampiMancanti]=useState(0);
    const [visualizzaCampiObbligatoriMancanti,setVisualizzaCampiObbligatoriMancanti]=useState(0);

    const [procediResettaLayout,setProcediResettaLayout]=useState(-1);

    const [error,setError]=useState('');

    const [flagAttivaModaleMancanzaCampiObbligatori, setFlagAttivaModaleMancanzaCampiObbligatori] = useState(0);
    const [flagAttivaModaleColonneDoppie, setFlagAttivaModaleColonneDoppie] = useState(0);
    const [flagAttivaModaleColonneVuote, setFlagAttivaModaleColonneVuote] = useState(0);
    const [flagAttivaModaleMancanzaFile, setFlagAttivaModaleMancanzaFile] = useState(0);
    const [flagAttivaModaleE1, setFlagAttivaModaleE1] = useState(0);
    const [flagAttivaModaleE2, setFlagAttivaModaleE2] = useState(0);
    const [flagAttivaModaleE3, setFlagAttivaModaleE3] = useState(0);
    const [flagAttivaModaleE4, setFlagAttivaModaleE4] = useState(0);
    const [flagAttivaModaleE5, setFlagAttivaModaleE5] = useState(0);
    const [flagAttivaModaleE6, setFlagAttivaModaleE6] = useState(0);
    const [flagAttivaModaleE7, setFlagAttivaModaleE7] = useState(0);
    const [flagAttivaModaleE8, setFlagAttivaModaleE8] = useState(0);
    const [flagAttivaModaleE9, setFlagAttivaModaleE9] = useState(0);
    const [flagAttivaModaleE10, setFlagAttivaModaleE10] = useState(0);
    const [flagAttivaModaleE11, setFlagAttivaModaleE11] = useState(0);
    const [flagAttivaModaleE12, setFlagAttivaModaleE12] = useState(0);

    const [risultatoServerAnomalieCampo,setRisultatoServerAnomalieCampo]=useState([]);
    const [risultatoServerAnomalieValore,setRisultatoServerAnomalieValore]=useState([]);
    const [risultatoServerAnomalieRiga,setRisultatoServerAnomalieRiga]=useState([]);

    const [righeInserite,setRigheInserite]=useState(0);
    const [righeIgnorate,setRigheIgnorate]=useState(0);
    const [stringaRigheIgnorate,setStringaRigheIgnorate]=useState(0);

    //per visualizzare gli errori nei file:
    const righeErrate = [];
    const valoriErrati = [];

    const colonneDisponibili=["",
    "1","2","3","4","5","6","7","8","9","10",
    "11","12","13","14","15","16","17","18","19","20",
    "21","22","23","24","25","26","27","28","29","30",
    "31","32","33","34","35","36","37","38","39","40",
    "41","42","43","44","45","46","47","48","49","50",
    "51","52","53","54","55","56","57","58","59","60",
    "61","62","63","64","65","66","67","68","69","70",
    "71","72","73","74","75","76","77","78","79","80",
    "81","82","83","84","85","86","87","88","89","90",
    "91","92","93","94","95","96","97","98","99","100"];

    const [loading,setLoading]= useState(false);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(true);
    
    useEffect(() => {
        //selezionato il tipo di importazione, vado alla ricerca del layout default relativo alla persona in esame:
        if(tipologiaImportazioneDefault !== '') ricercaLayoutPersona(); 
    },[tipologiaImportazioneDefault]);

    useEffect(() => {
        //procedo al reset della bozza: 0-> svuota tutto, 1-> torna al layout standard
        if(procediResettaLayout === 1 || procediResettaLayout === 0) resettaLayout(procediResettaLayout); 
    },[procediResettaLayout]);

    useEffect(() => {
        //selezionato il tipo di importazione, vado alla ricerca del layout default relativo alla persona in esame:
        if(verificaCampiObbligatori === 1){
            funzioneVerificaCampiObbligatori();
            setVerificaCampiObbligatori(0);
        }  
    },[verificaCampiObbligatori]);

    useEffect(() => {
        //selezionato il tipo di importazione, vado alla ricerca del layout default relativo alla persona in esame:
        if(verificaCampi === 1){
            funzioneVerificaCampi();
            setVerificaCampi(0);
        }  
    },[verificaCampi]);
    
    useEffect(() => {
        //selezionato il tipo di importazione, vado alla ricerca del layout default relativo alla persona in esame quindi lo presento come default a video:
        if(statoCaricamentoLayoutPersona === true){
            setStatoPagina(STATO_IMPOSTA_LAYOUT);
        }
    },[statoCaricamentoLayoutPersona]);

    //per il controllo delle anomalie nei file:
    useEffect(() => {
        for (let i = 0; i < risultatoServerAnomalieRiga.length; i++) {
            righeErrate.push(<li key={i}>{risultatoServerAnomalieRiga[i]}</li>);
        }
    },[risultatoServerAnomalieRiga]);  

    useEffect(() => {
        for (let i = 0; i < risultatoServerAnomalieValore.length; i++) {
            valoriErrati.push(<li key={i}>{risultatoServerAnomalieValore[i]}</li>);
        }
    },[risultatoServerAnomalieValore]);  


    function tornaScheda(valore) {

        setFlagAttivaModaleMancanzaCampiObbligatori(0);
        setFlagAttivaModaleColonneDoppie(0);
        setFlagAttivaModaleColonneVuote(0);
        setFlagAttivaModaleMancanzaFile(0);
        setFlagAttivaModaleE1(0);
        setFlagAttivaModaleE2(0);
        setFlagAttivaModaleE3(0);
        setFlagAttivaModaleE4(0);
        setFlagAttivaModaleE5(0);
        setFlagAttivaModaleE6(0);
        setFlagAttivaModaleE7(0);
        setFlagAttivaModaleE8(0);
        setFlagAttivaModaleE9(0);
        setFlagAttivaModaleE10(0);
        setFlagAttivaModaleE11(0);
        setFlagAttivaModaleE12(0);
    }
   
    function chiudiModaleMancanzaFile() {
        setFlagAttivaModaleMancanzaFile(0);
    }
    function chiudiModaleE1() {
        setFlagAttivaModaleE1(0);
    }
    function chiudiModaleE2() {
        setFlagAttivaModaleE2(0);
    }
    function chiudiModaleE3() {
        setFlagAttivaModaleE3(0);
    }
    function chiudiModaleE4() {
        setFlagAttivaModaleE4(0);
    }
    function chiudiModaleE5() {
        setFlagAttivaModaleE5(0);
    }
    function chiudiModaleE6() {
        setFlagAttivaModaleE6(0);
    }
    function chiudiModaleE7() {
        setFlagAttivaModaleE7(0);
    }
    function chiudiModaleE8() {
        setFlagAttivaModaleE8(0);
    }
    function chiudiModaleE9() {
        setFlagAttivaModaleE9(0);
    }
    function chiudiModaleE10() {
        setFlagAttivaModaleE10(0);
    }
    function chiudiModaleE11() {
        setFlagAttivaModaleE11(0);
    }
    function chiudiModaleE12() {
        setFlagAttivaModaleE12(0);
    }

    function resettaLayout(tipoReset){
        //se tipoReset === 0 -> elimino tutta la bozza di layout fatta fino ad ora svuotando tutto
        //se tipoReset === 1 -> adotto come layout quello standard
        if(tipoReset === 0){
            setRisultatoServerLayoutPersonaDescrizioni([]);
            setRisultatoServerLayoutPersonaPosizioni([]);
            setRisultatoServerLayoutPersonaTipi([]);
            setRisultatoServerLayoutPersonaMaxCaratteri([]);
            setRisultatoServerLayoutPersonaObbligatorio([]);
            setRisultatoServerLayoutPersonaUnivoco([]);
            setRisultatoServerLayoutPersonaColonne([]);
        }
        if(tipoReset === 1){
            setRisultatoServerLayoutPersonaDescrizioni([...risultatoServerLayoutPersonaDescrizioniSTD]);
            setRisultatoServerLayoutPersonaPosizioni([...risultatoServerLayoutPersonaPosizioniSTD]);
            setRisultatoServerLayoutPersonaTipi([...risultatoServerLayoutPersonaTipiSTD]);
            setRisultatoServerLayoutPersonaMaxCaratteri([...risultatoServerLayoutPersonaMaxCaratteriSTD]);
            setRisultatoServerLayoutPersonaObbligatorio([...risultatoServerLayoutPersonaObbligatorioSTD]);
            setRisultatoServerLayoutPersonaUnivoco([...risultatoServerLayoutPersonaUnivocoSTD]);
            setRisultatoServerLayoutPersonaColonne([...risultatoServerLayoutPersonaColonneSTD]);
        }

        //in ogni caso faccio il controllo dei campi (che ci siano tutti o meno) e deiìlla presenza dei campi obbligatori:
        setCampiObbligatoriMancanti([]);
        setVerificaCampiObbligatori(1);//vado con la verifica in modo da vedere se i campi selezionati coprono tutti gli obbligatori

        setCampiMancanti([]);
        setVerificaCampi(1);//vado con la verifica in modo da vedere se i campi selezioanti coprono tutti STD
        setProcediResettaLayout(-1);
        setStatoPagina(STATO_IMPOSTA_LAYOUT);
    }

    function funzioneVerificaCampiObbligatori(){
        //noti i campi obbligatori, vado a controllare che quelli selezionati li comprendano tutti:
        let trovato = 0;
        let dati1 = [];
        let pos = 0;
        //console.log("num campi obbl"+risultatoServerCampiObbligatori.length)
        for (let i=0; i<risultatoServerCampiObbligatori.length ; i++) {
            trovato = 0;
            //console.log("vedo"+risultatoServerCampiObbligatori[i]);
            for (let j=0; j<risultatoServerLayoutPersonaPosizioni.length ; j++) {
                //console.log("verifico con "+risultatoServerLayoutPersonaPosizioni[j]);
                if(parseInt(risultatoServerCampiObbligatori[i]) === parseInt(risultatoServerLayoutPersonaPosizioni[j])){
                    //console.log("trovato"+risultatoServerCampiObbligatori[i]);
                    trovato = 1;
                    break;
                } 
            }
            if(trovato === 0){
                //aggiungo tra i campi obbligatori che non sono stati messi nel layout:
                //console.log("metto"+risultatoServerCampiObbligatori[i]+"in pos"+pos)
                dati1.splice(pos,1,parseInt(risultatoServerCampiObbligatori[i]));//modifico l'elemento desiderato
                pos = pos +1;
            }
        }
        setCampiObbligatoriMancanti(dati1);//aggiorno nuovamente la var di stato
    }
    

    function funzioneVerificaCampi(){
        //noti i campi STD, vado a controllare quali sono quelli che non sono stati selezionati dal layout personalizzato 
        //(per agevolare il compito di spostarli ma non dimenticarsi qulche campo):

        let trovato = 0;
        let dati1 = [];
        let pos = 0;
        //console.log("num campi obbl"+risultatoServerCampiObbligatori.length)
        for (let i=0; i<risultatoServerLayoutPersonaPosizioniSTD.length ; i++) {
            trovato = 0;
            for (let j=0; j<risultatoServerLayoutPersonaPosizioni.length ; j++) {
                if(parseInt(risultatoServerLayoutPersonaPosizioniSTD[i]) === parseInt(risultatoServerLayoutPersonaPosizioni[j])){
                    trovato = 1;
                    break;
                } 
            }
            if(trovato === 0){
                //aggiungo tra i campi che non sono stati messi nel layout:
                dati1.splice(pos,1,parseInt(risultatoServerLayoutPersonaPosizioniSTD[i]));//modifico l'elemento desiderato
                pos = pos +1;
            }
        }
        setCampiMancanti(dati1);//aggiorno nuovamente la var di stato
    }
    function aggiornaFile(valore){
        //console.log("lungo"+valore.size);
        //console.log("nome"+valore.name);
        //console.log("dai"+readAsText(valore));
        //let dati1 = [...valore];
        //setContenutoFile(dati1); 

        //let data ='';
        //data.append('file', valore);
        setContenutoFile(valore);
    }
    function aggiornaColonne(colonna,posizione){
        let datiColonne = [...risultatoServerLayoutPersonaColonne];//popolo l'array locale con i dati dell'array di stato
    //console.log("modifico la colonna"+colonna+"in pos"+posizione)
        datiColonne.splice(posizione,1,colonna);//modifico l'elemento desiderato
        setRisultatoServerLayoutPersonaColonne(datiColonne);//aggiorno nuovamente la var di stato

        let dati2 = [...risultatoServerLayoutPersonaPosizioni];//popolo l'array locale con i dati dell'array di stato
        dati2.splice(posizione,1,posizione);//modifico l'elemento desiderato
        setRisultatoServerLayoutPersonaPosizioni(dati2);//aggiorno nuovamente la var di stato
    }
    
    function aggiornaCampi(valore,posizione){
      
        if(valore === ''){
            //devo cancellate i dati relativi alla posizione in esame senza mettervi nulla al suo posto ma aggiungendo poi un posto vuoto in fondo:
            let dati1 = [...risultatoServerLayoutPersonaDescrizioni];//popolo l'array locale con i dati dell'array di stato
            dati1.splice(posizione,1);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaDescrizioni(dati1);//aggiorno nuovamente la var di stato
           
            let dati2 = [...risultatoServerLayoutPersonaPosizioni];//popolo l'array locale con i dati dell'array di stato
            dati2.splice(posizione,1);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaPosizioni(dati2);//aggiorno nuovamente la var di stato

            let dati3 = [...risultatoServerLayoutPersonaTipi];//popolo l'array locale con i dati dell'array di stato
            dati3.splice(posizione,1);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaTipi(dati3);//aggiorno nuovamente la var di stato

            let dati4 = [...risultatoServerLayoutPersonaMaxCaratteri];//popolo l'array locale con i dati dell'array di stato
            dati4.splice(posizione,1);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaMaxCaratteri(dati4);//aggiorno nuovamente la var di stato

            let dati5 = [...risultatoServerLayoutPersonaObbligatorio];//popolo l'array locale con i dati dell'array di stato
            dati5.splice(posizione,1);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaObbligatorio(dati5);//aggiorno nuovamente la var di stato

            let dati6 = [...risultatoServerLayoutPersonaUnivoco];//popolo l'array locale con i dati dell'array di stato
            dati6.splice(posizione,1);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaUnivoco(dati6);//aggiorno nuovamente la var di stato

            let dati7 = [...risultatoServerLayoutPersonaColonne];//popolo l'array locale con i dati dell'array di stato
            dati7.splice(posizione,1);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaColonne(dati7);//aggiorno nuovamente la var di stato
        }else{
            
            //sostituisco un campo con un'altro per cui devo aggiornare in tutti gli array nella stessa posizione:
            //aggiorno la descrizione:
            let dati1 = [...risultatoServerLayoutPersonaDescrizioni];//popolo l'array locale con i dati dell'array di stato
            dati1.splice(posizione,1,valore);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaDescrizioni(dati1);//aggiorno nuovamente la var di stato
        
            //e di conseguenza anche tutti gli altri array:
            let indice = '';
            for (let i=0; i<risultatoServerLayoutPersonaDescrizioniSTD.length ; i++) {
                if(risultatoServerLayoutPersonaDescrizioniSTD[i] === valore){
                    indice = i;
                    break;
                } 
            }
            //trovato l'indice vado ad aggiornare
            let dati2 = [...risultatoServerLayoutPersonaPosizioni];//popolo l'array locale con i dati dell'array di stato
            dati2.splice(posizione,1,indice);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaPosizioni(dati2);//aggiorno nuovamente la var di stato

            let dati3 = [...risultatoServerLayoutPersonaTipi];//popolo l'array locale con i dati dell'array di stato
            dati3.splice(posizione,1,risultatoServerLayoutPersonaTipiSTD[indice]);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaTipi(dati3);//aggiorno nuovamente la var di stato

            let dati4 = [...risultatoServerLayoutPersonaMaxCaratteri];//popolo l'array locale con i dati dell'array di stato
            dati4.splice(posizione,1,risultatoServerLayoutPersonaMaxCaratteriSTD[indice]);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaMaxCaratteri(dati4);//aggiorno nuovamente la var di stato

            let dati5 = [...risultatoServerLayoutPersonaObbligatorio];//popolo l'array locale con i dati dell'array di stato
            dati5.splice(posizione,1,risultatoServerLayoutPersonaObbligatorioSTD[indice]);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaObbligatorio(dati5);//aggiorno nuovamente la var di stato

            let dati6 = [...risultatoServerLayoutPersonaUnivoco];//popolo l'array locale con i dati dell'array di stato
            dati6.splice(posizione,1,risultatoServerLayoutPersonaUnivocoSTD[indice]);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaUnivoco(dati6);//aggiorno nuovamente la var di stato

            let dati7 = [...risultatoServerLayoutPersonaColonne];//popolo l'array locale con i dati dell'array di stato
            dati7.splice(posizione,1,indice);//modifico l'elemento desiderato
            setRisultatoServerLayoutPersonaColonne(dati7);//aggiorno nuovamente la var di stato
        }

        setCampiObbligatoriMancanti([]);
        setVerificaCampiObbligatori(1);//vado con la verifica in modo da vedere se i campi selezioanti coprono tutti gli obbligatori

        setCampiMancanti([]);
        setVerificaCampi(1);//vado con la verifica in modo da vedere se i campi selezioanti coprono tutti STD
   
    }


    function ricercaLayoutPersona() {
        //ricerco tra i layout della persona quelli relativi al tipo fdi importazione che vuole attuare:
        //console.log("w"+props.sessionId)
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"layoutImportatore",operazione:"cerca",tipo:tipologiaImportazioneDefault,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                  }
                  if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);

                  if(valoreDiRitorno.risultatoOperazione===1){
                    let arrLocale1=[];
                    let arrLocale2=[];
                    let arrLocale3=[];
                    let arrLocale4=[];
                    let arrLocale5=[];
                    let arrLocale5a=[];
                  
                    let arrLocale6=[];
                    let arrLocale7=[];

                    let arrLocale1STD=[];
                    let arrLocale2STD=[];
                    let arrLocale3STD=[];
                    let arrLocale4STD=[];
                    let arrLocale5STD=[];
                    let arrLocale5aSTD=[];
                    let arrLocale6aSTD=[];
                    
                    let arrLocale8=[];

                    //QUI METTO LE SCELTE CUSTOMIZZATE
                    for (let i=0; i<valoreDiRitorno.datiCampi.length ; i++) {
                        arrLocale1[i]=valoreDiRitorno.datiCampi[i].posizioneCampi;
                        setRisultatoServerLayoutPersonaPosizioni(arrLocale1);
                        arrLocale2[i]=valoreDiRitorno.datiCampi[i].descrizioneCampi;
                        setRisultatoServerLayoutPersonaDescrizioni(arrLocale2);
                        arrLocale3[i]=valoreDiRitorno.datiCampi[i].tipiCampi;
                        setRisultatoServerLayoutPersonaTipi(arrLocale3);
                        arrLocale4[i]=valoreDiRitorno.datiCampi[i].maxCaratteriCampi;
                        setRisultatoServerLayoutPersonaMaxCaratteri(arrLocale4);
                        arrLocale5[i]=valoreDiRitorno.datiCampi[i].obbligatorio;
                        setRisultatoServerLayoutPersonaObbligatorio(arrLocale5);
                        arrLocale5a[i]=valoreDiRitorno.datiCampi[i].univoco
                        setRisultatoServerLayoutPersonaUnivoco(arrLocale5a);
                        arrLocale8[i]=valoreDiRitorno.datiCampi[i].colonne;
                        setRisultatoServerLayoutPersonaColonne(arrLocale8);
                    } 

                    for (let i=0; i<valoreDiRitorno.campiObbligatori.length ; i++) {
                        arrLocale6[i]=valoreDiRitorno.campiObbligatori[i];
                        setRisultatoServerCampiObbligatori(arrLocale6);
                    }

                    for (let i=0; i<valoreDiRitorno.campiCustom.length ; i++) {
                        arrLocale7[i]=valoreDiRitorno.campiCustom[i];
                        setRisultatoServerCampiCustom(arrLocale7);
                    }
                   
                    //QUI METTO GLI STANDARD
                    for (let i=0; i<valoreDiRitorno.datiCampiSTD.length ; i++) {
                        arrLocale1STD[i]=valoreDiRitorno.datiCampiSTD[i].posizioneCampiSTD;
                        setRisultatoServerLayoutPersonaPosizioniSTD(arrLocale1STD);
                        arrLocale2STD[i]=valoreDiRitorno.datiCampiSTD[i].descrizioneCampiSTD;
                        setRisultatoServerLayoutPersonaDescrizioniSTD(arrLocale2STD);
                        arrLocale3STD[i]=valoreDiRitorno.datiCampiSTD[i].tipiCampiSTD;
                        setRisultatoServerLayoutPersonaTipiSTD(arrLocale3STD);
                        arrLocale4STD[i]=valoreDiRitorno.datiCampiSTD[i].maxCaratteriCampiSTD;
                        setRisultatoServerLayoutPersonaMaxCaratteriSTD(arrLocale4STD);
                        arrLocale5STD[i]=valoreDiRitorno.datiCampiSTD[i].obbligatorioSTD;
                        setRisultatoServerLayoutPersonaObbligatorioSTD(arrLocale5STD);
                        arrLocale5aSTD[i]=valoreDiRitorno.datiCampiSTD[i].univocoSTD;
                        setRisultatoServerLayoutPersonaUnivocoSTD(arrLocale5aSTD);
                        arrLocale6aSTD[i]=valoreDiRitorno.datiCampiSTD[i].colonneSTD;
                        setRisultatoServerLayoutPersonaColonneSTD(arrLocale6aSTD);
                    } 

                    for (let i=0; i<valoreDiRitorno.campiObbligatori.length ; i++) {
                        arrLocale6[i]=valoreDiRitorno.campiObbligatori[i];
                        setRisultatoServerCampiObbligatori(arrLocale6);
                    }

                    for (let i=0; i<valoreDiRitorno.campiCustom.length ; i++) {
                        arrLocale7[i]=valoreDiRitorno.campiCustom[i];
                        setRisultatoServerCampiCustom(arrLocale7);
                    }
                
                    setRisultatoServerCampiObbligatori(valoreDiRitorno.campiObbligatori);
                    setStatoCaricamentoLayoutPersona(true);
                    //eseguo i controlli dei campi: in teoria sono già ok perchè mi arrivano da backend ma è meglio riverificare:
                    setVerificaCampiObbligatori(1);
                    setVerificaCampi(1);
                  }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function fetchOperazioneServer(bypassaControlli=0) {
        //invio il nuovo layout al backend
        let controlli=0;

        //controllo che tutte le colonne inviate non si ripetano (in sostanza non devo avere 2 campi associati alla medesima colonna):
        let colonne_usate=[];

        let colonne_verificate = 0;
        let colonne_vuote = 0;
        for (let i=0; i<risultatoServerLayoutPersonaDescrizioni.length ; i++) {
            colonne_verificate = colonne_verificate + 1;

            if(risultatoServerLayoutPersonaColonne[i] === '' || risultatoServerLayoutPersonaColonne[i] === undefined){
                colonne_vuote = colonne_vuote + 1;
            } 
            
            if (colonne_usate.includes(risultatoServerLayoutPersonaColonne[i])){
                controlli = 1;
                setStatoPagina(STATO_IMPOSTA_LAYOUT);
                setFlagAttivaModaleColonneDoppie(1);
            }else{
                colonne_usate.push(risultatoServerLayoutPersonaColonne[i]);
            }
        }
        if(colonne_vuote>0){ 
            controlli = 1;
            setStatoPagina(STATO_IMPOSTA_LAYOUT);
            setFlagAttivaModaleColonneVuote(1);
        }
/*console.log("controlli"+controlli);
console.log("colonne_verificate"+colonne_verificate);
console.log("risultatoServerLayoutPersonaColonne"+risultatoServerLayoutPersonaColonne.length);*/
        if(controlli===0 && colonne_verificate === risultatoServerLayoutPersonaColonne.length){ 

            // parametri per inviare i dati al server:
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"layoutImportatore",
                operazione:"modifica",
                sequenzaDescrizioni: risultatoServerLayoutPersonaDescrizioni,
                sequenzaColonne: risultatoServerLayoutPersonaColonne,
                tipo:tipologiaImportazioneDefault,
                emak: props.emak,
                sessionId:props.sessionId})
            };
            
            // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
            // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
            // variabile di stato "risultato_server"
            //setStatoPagina(STATO_MEMORIZZAZIONE);
            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                (valoreDiRitorno) => {
                    setLoading(false);
                    if(valoreDiRitorno.risultatoOperazione===1) setStatoPagina(STATO_SELEZIONA_FILE); 
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                
                        if(valoreDiRitorno.mess !== undefined){
                            if(valoreDiRitorno.mess.indexOf("Campi obbligatori non presenti") !== -1){
                                setStatoPagina(STATO_IMPOSTA_LAYOUT);
                                
                                setFlagAttivaModaleMancanzaCampiObbligatori(1);
                            } 

                            
                        }else{
                            //altrimenti vado in errore generico
                            setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        }
                     }
                },
                (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }
    }
    function fetchOperazioneServer2(bypassaControlli=0) {
        setDisabilitaBottoni(true);
        //invio il nuovo layout al backend
        let controlli=0;
        //controllo che effettivamente sia stato indicato il file da importare:

        const formData = new FormData();
        formData.append('op', "layoutImportatore");
        formData.append('operazione',"importa");
        formData.append('contenutoFile', contenutoFile);
        formData.append('tipo', tipologiaImportazioneDefault);
        formData.append('emak', props.emak);
        formData.append('session_id',props.sessionId);

        if(controlli===0){ 

            // parametri per inviare i dati al server:
            //console.log("contenuto file"+contenutoFile);
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                body: formData
            }
            
            // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
            // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
            // variabile di stato "risultato_server"
            //setStatoPagina(STATO_MEMORIZZAZIONE);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                (valoreDiRitorno) => {
                    setLoading(false);
                    //console.log("risultato importazione"+valoreDiRitorno.risultatoOperazione);
                    if(valoreDiRitorno.risultatoOperazione===1){
                        setStatoPagina(STATO_PRESENTA_REPORT_FINALE); 
                        setRigheInserite(valoreDiRitorno.righeInserite);
                        setRigheIgnorate(valoreDiRitorno.righeIgnorate);
                        setStringaRigheIgnorate(valoreDiRitorno.stringaRigheIgnorate);
                        setContenutoFile([]);
                    } 
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        
                        let arrLocale1=[];
                        let arrLocale2=[];
                        
                        if(valoreDiRitorno.mess !== undefined){
                            setContenutoFile([]);
                            
                            if(valoreDiRitorno.mess.indexOf("Non è stato selezionato alcun file") !== -1){
                                setStatoPagina(STATO_SELEZIONA_FILE);
                                setFlagAttivaModaleMancanzaFile(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Il file presenta alcune anomalie relative a campi che devono essere univoci mentre nel file compaiono più volte") !== -1){
                                setStatoPagina(STATO_SELEZIONA_FILE);
                                for (let i=0; i<valoreDiRitorno.anomalie.length ; i++) {
                                    arrLocale1[i]=valoreDiRitorno.anomalie[i].campo;
                                    setRisultatoServerAnomalieCampo(arrLocale1);
                                    arrLocale2[i]=valoreDiRitorno.anomalie[i].valore;
                                    setRisultatoServerAnomalieValore(arrLocale2);
                                }
                                setFlagAttivaModaleE1(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Il file presenta alcune anomalie relative a campi che devono essere univoci mentre il dato presente nel file risulta già presente nel db") !== -1){
                                setStatoPagina(STATO_SELEZIONA_FILE);
                                for (let i=0; i<valoreDiRitorno.anomalie.length ; i++) {
                                    arrLocale1[i]=valoreDiRitorno.anomalie[i].campo;
                                    setRisultatoServerAnomalieCampo(arrLocale1);
                                    arrLocale2[i]=valoreDiRitorno.anomalie[i].valore;
                                    setRisultatoServerAnomalieValore(arrLocale2);
                                }
                                setFlagAttivaModaleE2(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Il file presenta alcune anomalie relative al numero massimo di caratteri utilizzabili nei singoli campi") !== -1){
                                setStatoPagina(STATO_SELEZIONA_FILE);
                                for (let i=0; i<valoreDiRitorno.anomalie.length ; i++) {
                                    arrLocale1[i]=valoreDiRitorno.anomalie[i].riga;
                                    setRisultatoServerAnomalieRiga(arrLocale1);
                                    arrLocale2[i]=valoreDiRitorno.anomalie[i].campo;
                                    setRisultatoServerAnomalieCampo(arrLocale2);
                                }
                                setFlagAttivaModaleE3(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Il file presenta alcune anomalie relative a campi tipo custom che presentano valori non ammessi") !== -1){
                                setStatoPagina(STATO_SELEZIONA_FILE);
                                for (let i=0; i<valoreDiRitorno.anomalie.length ; i++) {
                                    arrLocale1[i]=valoreDiRitorno.anomalie[i].riga;
                                    setRisultatoServerAnomalieRiga(arrLocale1);
                                    arrLocale2[i]=valoreDiRitorno.anomalie[i].campo;
                                    setRisultatoServerAnomalieCampo(arrLocale2);
                                }
                                setFlagAttivaModaleE4(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Il file presenta alcune anomalie relative a campi tipo float che presentano valori non ammessi (non sono numeri decimali)") !== -1){
                                setStatoPagina(STATO_SELEZIONA_FILE);
                                for (let i=0; i<valoreDiRitorno.anomalie.length ; i++) {
                                    arrLocale1[i]=valoreDiRitorno.anomalie[i].riga;
                                    setRisultatoServerAnomalieRiga(arrLocale1);
                                    arrLocale2[i]=valoreDiRitorno.anomalie[i].campo;
                                    setRisultatoServerAnomalieCampo(arrLocale2);
                                }
                                setFlagAttivaModaleE5(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Il file presenta alcune anomalie relative a campi tipo float che presentano valori non ammessi (hanno più di 2 cifre decimali)") !== -1){
                                setStatoPagina(STATO_SELEZIONA_FILE);
                                for (let i=0; i<valoreDiRitorno.anomalie.length ; i++) {
                                    arrLocale1[i]=valoreDiRitorno.anomalie[i].riga;
                                    setRisultatoServerAnomalieRiga(arrLocale1);
                                    arrLocale2[i]=valoreDiRitorno.anomalie[i].campo;
                                    setRisultatoServerAnomalieCampo(arrLocale2);
                                }
                                setFlagAttivaModaleE6(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Il file presenta alcune anomalie relative a campi tipo data che presentano valori non ammessi") !== -1){
                                setStatoPagina(STATO_SELEZIONA_FILE);
                                for (let i=0; i<valoreDiRitorno.anomalie.length ; i++) {
                                    arrLocale1[i]=valoreDiRitorno.anomalie[i].riga;
                                    setRisultatoServerAnomalieRiga(arrLocale1);
                                    arrLocale2[i]=valoreDiRitorno.anomalie[i].campo;
                                    setRisultatoServerAnomalieCampo(arrLocale2);
                                }
                                setFlagAttivaModaleE7(1);
                            }
                            if(valoreDiRitorno.mess.indexOf("Il file presenta alcune anomalie relative a campi da valorizzare Si/No (case insensitive, 'Si' accettato anche con accento) che presentano valori non ammessi") !== -1){
                                setStatoPagina(STATO_SELEZIONA_FILE);
                                for (let i=0; i<valoreDiRitorno.anomalie.length ; i++) {
                                    arrLocale1[i]=valoreDiRitorno.anomalie[i].riga;
                                    setRisultatoServerAnomalieRiga(arrLocale1);
                                    arrLocale2[i]=valoreDiRitorno.anomalie[i].campo;
                                    setRisultatoServerAnomalieCampo(arrLocale2);
                                }
                                setFlagAttivaModaleE8(1);
                            }
                            if(valoreDiRitorno.mess.indexOf("Il file presenta alcune anomalie relative a campi da valorizzare con numeri interi che presentano valori non ammessi") !== -1){
                                setStatoPagina(STATO_SELEZIONA_FILE);
                                for (let i=0; i<valoreDiRitorno.anomalie.length ; i++) {
                                    arrLocale1[i]=valoreDiRitorno.anomalie[i].riga;
                                    setRisultatoServerAnomalieRiga(arrLocale1);
                                    arrLocale2[i]=valoreDiRitorno.anomalie[i].campo;
                                    setRisultatoServerAnomalieCampo(arrLocale2);
                                }
                                setFlagAttivaModaleE9(1);
                            }

                            if(valoreDiRitorno.mess.indexOf("Il file presenta alcune anomalie relative a campi tipo data che dovrebbero essere consecutivi e non lo sono") !== -1){
                                setStatoPagina(STATO_SELEZIONA_FILE);
                                for (let i=0; i<valoreDiRitorno.anomalie.length ; i++) {
                                    arrLocale1[i]=valoreDiRitorno.anomalie[i].riga;
                                    setRisultatoServerAnomalieRiga(arrLocale1);
                                    arrLocale2[i]=valoreDiRitorno.anomalie[i].campo;
                                    setRisultatoServerAnomalieCampo(arrLocale2);
                                }
                                setFlagAttivaModaleE10(1);
                            }
                            if(valoreDiRitorno.mess.indexOf("Il file presenta alcune anomalie relative a campi tipo data che non possono superare una determinata distanza tra loro") !== -1){
                                setStatoPagina(STATO_SELEZIONA_FILE);
                                for (let i=0; i<valoreDiRitorno.anomalie.length ; i++) {
                                    arrLocale1[i]=valoreDiRitorno.anomalie[i].riga;
                                    setRisultatoServerAnomalieRiga(arrLocale1);
                                    arrLocale2[i]=valoreDiRitorno.anomalie[i].campo;
                                    setRisultatoServerAnomalieCampo(arrLocale2);
                                }
                                setFlagAttivaModaleE11(1);
                            }
                            if(valoreDiRitorno.mess.indexOf("Campi obbligatori non valorizzati") !== -1){
                                setStatoPagina(STATO_SELEZIONA_FILE);
                                setFlagAttivaModaleE12(1);
                            }
                          
                        }else{
                            //altrimenti vado in errore generico
                            setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        }
                     }
                },
                (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }
    }
    
    return(
        <Fragment>
            
        <div style={{height: props.dimensioniApp.yMain, display:"flex", flexDirection:"column"}}>

            {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
            <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
            </div>
            </div> :""}
            {statoPagina === STATO_ERRORE_CONNESSIONE ? <div className="app-main__outer">Errore: 1ImpMass {error.message}</div> :""}
            {statoPagina === STATO_OPERAZIONE_FALLITA ? <div className="app-main__outer">Errore per operazione errata</div> :""}

            {statoPagina === STATO_SCEGLI_IMPORTAZIONE ?
                
                    <Fragment>
                   
                    <Clessidra loading={loading}/>
                            <CardBody>
                                <Col md={12}>         
                                <CardTitle>Selezionare la tipologia di Importatore</CardTitle>
                                <Row><Col md={12}><b>Attenzione:</b><br></br> 
                                - il file da importare deve essere privo di riga di intestazione, cioè nella prima riga ci sono già i dati da importare,<br></br> 
                                - è necessario procedere prima con l'importazione delle anagrafiche e solo successivamente importare i movimenti prima nota.
                                </Col></Row>
                                &nbsp;
                                <Row ><Col md={6} style={{marginLeft:15,marginRight:20,border: '1px solid black'}}>
                                <Row style={{backgroundColor : "#99cbff"}}><Col md={4}><b><center>Formato file</center></b></Col><Col md={4}><center><b>Carattere separatore campi</b></center></Col><Col md={4}><center><b>Carattere separatore decimali</b></center></Col></Row>&nbsp;
                                <Row><Col md={4}><center>CSV</center></Col><Col md={4}><center><b>,</b> (virgola)</center></Col><Col md={4}><center><b>.</b> (punto)</center></Col></Row>&nbsp;
                                <Row><Col md={4}><center>XLSX</center></Col><Col md={4}><center></center></Col><Col md={4}><center><b>,</b> (virgola)</center></Col></Row>&nbsp;
                                <Row><Col md={4}><center>ODS</center></Col><Col md={4}><center></center></Col><Col md={4}><center><b>,</b> (virgola)</center></Col></Row>&nbsp;
                                </Col></Row>
                                
                                &nbsp;
                                <Row><Col>
                                Selezionare la tipologia di importazione:
                                </Col></Row>
                                <Row><Col>
                                <select className="inputStandard" name="tipologiaImportazione" id="tipologiaImportazione" onChange={(e)=>{setTipologiaImportazioneDefault(e.target.value)}}>
                                    <option value=''></option>
                                    <option value='abbonamenti'>Abbonamenti</option>
                                    <option value='abbonati'>Abbonati</option>
                                    <option value='adempimenti'>Adempimenti</option>
                                    <option value='articoli'>Articoli (Sez. Merchandizing)</option>
                                    <option value='associaGruppiAllePersone'>Associazioni dei Raggruppamenti agli Iscritti</option>
                                    <option value='associaPosizioniSocietaAllePersone'>Associazioni delle Posizioni in Società agli Iscritti</option>
                                    <option value='associaRiferimentiAllePersone'>Associazioni dei Riferimenti agli Iscritti</option>
                                    {/*<option value='associaSchedeAllenamento'>Associazioni delle Schede Allenamento agli Iscritti (TOLTO PER ORA)</option>*/}
                                    <option value='contattiUtili'>Contatti Utili</option>
                                    <option value='entiConvenzioni'>Enti Convenzioni</option>
                                    <option value='entiEsterniFornitori'>Enti Esterni / Fornitori</option>
                                    <option value='esercizi'>Esercizi (Sez. Allenatori)</option>
                                    <option value='iscritti'>Iscritti</option>
                                    <option value='giorniDiChiusuraStruttura'>Giorni di Chiusura Struttura</option>
                                    <option value='movimentoPrimaNota'>Movimenti Prima Nota</option>
                                    <option value='posizioniInSocieta'>Posizioni in Società</option>
                                    <option value='corsi'>Prestazioni / Attrezzature Prenotabili</option>
                                    <option value='quoteAssociative'>Quote Associative</option>
                                    <option value='gruppi'>Raggruppamenti</option>
                                    <option value='risorse'>Risorse</option>
                                    {/*<option value='schedeAllenamento'>Schede Allenamento Standard (TOLTO PER ORA)</option>*/}
                                    <option value='societaAnaloghe'>Società Analoghe</option>
                                    <option value='sponsor'>Sponsor</option>
                                    <option value='tesseramenti'>Tesseramenti</option>
                                    <option value='vociDiBilancio'>Voci di Bilancio</option>
                                </select>
                                </Col></Row>
                                </Col>      
                            </CardBody>
                        
                        </Fragment>
              
            :""}

            {statoPagina === STATO_IMPOSTA_LAYOUT ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                {flagAttivaModaleMancanzaCampiObbligatori=== 1 ?
                    <Modale 
                    titolo="Informativa campi obbligatori non presenti"
                    flagErrore={true}
                    contenuto={<div>Layout errato: campi obbligatori non presenti. Correggere il layout e riprovare</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);tornaScheda()}
                        }    
                    ]}
                    />
                :""}

                {flagAttivaModaleColonneVuote=== 1 ?
                    <Modale 
                    titolo="Informativa presenti colonne vuote"
                    flagErrore={true}
                    contenuto={<div>Layout errato: ci sono campi per i quali non è stata indicata la colonna. Correggere il layout e riprovare</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);tornaScheda()}
                        }    
                    ]}
                    />
                :""}

                {flagAttivaModaleColonneDoppie=== 1 ?
                    <Modale 
                    titolo="Informativa presenti colonne doppie"
                    flagErrore={true}
                    contenuto={<div>Layout errato: ci sono campi legati alle medesime colonne. Correggere il layout e riprovare</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);tornaScheda()}
                        }    
                    ]}
                    />
                :""}

               <Clessidra loading={loading}/>
                    
                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                  
                        {/*metto le tendine con i campi tovati nel layout impostato ad oggi (può essere il base oppure quello personalizzato contenuto nel db tabella LAYOUT_IMPORTATORE*/}
                        <Row form>
                        <Col md={12}> 
                           

                        {tipologiaImportazioneDefault === 'abbonamenti' ? <CardTitle>Layout Importatore 'Abbonamenti'&nbsp;</CardTitle> :""}
                        {tipologiaImportazioneDefault === 'abbonati' ? <CardTitle>Layout Importatore 'Abbonati'&nbsp;</CardTitle> :""}
                        {tipologiaImportazioneDefault === 'adempimenti' ? <CardTitle>Layout Importatore 'Adempimenti'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'articoli' ? <CardTitle>Layout Importatore 'Articoli'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'associaGruppiAllePersone' ? <CardTitle>Layout Importatore 'Associazioni dei Raggruppamenti agli Iscritti'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'associaPosizioniSocietaAllePersone' ? <CardTitle>Layout Importatore 'Associazioni delle Posizioni in Società agli Iscritti'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'associaRiferimentiAllePersone' ? <CardTitle>Layout Importatore 'Associazioni dei Riferimenti agli Iscritti'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'associaSchedeAllenamento' ? <CardTitle>Layout Importatore 'Associazioni delle Schede Allenamento agli Iscritti (Sez. Allenatori)'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'attrezzature' ? <CardTitle>Layout Importatore 'Attrezzature'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'campagneSponsor' ? <CardTitle>Layout Importatore 'Campagne Sponsorizzazione'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'contattiUtili' ? <CardTitle>Layout Importatore 'Contatti Utili'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'corsi' ? <CardTitle>Layout Importatore 'Prestazioni / Attrezzature Prenotabili'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'documenti' ? <CardTitle>Layout Importatore 'Abbonamenti'&nbsp;</CardTitle> :""}  
                        {tipologiaImportazioneDefault === 'entiEsterniFornitori' ? <CardTitle>Layout Importatore 'Enti Esterni / Fornitori'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'entiConvenzioni' ? <CardTitle>Layout Importatore 'Enti Convenzioni'&nbsp;</CardTitle> :""}  
                        {tipologiaImportazioneDefault === 'eventi' ? <CardTitle>Layout Importatore 'Eventi'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'esercizi' ? <CardTitle>Layout Importatore 'Esercizi'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'incassiDiversiDaEsterni' ? <CardTitle>Layout Importatore 'Incassi Diversi da Esterni'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'incassiDiversiDaIscritti' ? <CardTitle>Layout Importatore 'Incassi Diversi da Iscritti'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'incassiQuoteAssociative' ? <CardTitle>Layout Importatore 'Incassi Quote Associative'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'incassiQuoteAbbonamento' ? <CardTitle>Layout Importatore 'Incassi Quote Abbonamento'&nbsp;</CardTitle> :""}  
                        {tipologiaImportazioneDefault === 'iscritti' ? <CardTitle>Layout Importatore 'Iscritti'&nbsp;</CardTitle> :""}  
                        {tipologiaImportazioneDefault === 'giorniDiChiusuraStruttura' ? <CardTitle>Layout Importatore 'Giorni di Chiusura Struttura'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'gruppi' ? <CardTitle>Layout Importatore 'Raggruppamenti'&nbsp;</CardTitle> :""} 
                        {tipologiaImportazioneDefault === 'movimentoPrimaNota' ? <CardTitle>Layout Importatore 'Movimenti Prima Nota'&nbsp;</CardTitle> :""}  
                        {tipologiaImportazioneDefault === 'posizioniInSocieta' ? <CardTitle>Layout Importatore 'Posizioni in Società'&nbsp;</CardTitle> :""}  
                        {tipologiaImportazioneDefault === 'quoteAssociative' ? <CardTitle>Layout Importatore 'Quote Associative'&nbsp;</CardTitle> :""}  
                        {tipologiaImportazioneDefault === 'rimborsi' ? <CardTitle>Layout Importatore 'Rimborsi'&nbsp;</CardTitle> :""}   
                        {tipologiaImportazioneDefault === 'risorse' ? <CardTitle>Layout Importatore 'Risorse'&nbsp;</CardTitle> :""}  
                        {tipologiaImportazioneDefault === 'schedeAllenamento' ? <CardTitle>Layout Importatore 'Schede Allenamento Standard (Sez. Allenatori)'&nbsp;</CardTitle> :""}
                        {tipologiaImportazioneDefault === 'societaAnaloghe' ? <CardTitle>Layout Importatore 'Società Analoghe'&nbsp;</CardTitle> :""}
                        {tipologiaImportazioneDefault === 'spese' ? <CardTitle>Layout Importatore 'Spese'&nbsp;</CardTitle> :""} 
                        {tipologiaImportazioneDefault === 'sponsor' ? <CardTitle>Layout Importatore 'Sponsor'&nbsp;</CardTitle> :""}
                        {tipologiaImportazioneDefault === 'tesseramenti' ? <CardTitle>Layout Importatore 'Tesseramenti'&nbsp;</CardTitle> :""}
                        {tipologiaImportazioneDefault === 'vociDiBilancio' ? <CardTitle>Layout Importatore 'Voci di Bilancio'&nbsp;</CardTitle> :""} 

                        {tipologiaImportazioneDefault === 'adempimenti' ? <><b>Attenzione:</b> L'intervallo tra data di prima ed ultima esecuzione massimo accettato è di 5 anni.
                        </> :""} 
                        {tipologiaImportazioneDefault === 'quoteAssociative' ? <><b>Attenzione:</b> nello stesso anno, è possibile assegnare una sola quota associativa per ogni posizione (eventuali record nel file contenenti dati doppi con stessa posizione e stesso anno verranno ignorati)
                        </> :""} 
                        {tipologiaImportazioneDefault === 'associaGruppiAllePersone' 
                        || tipologiaImportazioneDefault === 'associaPosizioniSocietaAllePersone' 
                        || tipologiaImportazioneDefault === 'associaRiferimentiAllePersone'? <>
                            <b>Attenzione:</b> questo importatore non inserisce alcun Iscritto pertanto, prima di importare l'associazione dei dati agli Iscritti è necessario prima procedere con l'importazione dell'anagrafica Iscritti.<br></br>  
                            </> :""} 
                        {tipologiaImportazioneDefault === 'associaPosizioniSocietaAllePersone' ? <><b><br></br>Criterio di ricerca dalla Posizione in Società:</b><br></br>  
                        Per individuare la posizione da associare viene utilizzata la Descrizione della Posizione. <br></br>  
                        Se non c'è alcun record che soddisfa la ricerca viene inserita una nuova Posizione in Società che avrà i campi Direttivo e Appartenete al Libro Soci disabilitati.<br></br>
                        Il campo Rateizzazione viene considerato solo in fase di inserimento di una nuova posizione.<br></br>
                        Tali caratteristiche potranno eventualmente essere variate in un secondo momento dal menu principale, sezione Impostazioni, Posizioni in Società ed andando a modificare il relativo record.<br></br><br></br>
                        Qualora un iscritto dovesse avere già la stessa posizione con la stessa data di inizio nulla verrà variato. Se la data di inzio fosse invece differente verrà introdotta una nuova associazione con le caratteristiche riportate nel file.</>:""}
                        
                        {tipologiaImportazioneDefault === 'associaRiferimentiAllePersone' ? <>
                            Vengono ignorate tutte le righe del file che dovessero contenere una persona di riferimento (individuata da cognome nome e email) già legata all'Iscritto indicato.<br></br>  
                        </>:""}

                        {tipologiaImportazioneDefault === 'iscritti' ? <><b>Attenzione:</b> verranno ignorate le righe del file che dovessero contenere la stessa terna cognome/nome/nomero tessera di iscritti già inseriti a sistema nel momento dell'importazione del record.</> :""} 
                        {tipologiaImportazioneDefault === 'movimentoPrimaNota' ? <><b>Attenzione:</b> questo importatore non inserisce alcun Iscritto, Ente Esterno/Fornitore o Sponsor pertanto, pertanto, è necessario prima procedere con l'importazione delle relative anagrafiche. 
                        <br></br>Il campo Data Scad. Pagamento, seppur presente nel file, viene valorizzato a sistema solo nei casi di Campagna Sponsor, Incasso da Esterno o Spesa.
                        <br></br>Il campo IVA varrà sempre 0 nel caso di Rimborsi,Quote ed Incassi Diversi da Iscritti a prescindere dal valore riportato nel file.
                        </> :""} 

                            <Table responsive><thead>
                            <tr>
                                {/*<td><center><CardTitle>Colonna File</CardTitle></center></td>
                                <td><center><CardTitle>Campo</CardTitle></center></td>
                                <td><center><CardTitle>Tipo Campo</CardTitle></center></td>
                                <td><center><CardTitle>Max Caratteri</CardTitle></center></td>
                                <td><center><CardTitle>Campo Obbligatorio</CardTitle></center></td>
                                <td><center><CardTitle>Campo Univoco</CardTitle></center></td>*/}

                                <td style={{backgroundColor : "#99cbff"}}><center><b>Colonna File</b></center></td>
                                <td style={{backgroundColor : "#99cbff"}}><center><b>Campo</b></center></td>
                                <td style={{backgroundColor : "#99cbff"}}><center><b>Tipo Campo</b></center></td>
                                <td style={{backgroundColor : "#99cbff"}}><center><b>Max Caratteri</b></center></td>
                                <td style={{backgroundColor : "#99cbff"}}><center><b>Campo Obbligatorio</b></center></td>
                                <td style={{backgroundColor : "#99cbff"}}><center><b>Campo Univoco</b></center></td>
                            </tr>

                            {risultatoServerLayoutPersonaPosizioni.map((elemento,index) => <Fragment key={index}>
                                <tr>
                                   {/*COLONNE FILE*/}
                                    <td><center>
                                    <select className="inputStandard" onChange={(e)=>{aggiornaColonne(e.target.value,index)}} name="colonne" id="colonne" value={risultatoServerLayoutPersonaColonne[index]} >
                                    {colonneDisponibili.map((option1, indexColonne) => <Fragment key={indexColonne}>
                                        <option value={colonneDisponibili[indexColonne]}>{colonneDisponibili[indexColonne]}</option>
                                    </Fragment>)}
                                    </select></center></td>

                                    {/*CAMPO*/}
                                    <td><center>
                                    <select className="inputStandard" onChange={(e)=>{aggiornaCampi(e.target.value,index)}} name="campi" id="campi">
                                    {'' === risultatoServerLayoutPersonaDescrizioni[index] ? 
                                    <option selected value={''}></option>
                                    : <option value={''}></option>}
                                    {risultatoServerLayoutPersonaDescrizioniSTD.map((option1, index1) => <Fragment key={index1}>
                                        {risultatoServerLayoutPersonaDescrizioniSTD[index1] === risultatoServerLayoutPersonaDescrizioni[index] ? 
                                            <option selected value={risultatoServerLayoutPersonaDescrizioniSTD[index1]}>{risultatoServerLayoutPersonaDescrizioniSTD[index1]}</option> 
                                        : <option value={risultatoServerLayoutPersonaDescrizioniSTD[index1]}>{risultatoServerLayoutPersonaDescrizioniSTD[index1]}</option>}
                                    </Fragment>)}
                                    </select></center></td>

                                    {/*TIPO CAMPO*/}
                                    {risultatoServerLayoutPersonaTipi[index] === '0' ? 
                                        <td><center>Unici valori accettati:
                                        {risultatoServerCampiCustom.map((option2, index2) => <Fragment key={index2}>
                                                {risultatoServerLayoutPersonaDescrizioniSTD[risultatoServerCampiCustom[index2].posizione] === risultatoServerLayoutPersonaDescrizioni[index] ? 
                                                    <Fragment>
                                                        <Table border = {1} responsive><thead>
                                                        {risultatoServerCampiCustom[index2].valori.map((valore, indiceValore) => <Fragment key={indiceValore}>
                                                            <tr><td><center>{valore}</center></td></tr>
                                                        </Fragment>
                                                        )}
                                                        </thead></Table>
                                                    </Fragment>
                                                : ""}
                                            </Fragment>
                                      
                                        )}

                                        </center></td>
                                    : ""}

                                    {risultatoServerLayoutPersonaTipi[index] === '1' ? <td><center>Testuale</center></td> : ""}
                                    {risultatoServerLayoutPersonaTipi[index] === '2' ? <td><center>Numerico (max 2 cifre decimali)</center></td> : ""}
                                    {risultatoServerLayoutPersonaTipi[index] === '3' ? <td><center>Data in formato gg/mm/aaaa</center></td> : ""}
                                    {risultatoServerLayoutPersonaTipi[index] === '4' ? <td><center>Flag valorizzato 'Si' oppure 'No'</center></td> : ""}
                                    {risultatoServerLayoutPersonaTipi[index] === '5' ? <td><center>Numerico Intero</center></td> : ""}
                                    {risultatoServerLayoutPersonaTipi[index] === '6' ? <td><center>Data in formato aaaa</center></td> : ""}
                                    {/*MAX CARATTERI*/}
                                    {risultatoServerLayoutPersonaMaxCaratteri[index] !== '' ? 
                                        risultatoServerLayoutPersonaMaxCaratteri[index] !== '-1' ?
                                            <td><center>{risultatoServerLayoutPersonaMaxCaratteri[index]}</center></td>
                                        : <td><center>65000</center></td>
                                    : <td><center>-</center></td>}
                                    {/*CAMPO OBBLIGATORIO*/}
                                    {risultatoServerLayoutPersonaObbligatorio[index] === '1' ? <td><center>Si</center></td> : <td><center>-</center></td>}
                                    {/*CAMPO UNIVOCO*/}
                                    {risultatoServerLayoutPersonaUnivoco[index] === '0' ? <td><center>Si</center></td> : <td><center>-</center></td>}
                                   
                                </tr>
                            </Fragment>
                            )}    
                            {/*riga in più che viene messa sotto se devi aggiungere un campo*/}
                            {/*{risultatoServerLayoutPersonaDescrizioniSTD.length} -std-personale- {risultatoServerLayoutPersonaDescrizioni.length}*/}
                            {risultatoServerLayoutPersonaDescrizioniSTD.length > risultatoServerLayoutPersonaDescrizioni.length ?
                                <tr>
                                    <td><center>
                                    <select className="inputStandard" onChange={(e)=>{aggiornaColonne(e.target.value,risultatoServerLayoutPersonaDescrizioni.length)}} name="colonne" id="colonne" value=''>
                                    <option value=''></option>    
                                    {colonneDisponibili.map((newColonna, indexNewColonna) => <Fragment key={indexNewColonna}>
                                    <option value={newColonna}>{newColonna}</option>
                                    </Fragment>)}
                                    </select></center></td>
                                    
                                    <td><center>
                                    <select className="inputStandard" onChange={(e)=>{aggiornaCampi(e.target.value,risultatoServerLayoutPersonaDescrizioni.length)}} name="campi" id="campi" value=''>
                                    <option value=''></option>
                                    {risultatoServerLayoutPersonaDescrizioniSTD.map((newCampo, indexNewCampo) => <Fragment key={indexNewCampo}>
                                        <option value={newCampo}>{newCampo}</option>
                                    </Fragment>)}
                                </select>
                                </center></td>
                                <td><center>-</center></td>
                                <td><center>-</center></td>
                                <td><center>-</center></td>
                                <td><center>-</center></td>
                                </tr>

                            :""}
                            {/*
                            {risultatoServerCampiObbligatori.map((elemento,index) => <Fragment key={index}>
                                <tr><td><center>{risultatoServerCampiObbligatori[index]}</center></td></tr>
                            </Fragment>
                            )} 
                            */}
                            </thead>
                            </Table>

                            {campiObbligatoriMancanti.length > 0 ?
                                <div style={{verticalAlign:'middle', paddingBottom:5}}>I campi obbligatori mancanti sono: {campiObbligatoriMancanti.length}&nbsp;
                                {visualizzaCampiObbligatoriMancanti===0 ?
                                    <Button color="success" onClick={() => setVisualizzaCampiObbligatoriMancanti(1)} >Visualizza</Button>
                                    :
                                    <Button color="success" onClick={() => setVisualizzaCampiObbligatoriMancanti(0)} >Nascondi</Button>}
                                </div>
                            :""}

                            {visualizzaCampiObbligatoriMancanti === 1 ?
                                <Table style={{borderTop: "1px solid #B0B0B0", paddingTop:10, }}>
                                {campiObbligatoriMancanti.map((elementoMancante,indexMancante) => <Fragment key={indexMancante}>
                                    <tr><td style={{backgroundColor:"#FFFFFF"}}>{risultatoServerLayoutPersonaDescrizioniSTD[campiObbligatoriMancanti[indexMancante]]}</td></tr>
                                </Fragment>)}
                                </Table>
                            :""}

                            {campiMancanti.length > 0 ?
                                <div style={{verticalAlign:'middle', paddingBottom:5}}>I campi mancanti rispetto al layout Standard sono: {campiMancanti.length}&nbsp;
                                {visualizzaCampiMancanti===0 ?
                                    <Button color="success" onClick={() => setVisualizzaCampiMancanti(1)} >Visualizza</Button>
                                    :
                                    <Button color="success" onClick={() => setVisualizzaCampiMancanti(0)} >Nascondi</Button>}
                                </div>
                            :""}

                            {visualizzaCampiMancanti === 1 ?
                                <Table style={{borderTop: "1px solid #B0B0B0", paddingTop:5 }}>
                                {campiMancanti.map((elementoMancante1,indexMancante1) => <Fragment key={indexMancante1}>
                                    <tr><td style={{backgroundColor:"#FFFFFF"}}>{risultatoServerLayoutPersonaDescrizioniSTD[campiMancanti[indexMancante1]]}</td></tr>
                                </Fragment>)}
                                </Table>
                            :""}
                                
                        
                        </Col>    
                        </Row>
                        {/*per questa importazione i campi obbligatori sono*/}
     
                        {/*premendo procedi si và alla pagina successiva dove puoi inferire il file da importare. 
                        Il bottone di accesso compare solo se i campi obbligatori ci sono tutti*/}
                        <Row form>
                        <Col md={3}><center>
                       
                        {campiObbligatoriMancanti.length > 0 ?
                            <Label><b>Aggiungere nel layout i campi obbligatori indicati per poter proseguire</b></Label> 
                        :
                        <Button color="success" className="mt-2" onClick={() => fetchOperazioneServer()} >Conferma la bozza di layout</Button>}
                        </center></Col>
                        <Col md={3}><center>
                            <Button color="danger" className="mt-2" onClick={() => {setStatoPagina(STATO_MEMORIZZAZIONE);setProcediResettaLayout(0)}} >Resetta svuotando la bozza di layout</Button>
                            </center></Col>
                        <Col md={3}><center>
                       
                            <Button color="danger" className="mt-2" onClick={() => {setStatoPagina(STATO_MEMORIZZAZIONE);setProcediResettaLayout(1)}} >Resetta al layout standard</Button>
                            </center></Col>
                        <Col md={3}><center>
                       
                            <Button color="primary" className="mt-2" onClick={() => {
                            setStatoPagina(STATO_SCEGLI_IMPORTAZIONE);
                            setTipologiaImportazioneDefault('');
                            setStatoCaricamentoLayoutPersona(false);
                            setCampiObbligatoriMancanti([]);
                            setCampiMancanti([])}} >Annulla</Button>
                    
                    </center></Col>
                        </Row>
                    </div>
                </div>
            :""}

            {statoPagina === STATO_SELEZIONA_FILE ?
                <div className="app-main__outer">
                <Fragment>

                    {flagAttivaModaleMancanzaFile=== 1 ?
                    <Modale 
                    titolo="Informativa file non presente"
                    flagErrore={true}
                    contenuto={<div>Non è stato selezionato alcun file</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);chiudiModaleMancanzaFile()}
                        }    
                    ]}
                    />
                :""}


                {flagAttivaModaleE1=== 1 ?
                    <Modale 
                    titolo="Informativa errore"
                    flagErrore={true}
                    contenuto={<div>Il file presenta alcune anomalie relative a campi che devono essere univoci mentre nel file compaiono più volte. Valori errati: {valoriErrati}</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);chiudiModaleE1()}
                        }    
                    ]}
                    />
                :""}
                {flagAttivaModaleE2=== 1 ?
                    <Modale 
                    titolo="Informativa errore"
                    flagErrore={true}
                    contenuto={<div>Il file presenta alcune anomalie relative a campi che devono essere univoci mentre il dato presente nel file risulta già presente nel db. Correggere il file e riprovare. Valori errati: {valoriErrati}</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);chiudiModaleE2()}
                        }    
                    ]}
                    />
                :""}
                {flagAttivaModaleE3=== 1 ?
                    <Modale 
                    titolo="Informativa errore"
                    flagErrore={true}
                    contenuto={<div>Il file presenta alcune anomalie relative al numero massimo di caratteri utilizzabili nei singoli campi. Correggere il file e riprovare. Elenco righe errate: {righeErrate}</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);chiudiModaleE3()}
                        }    
                    ]}
                    />
                :""}
                {flagAttivaModaleE4=== 1 ?
                    <Modale 
                    titolo="Informativa errore"
                    flagErrore={true}
                    contenuto={<div>Il file presenta alcune anomalie relative a campi tipo custom che presentano valori non ammessi. Correggere il file e riprovare. Elenco righe errate: {righeErrate}</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);chiudiModaleE4()}
                        }    
                    ]}
                    />
                :""}
                {flagAttivaModaleE5=== 1 ?
                    <Modale 
                    titolo="Informativa errore"
                    flagErrore={true}
                    contenuto={<div>Il file presenta alcune anomalie relative a campi tipo float che presentano valori non ammessi (non sono numeri decimali). Correggere il file e riprovare. Elenco righe errate: {righeErrate}</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);chiudiModaleE5()}
                        }    
                    ]}
                    />
                :""}
                {flagAttivaModaleE6=== 1 ?
                    <Modale 
                    titolo="Informativa errore"
                    flagErrore={true}
                    contenuto={<div>Il file presenta alcune anomalie relative a campi tipo float che presentano valori non ammessi (hanno più di 2 cifre decimali). Correggere il file e riprovare. Elenco righe errate: {righeErrate}</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);chiudiModaleE6()}
                        }    
                    ]}
                    />
                :""}
                {flagAttivaModaleE7=== 1 ?
                    <Modale 
                    titolo="Informativa errore"
                    flagErrore={true}
                    contenuto={<div>Il file presenta alcune anomalie relative a campi tipo data che presentano valori non ammessi. Correggere il file e riprovare. Elenco righe errate: {righeErrate}</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);chiudiModaleE7()}
                        }    
                    ]}
                    />
                :""}
                {flagAttivaModaleE8=== 1 ?
                    <Modale 
                    titolo="Informativa errore"
                    flagErrore={true}
                    contenuto={<div>Il file presenta alcune anomalie relative a campi da valorizzare Si/No (case insensitive, 'Si' accettato anche con accento) che presentano valori non ammessi. Correggere il file e riprovare. Elenco righe errate: {righeErrate}</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);chiudiModaleE8()}
                        }    
                    ]}
                    />
                :""}
                {flagAttivaModaleE9=== 1 ?
                    <Modale 
                    titolo="Informativa errore"
                    flagErrore={true}
                    contenuto={<div>Il file presenta alcune anomalie relative a campi da valorizzare con numeri interi che presentano valori non ammessi. Correggere il file e riprovare. Elenco righe errate: {righeErrate}</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);chiudiModaleE9()}
                        }    
                    ]}
                    />
                :""}
                {flagAttivaModaleE10=== 1 ?
                    <Modale 
                    titolo="Informativa errore"
                    flagErrore={true}
                    contenuto={<div>Il file presenta alcune anomalie relative a campi tipo data che dovrebbero essere consecutivi e non risulta esserlo. Correggere il file e riprovare. Elenco righe errate: {righeErrate}</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);chiudiModaleE10()}
                        }    
                    ]}
                    />
                :""}
                {flagAttivaModaleE11=== 1 ?
                    <Modale 
                    titolo="Informativa errore"
                    flagErrore={true}
                    contenuto={<div>Il file presenta alcune anomalie relative a campi tipo data che non possono superare una determinata distanza tra loro. Correggere il file e riprovare. Elenco righe errate: {righeErrate}</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);chiudiModaleE11()}
                        }    
                    ]}
                    />
                :""}
                {flagAttivaModaleE12=== 1 ?
                    <Modale 
                    titolo="Informativa errore"
                    flagErrore={true}
                    contenuto={<div>Il file presenta alcuni campi obbligatori vuoti. Correggere il file e riprovare. Elenco righe errate: {righeErrate}</div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStatoPagina(STATO_IMPOSTA_LAYOUT);chiudiModaleE12()}
                        }    
                    ]}
                    />
                :""}

                    
                    <Clessidra loading={loading}/>
                        <CardBody>
                            <Row>
                            <Col md={12}> 
                                {tipologiaImportazioneDefault === 'abbonamenti' ? <CardTitle>Importatore 'Abbonamenti'&nbsp;</CardTitle> :""}
                                {tipologiaImportazioneDefault === 'abbonati' ? <CardTitle>Importatore 'Abbonati'&nbsp;</CardTitle> :""}
                                {tipologiaImportazioneDefault === 'adempimenti' ? <CardTitle>Importatore 'Adempimenti'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'articoli' ? <CardTitle>Importatore 'Articoli'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'associaGruppiAllePersone' ? <CardTitle>Importatore 'Associazioni dei Raggruppamenti agli Iscritti'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'associaPosizioniSocietaAllePersone' ? <CardTitle>Importatore 'Associazioni delle Posizioni in Società agli Iscritti'&nbsp;</CardTitle> :""}  
                                {tipologiaImportazioneDefault === 'associaRiferimentiAllePersone' ? <CardTitle>Importatore 'Associazioni dei Riferimenti agli Iscritti'&nbsp;</CardTitle> :""} 
                                {tipologiaImportazioneDefault === 'associaSchedeAllenamento' ? <CardTitle>Importatore 'Associazioni delle Schede Allenamento agli Iscritti (Sez. Allenatori)'&nbsp;</CardTitle> :""} 
                                {tipologiaImportazioneDefault === 'attrezzature' ? <CardTitle>Importatore 'Attrezzature'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'campagneSponsor' ? <CardTitle>Importatore 'Campagne Sponsorizzazione'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'contattiUtili' ? <CardTitle>Importatore 'Contatti Utili'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'corsi' ? <CardTitle>Importatore 'Prestazioni / Attrezzature Prenotabili'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'documenti' ? <CardTitle>Importatore 'Abbonamenti'&nbsp;</CardTitle> :""}  
                                {tipologiaImportazioneDefault === 'entiEsterniFornitori' ? <CardTitle>Importatore 'Enti Esterni / Fornitori'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'entiConvenzioni' ? <CardTitle>Importatore 'Enti Convenzioni'&nbsp;</CardTitle> :""}  
                                {tipologiaImportazioneDefault === 'eventi' ? <CardTitle>Importatore 'Eventi'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'esercizi' ? <CardTitle>Importatore 'Esercizi'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'incassiDiversiDaEsterni' ? <CardTitle>Importatore 'Incassi Diversi da Esterni'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'incassiDiversiDaIscritti' ? <CardTitle>Importatore 'Incassi Diversi da Iscritti'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'incassiQuoteAssociative' ? <CardTitle>Importatore 'Incassi Quote Associative'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'incassiQuoteAbbonamento' ? <CardTitle>Importatore 'Incassi Quote Abbonamento'&nbsp;</CardTitle> :""}  
                                {tipologiaImportazioneDefault === 'iscritti' ? <CardTitle>Importatore 'Iscritti'&nbsp;</CardTitle> :""}  
                                {tipologiaImportazioneDefault === 'giorniDiChiusuraStruttura' ? <CardTitle>Importatore 'Giorni di Chiusura Struttura'&nbsp;</CardTitle> :""} 
                                {tipologiaImportazioneDefault === 'gruppi' ? <CardTitle>Importatore 'Raggruppamenti'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'movimentoPrimaNota' ? <CardTitle>Importatore 'Movimenti Prima Nota'&nbsp;</CardTitle> :""}  
                                {tipologiaImportazioneDefault === 'posizioniInSocieta' ? <CardTitle>Importatore 'Posizioni in Società'&nbsp;</CardTitle> :""}  
                                {tipologiaImportazioneDefault === 'quoteAssociative' ? <CardTitle>Importatore 'Quote Associative'&nbsp;</CardTitle> :""}  
                                {tipologiaImportazioneDefault === 'rimborsi' ? <CardTitle>Importatore 'Rimborsi'&nbsp;</CardTitle> :""}   
                                {tipologiaImportazioneDefault === 'risorse' ? <CardTitle>Importatore 'Risorse'&nbsp;</CardTitle> :""}  
                                {tipologiaImportazioneDefault === 'schedeAllenamento' ? <CardTitle>Importatore 'Schede Allenamento Standard (Sez. Allenatori)'&nbsp;</CardTitle> :""}
                                {tipologiaImportazioneDefault === 'societaAnaloghe' ? <CardTitle>Importatore 'Società Analoghe'&nbsp;</CardTitle> :""}
                                {tipologiaImportazioneDefault === 'spese' ? <CardTitle>Importatore 'Spese'&nbsp;</CardTitle> :""} 
                                {tipologiaImportazioneDefault === 'sponsor' ? <CardTitle>Importatore 'Sponsor'&nbsp;</CardTitle> :""}
                                {tipologiaImportazioneDefault === 'tesseramenti' ? <CardTitle>Importatore 'Tesseramenti'&nbsp;</CardTitle> :""}
                                {tipologiaImportazioneDefault === 'vociDiBilancio' ? <CardTitle>Importatore 'Voci di Bilancio'&nbsp;</CardTitle> :""}
                                <FormGroup>
                                    <>
                                <label for="myfile">Selezionare il file da importare (formati ammessi: ods, csv, xlsx) <b>cliccando qui</b></label>
                                    <input type='file' style={{width:700}} accept=".ods,.csv,.xlsx" onChange={(e) => {
                                        aggiornaFile(e.target.files[0]);setDisabilitaBottoni(false); 
                                    }}/>
                                  

                                    {/*<input type="file" 
                                    name="fileImportare" 
                                    id="fileImportare" 
                                    accept=".csv"
                                    innerRef={fileImportare} 
                                onChange={(e)=>{aggiornaFile(e.target.files[0])}} />*/}
                                </>
                                </FormGroup>
                                {contenutoFile !==undefined && contenutoFile.name!==undefined? <Label>File selezionato: {contenutoFile.name}</Label> :""}
                            </Col>
                            </Row>

                            <Row>
                            <Col md={6}><center><Button disabled={disabilitaBottoni} color="success" className="mt-2" onClick={() => fetchOperazioneServer2()} >Importa</Button></center></Col> 
                            <Col md={6}>
                                <center>
                                <Button color="primary" className="mt-2" onClick={() => {
                                    setStatoPagina(STATO_SCEGLI_IMPORTAZIONE);
                                    setContenutoFile([]);
                                    setTipologiaImportazioneDefault('');
                                    setStatoCaricamentoLayoutPersona(false);
                                    setCampiObbligatoriMancanti([]);
                                    setCampiMancanti([])}} >Annulla Importazione</Button>
                                </center>
                            </Col> 
                            </Row>
                        </CardBody>
                </Fragment>
                </div>
            :""}

            {statoPagina === STATO_PRESENTA_REPORT_FINALE ?
                <div className="app-main__outer">
                <Fragment>

                
                <Clessidra loading={loading}/>
                        <CardBody>
                            <Col md={12}> 
                            {tipologiaImportazioneDefault === 'abbonamenti' ? <CardTitle>Importatore 'Abbonamenti'&nbsp;</CardTitle> :""}
                            {tipologiaImportazioneDefault === 'abbonati' ? <CardTitle>Importatore 'Abbonati'&nbsp;</CardTitle> :""}
                            {tipologiaImportazioneDefault === 'adempimenti' ? <CardTitle>Importatore 'Adempimenti'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'articoli' ? <CardTitle>Importatore 'Articoli'&nbsp;</CardTitle> :""}  
                            {tipologiaImportazioneDefault === 'associaGruppiAllePersone' ? <CardTitle>Importatore 'Associazioni dei Raggruppamenti agli Iscritti'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'associaPosizioniSocietaAllePersone' ? <CardTitle>Importatore 'Associazioni delle Posizioni in Società agli Iscritti'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'associaRiferimentiAllePersone' ? <CardTitle>Importatore 'Associazioni dei Riferimenti agli Iscritti'&nbsp;</CardTitle> :""}
                            {tipologiaImportazioneDefault === 'associaSchedeAllenamento' ? <CardTitle>Importatore 'Associazioni delle Schede Allenamento agli Iscritti (Sez. Allenatori)'&nbsp;</CardTitle> :""} 
                            {tipologiaImportazioneDefault === 'attrezzature' ? <CardTitle>Importatore 'Attrezzature'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'campagneSponsor' ? <CardTitle>Importatore 'Campagne Sponsorizzazione'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'contattiUtili' ? <CardTitle>Importatore 'Contatti Utili'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'corsi' ? <CardTitle>Importatore 'Prestazioni / Attrezzature Prenotabili'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'documenti' ? <CardTitle>Importatore 'Abbonamenti'&nbsp;</CardTitle> :""}  
                            {tipologiaImportazioneDefault === 'entiEsterniFornitori' ? <CardTitle>Importatore 'Enti Esterni / Fornitori'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'entiConvenzioni' ? <CardTitle>Importatore 'Enti Convenzioni'&nbsp;</CardTitle> :""}  
                            {tipologiaImportazioneDefault === 'eventi' ? <CardTitle>Importatore 'Eventi'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'esercizi' ? <CardTitle>Importatore 'Esercizi'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'incassiDiversiDaEsterni' ? <CardTitle>Importatore 'Incassi Diversi da Esterni'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'incassiDiversiDaIscritti' ? <CardTitle>Importatore 'Incassi Diversi da Iscritti'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'incassiQuoteAssociative' ? <CardTitle>Importatore 'Incassi Quote Associative'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'incassiQuoteAbbonamento' ? <CardTitle>Importatore 'Incassi Quote Abbonamento'&nbsp;</CardTitle> :""}  
                            {tipologiaImportazioneDefault === 'iscritti' ? <CardTitle>Importatore 'Iscritti'&nbsp;</CardTitle> :""}  
                            {tipologiaImportazioneDefault === 'giorniDiChiusuraStruttura' ? <CardTitle>Importatore 'Giorni di Chiusura Struttura'&nbsp;</CardTitle> :""} 
                            {tipologiaImportazioneDefault === 'gruppi' ? <CardTitle>Importatore 'Raggruppamenti'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'movimentoPrimaNota' ? <CardTitle>Importatore 'Movimenti Prima Nota'&nbsp;</CardTitle> :""}  
                            {tipologiaImportazioneDefault === 'posizioniInSocieta' ? <CardTitle>Importatore 'Posizioni in Società'&nbsp;</CardTitle> :""}  
                            {tipologiaImportazioneDefault === 'quoteAssociative' ? <CardTitle>Importatore 'Quote Associative'&nbsp;</CardTitle> :""}  
                            {tipologiaImportazioneDefault === 'rimborsi' ? <CardTitle>Importatore 'Rimborsi'&nbsp;</CardTitle> :""}   
                            {tipologiaImportazioneDefault === 'risorse' ? <CardTitle>Importatore 'Risorse'&nbsp;</CardTitle> :""}  
                            {tipologiaImportazioneDefault === 'schedeAllenamento' ? <CardTitle>Importatore 'Schede Allenamento Standard (Sez. Allenatori)'&nbsp;</CardTitle> :""}
                            {tipologiaImportazioneDefault === 'societaAnaloghe' ? <CardTitle>Importatore 'Società Analoghe'&nbsp;</CardTitle> :""}
                            {tipologiaImportazioneDefault === 'spese' ? <CardTitle>Importatore 'Spese'&nbsp;</CardTitle> :""} 
                            {tipologiaImportazioneDefault === 'sponsor' ? <CardTitle>Importatore 'Sponsor'&nbsp;</CardTitle> :""}
                            {tipologiaImportazioneDefault === 'tesseramenti' ? <CardTitle>Importatore 'Tesseramenti'&nbsp;</CardTitle> :""}
                            {tipologiaImportazioneDefault === 'vociDiBilancio' ? <CardTitle>Importatore 'Voci di Bilancio'&nbsp;</CardTitle> :""}
                            </Col> 

                            <Col md={12}>
                            <Table responsive><thead>
                            <tr><td><center>
                            Importazione terminata con successo.<br></br>
                            Sono stati inseriti nel sistema {righeInserite} record nuovi.<br></br>
                            Numero righe ignorate: {righeIgnorate}.<br></br>
                            {stringaRigheIgnorate !== ''? <>Elenco righe ignorate: {stringaRigheIgnorate}</>:"" }
                            </center></td></tr>
            
                            <tr><td><center><Button color="primary" className="mt-2" onClick={() => {
                            setStatoPagina(STATO_SCEGLI_IMPORTAZIONE);
                            setTipologiaImportazioneDefault('');
                            setStatoCaricamentoLayoutPersona(false);
                            setCampiObbligatoriMancanti([]);
                            setCampiMancanti([])}} >Ok</Button></center></td></tr>
                            </thead></Table>
                            </Col> 
                        </CardBody>
                    
                </Fragment>
                </div>

            :""}

            </div>                             
    </Fragment>
    );
};

export default ImportazioneMassiva;