import React, {useState,useEffect,Suspense} from 'react';
import { Table, Input,
  Card, CardBody,
  CardTitle,Button } from 'reactstrap';
import Parametri from '../../parametri';

import FormGridFormRow from '../SchedaIscritto/FormGridFormRow';
import TabellaRicerca from '../tabellaricerca.js';
import Modale from '../Modale.js';
import ModaleRicerca from '../ModaleRicerca.js';
import Clessidra from '../Clessidra.js';
import StampaTutteLeTessere from '../StampaTessera/StampaTutteLeTessere';

const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
const STATO_VAI_IN_ELIMINA= 5;
const STATO_VAI_IN_INSERISCI= 6;

function TabellaIscritti(props) {
    
    const operazione = "elencoPersone";
    
    const [flagElencaDisdetti, setFlagElencaDisdetti] = useState(false);

    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const [idModifica,setIdModifica]=useState('');

    const [flagModaleRicercaAvanzata, setFlagModaleRicercaAvanzata] = useState(false);
    const defaultSelezioneRicercaAvanzata = [
      {"campoDatabase":"", "criterio":"contiene", "dato":""},
      {"campoDatabase":"", "criterio":"contiene", "dato":""},
      {"campoDatabase":"", "criterio":"contiene", "dato":""}
    ];
    const [selezioneRicercaAvanzata, setSelezioneRicercaAvanzata] = useState(defaultSelezioneRicercaAvanzata);

    const coloreStaffRigaPari="#60FFFF";
    const coloreStaffRigaDispari="#60F0F0";
    const coloreAmministratoreRigaPari="#FFFF60";
    const coloreAmministratoreRigaDispari="#F0F060";
    const coloreAmministratoreEStaffRigaPari="#FFB6C1";
    const coloreAmministratoreEStaffDispari="#FFB6C1";

    const [flagModaleStampaTessere, setFlagModaleStampaTessere] = useState(false);

    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Cognome',
        accessor: 'COGNOME'
      },
      {
        etichetta: 'Nome',
        accessor: 'NOME'
      },
      {
        etichetta: 'N. Tessera',
        accessor: 'NUMERO_TESSERA'
      },
      {
        etichetta: 'N. Tessera Interna',
        accessor: 'NUMERO_TESSERA_INTERNA'
      },
      {
        etichetta: 'Cod. Fiscale',
        accessor: 'CODICE_FISCALE'
      },
      {
        etichetta: 'Comune',
        accessor: 'CITTA'
      },
      {
        etichetta: 'Indirizzo',
        accessor: 'INDIRIZZO'
      },
      {
        etichetta: 'Prov.',
        accessor: 'PROVINCIA'
      },
      {
        etichetta: 'Email',
        accessor: 'EMAIL'
      },
      {
        etichetta: 'Cellulare',
        accessor: 'CELLULARE'
      },
      {
        etichetta: 'Tel. Fisso',
        accessor: 'TELEFONO'
      },
      {
        etichetta: 'Data ingresso',
        accessor: 'DATA_INGRESSO_SOC'
      },
      {
        etichetta: 'Data uscita',
        accessor: 'DATA_USCITA_SOC'
      },
      {
        etichetta: '',
        accessor: 'FLAG_STAFF'
      },      
      {
        etichetta: '',
        accessor: 'FLAG_AMMINISTRAZIONE'
      },  
      {
        etichetta: '',
        accessor: 'FLAG_AMMINISTRAZIONE_STAFF'
      },     
    ];
    const arrayTipiDati=[];
    arrayTipiDati[12]="data";
    arrayTipiDati[13]="data";

    const campiRicercaAvanzata = [
      { etichetta: 'CAP', campoDatabase: 'CAP', tipo: 'alfanumerico' },
      { etichetta: 'Città', campoDatabase: 'CITTA', tipo: 'alfanumerico' },
      { etichetta: 'Codice fiscale', campoDatabase: 'CODICE_FISCALE', tipo: 'alfanumerico' },
      { etichetta: 'Cognome', campoDatabase: 'COGNOME', tipo: 'alfanumerico' },
      { etichetta: 'Comune di nascita', campoDatabase: 'COMUNE_NASCITA', tipo: 'alfanumerico' },
      { etichetta: 'Data di emissione certif. medico', campoDatabase: 'DATA_INIZIO_CERT_MEDICO', tipo: 'data' },
      { etichetta: 'Data di emissione doc. identità', campoDatabase: 'DATA_DOC_IDENTITA', tipo: 'data' },
      { etichetta: 'Data di ingresso nella società', campoDatabase: 'DATA_INGRESSO_SOC', tipo: 'data' },
      { etichetta: 'Data di nascita', campoDatabase: 'DATA_NASCITA', tipo: 'data' },
      { etichetta: 'Data di scadenza certif. medico', campoDatabase: 'DATA_FINE_CERT_MEDICO', tipo: 'data' },
      { etichetta: 'Data di uscita nella società', campoDatabase: 'DATA_USCITA_SOC', tipo: 'data' },
      { etichetta: 'Email', campoDatabase: 'EMAIL', tipo: 'alfanumerico' },
      { etichetta: 'Indirizzo', campoDatabase: 'INDIRIZZO', tipo: 'alfanumerico' },
      { etichetta: 'Intolleranze alimentari', campoDatabase: 'INTOLLERANZE_ALIMENTARI', tipo: 'alfanumerico' },
      { etichetta: 'Nome', campoDatabase: 'NOME', tipo: 'alfanumerico' },
      { etichetta: 'Note generiche', campoDatabase: 'NOTE', tipo: 'alfanumerico' },
      { etichetta: 'Note mediche', campoDatabase: 'NOTE_MEDICHE', tipo: 'alfanumerico' },
      { etichetta: 'Numero tessera', campoDatabase: 'NUMERO_TESSERA', tipo: 'alfanumerico' },
      { etichetta: 'Numero tessera interna', campoDatabase: 'NUMERO_TESSERA_INTERNA', tipo: 'alfanumerico' },
      { etichetta: 'Provincia', campoDatabase: 'PROVINCIA', tipo: 'alfanumerico' },
      { etichetta: 'Provincia di nascita', campoDatabase: 'PROVINCIA_NASCITA', tipo: 'alfanumerico' },
      { etichetta: 'Sito web', campoDatabase: 'SITO_WEB', tipo: 'alfanumerico' },
      { etichetta: 'Telefono cellulare', campoDatabase: 'CELLULARE', tipo: 'alfanumerico' },
      { etichetta: 'Telefono fisso', campoDatabase: 'TELEFONO', tipo: 'alfanumerico' }
    ]

    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function VaiElimina(id) {
      setStatoPagina(STATO_VAI_IN_ELIMINA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }

    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      console.log("STATO PAGINA "+statoPagina)
      if(statoPagina===STATO_RICERCA_DATI){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,selezioneRicercaAvanzata:selezioneRicercaAvanzata,elencaDisdetti:flagElencaDisdetti?1:0,emak:props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServer(valoreDiRitorno.risultatoRitorno);setStatoPagina(STATO_OPERAZIONE_CORRETTA);}
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])
    //},[props.numPagina])

 
  function ModaleRicercaAvanzata() {
    return (
      <ModaleRicerca        
        campiRicercaAvanzata={campiRicercaAvanzata}
        criteriIniziali = {selezioneRicercaAvanzata}
        onConferma={(criteri) => { 
          setFlagModaleRicercaAvanzata(false); 
          setSelezioneRicercaAvanzata(criteri);
          setStatoPagina(STATO_RICERCA_DATI);
        }}
        onAnnulla={() => { setFlagModaleRicercaAvanzata(false); setSelezioneRicercaAvanzata(defaultSelezioneRicercaAvanzata);setStatoPagina(STATO_RICERCA_DATI);}}

        callbackConferma = {
          (v) => {
            var indiceCampoSelezionato=-1;
            for (var i=0; i<campiRicercaAvanzata.length; i++) {                      
              if (campiRicercaAvanzata[i].campoDatabase==v[0].campoDatabase) {
                indiceCampoSelezionato=i;
                break;
              }
            }
            if (indiceCampoSelezionato<0) {
              setSelezioneRicercaAvanzata(defaultSelezioneRicercaAvanzata);
            } else {
              if (campiRicercaAvanzata[indiceCampoSelezionato].tipo==="data") {
                // verifica correttezza della data
                var dataOk = false;
                var x = v[0].dato.split("/");
                if (x.length==3) {
                  if (!isNaN(x[0])&&!isNaN(x[1])&&!isNaN(x[2])) {
                    if (x[0]>0 && x[0]<32 && x[1]>0 && x[1]<13 && x[2]>0 && x[2] < 2100) {
                      var dataOk = true;
                    }
                  }
                }
                if (!dataOk) {
                  alert("Data non corretta. Inserisci la data scrivendo giorno/mese/anno. Ad esempio: 1/1/2024");
                  return;
                }
              }
              setSelezioneRicercaAvanzata(v);
            }
            setFlagModaleRicercaAvanzata(false);
            setStatoPagina(STATO_RICERCA_DATI);
          }
        }    
      /> 
    )
  }

  function ModaleStampaTessere() {
    return (
        
      
            <StampaTutteLeTessere
                    {...props}
                    stampaMultipla = '1'
                    sessionId={props.sessionId} 
                    columns={colonne} 
                    data={risultatoServer} 

                    //cognome={cognomeDefault}
                    //nome={nomeDefault}
                    //dataNascita={dataNascitaDefault}
                    //sesso={sessoDefault}
                    //tesseraNumero={tesseraNumeroDefault}
                    //contenutoFileFoto={contenutoFileFoto}
                    //typeFileFoto={typeFileFoto}
                    //estensioneFileFoto={estensioneFileFoto}

                    //per questi dovò fare altre query
                    //arrayGruppiPersona={arrayGruppiPersona}
                    //arrayPosizioniPersona={arrayPosizioniPersona}
                    //arrayCampiAssociatiPosizioniValoriDefault={arrayCampiAssociatiPosizioniValoriDefault}
                    //arrayCampiAssociatiPosizioniNomeDatabase={arrayCampiAssociatiPosizioniNomeDatabase}
                    >
            </StampaTutteLeTessere>
    )
  }
   
  return (
    <div>

      {flagModaleRicercaAvanzata ? <ModaleRicercaAvanzata /> : <></>}
  
      {statoPagina === STATO_VAI_IN_INSERISCI ? 
        <div>
        <Suspense fallback={
          <div className="loader-container">
          <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda iscritto in corso...</h6></div>
          </div>
        }>
        <FormGridFormRow {...props} ope={"inserisci"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
        </Suspense>
      </div>
      :""} 

      {flagModaleStampaTessere? <ModaleStampaTessere /> : <></>}
        
      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda iscritto in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_VAI_IN_ELIMINA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda iscritto in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"cancella"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_Iscr</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
          <CardBody>
            <CardTitle>{flagElencaDisdetti ? "Elenco Iscritti (disdetti inclusi)" : "Elenco iscritti"}</CardTitle>
            <Button color="success" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo</Button>
            <Button className="ml-2" color="success" onClick={() => { setFlagElencaDisdetti(!flagElencaDisdetti); setStatoPagina(STATO_RICERCA_DATI); }}>
              {flagElencaDisdetti ? "Elenca solo gli iscritti attuali" : "Elenca anche gli iscritti disdetti"}
            </Button>
            <Button color="success" className="ml-2" onClick={() => setFlagModaleRicercaAvanzata(true)}>Ricerca avanzata</Button>

            <Button color="success" className="ml-2" onClick={() => setFlagModaleStampaTessere(true)}>Stampa Tessere</Button>

            &nbsp;&nbsp;Legenda:
            <span style={{whiteSpace:"pre",marginLeft:5,paddingLeft:5,paddingRight:5,border:"1px solid black"}}>Iscritto</span>
            <span style={{whiteSpace:"pre",backgroundColor:coloreStaffRigaPari,marginLeft:5,paddingLeft:5,paddingRight:5,border:"1px solid black"}}>Staff</span>
            <span style={{whiteSpace:"pre",backgroundColor:coloreAmministratoreRigaPari,marginLeft:5,paddingLeft:5,paddingRight:5,border:"1px solid black"}}>Amministrazione</span>
            <span style={{whiteSpace:"pre",backgroundColor:coloreAmministratoreEStaffRigaPari,marginLeft:5,paddingLeft:5,paddingRight:5,border:"1px solid black"}}>Staff e Amm.</span>

            {(selezioneRicercaAvanzata[0].campoDatabase!=="" || selezioneRicercaAvanzata[1].campoDatabase!=="" || selezioneRicercaAvanzata[2].campoDatabase!=="") &&
              <div 
                onClick={() => setFlagModaleRicercaAvanzata(true)} 
                style={{
                  marginLeft:0, 
                  marginTop:10, 
                  backgroundColor:"#FFFFFF", 
                  paddingLeft:5, 
                  paddingRight:5, 
                  paddingTop:5, 
                  paddingBottom:5, 
                  cursor:"pointer"
                }}>
                <b>Filtro</b>:&nbsp;
                {selezioneRicercaAvanzata.map((condizione, indiceCondizione) => 
                  <span key={indiceCondizione}>
                    {condizione.campoDatabase !== "" && 
                      <>
                        {indiceCondizione > 0 && <>,&nbsp;</>}
                        {campiRicercaAvanzata.map((x,i)=> {if(x.campoDatabase==condizione.campoDatabase) return x.etichetta; })}
                        &nbsp;{condizione.criterio} {condizione.dato}
                      </>
                    }
                  </span>
                )}
              </div>
            }

          </CardBody>
          {risultatoServer !== undefined && risultatoServer !== "" ?
            <TabellaRicerca 
              columns={colonne} 
              data={risultatoServer} 
              arrayTipiDati={arrayTipiDati} 
              campoChiave={0} 
              campoOrdinamento={1}
              nascondiColonne={[0,14,15,16]} 
              coloriPersonalizzati={[
                {"campo":"14","condizione":"==","valore":"1","carattereRigaPari":"#000000","sfondoRigaPari":coloreStaffRigaPari,"carattereRigaDispari":" #000000","sfondoRigaDispari":coloreStaffRigaDispari},
                {"campo":"15","condizione":"==","valore":"1","carattereRigaPari":"#000000","sfondoRigaPari":coloreAmministratoreRigaPari,"carattereRigaDispari":" #000000","sfondoRigaDispari":coloreAmministratoreRigaDispari},
                {"campo":["16"],"condizione":"==","valore":"2","carattereRigaPari":"#000000","sfondoRigaPari":coloreAmministratoreEStaffRigaPari,"carattereRigaDispari":" #000000","sfondoRigaDispari":coloreAmministratoreEStaffDispari}
              ]}
              callbackSelezionaElemento={VaiModifica} 
              callbackEliminaElemento={VaiElimina}/>
          : 
            <Table responsive bordered hover striped className="mb-0">
            <thead>
              <tr><th>Nessun dato presente</th></tr>
            </thead>
            </Table>
          }
        </div>
      : ""}
    </div>
   );
}
export default TabellaIscritti;