import React, {Fragment,useState,useEffect,useRef} from 'react';
import {
    Table,Col, Row, 
    CardTitle, Button, FormGroup, Label, Input
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import ConvocaAggiungiRimuovi from './ConvocaAggiungiRimuovi.js';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';

import { convertiDataComputer2Umano } from '../../funzioni.js'

import cerchio_vuoto from '../../immagini/cerchio_vuoto.png';
import cerchio_ok from '../../immagini/cerchio_ok.png';
import cerchio_no from '../../immagini/cerchio_no.png';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record

function FormGridFormRow(props) {
    
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno

    let SMeseSup=parseInt(sMese)+1;
    let SAnnoSup=today.getFullYear();
    if(SMeseSup>12){
      //ho cambiato anno:
      SMeseSup=1
      SAnnoSup = parseInt(SAnnoSup)+1;
    }
    SMeseSup = String(SMeseSup);
    if(SMeseSup.length === 1) SMeseSup = "0"+SMeseSup;
    const dataOggiPiuUnMese = SAnnoSup+'-'+SMeseSup+'-'+sGiorno;

    const [modaleInserisciTipoEvento, setModaleInserisciTipoEvento] = React.useState(false);
    const [modaleInserisciDataInizio, setModaleInserisciDataInizio] = React.useState(false);
    const [modaleInserisciLuogo, setModaleInserisciLuogo] = React.useState(false);
    const [modaleRichiediPermessoInserire, setModaleRichiediPermessoInserire] = React.useState(false);
    const [modaleConvocaAggiungiRimuovi, setModaleConvocaAggiungiRimuovi] = React.useState(false);
    const [azioneConvocazioni, setAzioneConvocazioni] = React.useState("");
    const [modaleCopiaEvento, setModaleCopiaEvento] = React.useState(false);
    const [modaleDateErrate, setModaleDateErrate] = React.useState(false);

    const descrizione   = useRef();
    const luogo   = useRef();
    const dataInizio   = useRef();
    const dataFine   = useRef();
    //const note   = useRef();
    const oraInizio  = useRef();
    const oraFine  = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);

    const [flagDatiLettiDaServer,setFlagDatiLettiDaServer]=useState(false);

    const [error,setError]=useState('');

    const [risultatoServerDatiTipo,setRisultatoServerDatiTipo]=useState(''); // non dovrebbe essere useState([]) ?

    const [permessoInserireTipo,setPermessoInserireTipo]=useState(0);

    const [descrizioneDefault,setDescrizioneDefault]=useState('');
    const [luogoDefault,setLuogoDefault]=useState('');
    const [dataInizioDefault,setDataInizioDefault]=useState('');
    const [dataFineDefault,setDataFineDefault]=useState('');
    const [oraInizioDefault,setOraInizioDefault]=useState('');
    const [oraFineDefault,setOraFineDefault]=useState('');
    //const [noteDefault,setNoteDefault]=useState('');
    const dataInizioCopiaDefault=dataOggi;
    const dataFineCopiaDefault=dataOggiPiuUnMese;
    const [tipoCopia,setTipoCopia] = useState(0);

    const [flagCopiaConvocati, setFlagCopiaConvocati] = useState(false);
    const [dataInizioNuovoEvento, setDataInizioNuovoEvento] = useState("");
    const [oraInizioNuovoEvento, setOraInizioNuovoEvento] = useState("");
    const [dataFineNuovoEvento, setDataFineNuovoEvento] = useState("");
    const [oraFineNuovoEvento, setOraFineNuovoEvento] = useState("");

    const [insTipoEdi,setInsTipoEdi]=useState('');

    const [persone, setPersone] = useState([]);
    const [raggruppamenti, setRaggruppamenti] = useState([]);

    const [variazionePresenzeRisultati, setVariazionePresenzeRisultati] = useState([]);

    const [stringhePersone,setStringhePersone] = useState([]);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [numeroConvocatiStaff, setNumeroConvocatiStaff] = useState(0);
    const [numeroConvocatiNonStaff, setNumeroConvocatiNonStaff] = useState(0);
       
    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setFlagDatiLettiDaServer(false);
        setPermessoInserireTipo(0);
    },[]);

    useEffect(() => {
        if(flagDatiLettiDaServer === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[flagDatiLettiDaServer]);

    useEffect(() => {
        if(permessoInserireTipo===1) fetchOperazioneServer();
    },[permessoInserireTipo]);

    useEffect(() => {
        if (modaleCopiaEvento) {
            setTipoCopia(0);
            setDataInizioNuovoEvento("");
            setDataFineNuovoEvento("");
        }
    },[modaleCopiaEvento]);

    useEffect(() => {
        setFlagDatiLettiDaServer(false);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"eventiRecuperaDati",
                idEvento:props.id,
                emak: props.emak,
                sessionId:props.sessionId
            })
        };
        setStatoPagina(STATO_RICERCA_DATI);

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
            (valoreDiRitorno) => {
                if (valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2) {
                    setError("Si è verificato un errore, cortesemente contattare l'assistenza");
                    setStatoPagina(STATO_ERRORE_CONNESSIONE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if (valoreDiRitorno.evento[0]) {
                    setInsTipoEdi(valoreDiRitorno.evento[0].TIPO);//serve per settare la var di stato se non cambi valore nella tendina in caso di modifica
                    setDescrizioneDefault(valoreDiRitorno.evento[0].DESCRIZIONE);
                    setLuogoDefault(valoreDiRitorno.evento[0].LUOGO);
                    setDataInizioDefault(valoreDiRitorno.evento[0].DATA_INIZIO);
                    setDataFineDefault(valoreDiRitorno.evento[0].DATA_FINE);
                    setOraInizioDefault(valoreDiRitorno.evento[0].ORA_INIZIO);
                    setOraFineDefault(valoreDiRitorno.evento[0].ORA_FINE);
                }

                setRaggruppamenti(valoreDiRitorno.raggruppamenti);
                setPersone(valoreDiRitorno.persone);

                impostaVariabiliDopoScaricamento(valoreDiRitorno.persone);
                              
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({op:"elencoTipiEventi",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
                };
                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(                        
                    (valoreDiRitorno) => {
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                        }
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione===1){

                        setRisultatoServerDatiTipo(valoreDiRitorno.risultatoRitorno);
                        setFlagDatiLettiDaServer(true);
                        }
                    },
                    (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);return;}
                    );
        
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);return;}
        ); 
    },[props.ope]);

    function tornaElenco() {
        props.callbackTermineModifica();
    }

    function handleCambiamentoMod(event) {
    // event.preventDefault();
        setVariazioneCampi(1);
    }

    function impostaVariabiliDopoScaricamento(persone) {
        var strPers=[];
        var nConvocatiStaff = 0;
        var nConvocatiNonStaff = 0;
        for (let i=0; i<persone.length; i++) {
            strPers[i] = persone[i].COGNOME;
            if (persone[i].COGNOME!="" && persone[i].NOME!="") strPers[i]+=" ";
            strPers[i] += persone[i].NOME;
            if (persone[i].NUMERO_TESSERA !== "") strPers[i] += " ("+persone[i].NUMERO_TESSERA+")";
            if (persone[i].CONVOCATA==1) {
                if (persone[i].FLAG_STAFF==1) nConvocatiStaff++; else nConvocatiNonStaff++;
            }
        }
        setStringhePersone(strPers);
        setNumeroConvocatiStaff(nConvocatiStaff);
        setNumeroConvocatiNonStaff(nConvocatiNonStaff);
    }
   
    function annullaInserimentoDatiNuovi(){
        setPermessoInserireTipo(0);
        setModaleInserisciTipoEvento(false);
        setModaleInserisciDataInizio(false);
        setModaleInserisciLuogo(false);
        setModaleDateErrate(false);
        setModaleRichiediPermessoInserire(false);
        //setStatoPagina(STATO_INSERISCI);
    }

    function confermaInserimentoDatiNuovi(){
        //setStatoPagina(STATO_INSERISCI);
        setPermessoInserireTipo(1);
    }

    function callbackConvocaAggiungiRimuovi(elenco,azione) {        
        setModaleConvocaAggiungiRimuovi(false);
        setVariazionePresenzeRisultati([]);        
        inviaVariazionePartecipanti(elenco,azione);
    }

    function scaricaPersoneNonConvocate() {
        setStatoPagina(STATO_MEMORIZZAZIONE);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "eventiPersoneNonConvocate", idEvento: props.id, emak: props.emak, sessionId:props.sessionId })
        }
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINE.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); return; }                    

                    setRaggruppamenti(valore_di_ritorno.raggruppamenti);
                    setPersone(valore_di_ritorno.persone);
                    impostaVariabiliDopoScaricamento(valore_di_ritorno.persone);
                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                },
                (error) => {
                    console.log("ERRORE DA BACKEND NON GESTITO (catch)");
                    props.callback(props.PAGINE.login);
                    return;
                }
            );
    }    

    function inviaVariazionePartecipanti(elencoID, azione) {
        setStatoPagina(STATO_MEMORIZZAZIONE);
        var op = "partecipantiEventiAggiungi";
        if (azione == "rimuoviConvocati" || azione == "rimuoviStaff") op = "partecipantiEventiRimuovi";

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: op, idEvento: props.id, elenco: elencoID, emak: props.emak, sessionId:props.sessionId })
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { alert("Operazione fallita: Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); return; }
                    // scarica nuovamente tutte le persone in modo da aggiornare i convocati, eventualmente recependo anche variazioni fatte da altri nel frattempo
                    scaricaPersoneNonConvocate();
                },
                (error) => {
                    console.log("ERRORE DA BACKEND NON GESTITO (catch)");
                    props.callback(props.PAGINE.login);
                    return;
                }
            );
    }
   
    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        let op="";
        let body = [];
        if(props.ope==='inserisci') op = "eventiIns";
        if(props.ope==='modifica') op = "eventiMod";
        if(props.ope==='cancella') op = "eventiCanc";

        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(insTipoEdi=== ''){
                controlli = 1;
                setModaleInserisciTipoEvento(true);
            }
            if(dataInizio.current.value === ''){
                controlli = 1;
                setModaleInserisciDataInizio(true);
            }
            if(luogo.current.value === ''){
                controlli = 1;
                setModaleInserisciLuogo(true);
            }

            //controlla le date:
            if(dataInizio.current.value>dataFine.current.value && dataFine.current.value!==''){
                controlli = 1;
                setModaleDateErrate(true);
            }
        }

        if(controlli===0){ 
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
                return;
            }
            if (props.ope==='inserisci'|| props.ope==='modifica') {   
        
                //se entro qui significa che lo sto modificando o inserendo
                body = JSON.stringify({op:op,
                    descrizione:descrizione.current.value,
                    luogo:luogo.current.value,
                    dataInizio:dataInizio.current.value,
                    dataFine:dataFine.current.value,
                    oraInizio:oraInizio.current.value,
                    oraFine:oraFine.current.value,
                    //note:note.current.value,
                    tipo:insTipoEdi,
                    permessoInserireTipo:permessoInserireTipo,
                    idElemento:props.id,
                    variazionePresenzeRisultati:JSON.stringify({variazionePresenzeRisultati}),
                    emak: props.emak,
                    sessionId:props.sessionId
                });
            } else {
                //se entro qui significa che stò cancellando l'evento
                body = JSON.stringify({op:op,
                    idElemento:props.id,
                    cancella_comunque:1,
                    emak: props.emak,
                    sessionId:props.sessionId
                });
            }
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: body
            };                        
        
            // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
            // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
            // variabile di stato "risultato_server"
            //setStatoPagina(STATO_MEMORIZZAZIONE);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                (valoreDiRitorno) => {
                    setLoading(true);
                    if(valoreDiRitorno.risultatoOperazione===1){
                        tornaElenco();
                    } 
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        if(valoreDiRitorno.mess !== undefined){
                            if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire tipo") !== -1){
                                //setStatoPagina(STATO_RICHIEDI_PERMESSO_INSERIRE),
                                //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                setModaleRichiediPermessoInserire(true);
                                setPermessoInserireTipo(-1);
                                
                            }
                            if(valoreDiRitorno.mess.indexOf("Indicare il tipo da tendina") !== -1)  setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        }
                        //altrimenti vado in errore generico
                        if(!valoreDiRitorno.mess) setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    }
                },
                (error) => {setLoading(true);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
        }
    }

    function copiaEvento() {        
        var numeroEventi = 0;

        if (tipoCopia==0) {
            if (dataInizioNuovoEvento=="") {
                alert("Impossibile memorizzare: indica la data d'inizio dell'evento"); 
                return;
            }    
            if (dataInizioNuovoEvento>dataFineNuovoEvento && dataFineNuovoEvento!="") {
                alert("Impossibile memorizzare: verifica le date inserite, la data di fine evento non può essere antecedente la data d'inizio"); 
                return;                        
            }      
            numeroEventi = 1;          
        } else {
            if (dataInizioNuovoEvento=="") {
                alert("Impossibile memorizzare: indica la data fino alla quale copiare l'evento"); 
                return;
            }
            const dataEvento = new Date(dataInizioDefault);
            const dataFineCopia = new Date(dataInizioNuovoEvento);
            const dataEvento_ms = dataEvento.getTime();
            const dataFineCopia_ms = dataFineCopia.getTime();
            const UN_GIORNO_MS = 1000 * 60 * 60 * 24;           
            const diff_ms = dataFineCopia_ms - dataEvento_ms;
            if (diff_ms<0) {
                alert("Impossibile memorizzare: la data fino alla quale copiare l'evento deve essere successiva alla data dell'evento"); 
                return;
            }
            const giorni = Math.round(diff_ms/UN_GIORNO_MS);            
            var descrizione = "dal giorno " + convertiDataComputer2Umano(dataInizioDefault,"") + " (escluso) al " + convertiDataComputer2Umano(dataInizioNuovoEvento,"") + " (compreso)";
            if (tipoCopia==1) {
                descrizione += ", tutti i giorni";
                numeroEventi = giorni;
            }
            if (tipoCopia==2) {
                descrizione += ", tutte le settimane";
                numeroEventi = Math.floor(giorni/7);
            }
            if (tipoCopia==3) {
                descrizione += ", tutti i mesi";
                const meseEvento = dataEvento.getMonth()+1;
                const annoEvento = dataEvento.getFullYear();
                const meseFineCopia = dataFineCopia.getMonth()+1;
                const annoFineCopia = dataFineCopia.getFullYear();
                numeroEventi = (annoFineCopia-annoEvento)*12 + meseFineCopia - meseEvento;
            }

            if (numeroEventi==0) {
                alert("Impossibile memorizzare: verifica le date, con quelle impostate non vengono creati nuovi eventi"); 
                return;
            }

        }
        var testoAvviso = "";
        if (numeroEventi == 1) {
            testoAvviso = "Verrà creato 1 nuovo evento, confermi?";
        } else {
            testoAvviso = "Verranno creati " + numeroEventi + " nuovi eventi, " + descrizione + ": confermi?";
        }

        var answer = window.confirm(testoAvviso);
        if (answer) copiaEventoConfermato();
        else setModaleCopiaEvento(false);
    }

    function copiaEventoConfermato() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "eventiCopia", 
                idEvento: props.id, 
                tipoCopia: tipoCopia, 
                dataInizio: dataInizioNuovoEvento,
                oraInizio: oraInizioNuovoEvento,
                dataFine: dataFineNuovoEvento,
                oraFine: oraFineNuovoEvento,
                copiaConvocati:flagCopiaConvocati?1:0,
                emak: props.emak,
                sessionId:props.sessionId
            })
        };
        setLoading(true);
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if(valore_di_ritorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        var titoloErrore = "Operazione fallita";
                        var messaggioErrore = "Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica";
                        if (valore_di_ritorno.errore != undefined) {
                            if (valore_di_ritorno.errore != "") {
                                titoloErrore = "Errore";
                                messaggioErrore = valore_di_ritorno.errore;
                            }
                        }
                        alert(titoloErrore+": "+messaggioErrore);
                        return;
                    }
                    setModaleCopiaEvento(false);
                    setLoading(false);
                    tornaElenco();
                },
                (error) => {
                    console.log("ERRORE DA BACKEND NON GESTITO (catch)");
                    props.setPagina(props.PAGINE.tornaLogin);
                    return;
                }
            );
    }

    if (statoPagina === STATO_MEMORIZZAZIONE) return (<div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
    </div>
    </div>);
    if (statoPagina === STATO_ERRORE_CONNESSIONE) return (<Fragment><div>Errore: 1Eve {error.message}</div></Fragment>);
    if (statoPagina === STATO_OPERAZIONE_FALLITA) return (<Fragment><div>Errore per operazione errata</div></Fragment>);
    if (statoPagina === STATO_RICERCA_DATI) return (<div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
    </div>
    </div>);   
  
    if (statoPagina === STATO_RICERCA_DATI_CORRETTA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                
                {modaleRichiediPermessoInserire=== true && permessoInserireTipo===-1?
                    <Modale 
                        titolo="Richiesta conferma per inserimento"
                        flagErrore={true}
                        contenuto={<div>
                                Vuoi davvero inserire {insTipoEdi} nell'elenco dei tipi di eventi?                                 
                            </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);confermaInserimentoDatiNuovi()}
                                
                            },
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }   
                        ]}
                    />
                :""}

                {modaleInserisciTipoEvento=== true ?
                    <Modale 
                        titolo="Richiesta tipo evento"
                        flagErrore={true}
                        contenuto={<div>Non è stato indicato il tipo evento, che è necessario</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                :""}

                {modaleInserisciDataInizio=== true ?
                    <Modale 
                        titolo="Richiesta Data Inizio"
                        flagErrore={true}
                        contenuto={<div>Non è stata indicata la data di inizio, che è necessaria</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                :""}

                {modaleInserisciLuogo=== true ?
                    <Modale 
                        titolo="Richiesta luogo"
                        flagErrore={true}
                        contenuto={<div>Non è stato indicato il luogo, che è necessario</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                :""}

                {modaleCopiaEvento=== true ?
                    <Modale 
                        titolo="Copia Evento"
                        flagErrore={true}
                        contenuto={<div>
            
                        
                            <Clessidra loading={loading}/>
                            <Row form>                              
                                <Col md={12}>                                                                    
                                Verranno copiati: luogo e descrizione.<br/><br/>
                                <Input type="checkbox" 
                                    checked = {flagCopiaConvocati ? 'checked' :""}
                                    onInput={(e)=> setFlagCopiaConvocati(!flagCopiaConvocati)}
                                /> Copia anche l'elenco dei convocati: {flagCopiaConvocati?"sì":"no"}<br/><br/>
                                </Col>
                                <Col md={12}>                                    
                                    Quante volte vuoi copiare questo evento?<br/>
                                    {/*tipoCopia : 0:una volta sola, 1=ogni giorno, 2=ogni settimana, 3=ogni mese*/}
                                    <select onChange={(event) => setTipoCopia(event.target.value)}>
                                    <option selected value="0">Una volta sola</option>
                                    <option value="1">Uno al giorno</option>
                                    <option value="2">Uno alla settimana</option>
                                    <option value="3">Uno al mese</option>
                                    </select>
                                </Col>
                                <br/><br/><br/>
                                {tipoCopia==0 ?
                                    <>
                                        <Col md={6}>
                                            <Label for="dataInizio">Data di inizio del nuovo evento</Label>
                                            <Input maxLength={10} type="date" placeholder=""
                                                onChange={(e) => setDataInizioNuovoEvento(e.target.value)}                                                    
                                            /> 
                                        </Col>
                                        <Col md={6}>
                                            <Label for="OraInizio">Ora di inizio</Label>
                                            <Input maxLength={10} type="text" placeholder=""
                                                onChange={(e) => setOraInizioNuovoEvento(e.target.value)}
                                            /> 
                                        </Col>
                                        <br/><br/>
                                        <Col md={6}>
                                            <Label for="dataFine">Data di fine del nuovo evento</Label>
                                            <Input maxLength={10} type="date" placeholder=""
                                                onChange={(e) => setDataFineNuovoEvento(e.target.value)}
                                            />                                         
                                        </Col>
                                        <Col md={6}>
                                            <Label for="OraInizio">Ora di fine</Label>
                                            <Input maxLength={10} type="text" placeholder=""
                                                onChange={(e) => setOraFineNuovoEvento(e.target.value)}
                                            /> 
                                        </Col>

                                    </>
                                :
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="dataInizio">Dal giorno {convertiDataComputer2Umano(dataInizioDefault, "")} (escluso) al giorno (compreso)</Label>
                                        <Input maxLength={10} type="date" placeholder=""
                                            onChange={(e) => setDataInizioNuovoEvento(e.target.value)}
                                        /> 
                                    </FormGroup>
                                </Col>
                                }
                            </Row>
                        

                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);copiaEvento()}
                            },
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi();setModaleCopiaEvento(false)}
                            }       
                        ]}
                    />
                :""}

                {modaleDateErrate ?
                    <Modale 
                        titolo="Date Errate"
                        flagErrore={true}
                        contenuto={<div>Verifica le date inserite, la data di fine evento non può essere antecedente la data d'inizio</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                :""}

                {modaleConvocaAggiungiRimuovi &&  
                    <ConvocaAggiungiRimuovi 
                        azioneConvocazioni={azioneConvocazioni}
                        setPagina={props.setPagina}
                        PAGINE={props.PAGINE}
                        mioId={props.identitaSelezionata.idpersona}
                        idEvento={props.id}
                        persone={persone}
                        stringhePersone={stringhePersone}
                        raggruppamenti={raggruppamenti}
                        callbackConvocaAggiungiRimuovi={callbackConvocaAggiungiRimuovi}
                    /> 
                }

                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>  
                    {props.ope === "inserisci" ? <CardTitle>Inserimento Evento</CardTitle> :""}
                    {props.ope === "modifica" ? <CardTitle>Modifica Evento</CardTitle> :""}
                    
                        
                    <Clessidra loading={loading}/>

                    {props.ope === "cancella" ?
                        <FinestraDialogo 
                            titolo="Conferma eliminazione"
                            flagErrore={true}                                
                            contenuto={<div>
                                Confermi l'eliminazione dell'evento <b>{descrizioneDefault}</b> 
                                {insTipoEdi!=="" ? <> ({insTipoEdi})</>:<></>}
                                {dataInizioDefault!=="" ? <> iniziato il <b><TrasformaDateAUmano data={dataInizioDefault}></TrasformaDateAUmano></b> </>:<></>}
                                {dataFineDefault!=="" ? <> terminato il <b><TrasformaDateAUmano data={dataFineDefault}></TrasformaDateAUmano></b> </>:<></>}
                                ?<br/>                                    
                            </div>}
                            bottoni={[
                                {
                                    "etichetta":"Conferma",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                },
                                {
                                    "etichetta":"Annulla",
                                    "tipo":"primary",
                                    callback: () => {tornaElenco()}
                                }
                            ]}
                        />                        

                    :

                        <>
                        <Row form>  

                        <Col md={12}>
                        <FormGroup>
                        <Label>Tipo evento*</Label>
                        <TendinaEditabile
                            //titolo={"Tipo evento*"}
                            nomeCampo="DESCRIZIONE"
                            valoreDefault={insTipoEdi}
                            arrayValori={risultatoServerDatiTipo}
                            callbackSetVariazioneCampi={setVariazioneCampi}
                            callbackSetValoreTendEditabile={setInsTipoEdi}
                        ></TendinaEditabile>
                        </FormGroup>
                        </Col>

                        </Row>
                        <Row form>  
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="descrizione">Descrizione</Label>
                                        <Input maxLength={200} type="text" name="descrizione" id="descrizione"
                                        innerRef={descrizione} 
                                        placeholder=""
                                        defaultValue={descrizioneDefault}
                                        onInput={(e)=>{handleCambiamentoMod(e);}}/> 
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="luogo">Luogo*</Label>
                                        <Input maxLength={200} type="text" name="luogo" id="luogo"
                                        innerRef={luogo} 
                                        placeholder=""
                                        defaultValue={luogoDefault}
                                        onInput={(e)=>{handleCambiamentoMod(e);}}/> 
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>  
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="dataInizio">Data Inizio*</Label>
                                        <Input maxLength={10} type="date" name="dataInizio" id="dataInizio"
                                        innerRef={dataInizio} 
                                        placeholder=""
                                        defaultValue={dataInizioDefault}
                                        onInput={(e)=>{handleCambiamentoMod(e);}}/> 
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label for="oraInizio">Orario Inizio</Label>
                                        <Input maxLength={10} type="text" name="oraInizio" id="oraInizio"
                                        innerRef={oraInizio} 
                                        placeholder=""
                                        defaultValue={oraInizioDefault}
                                        onInput={(e)=>{handleCambiamentoMod(e);}}/> 
                                </FormGroup>

                                {/*<FormGroup>
                                <Label for="oraInizio">Orario Inizio</Label><br/>                                         
                                        <TendinaOrariAStep
                                            step = '30' //step espresso in minuti tra un orario ed il successivo
                                            orarioDefault= {oraInizioDefault}
                                            callbackSetVariazioneCampi={callbackSetVariazioneCampiOrari}
                                            callbacksetOrarioSelezionato={callbacksetOraSelezionatoInizio} 
                                        ></TendinaOrariAStep>                                             
                                </FormGroup>*/}
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="dataFine">Data Fine</Label>
                                        <Input maxLength={10} type="date" name="dataFine" id="dataFine"
                                        innerRef={dataFine} 
                                        placeholder=""
                                        defaultValue={dataFineDefault}
                                        onInput={(e)=>{handleCambiamentoMod(e);}}/> 
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label for="oraFine">Orario Fine</Label>
                                        <Input maxLength={10} type="text" name="oraFine" id="oraFine"
                                        innerRef={oraFine} 
                                        placeholder=""
                                        defaultValue={oraFineDefault}
                                        onInput={(e)=>{handleCambiamentoMod(e);}}/> 
                                </FormGroup>
                                {/*}
                                <FormGroup>
                                <Label for="oraFine">Ora Fine</Label><br/>
                                    <TendinaOrariAStep
                                        step = '30' //step espresso in minuti tra un orario ed il successivo
                                        orarioDefault= {oraFineDefault}
                                        callbackSetVariazioneCampi={callbackSetVariazioneCampiOrari}
                                        callbacksetOrarioSelezionato={callbacksetOraSelezionatoFine} 
                                    ></TendinaOrariAStep>                                    
                            </FormGroup>*/}
                            </Col>
                        </Row>
        
                        {/*<Row form>  
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="note">Note</Label>
                                        <Input type="text" name="note" id="note"
                                        innerRef={note} 
                                        placeholder=""
                                        defaultValue={noteDefault}
                                        onInput={(e)=>{handleCambiamentoMod(e);}}/> 
                                </FormGroup>
                            </Col>
                        </Row>*/}

                        <div className='schedaSottotitolo'>
                        Convocati {numeroConvocatiNonStaff > 0 && ": " + numeroConvocatiNonStaff}
                        </div>
                        
                        
                        {numeroConvocatiNonStaff > 0 ?
                            <Row form>
                            <Col md={12}>
                            <Table bordered className="mb-0">
                            <tbody>
                            <tr>
                            <td className="titoloTabella">Convocato</td>
                            <td className="titoloTabella"><center>Conferma</center></td>
                            <td className="titoloTabella"><center>Presente</center></td>
                            <td className="titoloTabella">Risultati</td>
                            </tr>
                            
                        
                            {persone.map((x,i) =>
                                x.CONVOCATA==1 && x.FLAG_STAFF==0 ?
                                <Fragment>
                                <tr>

                                <td>{stringhePersone[i]}</td>
                                <td>
                                    <center>
                                    {x.CONFERMA === "1" ?  <Label>Si</Label> :<Label>No</Label>}
                                    </center>
                                </td>
                                <td>
                                    <center>
                                        <div style={{cursor:"pointer"}} onClick={() => {
                                                handleCambiamentoMod();
                                                var presente=1;
                                                const presente_precedente = x.PRESENTE;
                                                if (x.PRESENTE==1) presente=0;
                                                var personeLocale=[...persone];
                                                for (let n=0; n<personeLocale.length; n++) if (x===personeLocale[n]) { personeLocale[n].PRESENTE=presente; break; }
                                                setPersone(personeLocale);

                                                // memorizzo la variazione
                                                var v=[...variazionePresenzeRisultati];
                                                var flagTrovato = false;
                                                for (let n=0; n<v.length; n++) {
                                                    if (v[n].ID==x.ID) {
                                                        v[n].PRESENTE = presente;
                                                        flagTrovato = true;
                                                        break;
                                                    }
                                                }
                                                if (!flagTrovato) {
                                                    var elemento = {}; // è un oggetto, non un array
                                                    elemento.ID = x.ID;

                                                    // attenzione nella riga seguente: "=presente_precedente" e non "=x.PRESENTE" perchè 
                                                    // sopra l'istruzione "personeLocale[n].PRESENTE=" l'ha modificato
                                                    // (evidentemente "var personeLocale=[...persone]" non copia l'array persone in un 
                                                    // altro array personeLocale ma ne fa solo un alias)
                                                    elemento.PRESENTE_PRECEDENTE = presente_precedente; 
                                                    elemento.RISULTATI_PRECEDENTE = x.RISULTATI;
                                                    elemento.PRESENTE = presente;
                                                    elemento.RISULTATI = x.RISULTATI;
                                                    v.push(elemento);
                                                }
                                                setVariazionePresenzeRisultati(v);                                                
                                            }}
                                        >
                                            {x.PRESENTE == -1 && <img height={20} src={cerchio_vuoto} alt="?"/>}
                                            {x.PRESENTE == 0 && <img height={20} src={cerchio_no} alt="NO"/>}
                                            {x.PRESENTE == 1 && <img height={20} src={cerchio_ok} alt="SI"/>}
                                        </div>                                    
                                    </center>
                                </td>
                                <td>
                                    <Input type="text" name="risultati" id="risultati" defaultValue={x.RISULTATI} placeholder=""
                                        onInput={(e)=>{
                                            handleCambiamentoMod(e);
                                            // memorizzo la variazione
                                            var v=[...variazionePresenzeRisultati];
                                            var flagTrovato = false;
                                            for (let n=0; n<v.length; n++) {
                                                if (v[n].ID==x.ID) {
                                                    v[n].RISULTATI = e.target.value;
                                                    flagTrovato = true;
                                                    break;
                                                }
                                            }
                                            if (!flagTrovato) {
                                                var elemento = {}; // è un oggetto, non un array
                                                elemento.ID = x.ID;
                                                elemento.PRESENTE_PRECEDENTE = x.PRESENTE;
                                                elemento.RISULTATI_PRECEDENTE = x.RISULTATI;
                                                elemento.PRESENTE = x.PRESENTE;
                                                elemento.RISULTATI = e.target.value;
                                                v.push(elemento);
                                            }
                                            setVariazionePresenzeRisultati(v);
                                        }}
                                    /> 
                                </td>
                                
                                </tr>
                                </Fragment>
                            : ""
                            )}
                            </tbody>
                            </Table>

                            </Col>
                            </Row>
                        :
                        
                            <Row form><Col md={12}>
                            {props.ope=="inserisci" ? 
                                    <Label>Potrai fare le convocazioni dopo aver inserito l'evento</Label>
                                :
                                    <Label>Non ci sono convocati per questo evento</Label>
                                }
                            </Col></Row>
                        }

                        <div className='schedaSottotitolo'>
                            Staff dell'evento {numeroConvocatiStaff > 0 && ": " + numeroConvocatiStaff}
                        </div>

                        {numeroConvocatiStaff > 0 ?
                            <Fragment> 
                            <Row form>
                            <Col md={12}>
                            <Table bordered className="mb-0">
                            <tbody>

                            {persone.map((x,i) =>
                                x.CONVOCATA==1 && x.FLAG_STAFF==1 &&
                                    <tr><td>{stringhePersone[i]}</td></tr>
                            )}

                            </tbody>
                            </Table>

                            </Col>
                            </Row>
                            </Fragment> 
                        :
                            <Row form><Col md={12}>
                                {props.ope=="inserisci" ? 
                                    <Label>Potrai aggiungere altri membri dello Staff dopo aver inserito l'evento</Label>
                                :
                                    <Label>Non ci sono elementi dello staff per questo evento</Label>
                                }
                            </Col></Row>
                        }

                        </>
                    }
                            

                </div>

                <div className="schedaSpazioInferiore">
                    {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                    {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                
                    <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                        {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                    </Button>

                    {props.ope === "modifica" && variazioneCampi === 0 &&
                        <>
                            <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineASinistra" onClick={() => {setDisabilitaBottoni(true);setModaleCopiaEvento(true)}}>Copia evento</Button>
                            <Button disabled={disabilitaBottoni} className="mt-2 bottoneMargineASinistra" color='primary' onClick={() => {setModaleConvocaAggiungiRimuovi(true); setAzioneConvocazioni("aggiungiConvocati")}}>Aggiungi Convocati</Button>
                            <Button disabled={disabilitaBottoni} className="mt-2 bottoneMargineASinistra" color='primary' onClick={() => {setModaleConvocaAggiungiRimuovi(true); setAzioneConvocazioni("rimuoviConvocati")}}>Rimuovi Convocati</Button>
                            <Button disabled={disabilitaBottoni} className="mt-2 bottoneMargineASinistra" color='primary' onClick={() => {setModaleConvocaAggiungiRimuovi(true); setAzioneConvocazioni("aggiungiStaff")}}>Aggiungi Staff</Button>
                            <Button disabled={disabilitaBottoni} className="mt-2 bottoneMargineASinistra" color='primary' onClick={() => {setModaleConvocaAggiungiRimuovi(true); setAzioneConvocazioni("rimuoviStaff")}}>Rimuovi Staff</Button>
                        </>
                    }                                                            
                </div>                            
            </div>
        );
    }

    // default, stato sbagliato
    return <Fragment><div>Errore SE-P{statoPagina}</div></Fragment>
}
export default FormGridFormRow;
