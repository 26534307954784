import React from 'react';
import FadeLoader from "react-spinners/FadeLoader";

function Clessidra(props) {
    
    //const override = {position: "absolute",left: "50%",top: "50%",zIndex: 1000};
    const override = {position: "absolute",left: "50%",top: "50%"};
    
    return (
        <FadeLoader color={"#000000"} loading={props.loading} cssOverride={override} aria-label="Loading Spinner" data-testid="loader"/>
   );
}
export default Clessidra;
