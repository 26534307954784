
function FormattaFlagVideo(valore) {
    let valoreOutput='';
    //
    if(valore){
        if(parseInt(valore)===0) valoreOutput = "No";
        if(parseInt(valore)===1) valoreOutput = "Si";
    }else valoreOutput = 'No';

    return valoreOutput;
}

export default FormattaFlagVideo;
