import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagAttivaModaleMezzoNonCancellabile, setFlagAttivaModaleMezzoNonCancellabile] = useState(0);
    const [flagAttivaModaleMezzoNonModificabile, setFlagAttivaModaleMezzoNonModificabile] = useState(0);
    const [flagAttivaModaleMezzoNonInseribile, setFlagAttivaModaleMezzoNonInseribile] = useState(0);
    const [flagAttivaModaleInserisciDescrizione, setFlagAttivaModaleInserisciDescrizione] = useState(0);
    const [flagAttivaModaleInserisciPeso, setFlagAttivaModaleInserisciPeso] = useState(0);
    const [flagAttivaModaleMezzoAlternativoErrato, setFlagAttivaModaleMezzoAlternativoErrato] = useState(0);

    const descrizione   = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    
    const [descrizioneDefault,setDescrizioneDefault] = useState('');
    const [pesoDefault,setPesoDefault] = useState('');

    const [risultatoServerDatiMezzi,setRisultatoServerDatiMezzi]=useState('');
    const [statoTendinaMezzi,setStatoTendinaMezzi]=useState(false);
    const [insMezziEdi,setInsMezziEdi]=useState('');

    const [variazioneCampiTendinaMezzi,setVariazioneCampiTendinaMezzi]=useState(0);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [pesiPossibili,setPesiPossibili]= useState(["Alto","Medio-Alto","Medio","Medio-Basso","Basso"]);
    
    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaMezzi(false);
    },[]);

    useEffect(() => {
        if(statoTendinaMezzi === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaMezzi]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("mezziComunicazioneCrmIns");
        if(props.ope==='modifica') setOperazione("mezziComunicazioneCrmMod");
        if(props.ope==='cancella') setOperazione("mezziComunicazioneCrmCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"mezziComunicazioneCrmRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                    setPesoDefault(valoreDiRitorno.risultatoRitorno[0].PESO);
                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[props.ope]);

    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function tornaScheda(valore) {
        setFlagAttivaModaleMezzoNonModificabile(0);
        setFlagAttivaModaleMezzoNonInseribile(0);
        setFlagAttivaModaleInserisciDescrizione(0);
        setFlagAttivaModaleInserisciPeso(0);
        setFlagAttivaModaleMezzoAlternativoErrato(0);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(descrizione.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDescrizione(1);
            }
            if(pesoDefault === ''){
                controlli = 1;
                setFlagAttivaModaleInserisciPeso(1);
            }
        }

        if(controlli===0){ 
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{

                // parametri per inviare i dati al server:
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ?
                    JSON.stringify({op:operazione,
                        descrizione:descrizione.current.value,
                        peso:pesoDefault,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        mezzo:insMezziEdi,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Mezzo di comunicazione non cancellabile: risulta utilizzato in comunicazioni") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setRisultatoServerDatiMezzi(valoreDiRitorno.mezzi);
                                    setFlagAttivaModaleMezzoNonCancellabile(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Mezzo di comunicazione non modificabile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleMezzoNonModificabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Mezzo di comunicazione non inseribile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleMezzoNonInseribile(1);
                                }
                                
                                if(valoreDiRitorno.mess.indexOf("Peso non valorizzato") !== -1){
                                    setFlagAttivaModaleInserisciPeso(1);
                                }

                                if(valoreDiRitorno.mess.indexOf("Descrizione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDescrizione(1);
                                }

                                if(valoreDiRitorno.mess.indexOf("Mezzo di comunicazione alternativo non presente nel db") !== -1){
                                    setFlagAttivaModaleMezzoAlternativoErrato(1);
                                }
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
           
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1MezComCrm {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                        
                    {flagAttivaModaleInserisciDescrizione===1 ?
                        <Modale 
                            titolo="Richiesta descrizione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la descrizione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciPeso===1 ?
                        <Modale 
                            titolo="Richiesta Livello di Importanza"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato il livello di importanza, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleMezzoNonCancellabile=== 1 ?
                        <Modale 
                        titolo="Informativa mezzo di comunicazione non cancellabile"
                        flagErrore={true}
                        contenuto={<div><Row><Col>
                            <center>Il mezzo di comunicazione risulta associato ad uno o più comunicazioni. 
                                Per eliminarlo è necessario selezionare uno degli altri mezzi di comunicazione attualmente esistenti al quale associare le comunicazioni attualmente associate al mezzo di comunicazione che si intende eliminare. 
                                Si ricorda che il livello di importanza del mezzo che si intende eliminare è pari a: {pesoDefault}.
                                In tendina sono riportati tra parentesi i livelli di importanza di ciascun mezzo di comunicazione disponibile.
                                </center>
                            </Col></Row>
                            <Row><Col><center><TendinaEditabile
                                    //titolo={"Mezzi di Comunicazione"}
                                    nomeCampo="DESCRIZIONE"
                                    nomeCampoParentesi="PESO"
                                    valoreDefault={insMezziEdi}
                                    bloccoInserimento="1"
                                    arrayValori={risultatoServerDatiMezzi}
                                    callbackSetVariazioneCampi={setVariazioneCampiTendinaMezzi}
                                    callbackSetValoreTendEditabile={setInsMezziEdi}
                                ></TendinaEditabile></center>
                            </Col></Row>
                            </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);fetchOperazioneServer()}
                            }, 
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaElenco()}
                            }   
                        ]}
                    />
                    :""}

                    {flagAttivaModaleMezzoAlternativoErrato=== 1 ?
                        <Modale 
                        titolo="Informativa mezzo di comunicazione alternativo errato"
                        flagErrore={true}
                        contenuto={<div>Il mezzo di comunicazione alternativo fornito non risulta presente nel database</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}

                    {flagAttivaModaleMezzoNonModificabile=== 1 ?
                        <Modale 
                        titolo="Informativa mezzo di comunicazione non modificabile"
                        flagErrore={true}
                        contenuto={<div>La descrizione del mezzo di comunicazione non è modificabile in {descrizione.current.value} poichè nel database è già presente un mezzo di comunicazione con la stessa descrizione</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}

                    {flagAttivaModaleMezzoNonInseribile=== 1 ?
                        <Modale 
                        titolo="Informativa mezzo di comunicazione non inseribile"
                        flagErrore={true}
                        contenuto={<div>La descrizione del mezzo di comunicazione non è inseribile in {descrizione.current.value} poichè nel database è già presente un mezzo di comunicazione con la stessa descrizione</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Mezzo di Comunicazione</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Mezzo di Comunicazione</CardTitle> :""}
                        
                            
                            <Clessidra loading={loading}/>


                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione del mezzo di comunicazione <b>{descrizioneDefault}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :
                                <>
                                <Row form>  
                                    <Col md={10}>
                                        <FormGroup>
                                            <Label for="descrizione">Descrizione*</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={200} type="text" name="descrizione" id="descrizione"
                                                innerRef={descrizione} 
                                                placeholder=""
                                                defaultValue={descrizioneDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value)}}/> 
                                            :""}
                                            {props.ope === "cancella" ? <Label>: {descrizioneDefault}</Label> : ""}
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Label for="livello">Livello di Importanza*</Label>        
                                        <select
                                                    className="form-control"
                                                    style={{ width: '150px' }}
                                                    value={pesoDefault} 
                                                    onChange={(e) => {
                                                        setPesoDefault(e.target.value);
                                                        handleCambiamentoMod(e)
                                                    }}
                                                >
                                                <option></option>

                                                {pesiPossibili.map((elementoPossibile,indexPossibile) =>

                                                    pesoDefault !== undefined && elementoPossibile === pesoDefault ? 
                                                    <option key={indexPossibile} value={elementoPossibile} selected>{elementoPossibile}</option> : 
                                                    <option key={indexPossibile} value={elementoPossibile}>{elementoPossibile}</option>
                                                                        
                                                    )};

                                                </select>
                                                </Col>
                                </Row>
                                
                                
                                </>
                            }
                                
                                 
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                                
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                        
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
