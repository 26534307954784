import React, {useState, useEffect, useRef} from 'react';

import {
    Table, Col, Row, Card,
    CardTitle, Button, Label, Input
} from 'reactstrap';

import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js'

import Parametri from '../../parametri';
import Clessidra from '../Clessidra.js';
import TendinaEditabile from '../TendinaEditabile.js';
import FinestraDialogo from '../FinestraDialogo.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import iconaPresenze from '../../immagini/convocati2.png';
import iconaRisultati from '../../immagini/cronometro.png';
import iconaRisultatiSingolo from '../../immagini/cronometro-uomo.png';

import Interrogativo from '../../immagini/interrogativo-azzurro.png';

import Modale from '../Modale.js';


const INDEFINITO = 0;

const STATO_SCELTA_TIPO_REPORT = 1;
const STATO_ATTESA_BACKEND = 2;
const STATO_IMPOSTA_OPZIONI_REPORT = 3;
const STATO_VISUALIZZA_REPORT = 4;
const STATO_ESPORTA_PDF = 5;

const TIPO_REPORT_PRESENZE = 1;
const TIPO_REPORT_RISULTATI = 2;
const TIPO_REPORT_SINGOLO_ATLETA = 3;

function ReportEventi(props) {

    const [stato, setStato] = useState(STATO_SCELTA_TIPO_REPORT);
    const [tipoReport, setTipoReport] = useState(INDEFINITO);

    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');
    
    const [dataInizio, setDataInizio] = useState("");
    const [dataFine, setDataFine] = useState("");
    const [elencoTipiEventi, setElencoTipiEventi] = useState([]);
    const [elencoIdTipiEventi, setElencoIdTipiEventi] = useState([]);
    const [flagIdTipiEventiSelezionati, setFlagIdTipiEventiSelezionati] = useState([]);
    const [elencoGruppi, setElencoGruppi] = useState([]);
    const [elencoIdGruppi, setElencoIdGruppi] = useState([]);
    const [flagIdGruppiSelezionati, setFlagIdGruppiSelezionati] = useState([]);
    const [esitoReport, setEsitoReport] = useState([]);

    const [elencoNomiPersone, setElencoNomiPersone] = useState([]);
    const [idPersonaSelezionata, setIdPersonaSelezionata] = useState(-1);
    const [nomePersonaSelezionata, setNomePersonaSelezionata] = useState("");

    const opzioniOrdinamento = ["crescente per risultato","decrescente per risultato","crescente per data","decrescente per data","nessuno"];
    const [opzioneOrdinamento, setOpzioneOrdinamento] = useState(opzioniOrdinamento[0]);

    const refDataInizio = useRef();
    const refDataFine = useRef();

    const [flagModaleInfoPeriodo, setFlagModaleInfoPeriodo] = React.useState(false);
    const [flagModaleRichiediAtleta, setFlagModaleRichiediAtleta] = React.useState(false);

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    useEffect(() => {
        if (ricercaLogo!==1) scaricaLogo();
    },[ricercaLogo])

    function VoceMenu(props) {
        return (
            <div 
                style={{flex:1, border: "1px solid black", marginLeft:10, marginRight:10, padding: 10, cursor:"pointer", textAlign:'center'}} 
                onClick={() => { setStato(STATO_ATTESA_BACKEND); setTipoReport(props.tipoReport); scaricaElenchi(props.tipoReport)}}
            >
                <img src={props.icona} height="50vh" width="50vw" alt=""/>
                <div style={{padding:"0.5em"}}/>
                <Button color="primary">{props.etichetta}</Button>
            </div> 
        )
    }

    function VoceElencoCheckbox(props) {
        return (
        <>
            <Input type='checkbox' className="ml-1 mt-0" style={{position:"relative"}} checked={props.selezionata}/>
            <Label style={{
                flex:1,
                cursor:"pointer", 
                marginLeft:5,                                 
                backgroundColor:props.selezionata?"#90F0C0":"",
                userSelect: "none",
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                msUserSelect: "none"
            }}>
                &nbsp;{props.etichetta}&nbsp;
            </Label>
        </>
        )
    }

    function TitoloReport() {
        return (
            <CardTitle>
                <TestoTitoloReport/>
            </CardTitle>
        )
    }

    function TestoTitoloReport() {
        switch (tipoReport) {
            case TIPO_REPORT_PRESENZE: return "Report Presenze Atleti";
            case TIPO_REPORT_RISULTATI: return "Report Risultati Atleti";
            case TIPO_REPORT_SINGOLO_ATLETA: return "Report Risultati Singolo Atleta";
            default: return "";
        }
    }

    function VisualizzaSelezioniUtente(props) {
        var ret="";
        for (var i=0; i<props.arrayFlag.length; i++) {
            if (props.arrayFlag[i]) {
                if (ret!=="") ret+=", ";
                ret+=props.elenco[i];
            }
        }
        if (ret==="") return "";
        if (props.pdf) return (<Text>{props.etichetta}: {ret}</Text>)
        return (<><b>{props.etichetta}</b>: {ret}<br/></>)
    }

    function ElencoRaggruppamentiSelezionati(props) {
        var ret="";
        for (var i=0; i<flagIdGruppiSelezionati.length; i++) {
            if (flagIdGruppiSelezionati[i]) {
                if (ret!=="") ret+=", ";
                ret+=elencoGruppi[i];
            }
        }
        if (ret==="") return "";
        if (props.pdf) return (<Text>Raggruppamenti: {ret}</Text>)
        return (<><b>Raggruppamenti</b>: {ret}<br/></>)
    }

    function ElencoTipiEventiSelezionati(props) {
        var ret="";
        for (var i=0; i<flagIdTipiEventiSelezionati.length; i++) {
            if (flagIdTipiEventiSelezionati[i]) {
                if (ret!=="") ret+=", ";
                ret+=elencoTipiEventi[i];
            }
        }
        if (ret==="") return "";
        if (props.pdf) return (<Text>Tipi di Eventi: {ret}</Text>)
        return (<><b>Tipi di Eventi</b>: {ret}<br/></>)
    }

    function scaricaLogo() {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {          
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                  if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                    setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                    setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                    setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                    setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                    setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                    setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                    setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
                  }
                  setRicercaLogo(1);
              }
          },
          (error) => {console.log("Errore connessione");}
        );        
    }

    function scaricaElenchi(tipoReport) {
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "elencoTipiEventi",emak: props.emak, sessionId:props.sessionId  })
        };
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        console.log("VERSIONE NON ACCETTATA DAL BACKEND (indica di aggiornare la app)");
                        return; // QUI VA INDICATO DI AGGIORNARE LA APP
                    }
                    if(valore_di_ritorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if (valore_di_ritorno.risultatoOperazione !== 1) { alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); return; }
                    var desct=[];
                    var idt=[];
                    var flagt=[];
                    for (let i=0; i<valore_di_ritorno.risultatoRitorno.length; i++) {
                        desct.push(valore_di_ritorno.risultatoRitorno[i]["DESCRIZIONE"]);
                        idt.push(valore_di_ritorno.risultatoRitorno[i]["ID"]);
                        flagt.push(false); // se vuoi accenderli di default, metti qui true
                    }
                    setElencoTipiEventi(desct);
                    setElencoIdTipiEventi(idt);
                    setFlagIdTipiEventiSelezionati(flagt);
                   
                    if (tipoReport===TIPO_REPORT_PRESENZE||tipoReport===TIPO_REPORT_RISULTATI) {
                        requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ op: "elencoGruppi",emak: props.emak, sessionId:props.sessionId })
                        };
                        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                            .then(risposta => risposta.json())
                            .then(
                                (valore_di_ritorno) => {
                                    if (valore_di_ritorno.verifvers === 0) {
                                        console.log("VERSIONE NON ACCETTATA DAL BACKEND (indica di aggiornare la app)");
                                        return; // QUI VA INDICATO DI AGGIORNARE LA APP
                                    }
                                    if(valore_di_ritorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                                    if (valore_di_ritorno.risultatoOperazione !== 1) { alert("Operazione fallita", "Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica", [{ text: "OK" }]); return; }
                                    var desct=[];
                                    var idt=[];
                                    var flagt=[];
                                    for (let i=0; i<valore_di_ritorno.risultatoRitorno.length; i++) {
                                        desct.push(valore_di_ritorno.risultatoRitorno[i]["DESCRIZIONE"]);
                                        idt.push(valore_di_ritorno.risultatoRitorno[i]["ID"]);
                                        flagt.push(false); // se vuoi accenderli di default, metti qui true
                                    }
                                    setElencoGruppi(desct);
                                    setElencoIdGruppi(idt);
                                    setFlagIdGruppiSelezionati(flagt);
                                    setStato(STATO_IMPOSTA_OPZIONI_REPORT);
                                },
                                (error) => {
                                    console.log("ERRORE DA BACKEND NON GESTITO (catch)");
                                    /*redirect*/
                                    return;
                                }
                            );
                    }

                    if (tipoReport===TIPO_REPORT_SINGOLO_ATLETA) {
                        // serve l'elenco delle persone
                        requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ op: "elencoPersone", "ridotto":1,emak: props.emak, sessionId:props.sessionId })
                        };
                        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                            .then(risposta => risposta.json())
                            .then(
                                (valore_di_ritorno) => {
                                    if (valore_di_ritorno.verifvers === 0) {
                                        console.log("VERSIONE NON ACCETTATA DAL BACKEND (indica di aggiornare la app)");
                                        return; // QUI VA INDICATO DI AGGIORNARE LA APP
                                    }
                                    if(valore_di_ritorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                                    if (valore_di_ritorno.risultatoOperazione !== 1) { alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); return; }
                                    setElencoNomiPersone(valore_di_ritorno.risultatoRitorno);
                                    setStato(STATO_IMPOSTA_OPZIONI_REPORT);
                                },
                                (error) => {
                                    console.log("ERRORE DA BACKEND NON GESTITO (catch)");
                                    /*redirect*/
                                    return;
                                }
                            );
                    }

                },
                (error) => {
                    console.log("ERRORE DA BACKEND NON GESTITO (catch)");
                    /*redirect*/
                    return;
                }
            );
    }

    function generaReport() {
        var elencoIdGruppiSelezionati="";
        var elencoIdTipiEventiSelezionati="";
        var flagVirgola;
        var i;

        flagVirgola=false;
        for (i=0; i<elencoIdTipiEventi.length; i++) {
            if (flagIdTipiEventiSelezionati[i]) {
                if (flagVirgola) elencoIdTipiEventiSelezionati+=","; else flagVirgola=true;
                elencoIdTipiEventiSelezionati+=elencoIdTipiEventi[i];
            }
        }

        flagVirgola=false;
        for (i=0; i<elencoIdGruppi.length; i++) {
            if (flagIdGruppiSelezionati[i]) {
                if (flagVirgola) elencoIdGruppiSelezionati+=","; else flagVirgola=true;
                elencoIdGruppiSelezionati+=elencoIdGruppi[i];
            }
        }

        let controlli=0;
        if(tipoReport === TIPO_REPORT_SINGOLO_ATLETA){
            //controllo che sia stato davvero selezionato l'atleta:
            if(idPersonaSelezionata === -1 || idPersonaSelezionata === ''){
                setStato(STATO_IMPOSTA_OPZIONI_REPORT);
                setFlagModaleRichiediAtleta(true);
                controlli = 1;
            }
        }

        if(controlli===0){ 

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    op: tipoReport===TIPO_REPORT_PRESENZE?"eventiReportPresenze":"eventiReportRisultati",
                    dataInizio: dataInizio,
                    dataFine: dataFine,
                    elencoIdTipiEventiSelezionati: elencoIdTipiEventiSelezionati,
                    elencoIdGruppiSelezionati: elencoIdGruppiSelezionati,
                    opzioneOrdinamento: opzioneOrdinamento,
                    idPersonaSelezionata: idPersonaSelezionata,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                    (valore_di_ritorno) => {
                        if (valore_di_ritorno.verifvers === 0) {
                            console.log("VERSIONE NON ACCETTATA DAL BACKEND (indica di aggiornare la app)");
                            return; // QUI VA INDICATO DI AGGIORNARE LA APP
                        }
                        if(valore_di_ritorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if (valore_di_ritorno.risultatoOperazione !== 1) { alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); return; }
                        setEsitoReport(valore_di_ritorno.esito);
                        setStato(STATO_VISUALIZZA_REPORT);
                    },
                    (error) => {
                        console.log("ERRORE DA BACKEND NON GESTITO (catch)");
                        /*redirect*/
                        return;
                    }
                );
                }
    }

    if (stato === STATO_ATTESA_BACKEND) {
        return (<div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
        <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
        </div>
      </div>);
    }

    if (stato === STATO_SCELTA_TIPO_REPORT) {
        return (
            <div id="cont" className="schedaSpazioCentrale" style={{height:props.dimensioniApp.yMain}}>                
                <CardTitle>Report Eventi</CardTitle>
                <div style={{display:"flex", flexDirection:"row"}}>
                    <VoceMenu tipoReport={TIPO_REPORT_PRESENZE} icona={iconaPresenze} etichetta="Presenze Atleti" />
                    <VoceMenu tipoReport={TIPO_REPORT_RISULTATI} icona={iconaRisultati} etichetta="Risultati Atleti" />
                    <VoceMenu tipoReport={TIPO_REPORT_SINGOLO_ATLETA} icona={iconaRisultatiSingolo} etichetta="Risultati Singolo Atleta" />
                </div>
            </div>
        );
    }

    if (stato === STATO_IMPOSTA_OPZIONI_REPORT) {
    
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    {flagModaleInfoPeriodo === true ?
                        <Modale 
                            titolo="Periodo di estrazione del report"
                            flagErrore={false}
                            contenuto="Vengono considerati soltanto gli eventi che abbiano la data di inizio compresa all'interno del periodo indicato"
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setFlagModaleInfoPeriodo(false)}
                                }    
                            ]}
                        />
                    :""}
                    {flagModaleRichiediAtleta=== true ?
                        <Modale 
                            titolo="Richiesta atleta"
                            flagErrore={false}
                            contenuto="Non è stata indicato l'atleta, che è necessario"
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setFlagModaleRichiediAtleta(false)}
                                }    
                            ]}
                        />
                    :""}



                    <TitoloReport/>
                    {tipoReport===TIPO_REPORT_SINGOLO_ATLETA ?
                        <>
                            <div className='schedaSottotitolo'>
                                Atleta
                            </div>
                            <TendinaEditabile
                                nomeCampo="COGNOME"
                                nomeCampo2="NOME"
                                nomeCampoParentesi="NUMERO_TESSERA"
                                nomeCampoNonVisibile="ID"
                                bloccoInserimento = "1"
                                valoreDefault={nomePersonaSelezionata}
                                arrayValori={elencoNomiPersone}
                                callbackSetVariazioneCampi={() => console.log("")}
                                callbackSetValoreTendEditabile={(valore,posizioneTendina,id) => {
                                    setNomePersonaSelezionata(valore);
                                    {nomePersonaSelezionata === valore ? setIdPersonaSelezionata(idPersonaSelezionata) : setIdPersonaSelezionata(id)};
                                }}
                            ></TendinaEditabile>                        
                        </>
                    :
                        <></>
                    }

                    <div className='schedaSottotitolo'>
                        Periodo
                        <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoPeriodo(true)}/>
                    </div>
                    <div style={{display:"flex", flexDirection:"row"}}>
                        <div style={{flex:1, marginRight:5}}>
                            Dal giorno
                            <Input type="date" defaultValue={dataInizio} innerRef={refDataInizio} onInput={(e)=>{setDataInizio(refDataInizio.current.value)}}/> 
                            
                        </div>
                        <div style={{flex:1, marginLeft:5}}>        
                            Al giorno
                            <Input type="date" defaultValue={dataFine} innerRef={refDataFine} onInput={() => setDataFine(refDataFine.current.value)} />
                        </div>
                    </div>                
                  
                    <div className='schedaSottotitolo' style={{display:"flex", flexDirection:"row"}}>
                        <div style={{flex:1}}>
                            Tipo di Evento
                        </div>
                        <div>
                            <Button color="primary" onClick={() => {var p=[]; for(let i=0; i<flagIdTipiEventiSelezionati.length; i++) p[i]=true; setFlagIdTipiEventiSelezionati(p)}}>
                                Seleziona Tutti
                            </Button>
                            &nbsp;
                            <Button color="primary" onClick={() => {var p=[]; for(let i=0; i<flagIdTipiEventiSelezionati.length; i++) p[i]=false; setFlagIdTipiEventiSelezionati(p)}}>
                                Seleziona Nessuno
                            </Button>
                        </div>
                    </div>
                    {elencoTipiEventi.map((p, i) =>
                        <div
                            key={i}
                            style={{display:"flex", flexDirection:"row"}} 
                            onClick={() => {var es=[...flagIdTipiEventiSelezionati];es[i]=!es[i];setFlagIdTipiEventiSelezionati(es)}}
                        >
                            <VoceElencoCheckbox etichetta={p} selezionata={flagIdTipiEventiSelezionati[i]}/>
                        </div>
                    )}

                    {tipoReport===TIPO_REPORT_SINGOLO_ATLETA ? 
                        <></>
                    :
                        <>
                            <div className='schedaSottotitolo' style={{display:"flex", flexDirection:"row"}}>
                                <div style={{flex:1}}>
                                Visualizza Atleti Appartenenti a
                                </div>
                                <div>
                                <Button color="primary" onClick={() => {var p=[]; for(let i=0; i<flagIdGruppiSelezionati.length; i++) p[i]=true; setFlagIdGruppiSelezionati(p)}}>
                                    Seleziona Tutti
                                </Button>
                                &nbsp;
                                <Button color="primary" onClick={() => {var p=[]; for(let i=0; i<flagIdGruppiSelezionati.length; i++) p[i]=false; setFlagIdGruppiSelezionati(p)}}>
                                    Seleziona Nessuno
                                </Button>    
                                </div>
                            </div>
                            <div style={{overflow:"auto"}}>
                                {elencoGruppi.map((p, i) =>
                                    <div 
                                        key={i} 
                                        style={{display:"flex", flexDirection:"row"}} 
                                        onClick={() => {var es=[...flagIdGruppiSelezionati];es[i]=!es[i];setFlagIdGruppiSelezionati(es)}}
                                    >
                                        <VoceElencoCheckbox etichetta={p} selezionata={flagIdGruppiSelezionati[i]}/>
                                    </div>
                                )}
                            </div>
                        </>
                    }
                    
                    {tipoReport===TIPO_REPORT_RISULTATI||tipoReport===TIPO_REPORT_SINGOLO_ATLETA ? 
                        <>
                            <div className='schedaSottotitolo'>
                                Ordinamento Risultati
                            </div>
                            <select onChange={(e)=>setOpzioneOrdinamento(e.target.value)}>
                                {opzioniOrdinamento.map((opzione, indice) => 
                                    opzioneOrdinamento === opzione ?
                                    <option key={indice} value={opzione} selected="selected">{opzione}</option>
                                    :
                                    <option key={indice} value={opzione}>{opzione}</option>
                                )}
                            </select>                        
                        </>
                    :
                        <></>
                    }
                </div>
                <div className="schedaSpazioInferiore">
                    <Button color="primary" className="mt-2" onClick={() => { setStato(STATO_ATTESA_BACKEND); generaReport()}}>
                        Genera Report
                    </Button>
                </div>
            </div>
        )
    }

    if (stato===STATO_VISUALIZZA_REPORT) {
        if (esitoReport.length===0) {
            return (
                <FinestraDialogo 
                    titolo="Report Eventi"
                    flagErrore={true}                                
                    contenuto={<div>
                        {tipoReport===TIPO_REPORT_PRESENZE ? 
                            "Nessun atleta era presente ad eventi secondo i criteri impostati nella schermata precedente, verifica le impostazioni e genera un nuovo report"
                        :
                            tipoReport===TIPO_REPORT_RISULTATI ?
                                "Nessun atleta ha risultati secondo i criteri impostati nella schermata precedente, verifica le impostazioni e genera un nuovo report" 
                            :
                                "L'atleta selezionato non ha risultati secondo i criteri impostati nella schermata precedente, verifica le impostazioni e genera un nuovo report"
                        }
                        
                    </div>}
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setStato(STATO_IMPOSTA_OPZIONI_REPORT)}
                        },
                    ]}
                />
            )
        } else {
            return (                    
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                    <div className="schedaSpazioCentrale">
                        <TitoloReport/>
                        <div style={{marginBottom:"1rem"}}>

                            <Button color="primary" className="mt-2" onClick={() => {setStato(STATO_IMPOSTA_OPZIONI_REPORT)}}>
                                Nuovo report
                            </Button>
                            <Button color="primary" className="ml-2 mt-2" onClick={() => setStato(STATO_ESPORTA_PDF)}>
                                Esporta PDF
                            </Button>
                        </div>
                        <div style={{marginBottom:"1rem"}}>
                            <VisualizzaSelezioniUtente
                                pdf={false}
                                arrayFlag={flagIdGruppiSelezionati}
                                elenco={elencoGruppi}
                                etichetta="Raggruppamenti"
                            />
                            <VisualizzaSelezioniUtente
                                pdf={false}
                                arrayFlag={flagIdTipiEventiSelezionati}
                                elenco={elencoTipiEventi}
                                etichetta="Tipi di Eventi"
                            />

                            {dataInizio!=="" ?
                                dataFine!=="" ?
                                    <><b>Date</b>: dal <TrasformaDateAUmano data={dataInizio}/> al <TrasformaDateAUmano data={dataFine}/><br/></>
                                :
                                    <><b>Dal</b>: <TrasformaDateAUmano data={dataInizio}/><br/></>
                            :
                                dataFine!=="" ?
                                    <><b>Fino al</b>: <TrasformaDateAUmano data={dataFine}/><br/></>
                                :
                                    <></>                        
                            }

                        </div>

                        {tipoReport===TIPO_REPORT_PRESENZE ?
                            <Table>
                                <Row form className="rigaTitoloTabellaReport">
                                    <Col className="titoloTabellaReport">Atleta</Col>
                                    <Col className="titoloTabellaReport">Presenze</Col>
                                </Row>
                                {esitoReport.map((v,i) => 
                                    <Row form className="rigaTabellaReport" key={i}>
                                        <Col>{v.COGNOME} {v.NOME}</Col>
                                        <Col>{v.PRESENZE}</Col>
                                    </Row>
                                )}
                            </Table>
                        :
                            <>
                                {tipoReport===TIPO_REPORT_SINGOLO_ATLETA ? 
                                    <div className='schedaSottotitolo'>{nomePersonaSelezionata}</div>
                                :
                                    <></>
                                }
                                <Table>
                                <Row form className="rigaTitoloTabellaReport">                                
                                    {tipoReport===TIPO_REPORT_RISULTATI ?
                                        <Col className="titoloTabellaReport">Atleta/Evento</Col>
                                    :
                                        <Col className="titoloTabellaReport">Evento</Col>
                                    }
                                    <Col className="titoloTabellaReport">Risultati</Col>
                                </Row>
                                {esitoReport.map((v,i) => 
                                    <Row form className="rigaTabellaReport" key={i}>
                                        <Col>
                                            {tipoReport===TIPO_REPORT_RISULTATI ? <>{v.COGNOME} {v.NOME}<br/></>:<></>}
                                            {v.EVENTO}<br/>
                                            <TrasformaDateAUmano data={v.DATA_INIZIO}/>
                                        </Col>
                                        <Col>{v.RISULTATI}</Col>
                                    </Row>
                                )}
                                </Table>
                            </>
                        }
                    </div>
                </div>                    
            )            
        }
    }

    if (stato===STATO_ESPORTA_PDF) {
        if (ricercaLogo!==1){
            return (<div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
            <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
            </div>
          </div>);
        }
        return(
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                {/*<PDFViewer style={styles.viewer}>*/}
                <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
                <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
                    <Document>
                        <Page orientation="portrait" size="A4" style={styles.page}>
                            <View style={{width:"100%"}} >
                                <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                                    <View wrap={false} style={styles.tableRowIntestazione}> 
                                        <View style={styles.tableColIntestazioneSx}> 
                                            <View style={styles.tableCell}> 
                                                {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                                            </View> 
                                        </View> 
                                        <View style={styles.tableColIntestazioneDx}>   
                                            <View style={styles.tableCellIntestazione}> 
                                                {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                                                {indirizzoSocieta !== "" ?
                                                <Text style={styles.datisocieta}>{indirizzoSocieta}
                                                {cittaSocieta !== "" ?
                                                capSocieta !== "" ?
                                                " "+capSocieta+" "+cittaSocieta
                                                :" "+cittaSocieta
                                                :""}
                                                </Text>
                                                :<Text></Text>}
                                                {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                                                {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                                                <Text style={styles.titolo}>
                                                    <TestoTitoloReport/> 
                                                </Text>
                                            </View> 
                                        </View> 
                                    </View> 
                                </View> {/*chiude table intestazione*/}

                                {tipoReport===TIPO_REPORT_SINGOLO_ATLETA ? 
                                    <View style={styles.titoloPiccolo}>
                                        <Text>{nomePersonaSelezionata+"\n\n"}</Text>
                                    </View> 
                                : 
                                    <></> 
                                }

                                <View style={styles.testoNormale}>
                                    <VisualizzaSelezioniUtente
                                        pdf={true}
                                        arrayFlag={flagIdGruppiSelezionati}
                                        elenco={elencoGruppi}
                                        etichetta="Raggruppamenti"
                                    />
                                    <VisualizzaSelezioniUtente
                                        pdf={true}
                                        arrayFlag={flagIdTipiEventiSelezionati}
                                        elenco={elencoTipiEventi}
                                        etichetta="Tipi di Eventi"
                                    />
                                    {dataInizio!=="" ?
                                        dataFine!=="" ?
                                            <Text>Date: dal <TrasformaDateAUmano data={dataInizio}/> al <TrasformaDateAUmano data={dataFine}/></Text>
                                        :
                                            <Text>Dal: <TrasformaDateAUmano data={dataInizio}/></Text>
                                    :
                                        dataFine!=="" ?
                                            <Text>Fino al: <TrasformaDateAUmano data={dataFine}/></Text>
                                        :
                                            <Text></Text>                        
                                    }
                                </View>                                                            

                                {tipoReport===TIPO_REPORT_PRESENZE ?
                                    <View style={styles.table}>{/*apre table dati*/} 
                                        <View wrap={false} style={styles.tableRowBlu}> 
                                            <View style={{width: "60%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0}}>  
                                                <Text style={styles.tableCellGrassettoAllineatoSinistra}>Atleta</Text> 
                                            </View> 
                                            <View style={{width: "40%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0}}>  
                                                <Text style={styles.tableCellGrassettoAllineatoSinistra}>Presenze</Text> 
                                            </View>
                                        </View>

                                        {esitoReport.map((v,i) => 
                                            <View wrap={false} style={styles.tableRow} key={i}>
                                                <View style={{width: "60%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0}}>
                                                    <Text style={styles.tableCellAllineatoSinistra}>{v.COGNOME} {v.NOME}</Text>                        
                                                </View>
                                                <View style={{width: "40%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0}}>
                                                    <Text style={styles.tableCellAllineatoSinistra}>{v.PRESENZE}</Text>                        
                                                </View>
                                            </View>
                                        )}
                                    </View>
                                :
                                    <View style={styles.table}>{/*apre table dati*/}
                                        <View wrap={false} style={styles.tableRowBlu}> 
                                            <View style={{width: "60%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0}}>
                                                <Text style={styles.tableCellGrassettoAllineatoSinistra}>
                                                    {tipoReport===TIPO_REPORT_RISULTATI ? "Atleta/Evento" : "Evento" }
                                                </Text>
                                            </View>
                                            <View style={{width: "40%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0}}>
                                                <Text style={styles.tableCellGrassettoAllineatoSinistra}>Risultati</Text>
                                            </View>
                                        </View>
                                        {esitoReport.map((v,i) => 
                                            <View wrap={false} style={styles.tableRow} key={i}>
                                                <View style={{width: "60%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0}}>
                                                    <Text style={styles.tableCellAllineatoSinistra}>
                                                        {tipoReport===TIPO_REPORT_RISULTATI ? <>{v.COGNOME} {v.NOME}{"\n"}</>:<></>}
                                                        {v.EVENTO}{"\n"}                                                        
                                                        <TrasformaDateAUmano data={v.DATA_INIZIO}/>
                                                    </Text>
                                                </View>
                                                <View style={{width: "40%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0}}>
                                                    <Text style={styles.tableCellAllineatoSinistra}>{v.RISULTATI}</Text>
                                                </View>
                                            </View>
                                        )}
                                    </View>
                                }
                            </View>
                            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                        </Page>
                    </Document>
                </PDFViewer>
            </div>        
        );
    }

}
export default ReportEventi;
