import React, {Fragment,useState,useEffect,Suspense,useRef} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button,Input } from 'reactstrap';
import Parametri from '../../parametri';
import Clessidra from '../Clessidra.js';
import TendinaEditabile from '../TendinaEditabile.js';
import { calcolaAnnoOggi } from '../../funzioni.js';
import Modale from '../Modale.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import QrScanner from 'react-qr-scanner';
import debounce from 'lodash.debounce';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_SCEGLI_ABBONAMENTO = 2;
const STATO_SCEGLI_ABBONATI = 3;
const STATO_ERRORE_CONNESSIONE= 4;
const STATO_OPERAZIONE_FALLITA= 5;
const STATO_MOSTRA_REPORT = 6;
const STATO_SCARICA_PDF = 8;
const STATO_PREPARA_PDF = 9;
const STATO_RESETTA_TRA_SCANSIONI = 10;
const STATO_RICARICA = 11;

function TabellaAccessiQR(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno;
    const dataOggiSoloAnnoMese = today.getFullYear()+'-'+sMese;
  
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');
    const [loading,setLoading]= useState(false);
    const [testoRicercaPersone, setTestoRicercaPersone] = useState("");

    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [insAbbonamentoEdi,setInsAbbonamentoEdi]=useState('');//estremi abbonamento
    const [insIdAbbonamentoEdi,setInsIdAbbonamentoEdi]=useState('');//id abbonamento
    const [risultatoServerDatiAbbonamento,setRisultatoServerDatiAbbonamento]=useState('');
  
    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);
    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);
    const [risultatoServerDatiAbbonati,setRisultatoServerDatiAbbonati]=useState('');

    const [numeroPersoneSelezionate, setNumeroPersoneSelezionate] = useState(0);

    const [numeroPersoneSelezionateGiallo, setNumeroPersoneSelezionateGiallo] = useState(0);
    // array di boolean con tanti elementi quante sono tutte le persone: true per le persone selezionate da utente, per aggiungerle o rimuoverle
    const [personeSelezionate, setPersoneSelezionate] = useState([]);
    const [personeSelezionateGiallo, setPersoneSelezionateGiallo] = useState([]);

    const [modaleNoSelezionati, setModaleNoSelezionati] = useState(false);
    const [modaleInfoSceltaAbbonamento, setModaleInfoSceltaAbbonamento] = useState(false);
    const [modaleNontrovato, setModaleNontrovato] = useState(false);
    const [modaleInfoEvidenziature, setModaleInfoEvidenziature] = useState(false);
    
    const [disabilitaBottoni,setDisabilitaBottoni]= useState(true);

    const [dataInizioDefault,setDataInizioDefault]=useState(dataOggiSoloAnnoMese);
    const dataInizio   = useRef();

    const [risultatoServerRegistrazione,setRisultatoServerRegistrazione]=useState([]);

    const [classeSelezionata, setClasseSelezionata] = useState('');

    const [qrCodeResult, setQrCodeResult] = useState(null);

    const [arrTrovatiState, setArrTrovatiState] = useState([]);

    const [scanning, setScanning] = useState(false); // Usa stato per gestire la scansione

    // Impostazioni per la camera
    const previewStyle = {
      height: 240,
      width: 320,
    };

    //per il pdf:
    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);
    
    useEffect(() => {
        setStatoPagina(STATO_RICERCA_DATI);
        ricercaDatiAbbonamento();
    }, [])

    useEffect(() => {
      if(statoPagina === STATO_PREPARA_PDF && ricercaLogo === 1){
          setStatoPagina(STATO_SCARICA_PDF);
      }
  },[statoPagina,ricercaLogo]);

  useEffect(() => {
    //ogni volta che cambi la classe di abbonamento fai ricerca degli abbonati che abbiano uno degli abbonamenti della classe attivo
    if(classeSelezionata != ''){
      setStatoPagina(STATO_RICERCA_DATI);
      ricercaDatiAbbonati();
    }
},[classeSelezionata])

  const handleSelectChange = (event) => {
    setClasseSelezionata(event.target.value);
  };

  const handleScan = (data) => {
    //console.log('Scan:');
    
    // Verifica che ci sia un dato e che non sia già in scansione
    if (data && !scanning && !arrTrovatiState[data.text]) {
      setScanning(true); // Imposta a true per prevenire ulteriori scansioni
      //console.log('Scansione iniziata:', data);
      setQrCodeResult(data.text);
  
      let arrTrovati = risultatoServerDatiAbbonati.reduce((acc, curr, index) => {
        if (curr.QRCODE === data.text) {
          acc.push(index);
        }
        return acc;
      }, []);
  
      //setArrTrovatiState(arrTrovati);

      arrTrovatiState[data.text]=arrTrovati;
      //console.log("sono"+arrTrovati.length)
  
      // Logica per gestire i record trovati
      if (arrTrovati.length === 1) {
        const i = arrTrovati[0];
        const ps = [...personeSelezionate];
        ps[i] = !ps[i];
        
        setPersoneSelezionate(ps);
        setNumeroPersoneSelezionate(prev => ps[i] ? prev + 1 : prev - 1);
      } else if (arrTrovati.length > 1) {
        let tempSelezionateGiallo = [...personeSelezionateGiallo];
        arrTrovati.forEach(index => {
          if (!tempSelezionateGiallo[index]) {
            tempSelezionateGiallo[index] = true;
            setNumeroPersoneSelezionateGiallo(prev => prev + 1);
          }
        });
        setPersoneSelezionateGiallo(tempSelezionateGiallo);
      } else {
        setModaleNontrovato(true);
      }
  
      {/*const ciSonoGialli = personeSelezionateGiallo.some(selezionato => selezionato);
      const ciSonoVerdi = personeSelezionate.some(selezionato => selezionato);
      
      setDisabilitaBottoni(ciSonoGialli || (!ciSonoGialli && !ciSonoVerdi));*/}
  
      // Reset dopo un timeout
      setTimeout(() => {
        //console.log('Resetto i risultati QR e ripristino lo stato di scansione.');
        //setQrCodeResult(null);
        setScanning(false); // Usa lo stato per permettere nuove scansioni
        setStatoPagina(STATO_RESETTA_TRA_SCANSIONI);
      }, 600);
    } else {
      //console.log('Nessun dato trovato o scansione già in corso.');
    }
  };

  useEffect(() => {
    if(scanning==false){
      //console.log('chuamo useEffect per resettare');
      setQrCodeResult(null);
      setStatoPagina(STATO_SCEGLI_ABBONATI);
    }
},[scanning])

useEffect(() => {
  if(statoPagina == STATO_RICARICA){
    //entro qui se arrivo da onclick ovvero se ho convertito un giallo in un verde
    setStatoPagina(STATO_SCEGLI_ABBONATI);
  }else{
    //entro qui dopo aver acquisito una scansione
    if(personeSelezionateGiallo.length!=0 ||(personeSelezionateGiallo.length==0 && personeSelezionate.length==0)){
      setDisabilitaBottoni(true);
    }else{
      setDisabilitaBottoni(false);
    }
  }
},[numeroPersoneSelezionateGiallo,numeroPersoneSelezionate])


  const handleError = (err) => {
    console.error(err);
  };

  function scaricaLogo() {
    //console.log("scaricaLogo");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1){
              if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
                
              }
              
              setRicercaLogo(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    );        
  }

    function ricercaDatiAbbonamento() {
      //ricerco tra i Abbonamenti che mi servono per popolare la tendina:
      setLoading(true);
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"elencoClassiAbbonamenti",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {
              setLoading(false);
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                  alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
              setRisultatoServerDatiAbbonamento(valoreDiRitorno.risultatoRitorno);
              setStatoPagina(STATO_SCEGLI_ABBONAMENTO);
              }
          },
          (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
    }
    function ricercaDatiAbbonati() {
      //ricerco gli abbonati al corso selezionato:
      setLoading(true);
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"elencoAbbonati",ordina_cognome_nome:1,filtro_solo_attivi:1,filtro_solo_attivi_attuali:1,dataPartenza:dataPartenzaDefault,filtro_accessi_residui:1,classe:classeSelezionata,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {
              setLoading(false);
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                  alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
              setRisultatoServerDatiAbbonati(valoreDiRitorno.risultatoRitorno);
              setStatoPagina(STATO_SCEGLI_ABBONATI);
              }
          },
          (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
    }

    function callbackSetVariazioneCampi(variazioneCampi){
      setVariazioneCampi(variazioneCampi);
    }

    function callbackSetValoreTendEditabileAbbonamento(valore,posizioneTendina,valoreNonVisibile){
      if(valoreNonVisibile !== undefined &&  valoreNonVisibile !== '' && valoreNonVisibile !== -1){
          setInsAbbonamentoEdi(valore);
          setInsIdAbbonamentoEdi(valoreNonVisibile);
      }
  }

  function fetchOperazioneServer() {
    //invio l'azione da fare sul server

      var elencoID = [];
      var elencoCOGNOME = [];
      var elencoNOME = [];
      var elencoTESSERA = [];
      var elencoIDABBO = [];
      for (let n=0; n<personeSelezionate.length; n++) {
        if (personeSelezionate[n]) elencoID.push(risultatoServerDatiAbbonati[n].IDPERSONA);
        if (personeSelezionate[n]) elencoCOGNOME.push(risultatoServerDatiAbbonati[n].COGNOME);
        if (personeSelezionate[n]) elencoNOME.push(risultatoServerDatiAbbonati[n].NOME);
        if (personeSelezionate[n]) elencoTESSERA.push(risultatoServerDatiAbbonati[n].NUMERO_TESSERA);
        if (personeSelezionate[n]) elencoIDABBO.push(risultatoServerDatiAbbonati[n].IDABBONAMENTO);
      }
      
      if(elencoID.length>0){
        setLoading(true);
        // parametri per inviare i dati al server:
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: 
                      JSON.stringify({op:"memorizzaAccessiManuali",
                      idpersone:elencoID,
                      cognomi:elencoCOGNOME,
                      nomi:elencoNOME,
                      tessere:elencoTESSERA,
                      abbonamenti:elencoIDABBO,
                      data:dataInizioDefault,
                      classe:classeSelezionata,
                      emak: props.emak,
                      sessionId:props.sessionId})
              };
          
              // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
              // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
              // variabile di stato "risultato_server"
              //setStatoPagina(STATO_MEMORIZZAZIONE);

              fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                  .then(risposta => risposta.json())
                  .then(
                  (valoreDiRitorno) => {
                      setLoading(false);
                      if(valoreDiRitorno.risultatoOperazione===1){
                        setRisultatoServerRegistrazione(valoreDiRitorno.risultatoRitorno);
                        setStatoPagina(STATO_MOSTRA_REPORT);
                        //setInsAbbonamentoEdi('');
                        //setInsIdAbbonamentoEdi('');
                        setPersoneSelezionate([]);
                        setDisabilitaBottoni(true);
                      } 
                      if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                      if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                          if(valoreDiRitorno.mess !== undefined){
                              
                          }else{
                              //altrimenti vado in errore generico
                              setStatoPagina(STATO_OPERAZIONE_FALLITA);
                          }
                      }
                  },
                  (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
              );   
    }else{
      setModaleNoSelezionati(true);
    }
}



  var coloreEvidenziato="#84b352";

  return (
    <Fragment>
      <div id="cont" className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
        {statoPagina === STATO_SCEGLI_ABBONAMENTO ?
                <>
                {modaleInfoSceltaAbbonamento=== true ?
                  <Modale 
                  titolo="Informativa Registrazione Accessi con QRCode"
                  flagErrore={true}
                  contenuto={<div>Selezionando una classe abbonamento, si accede in automatico alla pagina successiva nella quale viene proposta la lista degli iscritti con un abbonamento della classe selezionata che sia attivo ed abbia un numero di accessi residui maggiore di 0. Si potrà quindi procedere con la scansione dei QR Code ed in questo modo selezionare uno o più iscritti della lista e registrarne l'accesso in data odierna.</div>}
                  bottoni={[
                      {
                          "etichetta":"OK",
                          "tipo":"primary",
                          callback:() => {setModaleInfoSceltaAbbonamento(false)}
                      }    
                  ]}
                />
                :""}
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                <Clessidra loading={loading}/>
                <Card className="main-card mb-3"> 
                            <CardBody>
                                <Col md={12}>    
                                <CardTitle>Registrazione Accessi con QR Code</CardTitle> 
                                Seleziona una classe abbonamento: <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoSceltaAbbonamento(true)}/>
                                
                                <select className="form-control" style={{ width: '100%' }} value={classeSelezionata} onChange={handleSelectChange}>
                                  <option></option>
                                  {risultatoServerDatiAbbonamento.map((abbonamento, index) => (
                                    <option key={index} value={abbonamento.CLASSE_ABBONAMENTO}>
                                      {abbonamento.CLASSE_ABBONAMENTO}
                                    </option>
                                  ))}
                                </select>
                                </Col>      
                            </CardBody>
                        </Card>
                </div>
                </>
            :""}
        {statoPagina === STATO_SCEGLI_ABBONATI ?
          <>
        
          {modaleNoSelezionati=== true ?
                        <Modale 
                        titolo="Informativa Iscritti Selezionati"
                        flagErrore={true}
                        contenuto={<div>Non è stato selezionato alcun iscritto.</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setModaleNoSelezionati(false)}
                            }    
                        ]}
                    />
          :""}

          {modaleInfoEvidenziature=== true ?
                        <Modale 
                        titolo="Informativa Selezioni Iscritti"
                        flagErrore={true}
                        contenuto={<div>Se il QR Code letto è associato ad un unico record della tabella degli abbonati cui è possibile registrare un accesso, il record viene selezionato ed evidenziato in verde. Se i record sono multipli (caso di un iscritto con più abbonamenti da cui poter stornare gli accessi), in seguito alla lettura del QR Code dell'iscritto, questi vengono evidenziati in giallo e sarà necessaria una selezione manuale in modo da evidenziare in verde il record da considerare. La possibilità di registrare gli accessi tramite il bottone Registra Accessi si attiverà soltato se non ci saranno più record gialli da selezionare manualmente.</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setModaleInfoEvidenziature(false)}
                            }    
                        ]}
                    />
          :""}

          {modaleNontrovato=== true ?
                        <Modale 
                        titolo="Informativa Lettura QR Code"
                        flagErrore={true}
                        contenuto={<div>Il QR Code letto non appartiene a nessun iscritto con un abbonamento della classe selezionata che sia attivo e con numero di accessi superiore a 0.</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setModaleNontrovato(false)}
                            }    
                        ]}
                    />
          :""}



          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
              <Clessidra loading={loading}/>
              <Card className="main-card mb-3"> 
                <CardBody>
                  <Col md={12}>    
                    <CardTitle>Registrazione Accessi con QR Code</CardTitle>  
                    {risultatoServerDatiAbbonati.length==0 ?
                      <><b>Non risultano iscritti con abbonamento di classe {classeSelezionata} valido.</b></>
                    :
                      <>
                      <div className='schedaSottotitolo'>Elenco Abbonati ad Abbonamenti di Classe {classeSelezionata}</div>  


                      <div style={{ textAlign: 'center', marginTop: '50px' }}>
                        <h1>Lettore QR Code</h1>
                        <QrScanner
                          delay={300}
                          style={previewStyle}
                          onError={handleError}
                          onScan={handleScan}
                          facingMode="user" // Usa la fotocamera anteriore, metti invece "environment" per la posteriore
                        />
                        {/*<p>{qrCodeResult ? `QR Code Data: ${qrCodeResult}` : 'No QR code detected.'}</p>*/}
                        <p>{qrCodeResult ? `QR Code acquisito correttamente` : 'Avvicinare il QR Code alla telecamera per la lettura'}</p>
                      </div>


                      Scansionare i QR Code per selezionare uno o più iscritti per registrarne gli accessi in data odierna:<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoEvidenziature(true)}/>
     
                          {/*Cerca: <input type='text' style={{width:"100%", border: "1px solid gray"}} onChange={(e) => setTestoRicercaPersone(e.target.value.toLowerCase())}/>*/}
                          <div style={{display:"flex", flexDirection:"row",marginTop:5}}>
                                    <div style={{flex:1, marginRight:5}}>
                                    <b>Iscritto</b>
                                    </div>
                                    <div style={{flex:1, marginRight:5}}>
                                    <b>Abbonamento</b>
                                    </div>
                                    <div style={{flex:1, marginRight:5}}>
                                    <b>Numero Accessi Residui</b>
                                    </div>
                          </div> 
                          
                          
                          {/*<div style={{ backgroundColor:"#EEEEEE", overflow:"auto"}}>*/}
                          <div>  
                              {risultatoServerDatiAbbonati.map((x,i) =>
                                  (testoRicercaPersone==="" || x.toLowerCase().includes(testoRicercaPersone)) 
                              &&  
                              <div 
                              key={i}
                              style={{
                                marginLeft: 5, 
                                backgroundColor: personeSelezionateGiallo[i] 
                                  ? "#FFFF00" 
                                  : personeSelezionate[i] 
                                    ? "#008000"  // Colore verde
                                    : "transparent", 
                                color: personeSelezionate[i]? "#FFFFFF" : "#000000", 
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                // Verifica se il record corrente è selezionato (giallo o verde)
                                if (personeSelezionate[i] || personeSelezionateGiallo[i]) {
                                  // Gestione del click su un record giallo
                                  if (personeSelezionateGiallo[i]) {
                                    let ps = [...personeSelezionate];
                                    let tempSelezionateGiallo = [...personeSelezionateGiallo];
                              
                                    // Deseleziona tutti gli altri gialli associati e seleziona questo come verde
                                    arrTrovatiState[risultatoServerDatiAbbonati[i].QRCODE].forEach(index => {
                                      if (index === i) {
                                        // Seleziona questo come verde
                                        ps[index] = true; // Imposta verde
                                        tempSelezionateGiallo[index] = false; // Rimuovi il giallo
                                        setNumeroPersoneSelezionate(prev => prev + 1);
                                        setNumeroPersoneSelezionateGiallo(prev => prev - 1); // Riduci il numero di selezionati gialli
                                      } else if (tempSelezionateGiallo[index]) {
                                        // Deseleziona gli altri gialli associati
                                        tempSelezionateGiallo[index] = false;
                                        setNumeroPersoneSelezionateGiallo(prev => prev - 1); // Riduci il numero di gialli
                                      }
                                    });
                              
                                    // Aggiorna lo stato dei verdi e dei gialli
                                    setPersoneSelezionate(ps); 
                                    setPersoneSelezionateGiallo(tempSelezionateGiallo);
                              
                                    // Controlla se ci sono ancora gialli o se non ci sono selezionati verdi
                                    const ciSonoGialli = tempSelezionateGiallo.some(selezionato => selezionato);
                                    const ciSonoVerdi = ps.some(selezionato => selezionato);
                                    setDisabilitaBottoni(ciSonoGialli || !ciSonoVerdi);
                                    setStatoPagina(STATO_RICARICA);
                                  } else {
                                    // Logica normale per il clic sui record verdi per ora inibita per evitare errori di cancellazioni involontarie
                                    {/*}
                                    const ps = [...personeSelezionate];
                                    ps[i] = !ps[i]; // toggle della selezione
                                    setNumeroPersoneSelezionate(prev => ps[i] ? prev + 1 : prev - 1);
                                    setPersoneSelezionate(ps);
                              
                                    // Controlla se ci sono ancora gialli o se non ci sono selezionati verdi
                                    const ciSonoGialli = personeSelezionateGiallo.some(selezionato => selezionato);
                                    const ciSonoVerdi = ps.some(selezionato => selezionato);
                                    setDisabilitaBottoni(ciSonoGialli || !ciSonoVerdi);*/}
                                  }
                                }
                                
                              }}
                              
                              
                          >
                                  <div style={{display:"flex", flexDirection:"row",}}>
                                    <div style={{flex:1, marginRight:5}}>
                                      {risultatoServerDatiAbbonati[i].COGNOME} {risultatoServerDatiAbbonati[i].NOME}
                                      {risultatoServerDatiAbbonati[i].NUMERO_TESSERA !='' ?
                                        <> ({risultatoServerDatiAbbonati[i].NUMERO_TESSERA})</>
                                      :""}
                                    </div>

                                    <div style={{flex:1, marginRight:5}}>
                                      <>{risultatoServerDatiAbbonati[i].NOMEABBONAMENTO}</>
                                    </div>

                                    <div style={{flex:1, marginRight:5}}>
                                      {risultatoServerDatiAbbonati[i].ACCESSI_RESIDUI !='-1' ?
                                        <>{risultatoServerDatiAbbonati[i].ACCESSI_RESIDUI}</>
                                      :<>Infiniti</>}
                                    </div>
                                  </div>    
                                  </div>                               
                              )}   
                          </div>  
                          </>                     
                        }
                  </Col>  
                      
              </CardBody>
              </Card>
          </div>
          
            <div className="schedaSpazioInferiore">   
              <div style={{display:"flex",flexDirection:"row"}}>
              <div style={{flex:1}}>
              <Button color="warning" disabled={disabilitaBottoni}  className="mt-2 bottoneMargineADestra" onClick={() => {fetchOperazioneServer(false)}}>
                Registra Accessi</Button>
              </div> 
              <div style={{flex:1,marginTop:10,marginRight:40,textAlign: "right"}}>
                {numeroPersoneSelezionateGiallo > 0 ?
                  numeroPersoneSelezionateGiallo > 1 ?
                  <>{numeroPersoneSelezionateGiallo} elementi selezionati da correggere</>
                  :
                  <>{numeroPersoneSelezionateGiallo} elemento selezionato da correggere</>
                :""}
              </div>
              <div style={{flex:1,marginTop:10,marginRight:40,textAlign: "right"}}>
                {numeroPersoneSelezionate > 0 ?
                  numeroPersoneSelezionate > 1 ?
                  <>{numeroPersoneSelezionate} elementi selezionati</>
                  :
                  <>{numeroPersoneSelezionate} elemento selezionato</>
                :""}
              </div>
              
            </div> 
          </div>
        </> 
        :""}

      {statoPagina === STATO_MOSTRA_REPORT ?
        <>
        <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
          <><b>Numero accessi registrati: {risultatoServerRegistrazione.length}</b>
         
          {risultatoServerRegistrazione.map((elemento,indice) =>
            <div key={indice}>
              {elemento.cognome} {elemento.nome}
              {elemento.tessera !='' ? " ("+elemento.tessera+")" : ""}
            </div>                                                   
          )
          }
          </>
        </div>
        <div className="schedaSpazioInferiore">   
        <div style={{display:"flex",flexDirection:"row"}}>
        <div style={{flex:1}}>
        <Button color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setStatoPagina(STATO_PREPARA_PDF);scaricaLogo()}}>
          Esporta il PDF </Button>
        </div> 
      </div>
    </div>
    </>
    :""}

    {statoPagina === STATO_SCARICA_PDF ? 
    
      <div>
        <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
                        
          <div style={{position:"absolute",top:props.dimensioniApp.yTitolo}}>
                        
                            
                                <PDFViewer style={
                                    {flex:1, height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent"}   
                                }>
          
                                <Document>
                                <Page size="A4" style={styles.page}>
                                <View style={{width:"100%"}} >
                                    <View style={styles.tableIntestazione} > 
                                        <View wrap={false} style={styles.tableRowIntestazione}> 
                                            <View style={styles.tableColIntestazioneSx}> 
                                                <View style={styles.tableCell}> 
                                                    {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                                                </View> 
                                            </View> 
                                            <View style={styles.tableColIntestazioneDx}>   
                                                <View style={styles.tableCellIntestazione}> 
                                                    {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}

                                                    {indirizzoSocieta !== "" ?
                                                    <Text style={styles.datisocieta}>{indirizzoSocieta}
                                                    {cittaSocieta !== "" ?
                                                        capSocieta !== "" ?
                                                            " "+capSocieta+" "+cittaSocieta
                                                        :" "+cittaSocieta
                                                    :""}
                                                    </Text>
                                                    :<Text></Text>}
                                                    {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                                                    {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                                              
                                                    <Text style={styles.titolo}>Riepilogo Registrazioni Accessi con QR Code</Text>
                            
                                                </View> 
                                            </View> 
                                        </View> 
                                        </View>
                                        <View>
                              
                                            <Text style={styles.titoloPiccolo}> Data <TrasformaDateAUmano data={dataOggi}></TrasformaDateAUmano></Text>
                                            <Text style={styles.titoloPiccolo}> Abbonamento {insAbbonamentoEdi}</Text>
                                          
                                        </View>



                                        <View style={styles.table}>{/*apre table dati*/}
                                        <View wrap={false} style={styles.tableRowBlu}> 
                                           <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                                            <Text style={styles.tableCellGrassettoAllineatoSinistra}>Cognome</Text> 
                                          </View> 
                                          <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                                            <Text style={styles.tableCellGrassettoAllineatoSinistra}>Nome</Text> 
                                          </View> 
                                          <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                                            <Text style={styles.tableCellGrassettoAllineatoSinistra}>Numero Tessera</Text> 
                                          </View>
                                        </View>

                                          {risultatoServerRegistrazione.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                                                      <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                   
                                                          <Text style={styles.tableCellAllineatoSinistra}>{elemento.cognome}</Text>                        
                                                      </View>
                                                      <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                   
                                                          <Text style={styles.tableCellAllineatoSinistra}>{elemento.nome}</Text>                        
                                                      </View>
                                                      {elemento.tessera !='' ? 
                                                        <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                   
                                                          <Text style={styles.tableCellAllineatoSinistra}>{elemento.tessera}</Text>                        
                                                        </View>
                                                      : <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                   
                                                      <Text style={styles.tableCellAllineatoSinistra}></Text>                        
                                                    </View>}
                                              
                                                  </View>)}
                                        </View> 
                                          
                          </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                    </Page>
                    </Document>
                    </PDFViewer>


                                
                    </div>
                    </div>//div
                  
                :""}
    </div>
                     
    </Fragment>
   );


}
export default TabellaAccessiQR