
function FormattaNumeroVideo(numero) {
    let numeroOutput='';
    //virgola in luogo del punto

    if(numero){

        if (typeof numero === 'string'){
            if(numero.includes(',')) return numero;//scommentare !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        }
        numeroOutput = numero.replace(".", ",");
    }else numeroOutput = "0";

    return numeroOutput;
}

export default FormattaNumeroVideo;
