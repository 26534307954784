import React, {useState,useEffect} from 'react';
import {
    Card, CardBody, CardTitle, Button, Modal, Row, Col
} from 'reactstrap';
import Modale from '../Modale.js';

function RequisitiAggiungiRimuovi(props) {
    const [testoRicercaRecord, setTestoRicercaRecord] = useState("");
    const [mostraClessidra, setMostraClessidra] = useState(false);
    const [numeroRecordSelezionati, setNumeroRecordSelezionati] = useState(0);
    // array di boolean con tanti elementi quante sono tutti i record: true per i record selezionati da utente, per aggiungerli o rimuoverli
    const [recordSelezionati,setRecordSelezionati] = useState([]);
    const [recordFiltrati, setRecordFiltrati] = useState([]);
    const [record,setRecord] = useState([]);
    const [idRecord,setIdRecord] = useState([]);
    //const [recordRichiesti,setRecordRichiesti] = useState([]);
    //const [idRecordRichiesti,setIdRecordRichiesti] = useState([]);
  
  
    useEffect(() => {
        let f=[];
        let s=[];
        if(props.azioneRequisiti == "aggiungiRimuoviAbbonamento"){
            let arrLocaleAbb=[];
            let arrLocaleIdAbb=[];
            for (let i=0; i<props.abbonamentiPossibili.length; i++) {
                f[i]=true;
                //che sia che props.abbonamentiRichiesti è undefined e quindi dà props.abbonamentiRichiesti.include is not a function ?

                if(props.abbonamentiRichiesti){
                    if(props.abbonamentiRichiesti.includes(props.abbonamentiPossibili[i].NOME) && props.idAbbonamentiRichiesti.includes(props.abbonamentiPossibili[i].ID)){
                        s[i]=true;
                    }else s[i]=false;
                }else s[i]=false;
            
    

                arrLocaleAbb[i]=props.abbonamentiPossibili[i].NOME;  
                //arrLocaleIdAbb[i]=props.abbonamentiPossibili[i].ID;
            }
            setRecord(arrLocaleAbb);
            setIdRecord(arrLocaleIdAbb);
        
            //setRecord(props.abbonamentiPossibili);
            //setRecordRichiesti(props.abbonamentiRichiesti);
            //setIdRecordRichiesti(props.idAbbonamentiRichiesti);
        }
        if(props.azioneRequisiti == "aggiungiRimuoviPosizione"){
            let arrLocalePos=[];
            let arrLocaleIdPos=[];
            for (let i=0; i<props.posizioniPossibili.length; i++) {
                f[i]=true;
                if(props.posizioniRichieste){
                    if(props.posizioniRichieste.includes(props.posizioniPossibili[i].DESCRIZIONE) && props.idPosizioniRichieste.includes(props.posizioniPossibili[i].ID)){
                        s[i]=true;
                    }else s[i]=false;
                }else s[i]=false; 

                arrLocalePos[i]=props.posizioniPossibili[i].DESCRIZIONE;  
                //arrLocaleIdPos[i]=props.posizioniPossibili[i].ID; 
            }
            setRecord(arrLocalePos);
            setIdRecord(arrLocaleIdPos);

            //setRecord(props.posizioniPossibili);
            //setRecordRichiesti(props.posizioniRichieste);
            //setIdRecordRichiesti(props.idPosizioniRichieste);
    
        }
        setRecordFiltrati(f);
        setRecordSelezionati(s);
    },[])
        
    // restituisce l'intersezione degli array a e b, che DEVONO essere array con elementi NUMERICI ORDINATI in ordine crescente
    function intersezioneArray(a, b) {
      var ai=0, bi=0, intersezione=[];
      while (ai<a.length && bi<b.length) {
         if (a[ai]<b[bi]) { ai++; }
         else if (a[ai]>b[bi]) { bi++; }
         else { intersezione.push(a[ai]); ai++; bi++ }
      }
      return intersezione;
    }

    var titoloModale="";
    var etichettaElementi="";
    var coloreEvidenziato="";
    switch(props.azioneRequisiti) {
        case "aggiungiRimuoviAbbonamento": titoloModale="Aggiungi/Rimuovi Abbonamento"; etichettaElementi="Abbonamenti da aggiungere/rimuovere dalla lista di quelli che permettono l'accesso in struttura"; coloreEvidenziato="#84b352"; break;
        case "aggiungiRimuoviPosizione": titoloModale="Aggiungi Posizione"; etichettaElementi="Posizioni da aggiungere/rimuovere dalla lista di quelle che permettono l'accesso in struttura"; coloreEvidenziato="#84b352"; break;

    }

    return (
        <Modale 
            titolo={titoloModale}
            larghezza = "xl"
            flagErrore={false}
            contenuto={<div>
                <Row form>  
                    <Col md={12}>
                        <b>{etichettaElementi}</b><br/>
                        Cerca: <input type='text' style={{width:"80%", border: "1px solid gray"}} onChange={(e) => setTestoRicercaRecord(e.target.value.toLowerCase())}/>
                        <div style={{height:300, backgroundColor:"#EEEEEE", overflow:"auto"}}>

                            {record.map((x,i) =>
                            recordFiltrati[i] 
                            && 
                                (testoRicercaRecord==="" || x.toLowerCase().includes(testoRicercaRecord)) 
                            &&  
                                <div 
                                    key={i}
                                    style={{
                                        marginLeft:5, 
                                        backgroundColor:recordSelezionati[i]?coloreEvidenziato:"transparent", 
                                        color:recordSelezionati[i]?"#FFFFFF":"#000000",
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        if (recordSelezionati[i]) setNumeroRecordSelezionati(numeroRecordSelezionati-1); else setNumeroRecordSelezionati(numeroRecordSelezionati+1);                                         
                                        var ps=[...recordSelezionati];                                        
                                        ps[i]=!ps[i];
                                        setRecordSelezionati(ps);
                                    }}
                                >
                                    {record[i]} {idRecord[i]}
                                </div>                                
                            )}   
                        </div>                     
                        
                    </Col>                    
                </Row>
                
                <Row form>  
                    <Col md={4}>

                    </Col>
                    <Col md={4}>
                        {numeroRecordSelezionati>0 ? numeroRecordSelezionati===1 ? "1 elemento selezionato" : numeroRecordSelezionati+" elementi selezionati" : "Nessun elemento selezionato" }                        
                    </Col>
                </Row>
                {props.azioneRequisiti == "aggiungiRimuoviAbbonamento" && <>Premendo il bottone verde "applica modifiche" gli abbonamenti selezionati saranno quelli che permetteranno l'accesso in struttura</>}
                {props.azioneRequisiti == "aggiungiRimuoviPosizione" && <>Premendo il bottone verde "applica modifiche" verranno aggiunte le posizioni selezionate saranno quelle che permetteranno l'accesso in struttura</>}
            </div>
            }
            bottoni={[
                {
                    "etichetta":"Applica Modifiche",
                    "tipo":"success",
                    callback:() => {
                        var elencoID = [];
                        for (let n=0; n<recordSelezionati.length; n++) {
                            if (recordSelezionati[n]) elencoID.push(idRecord[n]);
                        }
                        props.callbackRequisitiAggiungiRimuovi(elencoID, props.azioneRequisiti)
                    }
                },
                {
                    "etichetta":"Seleziona Visibili", 
                    "tipo":"primary",
                    callback:() => {
                        var ps=[...recordSelezionati]; 
                        for (let i=0; i<record.length; i++) if (recordFiltrati[i]) ps[i] = true; 
                        setRecordSelezionati(ps);
                    }
                },
                {
                    "etichetta":"Desel. Visibili", 
                    "tipo":"primary",
                    callback:() => {
                        var ps=[...recordSelezionati]; 
                        for (let i=0; i<record.length; i++) if (recordFiltrati[i]) ps[i] = false; 
                        setRecordSelezionati(ps);
                    }
                },
                {
                    "etichetta":"Annulla",
                    "tipo":"primary",
                    callback:() => {props.callbackRequisitiAggiungiRimuovi([],"")
                    }
                }
            ]}
        />
    )
}

export default RequisitiAggiungiRimuovi;