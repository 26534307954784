import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import FormattaImportoVideo from '../FormattaImportoVideo.js';
import FormattaImportoDb from '../FormattaImportoDb.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';

import Cestino from "../../immagini/cestino.png";
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagAttivaModaleSpesaNonCancellabile, setFlagAttivaModaleSpesaNonCancellabile] = useState(0);
    const [flagAttivaModaleInserisciEnte, setFlagAttivaModaleInserisciEnte] = useState(0);
    const [flagAttivaModaleRichiediPermessoInserire, setFlagAttivaModaleRichiediPermessoInserire] = useState(0);
    const [flagAttivaModaleRichiediPIVA, setFlagAttivaModaleRichiediPIVA] = useState(0);
    const [flagAttivaModaleInserisciDescrizione, setFlagAttivaModaleInserisciDescrizione] = useState(0);
    const [flagAttivaModaleInserisciDataPagamento, setFlagAttivaModaleInserisciDataPagamento] = useState(0);
    const [flagAttivaModaleInserisciDataEmissione, setFlagAttivaModaleInserisciDataEmissione] = useState(0);
    const [flagAttivaModaleInserisciRisorsa, setFlagAttivaModaleInserisciRisorsa] = useState(0);
    const [flagAttivaModaleInserisciVoceBilancio, setFlagAttivaModaleInserisciVoceBilancio] = useState(0);
    const [flagAttivaModaleInserisciImporto, setFlagAttivaModaleInserisciImporto]  = useState(0);
    const [flagAttivaModaleInserisciImportoPagamento, setFlagAttivaModaleInserisciImportoPagamento]  = useState(0);
    const [flagAttivaModaleImponibileErrato, setFlagAttivaModaleImponibileErrato] = useState(0);
    const [flagAttivaModaleImportoErrato, setFlagAttivaModaleImportoErrato] = useState(0);
    const [flagAttivaModaleIvaErrata, setFlagAttivaModaleIvaErrata] = useState(0);
    const [flagAttivaModaleImportoPagamentoErrato, setFlagAttivaModaleImportoPagamentoErrato] = useState(0);
    const [flagAttivaModaleIvaPagamentoErrata, setFlagAttivaModaleIvaPagamentoErrata] = useState(0);

    const [flagModaleInfoStato, setFlagModaleInfoStato] = React.useState(false);
    const [flagModaleInfoImporto, setFlagModaleInfoImporto] = React.useState(false);

    const importo   = useRef();
    const imponibile   = useRef();
    const iva   = useRef();
    const descrizione   = useRef();
    const dataEmissione   = useRef();
    //const stato   = useRef();
    const note   = useRef();

    const dataScadenzaRata   = useRef({});
    const dataPagamentoRata   = useRef({});
    const importoRata   = useRef({});
    //const ivaRata   = useRef({});
    const noteRata   = useRef({});


    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [statoTendinaClienteFornitore,setStatoTendinaClienteFornitore]=useState(false);
    const [statoRicercaRateFattura,setStatoRicercaRateFattura]=useState(false);
    const [statoTendinaRisorse,setStatoTendinaRisorse]=useState(false);
    const [statoTendinaVociDiBilancio,setStatoTendinaVociDiBilancio]=useState(false);

    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');

    const [risultatoServerDatiClienteFornitore,setRisultatoServerDatiClienteFornitore]=useState('');
    const [risultatoServerDatiTutteRisorse,setRisultatoServerDatiTutteRisorse]=useState('');
    const [risultatoServerDatiTutteVociDiBilancio,setRisultatoServerDatiTutteVociDiBilancio]=useState('');

    const [arrayRisorsaRataFatt,setArrayRisorsaRataFatt]=useState(['']);
    const [arrayVociDiBilancioRataFatt,setArrayVociDiBilancioRataFatt]=useState(['']);
    const [arrayImportoRataFatt,setArrayImportoRataFatt]=useState(['']);
    const [arrayIvaRataFatt,setArrayIvaRataFatt]=useState(['']);
    const [arrayDataScadenzaRataFatt,setArrayDataScadenzaRataFatt]=useState(['']);
    const [arrayDataPagamentoRataFatt,setArrayDataPagamentoRataFatt]=useState([dataOggi]);
    const [arrayNoteRataFatt,setArrayNoteRataFatt]=useState(['']);
    const [arrayNumeroRate,setArrayNumeroRate]=useState(['']);
    
    //const [defaultEnte,setDefaultEnte]=useState('');
    //const [defaultRisorse,setDefaultRisorse]=useState([]);
    //const [defaultVociDiBilancio,setDefaultVociDiBilancio]=useState([]);

    const [importoDefault,setImportoDefault]=useState('');
    const [imponibileDefault,setImponibileDefault]=useState('');
    const [ivaDefault,setIvaDefault]=useState('');
    const [descrizioneDefault,setDescrizioneDefault]=useState('');
    const [dataEmissioneDefault,setDataEmissioneDefault]=useState(dataOggi);
    const [noteDefault,setNoteDefault]=useState('');
    let tipologia = "spese";

    const [insClienteFornitoreEdi,setInsClienteFornitoreEdi]=useState('');
 
    const [insStato,setInsStato]=useState("");

    
    const [permessoInserireEnte,setPermessoInserireEnte]=useState(0);
    const [permessoInserireRisorsa,setPermessoInserireRisorsa]=useState(0);
    const [permessoInserireVoceDiBilancio,setPermessoInserireVoceDiBilancio]=useState(0);
    const [permessoCancellazioneForzata,setPermessoCancellazioneForzata]=useState(0);

    const [risorseNuove,setRisorseNuove]=useState([]);
    const [vociDiBilancioNuove,setVociDiBilancioNuove]=useState([]);

    //const [rivisualizza,setRivisualizza]=useState(0);
    const [eliminazioneRata,setEliminazioneRata]=useState(0);
    //const [aggiornatoImportoRata,setAggiornatoImportoRata]=useState(0);
    //const [aggiornatoIvaRata,setAggiornatoIvaRata]=useState(0);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    useEffect(() => {
//console.log("use iniziale")
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaClienteFornitore(false);
        setPermessoInserireEnte(0);
        setPermessoInserireRisorsa(0);
        setPermessoInserireVoceDiBilancio(0);
        setPermessoCancellazioneForzata(0);
        setInsStato("Non pagato");
        //setAggiornatoImportoRata(0);
        //setAggiornatoIvaRata(0);
    },[]);

 /*   useEffect(() => {
console.log("use rivisualizza")
        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[rivisualizza])*/

    useEffect(() => {
//console.log("use stati tendine")
        if(statoTendinaClienteFornitore === true 
            && statoRicercaRateFattura === true 
            &&  statoTendinaRisorse === true 
            &&  statoTendinaVociDiBilancio === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaClienteFornitore,statoRicercaRateFattura,statoTendinaRisorse,statoTendinaVociDiBilancio]);

    useEffect(() => {
        //if(props.ope === "inserisci" || (props.ope === "modifica" && variazioneCampi === 1)) aggiornaStatoPagamentoFattura();
        aggiornaStatoPagamentoFattura();
    },[arrayImportoRataFatt,arrayNumeroRate,importoDefault]);

    useEffect(() => {
//console.log("use permessoInserire")
        if((props.ope==="modifica" || props.ope==="inserisci") && 
        (permessoInserireEnte===1 && permessoInserireRisorsa===1 && permessoInserireVoceDiBilancio===1)) fetchOperazioneServer();
        if(props.ope==="cancella" && permessoCancellazioneForzata === 1) fetchOperazioneServer();
    },[permessoInserireEnte,permessoInserireRisorsa,permessoInserireVoceDiBilancio,permessoCancellazioneForzata]);

    useEffect(() => {
//console.log("use props.ope")
        if(props.ope==='inserisci') setOperazione("fattureIns");
        if(props.ope==='modifica') setOperazione("fattureMod");
        if(props.ope==='cancella') setOperazione("fattureCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"fattureRecuperaDati",
                    tipologia:tipologia,
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    let componiFornitore="";
                    if(valoreDiRitorno.risultatoRitorno[0].COGNOME !== '' && valoreDiRitorno.risultatoRitorno[0].COGNOME !== undefined) componiFornitore = componiFornitore + valoreDiRitorno.risultatoRitorno[0].COGNOME;
                    if(valoreDiRitorno.risultatoRitorno[0].P_IVA !== '' && valoreDiRitorno.risultatoRitorno[0].P_IVA !== undefined && componiFornitore !== "" ) componiFornitore = componiFornitore + " ";
                    if(valoreDiRitorno.risultatoRitorno[0].P_IVA !== '' && valoreDiRitorno.risultatoRitorno[0].P_IVA !== undefined ) componiFornitore = componiFornitore + "("+valoreDiRitorno.risultatoRitorno[0].P_IVA+")";

                    //setInsClienteFornitoreEdi(valoreDiRitorno.risultatoRitorno[0].COGNOME);

                    setInsClienteFornitoreEdi(componiFornitore);//serve per settare la var di stato se non cambi valore nella tendina in caso di modifica

                    //setDefaultEnte(valoreDiRitorno.risultatoRitorno[0].COGNOME);
                    setInsStato(valoreDiRitorno.risultatoRitorno[0].STATO);
                    setImportoDefault(FormattaImportoVideo(valoreDiRitorno.risultatoRitorno[0].IMPORTO));
                    setImponibileDefault(FormattaImportoVideo(valoreDiRitorno.risultatoRitorno[0].IMPONIBILE));
                    setIvaDefault(FormattaImportoVideo(valoreDiRitorno.risultatoRitorno[0].IVA));
                    setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                    setDataEmissioneDefault(valoreDiRitorno.risultatoRitorno[0].DATA_EMISSIONE);
                    setNoteDefault(valoreDiRitorno.risultatoRitorno[0].NOTE);


                    ricercaDatiClienteFornitore();
                    ricercaDatiRateFattura();
                    ricercaDatiTutteRisorse();
                    ricercaDatiTutteVociDiBilancio();
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else{
            setStatoPagina(STATO_RICERCA_DATI);
            ricercaDatiClienteFornitore();
            ricercaDatiRateFattura(props.ope);
            ricercaDatiTutteRisorse();
            ricercaDatiTutteVociDiBilancio();
        }
    },[props.ope]);

    function ricercaDatiClienteFornitore() {
        //ricerco i clienti ed i fornitori che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoCliFor",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiClienteFornitore(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaClienteFornitore(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiRateFattura(operazione) {
        if(operazione==='inserisci'){
            setStatoRicercaRateFattura(true);
        }else{
            
            //ricerco tutte le rate della fattura in esame:
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"elencoRateFattura",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId,idFattura:props.id,})
            };
    
            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                (valoreDiRitorno) => {
                    setLoading(false);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
             
                    let arrLocaleRisorsaRataFatt=[];
                    let arrLocaleVociDiBilancioRataFatt=[];
                    let arrLocaleImportoRataFatt=[];
                    let arrLocaleIvaRataFatt=[];
                    let arrLocaleDataScadenzaRataFatt=[];
                    let arrLocaleDataPagamentoRataFatt=[];
                    let arrLocaleNoteRataFatt=[];
                    let arrLocaleNumeroRate=[];
       
                    for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                                            
                                             
                        arrLocaleRisorsaRataFatt[i]=valoreDiRitorno.risultatoRitorno[i].RISORSA;    
                        //let risorseAppoggio = [...defaultRisorse];
                        //risorseAppoggio.push(valoreDiRitorno.risultatoRitorno[i].RISORSA);
                        //setDefaultRisorse(risorseAppoggio);
                        arrLocaleVociDiBilancioRataFatt[i]=valoreDiRitorno.risultatoRitorno[i].VOCE_DI_BILANCIO;  
                        //let vociAppoggio = [...defaultVociDiBilancio];
                        //vociAppoggio.push(valoreDiRitorno.risultatoRitorno[i].VOCE_DI_BILANCIO);
                        //setDefaultVociDiBilancio(vociAppoggio);                
                        arrLocaleImportoRataFatt[i]=FormattaImportoVideo(valoreDiRitorno.risultatoRitorno[i].IMPORTO_RATA);        
                        arrLocaleIvaRataFatt[i]=FormattaImportoVideo(valoreDiRitorno.risultatoRitorno[i].IVA_RATA);             
                        arrLocaleDataScadenzaRataFatt[i]=valoreDiRitorno.risultatoRitorno[i].DATA_SCADENZA_RATA;                    
                        arrLocaleDataPagamentoRataFatt[i]=valoreDiRitorno.risultatoRitorno[i].DATA_PAGAMENTO_RATA;                      
                        arrLocaleNoteRataFatt[i]=valoreDiRitorno.risultatoRitorno[i].NOTE_RATA;    
                        //aggiorno il numero di rate:
                        arrLocaleNumeroRate.push(i);
                        
                    } 
                    //setDefaultRisorse(arrLocaleRisorsaRataFatt);
                    //setDefaultVociDiBilancio(arrLocaleVociDiBilancioRataFatt);
                    
                    setArrayRisorsaRataFatt(arrLocaleRisorsaRataFatt);
                    setArrayVociDiBilancioRataFatt(arrLocaleVociDiBilancioRataFatt);
                    setArrayImportoRataFatt(arrLocaleImportoRataFatt);
                    setArrayIvaRataFatt(arrLocaleIvaRataFatt);
                    setArrayDataScadenzaRataFatt(arrLocaleDataScadenzaRataFatt);
                    setArrayDataPagamentoRataFatt(arrLocaleDataPagamentoRataFatt);
                    setArrayNoteRataFatt(arrLocaleNoteRataFatt);
                    setArrayNumeroRate(arrLocaleNumeroRate);
                    setStatoRicercaRateFattura(true);
                }
                },
                (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
        }
    }

    function ricercaDatiTutteRisorse() {
        //ricerco tutte le risorse disponibili che mi servono per popolare la tendina:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoRisorse",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiTutteRisorse(valoreDiRitorno.risultatoRitorno);         
                setStatoTendinaRisorse(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiTutteVociDiBilancio() {
        //ricerco tutte le risorse disponibili che mi servono per popolare la tendina:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoVociDiBilancio",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiTutteVociDiBilancio(valoreDiRitorno.risultatoRitorno);         
                setStatoTendinaVociDiBilancio(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
 
    function callbackSetValoreTendEditabileRisorsa(valore,index){
        let dati = [...arrayRisorsaRataFatt];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,valore);//);
        setArrayRisorsaRataFatt(dati);//aggiorno nuovamente la var di stato
    }

    function callbackSetValoreTendEditabileVociDiBilancio(valore,index){
        let dati = [...arrayVociDiBilancioRataFatt];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,valore);//);
        setArrayVociDiBilancioRataFatt(dati);//aggiorno nuovamente la var di stato
    }

    function aggiungiPagamento() {
        let ese = [...arrayNumeroRate];//popolo l'array locale con i dati dell'array di stato
        ese.push(ese.length);//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayNumeroRate(ese);//aggiorno nuovamente la var di stato

        let riso = [...arrayRisorsaRataFatt];
        riso.push("");
        setArrayRisorsaRataFatt(riso);

        let voci = [...arrayVociDiBilancioRataFatt];
        voci.push(arrayVociDiBilancioRataFatt[0]!==undefined ? arrayVociDiBilancioRataFatt[0] : "");
        setArrayVociDiBilancioRataFatt(voci);

        let dpagamento = [...arrayDataPagamentoRataFatt];//
        dpagamento.push(dataOggi);
        setArrayDataPagamentoRataFatt(dpagamento);

        let dscad = [...arrayDataScadenzaRataFatt];
        dscad.push("");
        setArrayDataScadenzaRataFatt(dscad);

        let importo = [...arrayImportoRataFatt];
        importo.push("");
        setArrayImportoRataFatt(importo);

        let iva = [...arrayIvaRataFatt];
        iva.push("");
        setArrayIvaRataFatt(iva);

        let note = [...arrayNoteRataFatt];
        note.push("");
        setArrayNoteRataFatt(note);   
    }

    function tornaElenco() {
        props.callbackTermineModifica();
    }

    function tornaScheda() {
        setFlagAttivaModaleSpesaNonCancellabile(0);
        setFlagAttivaModaleInserisciEnte(0);
        setFlagAttivaModaleRichiediPermessoInserire(0);
        setFlagAttivaModaleRichiediPIVA(0);
        setFlagAttivaModaleInserisciDescrizione(0);
        setFlagAttivaModaleInserisciDataPagamento(0);
        setFlagAttivaModaleInserisciDataEmissione(0);
        setFlagAttivaModaleInserisciRisorsa(0);
        setFlagAttivaModaleInserisciVoceBilancio(0);
        setFlagAttivaModaleInserisciImporto(0);
        setFlagAttivaModaleInserisciImportoPagamento(0);
        setFlagAttivaModaleImponibileErrato(0);
        setFlagAttivaModaleImportoErrato(0);
        setFlagAttivaModaleIvaErrata(0);
        setFlagAttivaModaleImportoPagamentoErrato(0);
        setFlagAttivaModaleIvaPagamentoErrata(0);

        setFlagModaleInfoStato(false);
        setFlagModaleInfoImporto(false);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
   
    }
    function annullaInserimentoDatiNuovi(){
        setPermessoInserireEnte(0);
        setPermessoInserireRisorsa(0);
        setPermessoInserireVoceDiBilancio(0);
        setPermessoCancellazioneForzata(0);
        tornaScheda();
        //setStatoPagina(STATO_INSERISCI);
    }

    function confermaInserimentoDatiNuovi(){
        setPermessoInserireEnte(1);
        setPermessoInserireRisorsa(1);
        setPermessoInserireVoceDiBilancio(1);
        setPermessoCancellazioneForzata(1);
        //setStatoPagina(STATO_INSERISCI);
    }

    function calcolaPrimoPagamento(val){
        let importo = [...arrayImportoRataFatt];

        if(props.ope=='inserisci' && arrayNumeroRate.length==1){
            importo.splice(0,1,val);//modifico l'elemento desiderato
            setArrayImportoRataFatt(importo);
        }
        ricalcolaIvaRate(val,iva.current.value,importo);
    }
   
    function eliminaRataFattura(posizione) {
        let nrat = [...arrayNumeroRate];//popolo l'array locale con i dati dell'array di stato
        nrat.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayNumeroRate(nrat);//aggiorno nuovamente la var di stato

        let riso = [...arrayRisorsaRataFatt];//
        riso.splice(posizione, 1);//
        setArrayRisorsaRataFatt(riso);//
        //setDefaultRisorse(riso);

        let voci = [...arrayVociDiBilancioRataFatt];//
        voci.splice(posizione, 1);//
        setArrayVociDiBilancioRataFatt(voci);//
        //setDefaultVociDiBilancio(voci);

        let dscad = [...arrayDataScadenzaRataFatt];//
        dscad.splice(posizione, 1);//
        setArrayDataScadenzaRataFatt(dscad);//

        let dpag = [...arrayDataPagamentoRataFatt];//
        dpag.splice(posizione, 1);//
        setArrayDataPagamentoRataFatt(dpag);//

        let importo = [...arrayImportoRataFatt];//
        importo.splice(posizione, 1);//
        setArrayImportoRataFatt(importo);//

        let iva = [...arrayIvaRataFatt];//
        iva.splice(posizione, 1);//
        setArrayIvaRataFatt(iva);//

        let note = [...arrayNoteRataFatt];//
        note.splice(posizione, 1);//
        setArrayNoteRataFatt(note);//

        setEliminazioneRata(eliminazioneRata+1);
        //setRivisualizza(rivisualizza+1);
    }

    function aggiornaStatoPagamentoFattura(){
        let datiStato = [...arrayImportoRataFatt];
        let i = 0;
        let sommatoriaRate = 0;
        for (i=0; i<datiStato.length; i++) { 
            if(datiStato[i]!==undefined && datiStato[i]!==''){
                sommatoriaRate = sommatoriaRate + parseFloat(FormattaImportoDb(datiStato[i]));
            }
        }
        //confronto il totale delle rate con il totale importo fattura e se >= allora pagato
        if(importo.current !== undefined){
            if(sommatoriaRate >= FormattaImportoDb(importo.current.value)){ 
                setInsStato("Pagato")
            }else setInsStato("Non pagato")
        }
    }

    function aggiornaDataScadenzaRata(index,dato){
        let dati = [...arrayDataScadenzaRataFatt];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayDataScadenzaRataFatt(dati);//aggiorno nuovamente la var di stato
    }

    function aggiornaDataPagamentoRata(index,dato){
        let dati = [...arrayDataPagamentoRataFatt];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayDataPagamentoRataFatt(dati);//aggiorno nuovamente la var di stato   
    }

    function aggiornaImportoRata(index,dato,aggiornatoAMano=0){
        let dati = [...arrayImportoRataFatt];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayImportoRataFatt(dati);//aggiorno nuovamente la var di stato
        //if(aggiornatoAMano === 0) setAggiornatoImportoRata(1);
        ricalcolaIvaRate(importoDefault,iva.current.value,dati);
    }


    function aggiornaNoteRata(index,dato){
        let dati = [...arrayNoteRataFatt];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayNoteRataFatt(dati);//aggiorno nuovamente la var di stato
    }


    function ricalcolaIvaRate(importoTotFattura,ivaTotFattura,arrayImportoRataFatt){
        let dati = [...arrayIvaRataFatt];//popolo l'array locale con i dati dell'array di stato

        //per ogni rata calcolo il valore dell'iva in proporzione a quanto ammonta l'importo della rata rispetto all'importo totale fattura
        for (let i=0; i<arrayImportoRataFatt.length ; i++) {
            let importoIvaRata = 0;

            console.log("arrayImportoRataFatt["+i+"]="+arrayImportoRataFatt[i])
            console.log("ivaTotFattura="+ivaTotFattura)
            console.log("importoTotFattura="+importoTotFattura)

            if(importoTotFattura!=='' && ivaTotFattura!==''){
                importoIvaRata = FormattaImportoDb(arrayImportoRataFatt[i]) * FormattaImportoDb(ivaTotFattura) / FormattaImportoDb(importoTotFattura);
                if(isNaN(importoIvaRata)) importoIvaRata = 0;
            }            
            dati.splice(i,1,FormattaImportoVideo(importoIvaRata.toFixed(2)));//modifico l'elemento desiderato
        }
        setArrayIvaRataFatt(dati);//aggiorno nuovamente la var di stato
    }



    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;

        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            //controllo lunghezza campo text:
           
            if(note.current.value.length>500){
                controlli = 1;
                alert("Il campo Note contiene troppi caratteri (massimo accettato 500)");
                setDisabilitaBottoni(false);
            }

            if(insClienteFornitoreEdi=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciEnte(1);
            }
            if(descrizione.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDescrizione(1);
            }
            if(importo.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciImporto(1);
            }
            if(dataEmissione.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDataEmissione(1);
            }
            if(imponibile.current.value==="") imponibile.current.value = 0;
            if(iva.current.value==="") iva.current.value = 0;
            if(isNaN(FormattaImportoDb(imponibile.current.value))){
                controlli = 1;
                setFlagAttivaModaleImponibileErrato(1);
            }
            if(isNaN(FormattaImportoDb(importo.current.value))){
                controlli = 1;
                setFlagAttivaModaleImportoErrato(1);
            }
            if(isNaN(FormattaImportoDb(iva.current.value))){
                controlli = 1;
                setFlagAttivaModaleIvaErrata(1);
            }
            let sommatoriaRate = 0;
            //controllo che ogni pagamento abbia la data di pagamento indicata:
            for (let i=0; i<arrayNumeroRate.length; i++) { 
                if(arrayDataPagamentoRataFatt[i]==='' || !(arrayDataPagamentoRataFatt[i])){
                    controlli = 1;
                    setFlagAttivaModaleInserisciDataPagamento(1);             
                }
                if(arrayRisorsaRataFatt[i]==='' || !(arrayRisorsaRataFatt[i])){
                    controlli = 1;
                    setFlagAttivaModaleInserisciRisorsa(1);             
                }
                if(arrayVociDiBilancioRataFatt[i]==='' || !(arrayVociDiBilancioRataFatt[i])){
                    controlli = 1;
                    setFlagAttivaModaleInserisciVoceBilancio(1);             
                }
                if(arrayImportoRataFatt[i]==='' || !(arrayImportoRataFatt[i])){
                    controlli = 1;
                    setFlagAttivaModaleInserisciImportoPagamento(1);             
                }
                if(isNaN(FormattaImportoDb(arrayImportoRataFatt[i]))){
                    controlli = 1;
                    setFlagAttivaModaleImportoPagamentoErrato(1);
                }
                /*if(isNaN(FormattaImportoDb(arrayIvaRataFatt[i]))){
                    controlli = 1;
                    setModaleIvaPagamentoErrata(true);
                    setFlagAttivaModaleIvaPagamentoErrata(1);
                }*/
                if(arrayImportoRataFatt[i]!==undefined && arrayImportoRataFatt[i]!==''){
                    sommatoriaRate = sommatoriaRate + parseFloat(FormattaImportoDb(arrayImportoRataFatt[i]));
                }
                if(arrayNoteRataFatt[i].length>500){
                    controlli = 1;
                    alert("Un campo Note Pagamento contiene troppi caratteri (massimo accettato 500)");
                    setDisabilitaBottoni(false);
                }
            }
            if(sommatoriaRate > FormattaImportoDb(importo.current.value)){
                alert("La somma degli importi dei pagamenti non può essere superiore all'importo totale della spesa")
                setDisabilitaBottoni(false);
                controlli = 1;
            }
        }

        if(controlli===0){ 
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                // parametri per inviare i dati al server:
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ?  JSON.stringify({op:operazione,
                        clienteFornitore:insClienteFornitoreEdi,
                        permessoInserireEnte:permessoInserireEnte,
                        permessoInserireRisorsa:permessoInserireRisorsa,
                        permessoInserireVoceDiBilancio:permessoInserireVoceDiBilancio,
                        importo:FormattaImportoDb(importo.current.value),
                        imponibile:FormattaImportoDb(imponibile.current.value),
                        iva:FormattaImportoDb(iva.current.value),
                        tipologia:tipologia,
                        descrizione:descrizione.current.value,
                        dataEmissione:dataEmissione.current.value,
                        stato:insStato,
                        note:note.current.value,

                        arrayRisorsaRataFatt:arrayRisorsaRataFatt,
                        arrayVociDiBilancioRataFatt:arrayVociDiBilancioRataFatt,
                        arrayDataScadenzaRataFatt:arrayDataScadenzaRataFatt ,
                        arrayDataPagamentoRataFatt:arrayDataPagamentoRataFatt,  
                        arrayImportoRataFatt:arrayImportoRataFatt, 
                        arrayIvaRataFatt:arrayIvaRataFatt, 
                        arrayNoteRataFatt:arrayNoteRataFatt,   
                        arrayNumeroRate:arrayNumeroRate,  

                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        permessoCancellazioneForzata:permessoCancellazioneForzata,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Indicare la partita iva per individuazione") !== -1){
                                    setFlagAttivaModaleRichiediPIVA(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire fornitore") !== -1){
                                    setPermessoInserireEnte(-1);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire risorse") !== -1){
                                    setPermessoInserireRisorsa(-1);
                                    setRisorseNuove(valoreDiRitorno.risorse_nuove);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire voci di bilancio") !== -1){
                                    setPermessoInserireVoceDiBilancio(-1);
                                    setVociDiBilancioNuove(valoreDiRitorno.voci_di_bilancio_nuove);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Richiedi titolare fattura") !== -1){
                                    setFlagAttivaModaleInserisciEnte(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dato non cancellabile: presente in prima nota") !== -1){
                                    setPermessoCancellazioneForzata(-1);
                                    setFlagAttivaModaleSpesaNonCancellabile(1);
                                    
                                } 
                                if(valoreDiRitorno.mess.indexOf("Descrizione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDescrizione(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Data pagamento non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDataPagamento(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Data emissione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDataEmissione(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Risorsa non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciRisorsa(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Voce bilancio non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciVoceBilancio(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Importo non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciImporto(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Importo pagamento non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciImportoPagamento(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Importo pagamento errato") !== -1){
                                    setFlagAttivaModaleImportoPagamentoErrato(1);
                                }
                                
                                if(valoreDiRitorno.mess.indexOf("Iva pagamento errata") !== -1){
                                    setFlagAttivaModaleIvaPagamentoErrata(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Importo errato") !== -1){
                                    setFlagAttivaModaleImportoErrato(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Imponibile errato") !== -1){
                                    setFlagAttivaModaleImponibileErrato(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Iva errata") !== -1){
                                    setFlagAttivaModaleIvaErrata(1);
                                }
                            }
                            //altrimenti vado in errore generico
                            if(!valoreDiRitorno.mess) setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
      
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1C_Spe {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA   ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                {flagAttivaModaleInserisciImportoPagamento===1 ?
                        <Modale 
                            titolo="Richiesta importo pagamento"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato l'importo pagamento, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciDataPagamento===1 ?
                        <Modale 
                            titolo="Richiesta importo pagamento"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la data pagamento, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciDataEmissione===1 ?
                        <Modale 
                            titolo="Richiesta data emissione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la data emissione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciVoceBilancio===1 ?
                        <Modale 
                            titolo="Richiesta voce bilancio"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la voce di bilancio, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciRisorsa===1 ?
                        <Modale 
                            titolo="Richiesta risorsa"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la risorsa, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciDescrizione===1 ?
                        <Modale 
                            titolo="Richiesta descrizione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la descrizione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        /> 
                    :""}

                    {flagAttivaModaleInserisciImporto===1 ?
                        <Modale 
                            titolo="Richiesta importo"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato l'importo, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        /> 
                    :""} 

                    {flagAttivaModaleSpesaNonCancellabile===1 ?
                        <Modale 
                            titolo="Informativa cancellazione spesa"
                            flagErrore={true}
                            contenuto={<div>La spesa selezionata è associata ad uno o più record in prima nota. Premendo Ok si andrà a cancellare sia il record relativo alla spesa in esame sia i relativi record in prima nota</div>}
                            bottoni={[
                                {
                                    "etichetta":"Ok",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);setPermessoCancellazioneForzata(1);fetchOperazioneServer(1)}
                                } ,
                                {
                                    "etichetta":"Annulla",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        /> 
                    :""}

                    {flagAttivaModaleRichiediPermessoInserire===1 ?
                        <Modale 
                        titolo="Richiesta permesso inserimento"
                        flagErrore={true}
                        contenuto={<div>
                            {permessoInserireEnte==-1 ? <>Vuoi davvero inserire {insClienteFornitoreEdi} nell'elenco degli enti esterni?<br/></> :""}
                            {permessoInserireRisorsa===-1 ? <>Vuoi davvero inserire le seguenti nell'elenco delle risorse? 
                                    {risorseNuove !== undefined ?
                                        risorseNuove.map(risorseNuoveElemento =>
                                            <div key={risorseNuoveElemento}>
                                            <Label>{risorseNuoveElemento}</Label>
                                            </div>                                                   
                                        )
                                    :""}<br/></> :""}
                            {permessoInserireVoceDiBilancio===-1 ?<>Vuoi davvero inserire le seguenti nell'elenco delle voci di bilancio?
                                        {vociDiBilancioNuove !== undefined ?
                                        vociDiBilancioNuove.map(vociDiBilancioNuoveElemento =>
                                            <div key={vociDiBilancioNuoveElemento}>
                                            <Label>{vociDiBilancioNuoveElemento}</Label>
                                            </div>                                                   
                                        )
                                    :""}<br/></> :""}
                        </div>}      

                        bottoni={
                           
                                [{
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);confermaInserimentoDatiNuovi()}
                                },  
                                {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }]
                            
                        }
                        />
                    :""}


                    {flagAttivaModaleRichiediPIVA===1 ?
                        <Modale 
                            titolo="Richiesta partita IVA"
                            flagErrore={true}
                            contenuto={<div>Nel database ci sono più fornitori con Ragione Sociale: {insClienteFornitoreEdi} Si prega di indicare la partita iva in modo da poter individuare quello desiderato oppure per procedere con l'inserimento di un nuovo fornitore qualorala partita iva non corrispondesse a nessuno degli enti individuati</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}
                    
                    {flagAttivaModaleInserisciEnte===1 ?
                        <Modale 
                            titolo="Richiesta fornitore"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato il fornitore, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />  
                    :""}

                    {flagAttivaModaleImponibileErrato===1 ?
                        <Modale 
                            titolo="Informativa imponibile errato"
                            flagErrore={true}
                            contenuto={<div>L'importo inserito per l'imponibile non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />  
                    :""}

                    {flagAttivaModaleImportoErrato===1 ?
                        <Modale 
                            titolo="Informativa importo errato"
                            flagErrore={true}
                            contenuto={<div>L'importo inserito non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />  
                    :""}

                    {flagAttivaModaleIvaErrata===1 ?
                        <Modale 
                            titolo="Informativa importo errato"
                            flagErrore={true}
                            contenuto={<div>L'importo inserito per l'iva non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />  
                    :""}

                    {flagAttivaModaleImportoPagamentoErrato===1 ?
                        <Modale 
                            titolo="Informativa importo pagamento errato"
                            flagErrore={true}
                            contenuto={<div>L'importo inserito per l'importo pagamento non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />  
                    :""}

                    {flagAttivaModaleIvaPagamentoErrata===1 ?
                        <Modale 
                            titolo="Informativa iva Pagamento errata"
                            flagErrore={true}
                            contenuto={<div>L'importo inserito per l'iva pagamento non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />  
                    :""}

                    {flagModaleInfoStato=== true ?
                    <Modale 
                        titolo="Informativa Stato Pagamento"
                        flagErrore={false}
                        contenuto="Lo stato pagamento è valorizzato inizialmente a 'Non Pagato' finchè la somma degli importi dei pagamenti non uguaglia o supera il valore dell'importo del'incasso. Il valore passa a 'Pagato', ma rimane comunque modificabile. Lo stato viene impostato 'Non Pagato' se non è presente alcun pagamento"
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {flagModaleInfoImporto=== true ?
                    <Modale 
                        titolo="Informativa Importo"
                        flagErrore={false}
                        contenuto="L'importo di una spesa deve essere POSITIVO e deve comprendere tutto il totale della spesa comprensivo di eventuali sconti o maggiorazioni"
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Spesa</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Spesa</CardTitle> :""}
                            

{/*{noteRata!== undefined ? <CardTitle>mostro noterata in zero={noteRata.current[0].value}</CardTitle> :""}*/}
                            
                            <Clessidra loading={loading}/>

                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione della spesa con fornitore <b>{insClienteFornitoreEdi}</b>, con importo <b>{FormattaImportoVideo(importoDefault)}</b> euro e con data emissione <b><TrasformaDateAUmano data={dataEmissioneDefault}></TrasformaDateAUmano></b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :
                                
                                <>
                                <Row form>  
                                <Col md={6}>
                                <Label>Fornitore*</Label>
                                <TendinaEditabile
                                    //titolo={"Fornitore*"}
                                    nomeCampo="COGNOME"
                                    nomeCampoParentesi="P_IVA"
                                    valoreDefault={insClienteFornitoreEdi}
                                    arrayValori={risultatoServerDatiClienteFornitore}
                                    callbackSetVariazioneCampi={setVariazioneCampi}
                                    callbackSetValoreTendEditabile={setInsClienteFornitoreEdi}
                                ></TendinaEditabile>
      
                                </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="imponibile">Imponibile</Label>
                                                <Input maxLength={12} type="text" name="imponibile" id="imponibile"
                                                innerRef={imponibile} 
                                                placeholder=""
                                                defaultValue={FormattaImportoVideo(imponibileDefault,1)}
                                                onInput={(e)=>{handleCambiamentoMod(e);setImponibileDefault(imponibile.current.value);}}/>  
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="iva">IVA</Label>
                                                <Input maxLength={12} type="text" name="iva" id="iva"
                                                innerRef={iva} 
                                                placeholder=""
                                                defaultValue={FormattaImportoVideo(ivaDefault,1)}
                                                onInput={(e)=>{handleCambiamentoMod(e);setIvaDefault(iva.current.value);ricalcolaIvaRate(importoDefault,iva.current.value,arrayImportoRataFatt);}}/>  
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="importo">Importo*<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoImporto(true)}/></Label>
                                                <Input maxLength={12} type="text" name="importo" id="importo"
                                                innerRef={importo} 
                                                placeholder=""
                                                defaultValue={FormattaImportoVideo(importoDefault,1)}
                                                onInput={(e)=>{handleCambiamentoMod(e);calcolaPrimoPagamento(importo.current.value);setImportoDefault(importo.current.value);}}/>  
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="descrizione">Descrizione*</Label>
                                                <Input maxLength={200} type="text" name="descrizione" id="descrizione"
                                                innerRef={descrizione} 
                                                placeholder=""
                                                defaultValue={descrizioneDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                              
                                    {arrayNumeroRate.length>0 ? 
                                        <Col md={3}>
                                        <FormGroup>
                                            <Label for="dataEmissione">Data Emissione*</Label>
                                                <Input maxLength={10} type="date" name="dataEmissione" id="dataEmissione"
                                                innerRef={dataEmissione} 
                                                placeholder=""
                                                defaultValue={dataEmissioneDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataEmissioneDefault(dataEmissione.current.value);}}/> 
                                        </FormGroup>
                                        </Col>
                                    :   <Col md={6}>
                                        <FormGroup>
                                        <Label for="dataEmissione">Data Emissione*</Label>
                                            <Input maxLength={10} type="date" name="dataEmissione" id="dataEmissione"
                                            innerRef={dataEmissione} 
                                            placeholder=""
                                            defaultValue={dataEmissioneDefault}
                                            onInput={(e)=>{handleCambiamentoMod(e);setDataEmissioneDefault(dataEmissione.current.value);}}/> 
                                        </FormGroup>
                                        </Col>
                                    }

                                    {arrayNumeroRate.length>0 ?
                                    <Col md={3}>
                               
                                    <FormGroup>
                                        <Label>Stato<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoStato(true)}/></Label><br/>
                                        <select value={insStato} className="inputStandard" onChange={(e) => {handleCambiamentoMod(e);setInsStato(e.target.value)}}>
                                        {insStato === 'Pagato' ? <option selected value="Pagato">Pagato</option> : <option value="Pagato">Pagato</option> }
                                        {insStato === 'Non pagato' ? <option selected value="Non pagato">Non pagato</option> : <option value="Non pagato">Non pagato</option> }
                                        </select>

                                        </FormGroup>
                                    </Col>
                                    :""}
                                </Row>

                                <div className='schedaSottotitolo'>
                                Pagamenti
                                </div>

                                <Row>
                                <Col lg="12">
                                    <FormGroup>
                        
                                        {arrayNumeroRate !== undefined && arrayNumeroRate !=='' && arrayNumeroRate.length>0 ?
                                        arrayNumeroRate.map((elemento,index) => <div key={index}>
                                            <FormGroup>
                                         <Row>  
                                         <Col lg="1"></Col>
                                   
                                        <Col lg="6">
                                        <FormGroup>
                                        <Label for="risorsa">Risorsa*</Label>
                                                <TendinaEditabile
                                                    nomeCampo="DESCRIZIONE"
                                                    valoreDefault={arrayRisorsaRataFatt[index]}
                                                    arrayValori={risultatoServerDatiTutteRisorse}
                                                    posizioneTendina = {index}
                                                    callbackSetVariazioneCampi={setVariazioneCampi}
                                                    callbackSetValoreTendEditabile={callbackSetValoreTendEditabileRisorsa}
                                                    eliminazioneRata={arrayRisorsaRataFatt[index]}
                                                ></TendinaEditabile>
                                        </FormGroup>
                                        </Col>
                                        <Col lg="5">
                                        <FormGroup>
                                        <Label for="risorsa">Voce di Bilancio*</Label>
                                            <TendinaEditabile
                                                nomeCampo="DESCRIZIONE"
                                                //valoreDefault={defaultVociDiBilancio[index]}
                                                valoreDefault={arrayVociDiBilancioRataFatt[index]}
                                                arrayValori={risultatoServerDatiTutteVociDiBilancio}
                                                posizioneTendina = {index}
                                                callbackSetVariazioneCampi={setVariazioneCampi}
                                                callbackSetValoreTendEditabile={callbackSetValoreTendEditabileVociDiBilancio}
                                            ></TendinaEditabile>
                                        </FormGroup>
                                        </Col>
                                        </Row>
                                        
                                        <Row>
                                        <Col lg="1"><center><img onClick={() => { handleCambiamentoMod(1);eliminaRataFattura(index);}} src={Cestino}/></center></Col>
                                        
                                        <Col lg="3">
                                            <FormGroup>
                                            <Label for="dataScadenzaRata">Data Scad. Pagamento</Label>
                                            <Input maxLength={10} type="date"  value={arrayDataScadenzaRataFatt[index]} 
                                            ref={(elemento1) => dataScadenzaRata.current[index] = elemento1}
                                                   placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaDataScadenzaRata(index,e.target.value)}}/> 
                                            </FormGroup>
                                        </Col>
                                                                      
                                        <Col lg="3">
                                            <FormGroup>
                                            <Label for="dataPagamentoRata">Data Pagamento*</Label>
                                            <Input maxLength={10} type="date"  value={arrayDataPagamentoRataFatt[index]} 
                                            ref={(elemento1) => dataPagamentoRata.current[index] = elemento1}
                                                   placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaDataPagamentoRata(index,e.target.value);}}/> 
                                            </FormGroup>
                                        </Col>
                                       
                                        <Col lg="3">
                                        
                                        <FormGroup>
                                            <Label for="importoPagamento">Importo Pagamento*</Label>
                                                <Input maxLength={12} type="text"
                                                innerRef={(elemento1) => importoRata.current[index] = FormattaImportoVideo(elemento1)}
                                                placeholder=""
                                                value={arrayImportoRataFatt[index]}
                                                onInput={(e)=>{handleCambiamentoMod(e);aggiornaImportoRata(index,e.target.value)}}/>   
                                        </FormGroup>

                                        </Col>
                                        <Col lg="2">
                                        <FormGroup>
                                            <Label for="ivaPagamento">IVA Pagamento: {arrayIvaRataFatt[index]}</Label> 
                                        </FormGroup>
                                        </Col>
                                        </Row> 

                                        <Row> 
                                        <Col lg="1">
                                        <Label for=""></Label>
                                        </Col>
                                        <Col lg="11">
                                            <FormGroup>
                                            <Label for="noteRata">Note Pagamento</Label>
                                            <Input type="text"  value={arrayNoteRataFatt[index]} 
                                            ref={(elemento1) => noteRata.current[index] = elemento1}
                                                   placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaNoteRata(index,e.target.value)}}/> 
                                            </FormGroup>
                                        </Col>
                                        </Row></FormGroup></div>)      
                                        :""}

                                        </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Row form> 
                                            <Col md={12}><Button color="primary" className="mt-2" onClick={() => {
                                                handleCambiamentoMod(1);
                                                aggiungiPagamento();
                                            }} 
                                            >Aggiungi Pagamento</Button></Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="note">Note</Label>
                                                <Input type="text" name="note" id="note"
                                                innerRef={note} 
                                                placeholder=""
                                                defaultValue={noteDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setNoteDefault(note.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>
                                </>
                                }
                                

                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() =>  {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() =>  {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                               
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment>Annulla</Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>

                                </div>

                    </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
