import React, {Fragment,useState,useEffect,useRef} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button,FormGroup,Label,Input,Modal } from 'reactstrap';

import Parametri from '../../parametri';
import arrayColoriGrafico from '../../coloriGrafici';

import FormattaImportoVideo from '../FormattaImportoVideo.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

//possibili grafici(possible exports: Bar, Bubble, Chart, Doughnut, Line, Pie, PolarArea, Radar, Scatter, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent)

import {Bar} from 'react-chartjs-2';//istogramma a barre verticali
import {Doughnut} from 'react-chartjs-2';//un grafico a torta con stile ciambella
//import {HorizontalBar} from 'react-chartjs-2';//istogramma a barre orizzontali
import {Line} from 'react-chartjs-2';//grafico con linea su piano cartesiano
import {Pie} from 'react-chartjs-2';//la classica torta
import {PolarArea} from 'react-chartjs-2';//tipo torta ma con un aspetto grafico più ricercato e tecnologico
import {Radar} from 'react-chartjs-2';//tipo radar con ai vertici le voci; è possibile rappresentare più dataset
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import styles from '../stiliPdf.js'

import { calcolaAnnoOggi } from '../../funzioni.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;

const STATO_SCARICA_PDF = 8;
const STATO_PREPARA_PDF = 9;


const COLORE_TITOLI_REPORT="#a5a5a4";

function TabellaReportEconPersone(props) {

    const [ricercaCompletata,setRicercaCompletata]=useState(0);

    const rimborsiGraficoCanvas = useRef();
    const incassiDiversiGraficoCanvas = useRef();
    const qAssGraficoCanvas = useRef();
    const qAbbGraficoCanvas = useRef();
    const totGraficoCanvas = useRef();
    const rimborsiStandardGraficoCanvas = useRef();
    const totIncassiStandardGraficoCanvas = useRef();
    const totStandardGraficoCanvas = useRef();

    const [necessitaRicalcolo,setNecessitaRicalcolo]=useState(0);
    const [topDefault,setTopDefault]=useState('10');
    const top = useRef();

    const coloriGraficoSfondo=arrayColoriGrafico;

    const coloriGraficoBordo='rgb(0,0,0)';

    const larghezzaBordoGrafico=1;

    const larghColonnaDati=1;
    const larghColonnaIscritto=9;//versione vecchia con iva : 3
    const larghColonnaDatiDett=2;
    const larghColonnaIscrittoDett=2;

    const flagVisualizzaImportiLordiDefault=1;

    const flagVisualizzaImportiLordiDefaultDett=1;//nel layout dettagliato faccio vedere di default il lordo

    const  radioPeriodo = useRef();
    const [radioPeriodoDefault,setRadioPeriodoDefault]=useState("1");

    const operazione = "reportSituazioneEconomicaIscritti";

    //const  intervallo = useRef();
    const  dataPartenza = useRef();
    
    const [risultatoServer,setRisultatoServer]=useState('');
    //const [entiScadenza,setEntiScadenza]=useState('');
    
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);
    
    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);
  
    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');

    //per grafici:
    const [dataRimborsi,setDataRimborsi]=useState([]);
    const [dataIncassiDiversi,setDataIncassiDiversi]=useState([]);
    const [dataQAss,setdataQAss]=useState([]);
    const [dataQAbb,setdataQAbb]=useState([]);

    const [dataRimborsiStandard,setDataRimborsiStandard]=useState([]);
    const [dataTotIncassiStandard,setDataTotIncassiStandard]=useState([]);

    const [dataTotale,setDataTotale]=useState([]);

    //DATI DA VISIONARE:
    const [arrayPersone,setArrayPersone]=useState([]);
    const [arrayRimborsiImporti,setArrayRimborsiImporti]=useState([]);
    const [arrayIncassiDiversiImporti,setArrayIncassiDiversiImporti]=useState([]);
    const [arrayQAssImporti,setArrayQAssImporti]=useState([]);
    const [arrayQAbbImporti,setArrayQAbbImporti]=useState([]);
    const [arrayTotIncassiImporti,setArrayTotIncassiImporti]=useState([]);//somma di IncassiDiversi + QAssImporti + QAbbImporti
    const [statoTotaleRimborsiAnno,setStatoTotaleRimborsiAnno]=useState([]);
    const [statoTotaleIncassiDiversiAnno,setStatoTotaleIncassiDiversiAnno]=useState([]);
    const [statoTotaleQAssAnno,setStatoTotaleQAssAnno]=useState([]);
    const [statoTotaleQAbbAnno,setStatoTotaleQAbbAnno]=useState([]);
    const [statoTotaleTotIncassiAnno,setStatoTotaleTotIncassiAnno]=useState([]);//totale della somma di IncassiDiversi + QAssImporti + QAbbImporti

    //consensi visualizzazione grafici:
    const [visualizzaRimborsi,setVisualizzaRimborsi]=useState(1);
    const [visualizzaRimborsiStandard,setVisualizzaRimborsiStandard]=useState(1);
    const [visualizzaIncassiDiversi,setVisualizzaIncassiDiversi]=useState(1);
    const [visualizzaQAss,setVisualizzaQAss]=useState(1);
    const [visualizzaQAbb,setVisualizzaQAbb]=useState(1);
    const [visualizzaTotIncassiStandard,setVisualizzaTotIncassiStandard]=useState(1); //totale della somma di IncassiDiversi + QAssImporti + QAbbImporti
    const [visualizzaTotaleImportiStandard,setVisualizzaTotaleImportiStandard]=useState(1);
    const [visualizzaTotaleImportiDettagliato,setVisualizzaTotaleImportiDettagliato]=useState(1);

    //tipologie di grafici per ogni sezione:
    const [tipoGraficoRimborsi,setTipoGraficoRimborsi]=useState("Bar");
    const [tipoGraficoRimborsiStandard,setTipoGraficoRimborsiStandard]=useState("Bar");
    const [tipoGraficoIncassiDiversi,setTipoGraficoIncassiDiversi]=useState("Bar");
    const [tipoGraficoQAss,setTipoGraficoQAss]=useState("Bar");
    const [tipoGraficoQAbb,setTipoGraficoQAbb]=useState("Bar");
    const [tipoGraficoTotIncassiStandard,setTipoGraficoTotIncassiStandard]=useState("Bar");//totale della somma di IncassiDiversi + QAssImporti + QAbbImporti
    const [tipoGraficoTotaleImportiStandard,setTipoGraficoTotaleImportiStandard]=useState("Bar");
    const [tipoGraficoTotaleImportiDettagliato,setTipoGraficoTotaleImportiDettagliato]=useState("Bar");

    //consensi visualizzazione tabelle:
    const [visualizzaTab,setVisualizzaTab]=useState(1);
    const [visualizzaTabStandard,setVisualizzaTabStandard]=useState(1);

    //nomi dei tipi di grafici: (se cambi vai a modificare anche le select dei tipi che ora sono in ordine alfabetico secondo quete deciture)
    let descriDoughnut = "Ciambella";
    let descriLine = "Funzione";
    //let descriHorizontalBar = "Istogramma orizzontale";
    let descriBar = "Istogramma verticale";
    let descriPolarArea = "Polare";
    let descriRadar = "Radar";
    let descriPie =  "Torta";

    const  TGRimborsi = useRef();
    const  TGRimborsiStandard = useRef();
    const  TGIncassi = useRef();
    const  TGQAss = useRef();
    const  TGQAbb = useRef();
    const  TGTotaleImportiDettagliato = useRef();
    const  TGTotIncassiStandard = useRef();
    const  TGTotaleImportiStandard = useRef();

    //modale per attivar le impostazioni:
    const [modaleImpostazioni, setModaleImpostazioni] = React.useState(false);
    const [modaleImpostazioniStandard, setModaleImpostazioniStandard] = React.useState(false);

    //tipologia di report : standard (default) o dettagliato
    const [reportDettagliato,setReportDettagliato]=useState(0);

    const [tableColStato,setTableColStato]=useState(0);

    const [arrayIscrittiTopRimborsi,setArrayIscrittiTopRimborsi]=useState([]);
    const [arrayRimborsiTopImporti,setArrayRimborsiTopImporti]=useState([]);
    const [arrayIscrittiTopIncassiDiversi,setArrayIscrittiTopIncassiDiversi]=useState([]);
    const [arrayIncassiDiversiTopImporti,setArrayIncassiDiversiTopImporti]=useState([]);
    const [arrayIscrittiTopQuoteAbb,setArrayIscrittiTopQuoteAbb]=useState([]);
    const [arrayQuoteAbbTopImporti,setArrayQuoteAbbTopImporti]=useState([]);
    const [arrayIscrittiTopQuoteAss,setArrayIscrittiTopQuoteAss]=useState([]);
    const [arrayQuoteAssTopImporti,setArrayQuoteAssTopImporti]=useState([]);
    const [arrayIscrittiTopTotale,setArrayIscrittiTopTotale]=useState([]);
    const [arrayTotaleTopImporti,setArrayTotaleTopImporti]=useState([]);
    const [arrayIscrittiTopTotaleIncassi,setArrayIscrittiTopTotaleIncassi]=useState([]);
    const [arrayTotaleIncassiTopImporti,setArrayTotaleIncassiTopImporti]=useState([]);

    const [graficoRimborsi,setGraficoRimborsi]=useState(0);
    const [graficoIncassiDiversi,setGraficoIncassiDiversi]=useState(0);
    const [graficoQass,setGraficoQass]=useState(0);
    const [graficoQabb,setGraficoQabb]=useState(0);
    const [graficoTotaleIncassi,setGraficoTotaleIncassi]=useState(0);
    const [graficoTotale,setGraficoTotale]=useState(0);
    
  
    /*const styles = StyleSheet.create({
      page: {flexDirection: 'row',paddingBottom: 50,paddingTop: 50,},
      titolo: {fontFamily: 'Helvetica-Bold',marginLeft: 1, fontSize: 18},
      titoloPiccolo: {fontFamily: 'Helvetica-Bold',marginLeft: 10, fontSize: 10},
      image: {height: 150,width: 200},
      logo: {height: 60,width: 80,objectFit: 'contain'},
      nomesocieta: {fontFamily: 'Helvetica-Bold',marginLeft: 1,marginBottom: 5, fontSize: 22,},
      viewer: {height: '100vh',width: '150vh'},

      table: {display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0,margin: 10, }, 
      tableIntestazione: { display: "table", width: "auto", borderRightWidth: 0, borderBottomWidth: 0,margin: 10,}, 
      tableRowGrigia: { margin: "auto", flexDirection: "row",height: "auto",backgroundColor : "silver",}, 
      tableRow: { margin: "auto", flexDirection: "row",height: "auto",}, 
      tableRowIntestazione: { margin: "auto", flexDirection: "row",height: 60,}, 
      tableRowBlu: { margin: "auto", flexDirection: "row",backgroundColor : "#99cbff",height: "auto",}, 
      tableColIntestazioneSx: { width: 100/3+"%", borderLeftWidth: 0, borderTopWidth: 0},
      tableColIntestazioneDx: { width: 2*100/3+"%", borderLeftWidth: 0, borderTopWidth: 0 },
      tableCol: { width: 100/4+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
      tableCell: { margin: "auto", fontSize: 10, },
      tableCellIntestazione: { marginLeft: 0,fontSize: 10},
      tableCellGrassettoAllineatoSinistra: {fontFamily: 'Helvetica-Bold',textAlign: 'left',marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellGrassettoAllineatoSinistraNoBorderTop: {fontFamily: 'Helvetica-Bold',textAlign: 'left',marginLeft: 5, fontSize: 10,marginBottom: 3},
      tableCellGrassettoAllineatoDestra: {fontFamily: 'Helvetica-Bold',textAlign: 'right',marginRight: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellGrassettoAllineatoDestraNoBorderTop: {fontFamily: 'Helvetica-Bold',textAlign: 'right',marginRight: 5, fontSize: 10,marginBottom: 3},
      tableCellAllineatoSinistra: {textAlign: 'left',marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellAllineatoDestra: {textAlign: 'right',marginRight: 5, fontSize: 10,marginTop: 3,marginBottom: 3},

      grafici: {width: 300,height: 300,},
      graficiLunghi: {width: 400,height: 200,},
      pageNumber:{position: "absolute",fontSize: 12,bottom:30,left:0,right:0,textAlign:'center',color:"gray"}
    });*/

    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    const [visualizzaAltro,setVisualizzaAltro]=useState(0);
    const  visualizzaAltroRif = useRef();

    const [cambiamento,setCambiamento] = useState(0);
    const [ricercaImpostazioniReport,setRicercaImpostazioniReport] = useState(0);

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    useEffect(() => {
      let sommaColonne=1;
        if(flagVisualizzaImportiLordiDefault === 1 && reportDettagliato===1) sommaColonne = sommaColonne + 5;
        if(flagVisualizzaImportiLordiDefault === 1 && reportDettagliato===0) sommaColonne = sommaColonne + 3;
        setTableColStato(sommaColonne);
      if(statoPagina === STATO_PREPARA_PDF){
          setStatoPagina(STATO_SCARICA_PDF);
      }
    },[statoPagina,reportDettagliato,flagVisualizzaImportiLordiDefault]);

    useEffect(() => {
      if(ricercaCompletata === 1
        && dataRimborsi
        && dataIncassiDiversi
        && dataQAss
        && dataQAbb
        && dataRimborsiStandard
        && dataTotIncassiStandard
        && dataTotale
        && ricercaLogo === 1
        && ricercaImpostazioniReport === 1
        ){
        setStatoPagina(STATO_OPERAZIONE_CORRETTA);
        setRicercaCompletata(0);
      }
    },[ricercaCompletata,ricercaLogo,ricercaImpostazioniReport])
    
    useEffect(() => { 
      //grafico Rimborsi
      setDataRimborsi({labels: [''],
          datasets: [{label: 'Rimborsi (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      //grafico incassi
      setDataIncassiDiversi({labels: [''],
          datasets: [{label: 'Incassi (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      //grafico quote associative
      setdataQAss({labels: [''],
          datasets: [{label: 'Quote associative (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      //grafico quote abbonamento
      setdataQAbb({labels: [''],
          datasets: [{label: 'Quote abbonamento (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      

      //grafico Rimborsi nel layout standard
      setDataRimborsiStandard({labels: [''],
      datasets: [{label: 'Rimborsi (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
      hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]}); 

      setDataTotIncassiStandard({labels: [''],
      datasets: [{label: 'Totale Incassi (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
      hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]}); 

      setDataTotale({labels: [''],
      datasets: [{label: 'Totale (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
      hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]}); 

      scaricaLogo();
      trovaImpostazioniReport();
      },[])

    useEffect(() => {
      //grafico Rimborsi lordi
      if(arrayIscrittiTopRimborsi.length>0){
      setDataRimborsi({labels: arrayIscrittiTopRimborsi,
      datasets: [
      {
          label: 'Importo Rimborsi',
          backgroundColor: coloriGraficoSfondo,
          borderColor: coloriGraficoBordo,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayRimborsiTopImporti
      }
      ]});
    }
    },[arrayRimborsiTopImporti])

    useEffect(() => {
      //grafico incassi diversi lordi
      if(arrayIscrittiTopIncassiDiversi.length>0){
      setDataIncassiDiversi({labels: arrayIscrittiTopIncassiDiversi,
      datasets: [
      {
          label: 'Importo Incassi',
          backgroundColor: coloriGraficoSfondo,
          borderColor: coloriGraficoBordo,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayIncassiDiversiTopImporti
      }
      ]});
    }
    },[arrayIncassiDiversiTopImporti])

    useEffect(() => {
      //grafico quote associative lordi
      if(arrayIscrittiTopQuoteAss.length>0){
      setdataQAss({labels: arrayIscrittiTopQuoteAss,
      datasets: [
      {
          label: 'Importo Quote Associative',
          backgroundColor: coloriGraficoSfondo,
          borderColor: coloriGraficoBordo,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayQuoteAssTopImporti
      }
      ]});
    }
    },[arrayQuoteAssTopImporti])

    useEffect(() => {
      //grafico quote abbonamento lordi
      if(arrayIscrittiTopQuoteAbb.length>0){
      setdataQAbb({labels: arrayIscrittiTopQuoteAbb,
      datasets: [
      {
          label: 'Importo Quote Abbonamento',
          backgroundColor: coloriGraficoSfondo,
          borderColor: coloriGraficoBordo,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayQuoteAbbTopImporti
      }
      ]});
    }
    },[arrayQuoteAbbTopImporti])

    //grafici per il layout standard:
    //grafico Rimborsi lordi (come sopra)

    useEffect(() => {
      //grafico tot Incassi Lordi


      if(arrayIscrittiTopTotaleIncassi.length>0){
      setDataTotIncassiStandard({labels: arrayIscrittiTopTotaleIncassi,
      datasets: [
      {
          label: 'Importo Totale Incassi',
          backgroundColor: coloriGraficoSfondo,
          borderColor: coloriGraficoBordo,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayTotaleIncassiTopImporti
      }
      ]});
    }
    },[arrayTotaleIncassiTopImporti])


    useEffect(() => {
      //grafico totale Lordi
      if(arrayIscrittiTopTotale.length>0){
      setDataTotale({labels: arrayIscrittiTopTotale,
      datasets: [
      {
          label: 'Importo Totale Incassi',
          backgroundColor: coloriGraficoSfondo,
          borderColor: coloriGraficoBordo,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayTotaleTopImporti
      }
      ]});
    }
    },[arrayTotaleTopImporti])

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI && ricercaImpostazioniReport===1){

        setGraficoRimborsi(0);
        setGraficoIncassiDiversi(0);
        setGraficoQass(0);
        setGraficoQabb(0);
        setGraficoTotaleIncassi(0);
        setGraficoTotale(0);

        // parametri per inviare i dati al server:
        //console.log("RICERCA");
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            op:operazione,
            sogliaTop:topDefault,
            visualizzaAltro:visualizzaAltro,
            dataPartenza:dataPartenzaDefault,
            radioPeriodo:radioPeriodoDefault,
            emak: props.emak,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              
              //console.log("RISULTATO"+valoreDiRitorno.risultatoOperazione);
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                let arrLocale1=[];//rimborsi -> persone
                let arrLocale2=[];//rimborsi -> importi (lordi)

                let arrLocale3=[];//incassi diversi -> importi (lordi)
                let arrLocale3a=[];//incassi diversi -> importi (netti)
                let arrLocale3b=[];//incassi diversi -> importi (iva)
                let arrLocale4=[];//quote associative-> importi (lordi)
                let arrLocale4a=[];//quote associative-> importi (netti)
                let arrLocale4b=[];//quote associative-> importi (iva)
                let arrLocale5=[];//quote abbonamento -> importi (lordi)
                let arrLocale5a=[];//quote abbonamento -> importi (netti)
                let arrLocale5b=[];//quote abbonamento -> importi (iva)

                //per layout standard aggiungo:
                let arrLocale6=[];//rimborsi -> importi (netti)
                let arrLocale7=[];//rimborsi -> importi (iva)
                let arrLocale8=[];//tot incassi -> importi (netti)
                let arrLocale9=[];//tot incassi -> importi (iva)
                let arrLocale10=[];//tot incassi -> importi (lordi)
                
                let totaleRimborsiAnno = 0;
                let totaleIncassiDiversiAnno = 0;
                let totaleIncassiDiversiAnnoNetti = 0;
                let totaleIncassiDiversiAnnoIva = 0;
                let totaleQAssAnno = 0;
                let totaleQAssAnnoNetti = 0;
                let totaleQAssAnnoIva = 0;
                let totaleQAbbAnno = 0;
                let totaleQAbbAnnoNetti = 0;
                let totaleQAbbAnnoIva = 0;

                //per layout standard aggiungo:
                let totaleRimborsiAnnoNetti = 0;
                let totaleRimborsiAnnoIva = 0;
                let totaleTotIncassiAnnoNetti = 0;
                let totaleTotIncassiAnnoIva = 0;
                let totaleTotIncassiAnno = 0;

                let arrLocale1TopRimborsi=[];
                let arrLocale5TopRimborsi=[];
                let arrLocale1TopIncassi=[];
                let arrLocale5TopIncassi=[];
                let arrLocale1TopQuoteAbb=[];
                let arrLocale5TopQuoteAbb=[];
                let arrLocale1TopQuoteAss=[];
                let arrLocale5TopQuoteAss=[];
                let arrLocale1TopTotaleIncassi=[];
                let arrLocale5TopTotaleIncassi=[];
                let arrLocale1TopTotale=[];
                let arrLocale5TopTotale=[];
    
                for (let i=0; i<valoreDiRitorno.risultatoRitorno[0].dati.length ; i++) {
                  arrLocale1[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].PERSONA;
                  setArrayPersone(arrLocale1);
                  arrLocale2[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_RIMBORSI;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_RIMBORSI) !== 0){
                    setGraficoRimborsi(1);
                    setGraficoTotale(1);
                  } 
                  setArrayRimborsiImporti(arrLocale2);
                 
                  arrLocale3[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI) !== 0){
                    setGraficoIncassiDiversi(1);
                    setGraficoTotaleIncassi(1);
                    setGraficoTotale(1);
                  } 
                  setArrayIncassiDiversiImporti(arrLocale3);
                  arrLocale3a[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI_NETTI;
                  arrLocale3b[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI_IVA;

                  arrLocale4[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE) !== 0){
                    setGraficoQass(1);
                    setGraficoTotaleIncassi(1);
                    setGraficoTotale(1);
                  } 
                  setArrayQAssImporti(arrLocale4);
                  arrLocale4a[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE_NETTI;
                  arrLocale4b[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE_IVA;

                  arrLocale5[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ABBONAMENTI;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ABBONAMENTI) !== 0){
                    setGraficoQabb(1);
                    setGraficoTotaleIncassi(1);
                    setGraficoTotale(1);
                  } 
                  setArrayQAbbImporti(arrLocale5);
                  arrLocale5a[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ABBONAMENTI_NETTI;
                  arrLocale5b[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ABBONAMENTI_IVA;
                 

                  //per layout standard aggiungo:
                  arrLocale6[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_RIMBORSI_NETTI;
                  arrLocale7[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_RIMBORSI_IVA;
                  arrLocale8[i]=parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI_NETTI)+
                  parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE_NETTI)+
                  parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ABBONAMENTI_NETTI);
   
                  arrLocale9[i]=parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI_IVA)+
                  parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE_IVA)+
                  parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ABBONAMENTI_IVA);
             
                  arrLocale10[i]=parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI)+
                  parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE)+
                  parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ABBONAMENTI);
                  setArrayTotIncassiImporti(arrLocale10);
                  
                  for (let i1=0; i1<valoreDiRitorno.risultatoRitorno[1].topRimborsi.length ; i1++) {
                    arrLocale1TopRimborsi[i1]=valoreDiRitorno.risultatoRitorno[1].topRimborsi[i1].ISCRITTO;
                    setArrayIscrittiTopRimborsi(arrLocale1TopRimborsi);
                    arrLocale5TopRimborsi[i1]=parseFloat(valoreDiRitorno.risultatoRitorno[1].topRimborsi[i1].IMPORTI);
                    setArrayRimborsiTopImporti(arrLocale5TopRimborsi);
                  }
                  
                  for (let i2=0; i2<valoreDiRitorno.risultatoRitorno[2].topIncassi.length ; i2++) {
                    arrLocale1TopIncassi[i2]=valoreDiRitorno.risultatoRitorno[2].topIncassi[i2].ISCRITTO;
                    setArrayIscrittiTopIncassiDiversi(arrLocale1TopIncassi);
                    arrLocale5TopIncassi[i2]=parseFloat(valoreDiRitorno.risultatoRitorno[2].topIncassi[i2].IMPORTI);
                    setArrayIncassiDiversiTopImporti(arrLocale5TopIncassi);
                  }
                  
                  for (let i3=0; i3<valoreDiRitorno.risultatoRitorno[3].topQuoteAbb.length ; i3++) {
                    arrLocale1TopQuoteAbb[i3]=valoreDiRitorno.risultatoRitorno[3].topQuoteAbb[i3].ISCRITTO;
                    setArrayIscrittiTopQuoteAbb(arrLocale1TopQuoteAbb);
                    arrLocale5TopQuoteAbb[i3]=parseFloat(valoreDiRitorno.risultatoRitorno[3].topQuoteAbb[i3].IMPORTI);
                    setArrayQuoteAbbTopImporti(arrLocale5TopQuoteAbb);
                  }

                  for (let i4=0; i4<valoreDiRitorno.risultatoRitorno[4].topQuoteAss.length ; i4++) {
                    arrLocale1TopQuoteAss[i4]=valoreDiRitorno.risultatoRitorno[4].topQuoteAss[i4].ISCRITTO;
                    setArrayIscrittiTopQuoteAss(arrLocale1TopQuoteAss);
                    arrLocale5TopQuoteAss[i4]=parseFloat(valoreDiRitorno.risultatoRitorno[4].topQuoteAss[i4].IMPORTI);
                    setArrayQuoteAssTopImporti(arrLocale5TopQuoteAss);
                  }

                  for (let i5=0; i5<valoreDiRitorno.risultatoRitorno[5].topTotaleIncassi.length ; i5++) {
                    arrLocale1TopTotaleIncassi[i5]=valoreDiRitorno.risultatoRitorno[5].topTotaleIncassi[i5].ISCRITTO;
                    setArrayIscrittiTopTotaleIncassi(arrLocale1TopTotaleIncassi);
                    arrLocale5TopTotaleIncassi[i5]=parseFloat(valoreDiRitorno.risultatoRitorno[5].topTotaleIncassi[i5].IMPORTI);
                    setArrayTotaleIncassiTopImporti(arrLocale5TopTotaleIncassi);
                  }

                  for (let i6=0; i6<valoreDiRitorno.risultatoRitorno[6].topTotale.length ; i6++) {
                    arrLocale1TopTotale[i6]=valoreDiRitorno.risultatoRitorno[6].topTotale[i6].ISCRITTO;
                    setArrayIscrittiTopTotale(arrLocale1TopTotale);
                    arrLocale5TopTotale[i6]=parseFloat(valoreDiRitorno.risultatoRitorno[6].topTotale[i6].IMPORTI);
                    setArrayTotaleTopImporti(arrLocale5TopTotale);
                  }

                  //totali:
                  totaleRimborsiAnno = totaleRimborsiAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_RIMBORSI);
                  totaleIncassiDiversiAnno = totaleIncassiDiversiAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI);
                  totaleIncassiDiversiAnnoNetti = totaleIncassiDiversiAnnoNetti + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI_NETTI);
                  totaleIncassiDiversiAnnoIva = totaleIncassiDiversiAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI_IVA);
                  totaleQAssAnno = totaleQAssAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE);
                  totaleQAssAnnoNetti = totaleQAssAnnoNetti + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE_NETTI);
                  totaleQAssAnnoIva = totaleQAssAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE_IVA);
                  totaleQAbbAnno = totaleQAbbAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ABBONAMENTI);
                  totaleQAbbAnnoNetti = totaleQAbbAnnoNetti + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE_NETTI);
                  totaleQAbbAnnoIva = totaleQAbbAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE_IVA);

                  //per layout standard aggiungo:
                  totaleRimborsiAnnoNetti = totaleRimborsiAnnoNetti + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_RIMBORSI_NETTI);
                  totaleRimborsiAnnoIva = totaleRimborsiAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_RIMBORSI_IVA);
            
                  totaleTotIncassiAnnoNetti = totaleTotIncassiAnnoNetti  
                  + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI_NETTI)
                  + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE_NETTI)
                  + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ABBONAMENTI_NETTI);

                  totaleTotIncassiAnnoIva = totaleTotIncassiAnnoIva  
                  + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI_IVA)
                  + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE_IVA)
                  + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ABBONAMENTI_IVA);

                  totaleTotIncassiAnno = totaleTotIncassiAnno
                  + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI)
                  + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ASSOCIATIVE)
                  + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_QUOTE_ABBONAMENTI);
                }

                setInizioPeriodo(valoreDiRitorno.risultatoRitorno[7].periodo[0].DATA_INIZIO_PERIODO);
                setFinePeriodo(valoreDiRitorno.risultatoRitorno[7].periodo[0].DATA_FINE_PERIODO);
                
                //ora setto le var di stato dei totali:
                setStatoTotaleRimborsiAnno(totaleRimborsiAnno);
                setStatoTotaleIncassiDiversiAnno(totaleIncassiDiversiAnno);
                setStatoTotaleQAssAnno(totaleQAssAnno);
                setStatoTotaleQAbbAnno(totaleQAbbAnno);
                //per layout standard aggiungo:
                setStatoTotaleTotIncassiAnno(totaleTotIncassiAnno);

                setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                //setStatoPagina(STATO_OPERAZIONE_CORRETTA);
                setRicercaCompletata(1);
              }
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina,ricercaImpostazioniReport])
    //},[props.numPagina])


    //useEffect(() => {
      //aggiornaImpostazioniReport();
    //},[reportDettagliato])

    useEffect(() => {
      if(necessitaRicalcolo===1){
        //resetto tutte le var di stato:
        setArrayPersone([]);
        setArrayRimborsiImporti([]);
        setArrayIncassiDiversiImporti([]);
        setArrayQAssImporti([]);
        setArrayQAbbImporti([]);
    
        //per layout standard aggiungo:
        setArrayTotIncassiImporti([]);

        setStatoTotaleRimborsiAnno(0);
        setStatoTotaleIncassiDiversiAnno(0);
        setStatoTotaleQAssAnno(0);
        setStatoTotaleQAbbAnno(0);

        //per layout standard aggiungo:
        setStatoTotaleTotIncassiAnno(0);

        setStatoPagina(STATO_RICERCA_DATI);
        setNecessitaRicalcolo(0);  
      }  
    },[necessitaRicalcolo])

    function chiudiModaleImpostazioni() {
      setModaleImpostazioni(false);
      //setStatoPagina(STATO_OPERAZIONE_CORRETTA);
      if(necessitaRicalcolo === 2) setNecessitaRicalcolo(1);
    }
    function ResettaImpostazioni() {
      setTipoGraficoRimborsi("Bar");
      setTipoGraficoIncassiDiversi("Bar");
      setTipoGraficoQAss("Bar");
      setTipoGraficoQAbb("Bar");
      setTipoGraficoTotaleImportiDettagliato("Bar");
    }

    function trovaImpostazioniReport() {
      //vado a fare la fetch di ricerca delle impostazioni del report per la persona in esame:
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          op:"impostazioniReport",
          ricercaImpostazioni : 1,
          report : 4,
          emak: props.emak,
          sessionId: props.sessionId})
      };

    
    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1){
              setVisualizzaTabStandard(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB_STANDARD));
              setVisualizzaRimborsiStandard(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_RIMBORSI_STANDARD));
              setTipoGraficoRimborsiStandard(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_RIMBORSI_STANDARD);
              setVisualizzaTotIncassiStandard(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TOT_INCASSI_STANDARD));
              setTipoGraficoTotIncassiStandard(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_TOT_INCASSI_STANDARD);
              setVisualizzaTotaleImportiStandard(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TOT_IMPORTI_STANDARD));
              setTipoGraficoTotaleImportiStandard(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_TOT_IMPORTI_STANDARD);

              setVisualizzaTab(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB));
              setVisualizzaRimborsi(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_RIMBORSI));
              setTipoGraficoRimborsi(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_RIMBORSI);
              setVisualizzaIncassiDiversi(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_INCASSI_DIVERSI));
              setTipoGraficoIncassiDiversi(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_INCASSI_DIVERSI);
              setVisualizzaQAss(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_QASS));
              setTipoGraficoQAss(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_QASS);
              setVisualizzaQAbb(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_QABB));
              setTipoGraficoQAbb(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_QABB);
              setVisualizzaTotaleImportiDettagliato(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TOTALE_DETTAGLIATO));
              setTipoGraficoTotaleImportiDettagliato(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_TOTALE_DETTAGLIATO);

              setRadioPeriodoDefault(valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO);
              if (valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO==2) setDataPartenzaDefault(new Date().getFullYear()); else setDataPartenzaDefault(annoOggi);
              setTopDefault(valoreDiRitorno.risultatoRitorno[0].TOP_DEFAULT);
              setVisualizzaAltro(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ALTRO);

              setReportDettagliato(parseInt(valoreDiRitorno.risultatoRitorno[0].TIPO_LAYOUT));
              setRicercaImpostazioniReport(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    ); 
    }

    function aggiornaImpostazioniReport(layout) {
      setCambiamento(0);

      //vado a fare la fetch di aggiornamento delle impostazioni del report per la persona in esame:
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          op:"impostazioniReport",
          modificaImpostazioni : 1,
          report : 4,
          visualizzaTabellaStandard:visualizzaTabStandard,
          visualizzaGraficiRimborsiStandard:visualizzaRimborsiStandard,
          tipoGraficiRimborsiStandard:tipoGraficoRimborsiStandard,
          visualizzaGraficiTotIncassiStandard:visualizzaTotIncassiStandard,
          tipoGraficiTotIncassiStandard:tipoGraficoTotIncassiStandard,
          visualizzaGraficiTotaleImportiStandard:visualizzaTotaleImportiStandard,
          tipoGraficiTotaleImportiStandard:tipoGraficoTotaleImportiStandard,
          visualizzaTabella:visualizzaTab,
          visualizzaGraficiRimborsi:visualizzaRimborsi,
          tipoGraficiRimborsi:tipoGraficoRimborsi,
          visualizzaIncassiDiversi:visualizzaIncassiDiversi,
          tipoGraficiIncassiDiversi:tipoGraficoIncassiDiversi,
          visualizzaQAss:visualizzaQAss,
          tipoGraficiQAss:tipoGraficoQAss,
          visualizzaQAbb:visualizzaQAbb,
          tipoGraficiQAbb:tipoGraficoQAbb,
          visualizzaTotaleImportiDettagliato:visualizzaTotaleImportiDettagliato,
          tipoTotaleImportiDettagliato:tipoGraficoTotaleImportiDettagliato,
          periodoConsiderato:radioPeriodoDefault,
          elementiSignificativi:topDefault,
          visualizzaAltro:visualizzaAltro,
          tipoLayout:layout,
          emak: props.emak,
          sessionId: props.sessionId})
      };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1 && valoreDiRitorno.risultatoRitorno){
            setVisualizzaTabStandard(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB_STANDARD));
            setVisualizzaRimborsiStandard(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_RIMBORSI_STANDARD));
            setTipoGraficoRimborsiStandard(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_RIMBORSI_STANDARD);
            setVisualizzaTotIncassiStandard(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TOT_INCASSI_STANDARD));
            setTipoGraficoTotIncassiStandard(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_TOT_INCASSI_STANDARD);
            setVisualizzaTotaleImportiStandard(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TOT_IMPORTI_STANDARD));
            setTipoGraficoTotaleImportiStandard(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_TOT_IMPORTI_STANDARD);

            setVisualizzaTab(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB));
            setVisualizzaRimborsi(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_RIMBORSI));
            setTipoGraficoRimborsi(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_RIMBORSI);
            setVisualizzaIncassiDiversi(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_INCASSI_DIVERSI));
            setTipoGraficoIncassiDiversi(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_INCASSI_DIVERSI);
            setVisualizzaQAss(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_QASS));
            setTipoGraficoQAss(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_QASS);
            setVisualizzaQAbb(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_QABB));
            setTipoGraficoQAbb(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_QABB);
            setVisualizzaTotaleImportiDettagliato(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TOTALE_DETTAGLIATO));
            setTipoGraficoTotaleImportiDettagliato(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_TOTALE_DETTAGLIATO);

            setRadioPeriodoDefault(valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO);
            if (valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO==2) setDataPartenzaDefault(new Date().getFullYear()); else setDataPartenzaDefault(annoOggi);
            setTopDefault(valoreDiRitorno.risultatoRitorno[0].TOP_DEFAULT);
            setVisualizzaAltro(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ALTRO);
            setReportDettagliato(valoreDiRitorno.risultatoRitorno[0].TIPO_LAYOUT);
            setRicercaImpostazioniReport(1);
            
            //setRicercaImpostazioniReport(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    );


    }

    function chiudiModaleImpostazioniStandard() {
      setModaleImpostazioniStandard(false);
      if(necessitaRicalcolo === 2) setNecessitaRicalcolo(1);
      if(cambiamento === 1) aggiornaImpostazioniReport();
    }
    function ResettaImpostazioniStandard() {
      setTipoGraficoRimborsiStandard("Bar");
      setTipoGraficoTotIncassiStandard("Bar");
      setTipoGraficoTotaleImportiStandard("Bar");
    }

    function funzioneTrasformaDateAUmano(data) {
      var dataOutput='';
      //da formato aaaa-mm-gg a gg/mm/aaaa
      if(data){
          const arrdata = data.split('-');
          let anno = arrdata[0];
          let mese = arrdata[1];
          let giorno = arrdata[2];
          dataOutput = giorno+"/"+mese+"/"+anno;
      }else dataOutput = '';
  
      return dataOutput;
   
  }
  function scaricaLogo() {
    //console.log("scaricaLogo");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1){
              if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
              }
              setRicercaLogo(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    );        
  }

  return (
    <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}>

      {statoPagina === STATO_SCARICA_PDF ? 
        <div>
          {/*app {props.dimensioniApp.x}*/}
          
          {/*cont {larghezzaPdf}*/}
          {/*<PDFViewer style={styles.viewer}>*/}
          <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
          <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
          
            <Document>
              <Page orientation={reportDettagliato === 1 ? "landscape" : "portrait"} size="A4" style={styles.page}>
                <View style={{width:"100%"}} >
                  <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                      <View wrap={false} style={styles.tableRowIntestazione}> 
                        <View style={styles.tableColIntestazioneSx}> 
                          <View style={styles.tableCell}> 
                            {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}
                            
                          </View> 
                        </View> 
                        <View style={styles.tableColIntestazioneDx}>   
                          <View style={styles.tableCellIntestazione}> 
                            {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                            {indirizzoSocieta !== "" ?
                            <Text style={styles.datisocieta}>{indirizzoSocieta}
                            {cittaSocieta !== "" ?
                            capSocieta !== "" ?
                            " "+capSocieta+" "+cittaSocieta
                            :" "+cittaSocieta
                            :""}
                            </Text>
                            :<Text></Text>}
                            {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                            {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                            <Text style={styles.titolo}>
                              Report Situazione Economica Iscritti {dataPartenzaDefault}</Text>
                          </View> 
                        </View> 
                      </View> 
                    </View> {/*chiude table intestazione*/}

                    <View>
                    <Text style={styles.titoloPiccolo}>
                      Periodo dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano> 
                    </Text>
                    </View>

                    {/*<View>
                    <Text style={styles.titoloPiccolo}>    
                                {reportDettagliato === 0 ?
                                  <Fragment> (Layout Standard) </Fragment>
                                :
                                  <Fragment> (Layout Dettagliato) </Fragment>
                                } 
                            </Text>
                    </View>*/}

                    {(visualizzaTab === 1 && reportDettagliato === 1) || (visualizzaTabStandard === 1 && reportDettagliato === 0)  ?
                    <View style={styles.table}>{/*apre table dati*/}
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Iscritto</Text> 
                      </View> 
                      {reportDettagliato === 1 && flagVisualizzaImportiLordiDefaultDett === 1 ? 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                        <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo</Text>  
                        <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Rimborsi</Text> 
                        </View>
                      :<Text></Text>}
                      {reportDettagliato === 1 && flagVisualizzaImportiLordiDefaultDett === 1 ? 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                        <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo</Text> 
                        <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Incassi Diversi</Text> 
                        </View>
                      :<Text></Text>}
                      {reportDettagliato === 1 && flagVisualizzaImportiLordiDefaultDett === 1 ? 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                        <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo</Text> 
                        <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Quote Ass.</Text> 
                        </View>
                      :<Text></Text>}
                      {reportDettagliato === 1 && flagVisualizzaImportiLordiDefaultDett === 1 ? 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                        <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo</Text> 
                        <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Quote Abb.</Text> 
                        </View>
                      :<Text></Text>}
                      {reportDettagliato === 1 && flagVisualizzaImportiLordiDefaultDett === 1 ? 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                        <Text style={styles.tableCellGrassettoAllineatoDestra}>TOTALE</Text> 
                        <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Importo</Text> 
                        </View>
                      :<Text></Text>}

                      {reportDettagliato === 0 && flagVisualizzaImportiLordiDefaultDett === 1 ? 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                        <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo</Text> 
                        <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Rimborsi</Text> 
                        </View>
                      :<Text></Text>}
                      {reportDettagliato === 0 && flagVisualizzaImportiLordiDefaultDett === 1 ? 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                        <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo</Text> 
                        <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Totale Incassi</Text> 
                        </View>
                      :<Text></Text>}
                      {reportDettagliato === 0 && flagVisualizzaImportiLordiDefaultDett === 1 ? 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                        <Text style={styles.tableCellGrassettoAllineatoDestra}>TOTALE</Text> 
                        <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Importo</Text> 
                        </View>
                      :<Text></Text>}
                    </View>

                    {reportDettagliato === 1 ?
                    arrayPersone.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                   
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayPersone[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayRimborsiImporti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayIncassiDiversiImporti[index])}</Text>
                        </View> 
                        :<Text></Text>} 
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayQAssImporti[index])}</Text>
                          </View>  
                        :<Text></Text>}   
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayQAbbImporti[index])}</Text>
                          </View>  
                        :<Text></Text>}  
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(Number(arrayRimborsiImporti[index])+Number(arrayIncassiDiversiImporti[index])+Number(arrayQAssImporti[index])+Number(arrayQAbbImporti[index]))}</Text>
                          </View>  
                        :<Text></Text>}  
                    </View>)
                    :<Text></Text>}

                    {reportDettagliato === 0 ?
                    arrayPersone.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                   
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayPersone[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayRimborsiImporti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayTotIncassiImporti[index])}</Text>
                        </View> 
                        :<Text></Text>} 
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(Number(arrayRimborsiImporti[index])+Number(arrayTotIncassiImporti[index]))}</Text>
                          </View>  
                        :<Text></Text>}  
                    </View>)
                    :<Text></Text>}

                    {reportDettagliato === 1 ?
                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleRimborsiAnno)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleIncassiDiversiAnno)}</Text>
                          </View> 
                        :<Text></Text>}   
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleQAssAnno)}</Text>
                          </View> 
                        :<Text></Text>}    
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleQAbbAnno)}</Text>
                          </View> 
                        :<Text></Text>}    
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(Number(statoTotaleRimborsiAnno)+Number(statoTotaleIncassiDiversiAnno)+Number(statoTotaleQAssAnno)+Number(statoTotaleQAbbAnno))}</Text>
                          </View> 
                        :<Text></Text>} 
                    </View>
                    :<Text></Text>}

                    {reportDettagliato === 0 ?
                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleRimborsiAnno)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleTotIncassiAnno)}</Text>
                          </View> 
                        :<Text></Text>}      
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(Number(statoTotaleRimborsiAnno)+Number(statoTotaleTotIncassiAnno))}</Text>
                          </View> 
                        :<Text></Text>} 
                    </View>
                    :<Text></Text>}
                  </View>:""}

                  {reportDettagliato === 1 && flagVisualizzaImportiLordiDefault === 1 && rimborsiGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoRimborsi === "Line" || tipoGraficoRimborsi === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + rimborsiGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {reportDettagliato === 1 && flagVisualizzaImportiLordiDefault === 1 && incassiDiversiGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoIncassiDiversi === "Line" || tipoGraficoIncassiDiversi === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + incassiDiversiGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {reportDettagliato === 1 && flagVisualizzaImportiLordiDefault === 1 && qAssGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoQAss === "Line" || tipoGraficoQAss === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + qAssGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {reportDettagliato === 1 && flagVisualizzaImportiLordiDefault === 1 && qAbbGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoQAbb === "Line" || tipoGraficoQAbb === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + qAbbGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {reportDettagliato === 1 && flagVisualizzaImportiLordiDefault === 1 && totGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoTotaleImportiDettagliato === "Line" || tipoGraficoTotaleImportiDettagliato === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + totGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {reportDettagliato === 0 && flagVisualizzaImportiLordiDefault === 1 && rimborsiStandardGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoRimborsiStandard === "Line" || tipoGraficoRimborsiStandard === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + rimborsiStandardGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {reportDettagliato === 0 && flagVisualizzaImportiLordiDefault === 1 && totIncassiStandardGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoTotIncassiStandard === "Line" || tipoGraficoTotIncassiStandard === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + totIncassiStandardGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                
                {reportDettagliato === 0 && flagVisualizzaImportiLordiDefault === 1 && totStandardGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoTotaleImportiStandard === "Line" || tipoGraficoTotaleImportiStandard === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + totStandardGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
              </Page>
            </Document>
          </PDFViewer>
        </div>

      :""}

    
    
      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_Sit_Iscr</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? 
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
          </div>
        </div> :""}
      {statoPagina === STATO_PREPARA_PDF ?
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
            <Clessidra loading={true}/>
          </div> 
        </div> :""}


      {statoPagina === STATO_OPERAZIONE_CORRETTA || statoPagina === STATO_SCARICA_PDF ||statoPagina === STATO_PREPARA_PDF ?
        <div className="schedaSpazioCentrale" style={{display:statoPagina === STATO_SCARICA_PDF?"none":"block"}}>
        
            
            <Row>
              <Col lg="12">
                    <Card className="main-card mb-3">
                    <CardBody>
                            <FormGroup>
                            <div id="reportIntestazione">
                            <CardTitle>
                            Report Situazione Economica Iscritti &nbsp;
                            <select name="dataPartenza" id="dataPartenza" innerRef={dataPartenza} value={parseInt(dataPartenzaDefault)} onChange={(e)=>{setDataPartenzaDefault(e.target.value);setNecessitaRicalcolo(1);}}>
                              {[...Array(11)].map((elemento, indice) => (
                                <option key={indice} value={annoOggi + 1 - indice}>
                                  {annoOggi + 1 - indice}
                                </option>
                              ))}                              
                            </select>    
                            &nbsp; dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano>                  
                            {reportDettagliato === 0 ?
                              <Fragment> (Layout Standard) </Fragment>
                            :
                              <Fragment> (Layout Dettagliato) </Fragment>
                            }
                            </CardTitle>
                            {reportDettagliato === 0 ?
                              <Button color="success" className="bottoneMargineASinistra" onClick={() => {setModaleImpostazioniStandard(true)}}>Impostazioni Layout Standard</Button>
                              :
                              <Button color="success" className="bottoneMargineASinistra" onClick={() => {setModaleImpostazioni(true)}}>Impostazioni Layout Dettagliato</Button>
                              }
                              {reportDettagliato === 0 ?
                              <Button color="success" className="bottoneMargineASinistra" onClick={() => {setReportDettagliato(1);aggiornaImpostazioniReport(1);}}>Visualizza Layout Dettagliato</Button>
                              :
                              <Button color="success" className="bottoneMargineASinistra" onClick={() => {setReportDettagliato(0);aggiornaImpostazioniReport(0);}}>Visualizza Layout Standard</Button>
                            }
                            
                            {reportDettagliato === 0 ?
                             <Button color="success" className="bottoneMargineASinistra" onClick={(e)=>{setStatoPagina(STATO_PREPARA_PDF);}}>Esporta il PDF</Button>
                              :
                              <Button color="success" className="bottoneMargineASinistra" onClick={(e)=>{setStatoPagina(STATO_PREPARA_PDF);}}>Esporta il PDF</Button>
                            }
                            

                            {modaleImpostazioniStandard === true ?
                            <Modale 
                                larghezza = "lg"
                                titolo="Selezione Impostazioni Report Layout Standard"
                                flagErrore={false}
                                contenuto={<div>
                                <CardBody>
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center></center></b></Col>  
                                <Col><b><center>Tabella</center></b></Col>
                                <Col><b><center>Grafici</center></b></Col>
                                <Col><b><center>Tipo Grafici</center></b></Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Rimborsi, Totale Incassi e Totale</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaTabStandard" 
                                      defaultChecked = {visualizzaTabStandard === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaTabStandard === 1 ? setVisualizzaTabStandard(0) : setVisualizzaTabStandard(1);setCambiamento(1);}}/></center></Col>
                                <Col><center></center></Col>
                                <Col></Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Rimborsi</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaRimborsiStandard" 
                                      defaultChecked = {visualizzaRimborsiStandard === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaRimborsiStandard === 1 ? setVisualizzaRimborsiStandard(0) : setVisualizzaRimborsiStandard(1);setCambiamento(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGRimborsiStandard" id="TGRimborsiStandard" innerRef={TGRimborsiStandard} onChange={(e)=>{setTipoGraficoRimborsiStandard(e.target.value)}}>
                                  {tipoGraficoRimborsiStandard === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoRimborsiStandard === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoRimborsiStandard === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoRimborsiStandard === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoRimborsiStandard === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoRimborsiStandard === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Totale Incassi</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaTotIncassiStandard" 
                                      defaultChecked = {visualizzaTotIncassiStandard === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaTotIncassiStandard === 1 ? setVisualizzaTotIncassiStandard(0) : setVisualizzaTotIncassiStandard(1);setCambiamento(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGTotIncassiStandard" id="TGTotIncassiStandard" innerRef={TGTotIncassiStandard} onChange={(e)=>{setTipoGraficoTotIncassiStandard(e.target.value)}}>
                                  {tipoGraficoTotIncassiStandard === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoTotIncassiStandard === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoTotIncassiStandard === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoTotIncassiStandard === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoTotIncassiStandard === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoTotIncassiStandard === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Totale</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaTotaleImportiStandard" 
                                      defaultChecked = {visualizzaTotaleImportiStandard === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaTotaleImportiStandard === 1 ? setVisualizzaTotaleImportiStandard(0) : setVisualizzaTotaleImportiStandard(1);setCambiamento(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGTotaleImportiStandard" id="TGTotaleImportiStandard" innerRef={TGTotaleImportiStandard} onChange={(e)=>{setTipoGraficoTotaleImportiStandard(e.target.value)}}>
                                  {tipoGraficoTotaleImportiStandard === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoTotaleImportiStandard === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoTotaleImportiStandard === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoTotaleImportiStandard === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoTotaleImportiStandard === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoTotaleImportiStandard === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>
                                </CardBody>

                                <CardBody>
                                {/*settaggio tipo periodo*/}
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center>Periodo Considerato</center></b></Col>
                                </Row>
                                <Row form className="border">
                                <Col><center>
                                <FormGroup check>
                                  <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "1" ?  'checked' :""}
                                            value="1"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1);setDataPartenzaDefault(annoOggi)}}/> {'Apertura/Chiusura Esercizio'}
                                  </Label>
                                </FormGroup>
                                </center></Col>
                                <Col><center>
                                <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "2" ?  'checked' :""}
                                            value="2"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1);setDataPartenzaDefault(new Date().getFullYear())}}/> {'Anno Solare'}
                                  </Label>
                                </FormGroup>
                                </center></Col>
                                </Row>
                                </CardBody>
                                <CardBody>
                                {/*settaggio Top N*/}
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center>Nei grafici visualizza i <Input type="select" name="top" id="top" innerRef={top}  onInput={(e)=>{setTopDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1)}}>
                                  {topDefault === '5' ? <option selected value="5">5</option> : <option value="5">5</option> }
                                  {topDefault === '10' || topDefault === '' ? <option selected value="10">10</option> : <option value="10">10</option> }
                                  {topDefault === '20' ? <option selected value="20">20</option> : <option value="20">20</option> }
                                  {topDefault === '30' ? <option selected value="30">30</option> : <option value="30">30</option> }
                                  {topDefault === '40' ? <option selected value="40">40</option> : <option value="40">40</option> }
                                  {topDefault === '50' ? <option selected value="50">50</option> : <option value="50">50</option> }
                                  {topDefault === '60' ? <option selected value="60">60</option> : <option value="60">60</option> }
                                  {topDefault === '70' ? <option selected value="70">70</option> : <option value="70">70</option> }
                                  {topDefault === '80' ? <option selected value="80">80</option> : <option value="80">80</option> }
                                  {topDefault === '90' ? <option selected value="90">90</option> : <option value="90">90</option> }
                                  {topDefault === '100' ? <option selected value="100">100</option> : <option value="100">100</option> }
           
                                </Input> elementi significativi  
                                  
                                </center></Col>
                                <Col>
                                  <Label>Nei grafici visualizza anche dati relativi ad 'Altro'</Label>
                                    <Input className="ml-2 mt-2" type="checkbox" name="visualizzaAltro" id="visualizzaAltro"
                                      defaultChecked = {visualizzaAltro === "1" ?  'checked' :""}
                                      innerRef={visualizzaAltroRif}                                 
                                      placeholder=""
                                      onClick={(e)=>{visualizzaAltro === '1' ? setVisualizzaAltro('0') : setVisualizzaAltro('1');setNecessitaRicalcolo(2);setCambiamento(1)}}/>
                                </Col>
                                </Row>
                                </CardBody>
                                  </div>}
                                bottoni={[
                                  {
                                      "etichetta":"OK",
                                      "tipo":"primary",
                                      callback:() => {chiudiModaleImpostazioniStandard()}
                                  },    
                                  {
                                    "etichetta":"Resetta Impostazioni Grafici",
                                    "tipo":"danger",
                                    callback:() => {ResettaImpostazioniStandard();setCambiamento(1)}
                                },  
                              ]}
                            />
                        :""}
                            
                        {modaleImpostazioni === true ?
                        <Modale 
                          larghezza = "lg"
                            titolo="Selezione Impostazioni Report Layout Dettagliato"
                            flagErrore={false}
                            contenuto={<div>
                              <CardBody>
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center></center></b></Col>  
                                <Col><b><center>Tabella</center></b></Col>
                                <Col><b><center>Grafici</center></b></Col>
                                <Col><b><center>Tipo Grafici</center></b></Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Rimborsi, Incassi Diversi, Quote Associative ed Abbonamento, Totale </Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaTab" 
                                      defaultChecked = {visualizzaTab === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaTab === 1 ? setVisualizzaTab(0) : setVisualizzaTab(1);setCambiamento(1);}}/></center></Col>
                                <Col><center></center></Col>
                                <Col></Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Rimborsi</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaRimborsi" 
                                      defaultChecked = {visualizzaRimborsi === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaRimborsi === 1 ? setVisualizzaRimborsi(0) : setVisualizzaRimborsi(1);setCambiamento(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGRimborsi" id="TGRimborsi" innerRef={TGRimborsi} onChange={(e)=>{setTipoGraficoRimborsi(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoRimborsi === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoRimborsi === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoRimborsi === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoRimborsi === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoRimborsi === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoRimborsi === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Incassi Diversi</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaIncassiDiversi" 
                                      defaultChecked = {visualizzaIncassiDiversi === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaIncassiDiversi === 1 ? setVisualizzaIncassiDiversi(0) : setVisualizzaIncassiDiversi(1);setCambiamento(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGIncassi" id="TGIncassi" innerRef={TGIncassi} onChange={(e)=>{setTipoGraficoIncassiDiversi(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoIncassiDiversi === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoIncassiDiversi === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoIncassiDiversi === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoIncassiDiversi === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoIncassiDiversi === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoIncassiDiversi === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>
                                
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Quote Associative</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaQAss" 
                                      defaultChecked = {visualizzaQAss === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaQAss === 1 ? setVisualizzaQAss(0) : setVisualizzaQAss(1);setCambiamento(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGQAss" id="TGQAss" innerRef={TGQAss} onChange={(e)=>{setTipoGraficoQAss(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoQAss === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoQAss === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoQAss === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoQAss === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoQAss === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoQAss === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Quote Abbonamento</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaQAbb" 
                                      defaultChecked = {visualizzaQAbb === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaQAbb === 1 ? setVisualizzaQAbb(0) : setVisualizzaQAbb(1);setCambiamento(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGQAbb" id="TGQAbb" innerRef={TGQAbb} onChange={(e)=>{setTipoGraficoQAbb(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoQAbb === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoQAbb === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoQAbb === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoQAbb === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoQAbb === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoQAbb === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Totale</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaTotaleImportiDettagliato" 
                                      defaultChecked = {visualizzaTotaleImportiDettagliato === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaTotaleImportiDettagliato === 1 ? setVisualizzaTotaleImportiDettagliato(0) : setVisualizzaTotaleImportiDettagliato(1);setCambiamento(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGTotaleImportiDettagliato" id="TGTotaleImportiDettagliato" innerRef={TGTotaleImportiDettagliato} onChange={(e)=>{setTipoGraficoTotaleImportiDettagliato(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoTotaleImportiDettagliato === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoTotaleImportiDettagliato === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoTotaleImportiDettagliato === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoTotaleImportiDettagliato === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoTotaleImportiDettagliato === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoTotaleImportiDettagliato === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>
                                </CardBody>

                                <CardBody>
                                {/*settaggio tipo periodo*/}
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center>Periodo Considerato</center></b></Col>
                                </Row>
                                <Row form className="border">
                                <Col><center>
                                <FormGroup check>
                                  <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "1" ?  'checked' :""}
                                            value="1"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setCambiamento(1)}}/> {'Apertura/Chiusura Esercizio'}
                                  </Label>
                                </FormGroup>
                                </center></Col>
                                <Col><center>
                                <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "2" ?  'checked' :""}
                                            value="2"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setCambiamento(1)}}/> {'Anno Solare'}
                                  </Label>
                                </FormGroup>
                                </center></Col>
                                </Row>
                                </CardBody>
                                <CardBody>
                                {/*settaggio Top N*/}
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center>Nei grafici visualizza i <Input type="select" name="top" id="top" innerRef={top}  onInput={(e)=>{setTopDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1)}}>
                                  {topDefault === '5' ? <option selected value="5">5</option> : <option value="5">5</option> }
                                  {topDefault === '10' || topDefault === '' ? <option selected value="10">10</option> : <option value="10">10</option> }
                                  {topDefault === '20' ? <option selected value="20">20</option> : <option value="20">20</option> }
                                  {topDefault === '30' ? <option selected value="30">30</option> : <option value="30">30</option> }
                                  {topDefault === '40' ? <option selected value="40">40</option> : <option value="40">40</option> }
                                  {topDefault === '50' ? <option selected value="50">50</option> : <option value="50">50</option> }
                                  {topDefault === '60' ? <option selected value="60">60</option> : <option value="60">60</option> }
                                  {topDefault === '70' ? <option selected value="70">70</option> : <option value="70">70</option> }
                                  {topDefault === '80' ? <option selected value="80">80</option> : <option value="80">80</option> }
                                  {topDefault === '90' ? <option selected value="90">90</option> : <option value="90">90</option> }
                                  {topDefault === '100' ? <option selected value="100">100</option> : <option value="100">100</option> }
           
                                </Input> elementi significativi  
                                  
                                </center></Col>
                                <Col>
                                  <Label>Nei grafici visualizza anche dati relativi ad 'Altro'</Label>
                                    <Input className="ml-2 mt-2" type="checkbox" name="visualizzaAltro" id="visualizzaAltro"
                                      defaultChecked = {visualizzaAltro === "1" ?  'checked' :""}
                                      innerRef={visualizzaAltroRif}                                 
                                      placeholder=""
                                      onClick={(e)=>{visualizzaAltro === '1' ? setVisualizzaAltro('0') : setVisualizzaAltro('1');setNecessitaRicalcolo(2);setCambiamento(1)}}/>
                                </Col>
                                </Row>
                                </CardBody>
                            </div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {chiudiModaleImpostazioni()}
                                },    
                                {
                                  "etichetta":"Resetta Impostazioni Grafici",
                                  "tipo":"danger",
                                  callback:() => {ResettaImpostazioni();setCambiamento(1)}
                              },  
                            ]}
                        />
                        :""}

                            {/*MODALE PER LE IMPOSTAZIONI DEL LAYOUT DETTAGLIATO*/}
{/*
                            <Modal size="lg" isOpen={modaleImpostazioni} contentLabel="Selezione Impostazioni Report Layout Dettagliato">
                              <Card className="main-card mb-3"> 
                                <CardBody>
                                <CardTitle>Impostazioni layout dettagliato report 
                                </CardTitle> 

                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center></center></b></Col>  
                                <Col><b><center>Tabella</center></b></Col>
                                <Col><b><center>Grafici</center></b></Col>
                                <Col><b><center>Tipo Grafici</center></b></Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Rimborsi, Incassi Diversi, Quote Associative ed Abbonamento, Totale </Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaTab" 
                                      defaultChecked = {visualizzaTab === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaTab === 1 ? setVisualizzaTab(0) : setVisualizzaTab(1);}}/></center></Col>
                                <Col><center></center></Col>
                                <Col></Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Rimborsi</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaRimborsi" 
                                      defaultChecked = {visualizzaRimborsi === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaRimborsi === 1 ? setVisualizzaRimborsi(0) : setVisualizzaRimborsi(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGRimborsi" id="TGRimborsi" innerRef={TGRimborsi} onChange={(e)=>{setTipoGraficoRimborsi(e.target.value)}}>
                                  {tipoGraficoRimborsi === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoRimborsi === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoRimborsi === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoRimborsi === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoRimborsi === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoRimborsi === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Incassi Diversi</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaIncassiDiversi" 
                                      defaultChecked = {visualizzaIncassiDiversi === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaIncassiDiversi === 1 ? setVisualizzaIncassiDiversi(0) : setVisualizzaIncassiDiversi(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGIncassi" id="TGIncassi" innerRef={TGIncassi} onChange={(e)=>{setTipoGraficoIncassiDiversi(e.target.value)}}>
                                  {tipoGraficoIncassiDiversi === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoIncassiDiversi === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoIncassiDiversi === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoIncassiDiversi === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoIncassiDiversi === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoIncassiDiversi === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Quote Associative</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaQAss" 
                                      defaultChecked = {visualizzaQAss === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaQAss === 1 ? setVisualizzaQAss(0) : setVisualizzaQAss(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGQAss" id="TGQAss" innerRef={TGQAss} onChange={(e)=>{setTipoGraficoQAss(e.target.value)}}>
                                  {tipoGraficoQAss === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoQAss === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoQAss === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoQAss === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoQAss === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoQAss === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Quote Abbonamento</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaQAbb" 
                                      defaultChecked = {visualizzaQAbb === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaQAbb === 1 ? setVisualizzaQAbb(0) : setVisualizzaQAbb(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGQAbb" id="TGQAbb" innerRef={TGQAbb} onChange={(e)=>{setTipoGraficoQAbb(e.target.value)}}>
                                  {tipoGraficoQAbb === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoQAbb === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoQAbb === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoQAbb === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoQAbb === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoQAbb === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Totale</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaTotaleImportiDettagliato" 
                                      defaultChecked = {visualizzaTotaleImportiDettagliato === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaTotaleImportiDettagliato === 1 ? setVisualizzaTotaleImportiDettagliato(0) : setVisualizzaTotaleImportiDettagliato(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGTotaleImportiDettagliato" id="TGTotaleImportiDettagliato" innerRef={TGTotaleImportiDettagliato} onChange={(e)=>{setTipoGraficoTotaleImportiDettagliato(e.target.value)}}>
                                  {tipoGraficoTotaleImportiDettagliato === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoTotaleImportiDettagliato === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoTotaleImportiDettagliato === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoTotaleImportiDettagliato === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoTotaleImportiDettagliato === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoTotaleImportiDettagliato === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>
                                </CardBody>

                                <CardBody>
                                
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center>Periodo Considerato</center></b></Col>
                                </Row>
                                <Row form className="border">
                                <Col><center>
                                <FormGroup check>
                                  <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "1" ?  'checked' :""}
                                            value="1"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value)}}/> {'Apertura/Chiusura Esercizio'}
                                  </Label>
                                </FormGroup>
                                </center></Col>
                                <Col><center>
                                <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "2" ?  'checked' :""}
                                            value="2"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value)}}/> {'Anno Solare'}
                                  </Label>
                                </FormGroup>
                                </center></Col>
                                </Row>
                                </CardBody>
                                <CardBody>
                            
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center>Nei grafici visualizza i <Input type="select" name="top" id="top" innerRef={top}  onInput={(e)=>{setTopDefault(e.target.value);setNecessitaRicalcolo(1)}}>
                                  {topDefault === '5' ? <option selected value="5">5</option> : <option value="5">5</option> }
                                  {topDefault === '10' || topDefault === '' ? <option selected value="10">10</option> : <option value="10">10</option> }
                                  {topDefault === '20' ? <option selected value="20">20</option> : <option value="20">20</option> }
                                  {topDefault === '30' ? <option selected value="30">30</option> : <option value="30">30</option> }
                                  {topDefault === '40' ? <option selected value="40">40</option> : <option value="40">40</option> }
                                  {topDefault === '50' ? <option selected value="50">50</option> : <option value="50">50</option> }
                                  {topDefault === '60' ? <option selected value="60">60</option> : <option value="60">60</option> }
                                  {topDefault === '70' ? <option selected value="70">70</option> : <option value="70">70</option> }
                                  {topDefault === '80' ? <option selected value="80">80</option> : <option value="80">80</option> }
                                  {topDefault === '90' ? <option selected value="90">90</option> : <option value="90">90</option> }
                                  {topDefault === '100' ? <option selected value="100">100</option> : <option value="100">100</option> }
           
                                </Input> elementi significativi  
                                  
                                </center></Col>
                                <Col>
                                  <Label>Nei grafici visualizza anche dati relativi ad 'Altro'</Label>
                                    <Input className="ml-2 mt-2" type="checkbox" name="visualizzaAltro" id="visualizzaAltro"
                                      defaultChecked = {visualizzaAltro === "1" ?  'checked' :""}
                                      innerRef={visualizzaAltroRif}                                 
                                      placeholder=""
                                      onClick={(e)=>{visualizzaAltro === '1' ? setVisualizzaAltro('0') : setVisualizzaAltro('1');setNecessitaRicalcolo(1)}}/>
                                </Col>
                                </Row>
                                </CardBody>
                                <CardBody>
                                <Row style={{marginLeft:20,marginRight:20}}>
                                <Col><center>
                                <Button color="success" className="mt-2 bottoneMargineASinistra" onClick={() => {chiudiModaleImpostazioni();}}>Visualizza Report</Button>
                                </center></Col><Col><center>
                                <Button color="danger" className="mt-2 bottoneMargineASinistra" onClick={() => {ResettaImpostazioni();}}>Resetta Impostazioni Grafici</Button>
                                </center></Col></Row></CardBody>

                              </Card>
                          </Modal>*/}
                  
                            </div>
                            </FormGroup>

                            <div id="titolo" style={{display:"none"}}>
                            Report Situazione Economica Iscritti {dataPartenzaDefault} calcolato dal {funzioneTrasformaDateAUmano(inizioPeriodo)} al {funzioneTrasformaDateAUmano(finePeriodo)} 
                            </div>

                            {/*LAYOUT DETTAGLIATO */}
                            {risultatoServer !== undefined && risultatoServer !== "" && reportDettagliato === 1 ?
                            <div id="reportSituazioneEconomicaIscritti"> 

                                <div id="situazioneEconomicaIscritti"  style={{width:'100%'}}>
                                {visualizzaTab === 1 || visualizzaRimborsi === 1 || visualizzaIncassiDiversi === 1 || visualizzaQAss === 1 || visualizzaQAbb === 1?
                                 <Row><Col><CardTitle>Riepilogo</CardTitle></Col></Row>
                                :""}
                                </div>
                                <div id="Tabella"  style={{width:'100%'}}>  
                                  {(arrayRimborsiImporti.length>0) ||
                                  (arrayIncassiDiversiImporti.length>0) ||
                                  (arrayQAssImporti.length>0) ||
                                  (arrayQAbbImporti.length>0) 
                                  ?
                                    visualizzaTab === 1 ?
                                    <Fragment>
                                    <Row form className="border"><Col md={larghColonnaIscrittoDett}><b>Iscritto</b></Col>

                                    {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right"><b>Importo<br></br>Rimborsi</b></Col> : ""}
                                    {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right"><b>Importo<br></br>Incassi Diversi</b></Col> : ""}
                                    {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right"><b>Importo<br></br>Quote Associative</b></Col> : ""}
                                    {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right"><b>Importo<br></br>Quote Abbonamento</b></Col> : ""}
                                    {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right"><b>TOTALE<br></br>Importo</b></Col> : ""}
                                    </Row>
                                    {arrayPersone.map((elemento,index) => <Row form className="border" key={index} >
                                      {arrayPersone[index]!=='' ? 
                                        <Col md={larghColonnaIscrittoDett}>{arrayPersone[index]}</Col>
                                      : <Col md={larghColonnaIscrittoDett}>Non specificato</Col>}
                                      
                                      {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right">{FormattaImportoVideo(arrayRimborsiImporti[index])}</Col> : ""}
                                      {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right">{FormattaImportoVideo(arrayIncassiDiversiImporti[index])}</Col> : ""}
                                      {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right">{FormattaImportoVideo(arrayQAssImporti[index])}</Col> : ""}
                                      {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right">{FormattaImportoVideo(arrayQAbbImporti[index])}</Col> : ""}
                                      {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right">{FormattaImportoVideo(
                                      Number(arrayRimborsiImporti[index])
                                      +Number(arrayIncassiDiversiImporti[index])
                                      +Number(arrayQAssImporti[index])
                                      +Number(arrayQAbbImporti[index])
                                      )}</Col> : ""}
                                      
                                      </Row>)
                                    }
                                   
                                    {/*riga dei totali in fondo alla tabella*/}
                                    <Row form className="border">
                                    <Col md={larghColonnaIscrittoDett}><b>TOTALE</b></Col>

                                    {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right"><b>{FormattaImportoVideo(statoTotaleRimborsiAnno)}</b></Col> : ""}
                                    {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right"><b>{FormattaImportoVideo(statoTotaleIncassiDiversiAnno)}</b></Col> : ""}
                                    {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right"><b>{FormattaImportoVideo(statoTotaleQAssAnno)}</b></Col> : ""}
                                    {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right"><b>{FormattaImportoVideo(statoTotaleQAbbAnno)}</b></Col> : ""}
                                    {flagVisualizzaImportiLordiDefaultDett === 1 ? <Col md={larghColonnaDatiDett} align="right"><b>{FormattaImportoVideo(Number(statoTotaleRimborsiAnno)+Number(statoTotaleIncassiDiversiAnno)+Number(statoTotaleQAssAnno)+Number(statoTotaleQAbbAnno))}</b></Col> : ""}
                                    </Row>

                                    </Fragment>
                                    :""
                                  : ""}
                                  </div>


                                  {/*da qui per i grafici lordi*/}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoRimborsi === 1 && tipoGraficoRimborsi==="Bar" && arrayRimborsiTopImporti.length>0  && visualizzaRimborsi === 1?
                                  <div id="rimborsiGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataRimborsi} ref={rimborsiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoRimborsi === 1 && tipoGraficoRimborsi==="Doughnut" && arrayRimborsiTopImporti.length>0 && visualizzaRimborsi === 1?
                                  <div id="rimborsiGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataRimborsi} ref={rimborsiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoRimborsi === 1 && tipoGraficoRimborsi==="Line" && arrayRimborsiTopImporti.length>0 && visualizzaRimborsi === 1?
                                  <div id="rimborsiGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataRimborsi} ref={rimborsiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoRimborsi === 1 && tipoGraficoRimborsi==="Pie" && arrayRimborsiTopImporti.length>0 && visualizzaRimborsi === 1?
                                  <div id="rimborsiGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataRimborsi} ref={rimborsiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoRimborsi === 1 && tipoGraficoRimborsi==="PolarArea" && arrayRimborsiTopImporti.length>0  && visualizzaRimborsi === 1?
                                  <div id="rimborsiGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataRimborsi} ref={rimborsiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoRimborsi === 1 && tipoGraficoRimborsi==="Radar" && arrayRimborsiTopImporti.length>0 && visualizzaRimborsi === 1?
                                  <div id="rimborsiGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataRimborsi} ref={rimborsiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                 
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoIncassiDiversi===1 && tipoGraficoIncassiDiversi==="Bar" && arrayIncassiDiversiTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataIncassiDiversi} ref={incassiDiversiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Incassi Diversi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoIncassiDiversi===1 && tipoGraficoIncassiDiversi==="Doughnut" && arrayIncassiDiversiTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataIncassiDiversi} ref={incassiDiversiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Incassi Diversi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoIncassiDiversi===1 && tipoGraficoIncassiDiversi==="Line" && arrayIncassiDiversiTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataIncassiDiversi} ref={incassiDiversiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Incassi Diversi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoIncassiDiversi===1 && tipoGraficoIncassiDiversi==="Pie" && arrayIncassiDiversiTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataIncassiDiversi} ref={incassiDiversiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Incassi Diversi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoIncassiDiversi===1 && tipoGraficoIncassiDiversi==="PolarArea" && arrayIncassiDiversiTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataIncassiDiversi} ref={incassiDiversiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Incassi Diversi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoIncassiDiversi===1 && tipoGraficoIncassiDiversi==="Radar" && arrayIncassiDiversiTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataIncassiDiversi} ref={incassiDiversiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Incassi Diversi (Top '+topDefault+')'}},}} /></div>: ""}
                            
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoQass === 1 && tipoGraficoQAss==="Bar" && arrayQuoteAssTopImporti.length>0 && visualizzaQAss === 1?
                                  <div id="qAssGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataQAss} ref={qAssGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Quote Associative (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoQass === 1 && tipoGraficoQAss==="Doughnut" && arrayQuoteAssTopImporti.length>0 && visualizzaQAss === 1?
                                  <div id="qAssGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataQAss} ref={qAssGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Quote Associative (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoQass === 1 && tipoGraficoQAss==="Line" && arrayQuoteAssTopImporti.length>0 && visualizzaQAss === 1?
                                  <div id="qAssGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataQAss} ref={qAssGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Quote Associative (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoQass === 1 && tipoGraficoQAss==="Pie" && arrayQuoteAssTopImporti.length>0 && visualizzaQAss === 1?
                                  <div id="qAssGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataQAss} ref={qAssGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Quote Associative (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoQass === 1 && tipoGraficoQAss==="PolarArea" && arrayQuoteAssTopImporti.length>0 && visualizzaQAss === 1?
                                  <div id="qAssGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataQAss} ref={qAssGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Quote Associative (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoQass === 1 && graficoQass === 1 && tipoGraficoQAss==="Radar" && arrayQuoteAssTopImporti.length>0 && visualizzaQAss === 1?
                                  <div id="qAssGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataQAss} ref={qAssGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Quote Associative (Top '+topDefault+')'}},}} /></div>: ""}
                               
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoQabb === 1 && tipoGraficoQAbb==="Bar" && arrayQuoteAbbTopImporti.length>0 && visualizzaQAbb === 1?
                                  <div id="qAbbGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataQAbb} ref={qAbbGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Quote Abbonamento (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoQabb === 1 && tipoGraficoQAbb==="Doughnut" && arrayQuoteAbbTopImporti.length>0 && visualizzaQAbb === 1?
                                  <div id="qAbbGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataQAbb} ref={qAbbGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Quote Abbonamento (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoQabb === 1 && tipoGraficoQAbb==="Line" && arrayQuoteAbbTopImporti.length>0 && visualizzaQAbb === 1?
                                  <div id="qAbbGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataQAbb} ref={qAbbGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Quote Abbonamento (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoQabb === 1 && tipoGraficoQAbb==="Pie" && arrayQuoteAbbTopImporti.length>0 && visualizzaQAbb === 1?
                                  <div id="qAbbGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataQAbb} ref={qAbbGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Quote Abbonamento (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoQabb === 1 && tipoGraficoQAbb==="PolarArea" && arrayQuoteAbbTopImporti.length>0 && visualizzaQAbb === 1?
                                  <div id="qAbbGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataQAbb} ref={qAbbGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Quote Abbonamento (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefaultDett === 1 && graficoQabb === 1 && tipoGraficoQAbb==="Radar" && arrayQuoteAbbTopImporti.length>0 && visualizzaQAbb === 1?
                                  <div id="qAbbGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataQAbb} ref={qAbbGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Quote Abbonamento (Top '+topDefault+')'}},}} /></div>: ""}
                                  
                                    {flagVisualizzaImportiLordiDefault === 1 && graficoTotale === 1  && tipoGraficoTotaleImportiDettagliato==="Bar" && arrayTotaleTopImporti.length>0 && visualizzaTotaleImportiDettagliato=== 1?
                                  <div id="totGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataTotale} ref={totGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale Importo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotale === 1  && tipoGraficoTotaleImportiDettagliato==="Doughnut" &&  arrayTotaleTopImporti.length>0  && visualizzaTotaleImportiDettagliato === 1?
                                  <div id="totGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataTotale} ref={totGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale Importo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotale === 1  && tipoGraficoTotaleImportiDettagliato==="Line" &&  arrayTotaleTopImporti.length>0  && visualizzaTotaleImportiDettagliato === 1?
                                  <div id="totGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataTotale} ref={totGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale Importo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotale === 1  && tipoGraficoTotaleImportiDettagliato==="Pie" &&  arrayTotaleTopImporti.length>0  && visualizzaTotaleImportiDettagliato === 1?
                                  <div id="totGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataTotale} ref={totGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale Importo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotale === 1  && tipoGraficoTotaleImportiDettagliato==="PolarArea" &&  arrayTotaleTopImporti.length>0  && visualizzaTotaleImportiDettagliato === 1?
                                  <div id="totGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataTotale} ref={totGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale Importo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotale === 1  && tipoGraficoTotaleImportiDettagliato==="Radar" &&  arrayTotaleTopImporti.length>0  && visualizzaTotaleImportiDettagliato === 1?
                                  <div id="totGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataTotale} ref={totGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale Importo (Top '+topDefault+')'}},}} /></div>: ""}
                                  
                                  
                              </div>
                            : ""}

                            {/*LAYOUT STANDARD */}
                            {risultatoServer !== undefined && risultatoServer !== "" && reportDettagliato === 0 ?
                            <div id="reportSituazioneEconomicaIscrittiStandard">  
                                <div id="situazioneEconomicaIscrittiStandard"  style={{width:'100%'}}>
                                {visualizzaTabStandard === 1 || visualizzaRimborsi === 1 || visualizzaIncassiDiversi === 1 || visualizzaQAss === 1 || visualizzaQAbb === 1?
                                 <Row><Col><CardTitle>Riepilogo</CardTitle></Col></Row>
                                :""}
                                </div>
                                <div id="TabellaStandard"  style={{width:'100%'}}>  
                                  {(arrayRimborsiImporti.length>0) ||
                                  (arrayIncassiDiversiImporti.length>0) ||
                                  (arrayQAssImporti.length>0) ||
                                  (arrayQAbbImporti.length>0) 
                                  ?
                                    visualizzaTabStandard === 1 ?
                                    <Fragment>
                                    <Row form className="border"><Col><b>Iscritto</b></Col>
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>Importo<br></br>Rimborsi</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>Importo<br></br>Totale Incassi</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>TOTALE<br></br>Importo</b></Col> :""}
                                    </Row>

                                    {arrayPersone.map((elemento,index) => <Row form className="border" key={index} >
                                      {arrayPersone[index]!=='' ? 
                                        <Col>{arrayPersone[index]}</Col>
                                      : <Col>Non specificato</Col>}
                                      
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayRimborsiImporti[index])}</Col> :""}
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayTotIncassiImporti[index])}</Col> :""}
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(Number(arrayRimborsiImporti[index])+Number(arrayTotIncassiImporti[index]))}</Col> :""}
         
                                      </Row>)
                                    }
                                    {/*riga dei totali in fondo alla tabella*/}
                                    <Row form className="border">
                                    <Col><b>TOTALE</b></Col>
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleRimborsiAnno)}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleTotIncassiAnno)}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(Number(statoTotaleRimborsiAnno)+Number(statoTotaleTotIncassiAnno))}</b></Col> :""}
                                    </Row>

                                    </Fragment>
                                    :""
                                  : ""}
                                  </div>
 
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoRimborsi === 1 && tipoGraficoRimborsiStandard==="Bar" && arrayRimborsiTopImporti.length>0 && visualizzaRimborsiStandard === 1?
                                  <div id="rimborsiStandardGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataRimborsi} ref={rimborsiStandardGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoRimborsi === 1 && tipoGraficoRimborsiStandard==="Doughnut" &&  arrayRimborsiTopImporti.length>0  && visualizzaRimborsiStandard === 1?
                                  <div id="rimborsiStandardGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataRimborsi} ref={rimborsiStandardGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoRimborsi === 1 && tipoGraficoRimborsiStandard==="Line" && arrayRimborsiTopImporti.length>0  && visualizzaRimborsiStandard === 1?
                                  <div id="rimborsiStandardGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataRimborsi} ref={rimborsiStandardGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoRimborsi === 1 && tipoGraficoRimborsiStandard==="Pie" && arrayRimborsiTopImporti.length>0  && visualizzaRimborsiStandard === 1?
                                  <div id="rimborsiStandardGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataRimborsi} ref={rimborsiStandardGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoRimborsi === 1 && tipoGraficoRimborsiStandard==="PolarArea" && arrayRimborsiTopImporti.length>0  && visualizzaRimborsiStandard === 1?
                                  <div id="rimborsiStandardGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataRimborsi} ref={rimborsiStandardGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoRimborsi === 1 && tipoGraficoRimborsiStandard==="Radar" && arrayRimborsiTopImporti.length>0 && visualizzaRimborsiStandard === 1?
                                  <div id="rimborsiStandardGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataRimborsi} ref={rimborsiStandardGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                 
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotaleIncassi === 1 && tipoGraficoTotIncassiStandard==="Bar" && arrayTotaleIncassiTopImporti.length>0 && visualizzaTotIncassiStandard === 1?
                                  <div id="totIncassiStandardGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataTotIncassiStandard} ref={totIncassiStandardGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Totale Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotaleIncassi === 1 && tipoGraficoTotIncassiStandard==="Doughnut" &&  arrayTotaleIncassiTopImporti.length>0  && visualizzaTotIncassiStandard === 1?
                                  <div id="totIncassiStandardGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataTotIncassiStandard} ref={totIncassiStandardGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Totale Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotaleIncassi === 1 && tipoGraficoTotIncassiStandard==="Line" &&  arrayTotaleIncassiTopImporti.length>0  && visualizzaTotIncassiStandard === 1?
                                  <div id="totIncassiStandardGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataTotIncassiStandard} ref={totIncassiStandardGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Totale Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotaleIncassi === 1 && tipoGraficoTotIncassiStandard==="Pie" &&  arrayTotaleIncassiTopImporti.length>0  && visualizzaTotIncassiStandard === 1?
                                  <div id="totIncassiStandardGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataTotIncassiStandard} ref={totIncassiStandardGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Totale Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotaleIncassi === 1 && tipoGraficoTotIncassiStandard==="PolarArea" &&  arrayTotaleIncassiTopImporti.length>0  && visualizzaTotIncassiStandard === 1?
                                  <div id="totIncassiStandardGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataTotIncassiStandard} ref={totIncassiStandardGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Totale Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotaleIncassi === 1 && tipoGraficoTotIncassiStandard==="Radar" &&  arrayTotaleIncassiTopImporti.length>0  && visualizzaTotIncassiStandard === 1?
                                  <div id="totIncassiStandardGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataTotIncassiStandard} ref={totIncassiStandardGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Totale Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotale === 1  && tipoGraficoTotaleImportiStandard==="Bar" && arrayTotaleTopImporti.length>0 && visualizzaTotaleImportiStandard === 1?
                                  <div id="totStandardGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataTotale} ref={totStandardGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale Importo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotale === 1  && tipoGraficoTotaleImportiStandard==="Doughnut" &&  arrayTotaleTopImporti.length>0  && visualizzaTotaleImportiStandard === 1?
                                  <div id="totStandardGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataTotale} ref={totStandardGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale Importo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotale === 1  && tipoGraficoTotaleImportiStandard==="Line" &&  arrayTotaleTopImporti.length>0  && visualizzaTotaleImportiStandard === 1?
                                  <div id="totStandardGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataTotale} ref={totStandardGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale Importo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotale === 1  && tipoGraficoTotaleImportiStandard==="Pie" &&  arrayTotaleTopImporti.length>0  && visualizzaTotaleImportiStandard === 1?
                                  <div id="totStandardGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataTotale} ref={totStandardGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale Importo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotale === 1  && tipoGraficoTotaleImportiStandard==="PolarArea" &&  arrayTotaleTopImporti.length>0  && visualizzaTotaleImportiStandard === 1?
                                  <div id="totStandardGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataTotale} ref={totStandardGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale Importo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotale === 1  && tipoGraficoTotaleImportiStandard==="Radar" &&  arrayTotaleTopImporti.length>0  && visualizzaTotaleImportiStandard === 1?
                                  <div id="totStandardGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataTotale} ref={totStandardGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale Importo (Top '+topDefault+')'}},}} /></div>: ""}
                              </div>
                            : ""}
                          
                            {/*SE NON HAI ALCUN DATO */}
                            {risultatoServer !== undefined && risultatoServer !== "" ? "" :
                              <Table responsive bordered hover striped className="mb-0">
                              <thead>
                                <tr>
                                  <th>Nessun dato presente</th>
                                </tr>
                              </thead>
                              </Table>
                            }
                          
                      </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
  
      :""}
    </div>
   );
}
export default TabellaReportEconPersone;