import React, {Fragment,useState,useEffect,useRef,Suspense} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button,FormGroup,Label,Input } from 'reactstrap';
import Parametri from '../../parametri';

import FormGridFormRow from '../SchedaIscritto/FormGridFormRow';

import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import TabellaRicerca from '../tabellaricerca.js';
import FormattaImportoVideo from '../FormattaImportoVideo.js';

import Clessidra from '../Clessidra.js';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js'

//import html2pdf from 'html2pdf.js';//per estrazione pdf

const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
const STATO_RICERCA_DATI_DETTAGLIO= 5;
//const STATO_VAI_IN_INSERISCI= 6;
const STATO_OPERAZIONE_CORRETTA2= 7;

const STATO_SCARICA_PDF = 9;
const STATO_PREPARA_PDF = 10;
const STATO_SCARICA_PDF_TUTTI = 11;
const STATO_PREPARA_PDF_TUTTI = 12;

// colori presenti anche in SchedaIscritto
const coloreRosso = "#D83D00"; //(PER DEBITI)
const coloreVerde = "#43BC6A"; //(PER CREDITI)

function TabellaInsolventi(props) {
    
    const operazione = "reportInsolventi";

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno

    const [iscritto,setIscritto]=useState('');
    const [movimenti, setMovimenti]=useState([]);
    const [totali,setTotali]=useState([]);
    
    const [risultatoServer,setRisultatoServer]=useState('');
    //const [risultatoServer2,setRisultatoServer2]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');

    const [idModifica,setIdModifica]=useState('');
    
    const [tipologiaInsolventeDefault, setTipologiaInsolventeDefault]=useState("1");
    
    const tipologiaInsolvente   = useRef();

    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Cognome',
        accessor: 'COGNOME'
      },
      {
        etichetta: 'Nome',
        accessor: 'NOME'
      },
      {
        etichetta: 'N. Tessera',
        accessor: 'NUMERO_TESSERA'
      },
      {
        etichetta: 'Saldo Quote Associative',
        accessor: 'TOTALE_ASS'
      },
      {
        etichetta: 'Saldo Quote Abbonamento',
        accessor: 'TOTALE_ABB'
      },
      {
        etichetta: 'Saldo Totale Quote',
        accessor: 'TOTALE'
      },
    ];

    const arrayTipiDati=[];
    arrayTipiDati[4]="importo";
    arrayTipiDati[5]="importo";
    arrayTipiDati[6]="importo";

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    function tornaSchedaIscritto() {
      //eseguitoModifica e idIscritto servono per quando arrivi da SchedaIscritto
      props.callbackTermineVisualizzazioneDettagliQuote();
    }

    /*function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }*/

    function VaiDettaglio(id) {
      setStatoPagina(STATO_RICERCA_DATI_DETTAGLIO);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }

    useEffect(() => {
      if(statoPagina === STATO_PREPARA_PDF){
         if(ricercaLogo===1) setStatoPagina(STATO_SCARICA_PDF);
      }
      if(statoPagina === STATO_PREPARA_PDF_TUTTI){
        if(ricercaLogo===1) setStatoPagina(STATO_SCARICA_PDF_TUTTI);
    }
    },[statoPagina]);

    useEffect(() => {
      setStatoPagina(STATO_RICERCA_DATI);
    }, [tipologiaInsolventeDefault])

    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      //entro qui se vendo richiamato dalla pagina della scheda iscritto
      if (props.idIscritto) {
        let componiPersona="";
        if(props.cognome !== '' && props.cognome !== undefined) componiPersona = componiPersona + props.cognome;
        if(props.nome !== '' && props.nome !== undefined && componiPersona !== "") componiPersona = componiPersona + " ";
        if(props.nome !== '' && props.nome !== undefined) componiPersona = componiPersona + props.nome;
        if(props.tessera !== '' && props.tessera !== undefined && componiPersona !== "") componiPersona = componiPersona + " ";
        if(props.tessera !== '' && props.tessera !== undefined && props.tessera!== "" ) componiPersona = componiPersona + "("+props.tessera+")";
              
        setIscritto(componiPersona);
        setStatoPagina(STATO_RICERCA_DATI_DETTAGLIO);
        setIdModifica(props.idIscritto);
      }else{
        setStatoPagina(STATO_RICERCA_DATI);
      }
      scaricaLogo();
    }, [])
    

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            op:operazione,
            tipoInadempienti:tipologiaInsolventeDefault,
            emak: props.emak,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServer(valoreDiRitorno.risultatoRitorno);setStatoPagina(STATO_OPERAZIONE_CORRETTA);}
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    

      if(statoPagina===STATO_RICERCA_DATI_DETTAGLIO){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            op:"restituisciSituazioneInsolvente",
            id:idModifica,
            emak: props.emak,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                let componiPersona="";
                if(valoreDiRitorno.COGNOME !== '' && valoreDiRitorno.COGNOME !== undefined) componiPersona = componiPersona + valoreDiRitorno.COGNOME;
                if(valoreDiRitorno.NOME !== '' && valoreDiRitorno.NOME !== undefined && componiPersona !== "") componiPersona = componiPersona + " ";
                if(valoreDiRitorno.NOME !== '' && valoreDiRitorno.NOME !== undefined) componiPersona = componiPersona + valoreDiRitorno.NOME;
                if(valoreDiRitorno.NUMERO_TESSERA !== '' && valoreDiRitorno.NUMERO_TESSERA !== undefined && componiPersona !== "") componiPersona = componiPersona + " ";
                if(valoreDiRitorno.NUMERO_TESSERA !== '' && valoreDiRitorno.NUMERO_TESSERA !== undefined && valoreDiRitorno.NUMERO_TESSERA !== "") componiPersona = componiPersona + "("+valoreDiRitorno.NUMERO_TESSERA+")";
              
                if(!props.idIscritto) setIscritto(componiPersona);

                setMovimenti(valoreDiRitorno.risultatoRitorno);
                setTotali(valoreDiRitorno.totali);

                setStatoPagina(STATO_OPERAZIONE_CORRETTA2);
              }
            },
          (error) => {console.log("errore"+error);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      } 
    },[statoPagina])
    //},[props.numPagina])

    function scaricaLogo() {
      //console.log("scaricaLogo");
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          
            if(valoreDiRitorno.risultatoOperazione===1){
                if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                  setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                  setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                  setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                  setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                  setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                  setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                  setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                  setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
                }
                setRicercaLogo(1);
            }
        },
        (error) => {console.log("Errore connessione");}
      );        
  }
  
  return (
      <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}>

      {statoPagina === STATO_SCARICA_PDF ? 
          <div>
            {/*<PDFViewer style={styles.viewer}>*/}
            <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
            <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
              <Document>
                <Page size="A4" style={styles.page}>
                  <View style={{width:"100%"}} >
                    <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                      <View wrap={false} style={styles.tableRowIntestazione}> 
                        <View style={styles.tableColIntestazioneSx}> 
                          <View style={styles.tableCell}> 
                            {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                          </View> 
                        </View> 
                      <View style={styles.tableColIntestazioneDx}>   
                        <View style={styles.tableCellIntestazione}> 
                          {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                          {indirizzoSocieta !== "" ?
                          <Text style={styles.datisocieta}>{indirizzoSocieta}
                          {cittaSocieta !== "" ?
                          capSocieta !== "" ?
                          " "+capSocieta+" "+cittaSocieta
                          :" "+cittaSocieta
                          :""}
                          </Text>
                          :<Text></Text>}
                          {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                          {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                                                
                          <Text style={styles.titolo}>Dettaglio Situazione Quote</Text>
                        </View> 
                      </View> 
                    </View> 
                  </View> {/*chiude table intestazione*/}

                  {iscritto ? <Text style={styles.tableCellAllineatoSinistra}>Iscritto: {iscritto}</Text> : <></>}
                  <Text style={styles.tableCellAllineatoSinistra}>Situazione aggiornata a {new Date().toLocaleDateString(undefined,{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Text>

                  <View style={styles.table}>{/*apre table dati*/}
                    
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={styles.tableCol6}> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Data</Text> 
                      </View> 
                        <View style={styles.tableCol6}> 
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>Tipo Quota</Text> 
                        </View>
                        <View style={styles.tableCol2}> 
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>Operazione</Text> 
                        </View> 
                        <View style={styles.tableCol6}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo</Text> 
                        </View>                       
                    </View>

                    {/*apre table con dentro i dati*/}
                      {movimenti.map((elemento,index) =>
                        <View wrap={false} style={styles.tableRow} key={index}>
                          <View style={styles.tableCol6}>                   
                            <Text style={styles.tableCellAllineatoSinistra}><TrasformaDateAUmano data={elemento.DATA.split(':')[0]}></TrasformaDateAUmano></Text> 
                          </View>

                          {elemento.TIPO_QUOTA==="1" ? <View style={styles.tableCol6}><Text style={styles.tableCellAllineatoSinistra}> Associativa</Text></View> : ""}
                          {elemento.TIPO_QUOTA==="2" ? <View style={styles.tableCol6}><Text style={styles.tableCellAllineatoSinistra}> Abbonamento</Text></View> : ""}

                          <View style={styles.tableCol2}><Text style={styles.tableCellAllineatoSinistra}>{elemento.OPERAZIONE}</Text></View>                        
                          <View style={styles.tableCol6}><Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(elemento.IMPORTO)}</Text></View> 

                        </View>
                      )}                      
                  </View>

                  {totali.map((elemento,index) => 
                    <View wrap={false}>
                      <Text style={styles.tableCellAllineatoSinistra}>{elemento.DATA}: {FormattaImportoVideo(elemento.IMPORTO)} €</Text>
                    </View>
                  )}

                </View>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
              </Page>
            </Document>
          </PDFViewer>
        </div>
      :""}

      {statoPagina === STATO_SCARICA_PDF_TUTTI ? 
          <div>
            {/*<PDFViewer style={styles.viewer}>*/}
            <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
            <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
              <Document>
                {/*<Page orientation="landscape" size="A4" style={styles.page}>*/}
                <Page size="A4" style={styles.page}>
                  <View style={{width:"100%"}} >
                    <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                      <View wrap={false} style={styles.tableRowIntestazione}> 
                        <View style={styles.tableColIntestazioneSx}> 
                          <View style={styles.tableCell}> 
                            {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                          </View> 
                        </View> 
                      <View style={styles.tableColIntestazioneDx}>   
                        <View style={styles.tableCellIntestazione}> 
                          {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                          {indirizzoSocieta !== "" ?
                          <Text style={styles.datisocieta}>{indirizzoSocieta}
                          {cittaSocieta !== "" ?
                          capSocieta !== "" ?
                          " "+capSocieta+" "+cittaSocieta
                          :" "+cittaSocieta
                          :""}
                          </Text>
                          :<Text></Text>}
                          {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                          {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                          <Text style={styles.titolo}>Elenco Insolventi</Text>
                        </View> 
                      </View> 
                    </View> 
                  </View> {/*chiude table intestazione*/}


                  <View>
                    <Text style={styles.titoloPiccolo}>Report del <TrasformaDateAUmano data={dataOggi}></TrasformaDateAUmano></Text>
                  </View>
                  <View style={styles.table}>{/*apre table dati*/}
                      <View wrap={false} style={styles.tableRowBlu}> 
                          <View style={styles.tableCol22Di100}> 
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}>Cognome</Text> 
                          </View> 
                            <View style={styles.tableCol22Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Nome</Text> 
                            </View>
                            <View style={styles.tableCol19Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>N.Tessera</Text> 
                            </View> 
                            <View style={styles.tableCol13Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoDestra}>Saldo</Text> 
                              <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Quote</Text> 
                              <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Associative</Text> 
                            </View> 
                            <View style={styles.tableCol13Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoDestra}>Saldo</Text> 
                              <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Quote</Text> 
                              <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Abbonamento</Text> 
                            </View> 
                            <View style={styles.tableCol11Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoDestra}>Saldo</Text> 
                              <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Totale</Text> 
                              <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Quote</Text> 
                            </View> 
                      </View>
                    {/*apre table con dentro i dati*/}

                    
                    {risultatoServer.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={styles.tableCol22Di100}>                   
                            <Text style={styles.tableCellAllineatoSinistra}>{risultatoServer[index].COGNOME}</Text>     
                        </View>
                        <View style={styles.tableCol22Di100}>                   
                            <Text style={styles.tableCellAllineatoSinistra}>{risultatoServer[index].NOME}</Text>     
                        </View>
                        <View style={styles.tableCol19Di100}>                   
                            <Text style={styles.tableCellAllineatoSinistra}>{risultatoServer[index].NUMERO_TESSERA}</Text>     
                        </View>
                        <View style={styles.tableCol13Di100}>                   
                            <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(risultatoServer[index].TOTALE_ASS)}</Text>     
                        </View>
                        <View style={styles.tableCol13Di100}>                   
                            <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(risultatoServer[index].TOTALE_ABB)}</Text>     
                        </View>
                        <View style={styles.tableCol11Di100}>                   
                            <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(risultatoServer[index].TOTALE)}</Text>     
                        </View>
                        </View>
                    )}
                  </View>
                </View>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
              </Page>
            </Document>
          </PDFViewer>
        </div>
      :""}

      
      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda insolvente in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_R_Insol</div> :""}  
      {statoPagina === STATO_RICERCA_DATI || statoPagina === STATO_RICERCA_DATI_DETTAGLIO ? 
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
          </div>
        </div> :""}
      {statoPagina === STATO_PREPARA_PDF ?
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
            <Clessidra loading={true}/>
          </div> 
        </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA2 || statoPagina === STATO_SCARICA_PDF ||statoPagina === STATO_PREPARA_PDF ?
        <div style={{display:statoPagina === STATO_SCARICA_PDF?"none":"block"}}>
          <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
            <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
              <CardTitle id="titolo">Dettaglio Situazione Quote di {iscritto} </CardTitle>
              <Button color="success"  onClick={(e)=>{setStatoPagina(STATO_PREPARA_PDF)}}>Esporta il PDF</Button>
                <Row form>  
                  <Col md={12}>
                    {/*<Table responsive bordered hover className="mb-0">*/}
                      <Table bordered className="mb-0" id="dettaglioReport">
                        <tbody>              
                          <tr><td><b>Data</b></td><td><b>Tipo Quota</b></td><td><b>Operazione</b></td><td align="right"><b>Importo</b></td></tr>
                            {movimenti.map((elemento,index) => 
                              <tr key={index}>
                                <td><TrasformaDateAUmano data={elemento.DATA.split(':')[0]}/></td>
                                <td>
                                  {elemento.TIPO_QUOTA==="1" ? "Associativa" : ""}
                                  {elemento.TIPO_QUOTA==="2" ? "Abbonamento" : ""}                          
                                </td>                                
                                <td>{elemento.OPERAZIONE}</td>
                                <td align="right">{FormattaImportoVideo(elemento.IMPORTO)}</td>
                              </tr>
                            )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                  <br/>
                  
                  <b>
                  {totali.map((elemento,index) => 
                    <div key={index} style={{color: "#F0F0F0", fontWeight:'bold', backgroundColor:elemento.IMPORTO<0?coloreRosso:coloreVerde, paddingLeft:5}}>
                      {elemento.DATA}: {FormattaImportoVideo(elemento.IMPORTO)} €
                    </div>
                  )}
                  </b>

                </div>
                <div className="schedaSpazioInferiore">
                  {props.idIscritto ?
                    <Button color="primary" className="mt-2" onClick={() => tornaSchedaIscritto()} >Torna alla scheda dell'iscritto</Button>
                      :""}
                </div> 
            </div>
        </div>
      :""
      }      

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
 
                  
                            <CardBody>
                            <CardTitle>Elenco Insolventi </CardTitle>  
                            <Button color="success" className="mt-2" onClick={(e)=>{setStatoPagina(STATO_PREPARA_PDF_TUTTI)}}>Esporta il PDF</Button>  
                            </CardBody> 

                            
                            <Row style={{marginBottom:10}}>
                            <Col lg="12">   
                            <FormGroup check>                                        
                              <Label check>
                                <Input type="radio" name="tipologiaInsolvente" 
                                  innerRef={tipologiaInsolvente} 
                                  defaultValue={tipologiaInsolventeDefault}
                                  defaultChecked = {tipologiaInsolventeDefault === "1" ?  'checked' :""}
                                  value="1"
                                  onInput={(e)=>{setTipologiaInsolventeDefault(e.target.value);}}/>  {'sul Saldo Totale Quote (Associative + Abbonamento)'}
                                  </Label>
                              </FormGroup></Col><Col lg="12">   
                              <FormGroup check>                                        
                              <Label check>
                                <Input type="radio" name="tipologiaInsolvente" 
                                  innerRef={tipologiaInsolvente} 
                                  defaultValue={tipologiaInsolventeDefault}
                                  defaultChecked = {tipologiaInsolventeDefault === "2" ?  'checked' :""}
                                  value="2"
                                  onInput={(e)=>{setTipologiaInsolventeDefault(e.target.value);}}/>  {'sul Saldo Quote Associative e Quote Abbonamento considerate separatamente'}
                                  </Label>
                              </FormGroup>
                              </Col>
                              </Row>
                               
                            {/*<Button color="success" className="mt-2" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo</Button>*/}                           
                        
                            {risultatoServer !== undefined && risultatoServer !== "" ?
                              <TabellaRicerca columns={colonne} data={risultatoServer} arrayTipiDati={arrayTipiDati} campoChiave={0} nascondiColonna={0} callbackSelezionaElemento={VaiDettaglio} callbackDettaglioElemento={VaiDettaglio}/>
                            : 
                              <Table responsive bordered hover striped className="mb-0">
                              <thead>
                                <tr>
                                  <th>Nessun dato presente</th>
                                </tr>
                              </thead>
                              </Table>
                          }
                    

        </div>
      :""}
    </div>
   );
}
export default TabellaInsolventi;