import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import styles from './stiliPdf.js'

function Esercizio(props) {
        return (
        <>
        {/* {index%2 === 1 ?<>*/}
        <View wrap={false} style={[styles.tableCol1NoBordo]}>
            <View wrap={false} style={[styles.tableRowGrigiaChiara,styles.tableCol1NoBordo]}> 
                <Text style={styles.testoGrassetto}>Esercizio n. {props.numeroDato+1} di {props.arrayEserciziScheda.length}</Text> 
            </View>
            <View wrap={false} style={[styles.tableRowVerdeMenta,styles.tableCol1NoBordo,{marginBottom:1}]}> 
                <Text style={styles.testoGrassetto}>{props.arrayEserciziScheda[props.numeroDato]} </Text> 
            </View>

            <View wrap={false} style={[{paddingLeft:2,paddingRight:2,paddingBottom:1}]}> 
                    {props.arrayClasseScheda[props.numeroDato] ?
                        <View><Text style={styles.testoNormaleInclinato}>{props.arrayClasseScheda[props.numeroDato]}</Text></View>
                    :<View><Text style={styles.testoNormaleInclinato}> </Text></View>}
            </View>
            

            <View wrap={false} style={[{display:'flex',flexDirection: "row"}]}> 
                <View style={[{width: 122,height: 122,border: '1px solid black'}]}> 
                    {props.arrayFotoScheda[props.numeroDato] !== "" ? <Image style={styles.fotoEsercizio} src={"data:" + props.typeFoto[props.numeroDato] + ";base64," + props.arrayFotoScheda[props.numeroDato]}/>:<Text></Text>}  
                </View>
                <View style={{flex:1,paddingLeft:10,paddingTop:10}}>
                    {(props.arraySerieScheda[props.numeroDato] || props.arrayRipetizioniScheda[props.numeroDato] || props.arrayRecuperoTraSerieScheda[props.numeroDato]) ?
                        <>
                            <View>
                                <Text style={styles.testoGrassettoNoMargin}>Serie: {props.arraySerieScheda[props.numeroDato]} </Text>
                                <Text style={styles.testoGrassettoNoMargin}>Ripetizioni: {props.arrayRipetizioniScheda[props.numeroDato]} </Text>
                            </View>
                            <View><Text style={styles.testoNormale}>Recupero: {props.arrayRecuperoTraSerieScheda[props.numeroDato]}</Text></View>
                        </>
                    :""}
                </View>
            </View>

            <View wrap={false} style={[{paddingLeft:2,paddingRight:2,paddingTop:1}]}> 
                    <View><Text style={styles.testoNormale}>{props.arraySpiegazioneScheda[props.numeroDato]}</Text></View>
            </View>

            {props.arrayNoteEsercizioScheda[props.numeroDato] ? 
                <View wrap={false} style={[{paddingLeft:2,paddingRight:2,paddingTop:2}]}> 
                    <View style={styles.tableCol1NoBordo}> 
                        <Text style={styles.testoNormale}>Note esercizio: {props.arrayNoteEsercizioScheda[props.numeroDato]}</Text>
                    </View>
                </View>
            :""}
                                                
        </View>
        </>
        );
    
}
export default Esercizio;
