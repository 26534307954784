import React, {Fragment,useState,useEffect,Suspense} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button } from 'reactstrap';
import Parametri from '../../parametri';

import FormGridFormRow from '../SchedaCliFor/FormGridFormRow';

import TabellaRicerca from '../tabellaricerca.js';

import Clessidra from '..//Clessidra.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
const STATO_VAI_IN_ELIMINA= 5;
const STATO_VAI_IN_INSERISCI= 6;


function TabellaCliFor(props) {
    
    const operazione = "elencoCliFor";
      
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');
    const [idModifica,setIdModifica]=useState('');

    const coloreClienteRigaPari="#FFC0CB";
    const coloreClienteRigaDispari="#fadadd";
    const coloreFornitoreRigaPari="#60FFFF";
    const coloreFornitoreRigaDispari="#60F0F0";
    const coloreClienteFornitoreRigaPari="#FFFF60";
    const coloreClienteFornitoreRigaDispari="#F0F060";
    
    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Ragione Sociale',
        accessor: 'COGNOME'
      },
      {
        etichetta: 'Cod. Fiscale',
        accessor: 'CODICE_FISCALE'
      },
      {
        etichetta: 'P. IVA',
        accessor: 'P_IVA'
      },
      {
        etichetta: 'Comune',
        accessor: 'CITTA'
      },
      {
        etichetta: 'Indirizzo',
        accessor: 'INDIRIZZO'
      },
      {
        etichetta: 'Provincia',
        accessor: 'PROVINCIA'
      },
      {
        etichetta: 'Email',
        accessor: 'EMAIL'
      },
      {
        etichetta: 'Sito Web',
        accessor: 'SITO_WEB'
      },
      {
        etichetta: 'Cellulare',
        accessor: 'CELLULARE'
      },
      {
        etichetta: 'Telefono',
        accessor: 'TELEFONO'
      },
    ];
    const arrayTipiDati=[];


    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function VaiElimina(id) {
      setStatoPagina(STATO_VAI_IN_ELIMINA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }

    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
              setRisultatoServer(valoreDiRitorno.risultatoRitorno);setStatoPagina(STATO_OPERAZIONE_CORRETTA);}
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])

  
  return (
      <div>
      
      {statoPagina === STATO_VAI_IN_INSERISCI ? 
        <div>
        <Suspense fallback={
          <div className="loader-container">
          <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda ente esterno/fornitore in corso...</h6></div>
          </div>
        }>
        <FormGridFormRow {...props} ope={"inserisci"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
        </Suspense>
      </div>
      :""} 

      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda ente esterno/fornitore in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_VAI_IN_ELIMINA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda ente esterno/fornitore in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"cancella"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_CliFor</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
          <CardBody>
            <CardTitle>Elenco Enti Esterni / Fornitori</CardTitle>
            <Button color="success" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo</Button>
          </CardBody>
          {risultatoServer !== undefined && risultatoServer !== "" ?
            <TabellaRicerca 
            columns={colonne} 
            arrayTipiDati={arrayTipiDati} 
            data={risultatoServer} 

            campoChiave={0} 
            campoOrdinamento={1} 
            nascondiColonna={0} 
            callbackSelezionaElemento={VaiModifica} 
            callbackEliminaElemento={VaiElimina}/>
          : 
            <Table responsive bordered hover striped className="mb-0">
            <thead>
              <tr>
                <th>Nessun dato presente</th>
              </tr>
            </thead>
            </Table>
          }
        </div>
      :""}
    </div>
   );
}
export default TabellaCliFor;