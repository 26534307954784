
import React, {Fragment,useState,useRef,useEffect} from 'react';
import {
    Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input
} from 'reactstrap';
import Parametri from './parametri';
import Clessidra from './Pagine/Clessidra';
//import Main from '../../../DemoPages/Main';
import './login.css';
import logo from './logoTitolo.jpeg';

const STATO_INDEFINITO = 0;
const STATO_LOGIN = 1;
const STATO_CONSULTAZIONE_BACKEND = 2;
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_LOGIN_OK = 4;
const STATO_RECUPERO_PASSWORD = 5;
const STATO_CAMBIO_PASSWORD = 6;
const STATO_SELEZIONA_IDENTITA = 7;
const STATO_RICARICA_APP = 8;

function PaginaInserimentoCredenziali(props) {
    const [insEmail,setInsEmail]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    const [sessionId,setIdSession]=useState('');
    const [flagRicordaLogin, setFlagRicordaLogin] = useState(true); 
    const [identitaSelezionata, setIdentitaSelezionata] = useState([]);
    const [identitaElenco, setIdentitaElenco] = useState([]);
    const [password, setPassword] = useState("");   
    const [vecchiaPassword, setVecchiaPassword] = useState("");   
    const [versioneMinima, setVersioneMinima] = useState("");
   
    useEffect(() => {
        setStatoPagina(STATO_LOGIN);        
        if (localStorage.getItem("emailEMA")!==null) {
            setInsEmail(localStorage.getItem('emailEMA'));
        }
        // fetch versione        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"noop",
                emak:props.emak,
                sessionId: sessionId
            })
        };
        fetch(Parametri("serverURL"), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===0){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                } else {
                    if (valoreDiRitorno.versione_minima > parseInt(Parametri("release").substring(2))) {
                        // necessario ricaricare, non lo faccio in automatico per non rischiare loop infiniti
                        setStatoPagina(STATO_RICARICA_APP);
                        setVersioneMinima(valoreDiRitorno.versione_minima);
                    }                    
                }                
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );
    },[]);

    function inviaCredenzialiServer() {
        const passwordInserita = document.getElementById("password").value===""?"aaaaaaaa":document.getElementById("password").value;
        setPassword(passwordInserita);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"appLogin",
                email:insEmail,
                password:passwordInserita,
                database_persona:"",
                fcm_token:"",
                client:"web",
                emak:props.emak,
                sessionId: sessionId
            })
        };

        setStatoPagina(STATO_CONSULTAZIONE_BACKEND);
        if (flagRicordaLogin) {
            localStorage.setItem("emailEMA", insEmail); 
        } else {
            if (localStorage.getItem("emailEMA")!==null) localStorage.setItem("emailEMA", ""); 
        }

        fetch(Parametri("serverURL"), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===1){
                    if (valoreDiRitorno.elenco !== undefined) {
                        if (valoreDiRitorno.elenco.length===1) {
                            // c'è una sola persona associata a questo indirizzo email, quindi non va chiesto quale identità selezionare perché c'è solo quella
                            selezionaIdentita(valoreDiRitorno.elenco[0],passwordInserita);
                            return;
                        } else {
                            setIdentitaElenco(valoreDiRitorno.elenco);
                        }
                    }
                    if (valoreDiRitorno.accesso === "cambio") {
                        console.log("richiesto cambio password");
                        setIdSession(valoreDiRitorno.session_id);
                        setStatoPagina(STATO_CAMBIO_PASSWORD);
                        setVecchiaPassword(passwordInserita);
                        return;
                    }
                    if (valoreDiRitorno.accesso === "multi") {
                        setIdSession(valoreDiRitorno.session_id);
                        setStatoPagina(STATO_SELEZIONA_IDENTITA);
                        return;
                    }
                    if (valoreDiRitorno.accesso !== "ok") { 
                        alert("Accesso fallito: "+valoreDiRitorno.motivazione);
                        setStatoPagina(STATO_LOGIN);
                        return; 
                    }
                    setIdSession(valoreDiRitorno.session_id);
                    setStatoPagina(STATO_LOGIN_OK);
                    return;                     
                }
                alert("Si è verificato un errore. Si prega di riprovare e, se l'errore persiste, contattare l'assistenza tecnica");
                setStatoPagina(STATO_LOGIN);
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );        
    }

    function selezionaIdentita(persona,passwordInserita="") {
        var url = Parametri("serverURL");
        if (persona.urlbackend != "primario") url = persona.urlbackend;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op:"appSelezionaPersona", 
                email:insEmail,
                password:passwordInserita!=""?passwordInserita:password,
                idpersona:persona.idpersona,
                tipo:persona.tipo, 
                nome_database:persona.nome_database,
                emak:props.emak,
                sessionId: sessionId 
            })
        };
        fetch(url, requestOptions)
        .then(risposta => risposta.json())
        .then(
            (valoreDiRitorno) => {
                if (valoreDiRitorno.verifvers===0) {
                console.log("VERSIONE NON ACCETTATA DAL BACKEND (indica di aggiornare la app)");
                return; // QUI VA INDICATO DI AGGIORNARE LA APP
                }
                if (valoreDiRitorno.risultatoOperazione === 2) { setStatoPagina(STATO_LOGIN); return; }
                if (valoreDiRitorno.risultatoOperazione !== 1) { alert("Richiesta fallita","Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); return; }                                
                setIdentitaSelezionata(valoreDiRitorno.identitaSelezionata);
                setIdSession(valoreDiRitorno.session_id);
                setStatoPagina(STATO_LOGIN_OK);
            },
            (error) => { 
                console.log("ERRORE DA BACKEND NON GESTITO (catch)");
                return; // QUI VA GESTITO COSA FARE NEL FRONTEND SE BACKEND DICE ERRORE
            }
        );          
    }

    function recuperoPassword() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"personeInviaPassword",
                email:insEmail,
                emak:props.emak,
                sessionId: sessionId
            })
        };

        setStatoPagina(STATO_CONSULTAZIONE_BACKEND);

        fetch(Parametri("serverURL"), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===0){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                } else {
                    alert("Abbiamo inviato una mail a "+insEmail+" con il link per impostare una nuova password. Se incontri difficoltà, contatta l'assistenza: assistenza@gestionesportiva.it");
                }
                setStatoPagina(STATO_LOGIN);                
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );
        
    }

    function cambioPassword() {
        if (document.getElementById("nuovaPassword").value !== document.getElementById("nuovaPassword2").value) {
            alert("Attenzione: le due password inserite non coincidono");
            return;
        }
        if (document.getElementById("nuovaPassword").value.length<8) {
            alert("Attenzione: inserire una password che abbia almeno 8 caratteri");
            return;
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"personaCambioPassword",
                email:insEmail,
                vecchia:vecchiaPassword,
                nuova:document.getElementById("nuovaPassword").value,
                emak:props.emak,
                sessionId: sessionId
            })
        };

        setStatoPagina(STATO_CONSULTAZIONE_BACKEND);

        fetch(Parametri("serverURL"), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===0){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    setStatoPagina(STATO_LOGIN);
                    return;    
                }
                setStatoPagina(STATO_LOGIN); // non posso andare in STATO_LOGIN_OK perché potrebbe richiedere la selezione dell'identità
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );
        
    }

    function Logo() {
        return (
            <div 
                style={{textAlign:"center", paddingBottom:15}}
                onClick={() => {
                    var msgVMin = "";
                    if (statoPagina===STATO_RICARICA_APP) msgVMin = ", richiesta v. " + versioneMinima;
                    alert("Gestione Sportiva Web App v. " + Parametri("release") + msgVMin)
                }}
            >                
                <img style={{border: "1px solid #D0D0D0"}} height={100} src={logo} alt="Logo"/>
            </div>
        )
    }

    if (statoPagina === STATO_CONSULTAZIONE_BACKEND) {
        return (
            <div className="contenitoreLogin">
                <Card className="main-card mb-3 larghezzaCardLogin">
                    <CardTitle>Accesso in corso...</CardTitle>
                    <CardBody>
                        <br/><br/><br/>
                        <Clessidra loading={true}/>
                    </CardBody>
                </Card>
            </div>
        )
    }

    if (statoPagina === STATO_LOGIN_OK) {
        props.setIdentitaSelezionata(identitaSelezionata);
        props.setSessionId(sessionId)
    }

    if (statoPagina === STATO_LOGIN) {
        return (
            <div className="contenitoreLogin">
                <Card className="main-card mb-3 larghezzaCardLogin">
                    <CardBody> 
                        <Logo/>                        
                        <CardTitle>Accesso</CardTitle>
                        <Form>
                            <Row form>  
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input type="text" name="email" id="email" placeholder="inserisci qui la tua email" defaultValue={insEmail}
                                            onChange={(e)=>{setInsEmail(document.getElementById("email").value);}}/>                                           
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="password">Password</Label>
                                        <Input type="password" name="password" id="password" placeholder="inserisci qui la tua password"/>                                           
                                    </FormGroup>
                                </Col>
                            </Row>
                                
                            <input type='checkbox' checked={flagRicordaLogin} onChange={(event) => {setFlagRicordaLogin(event.target.checked)}}/> Ricorda la mia email
                            <div style={{textAlign:"center"}}>
                                <Button color="primary" className="mt-2" onClick={() => inviaCredenzialiServer()}>Entra</Button>
                            </div>
                        </Form>
                        <br/>
                        <div style={{textDecoration:'underline', cursor:'pointer'}} onClick={() => setStatoPagina(STATO_RECUPERO_PASSWORD)}>Password dimenticata? Clicca qui per recuperarla</div>
                    </CardBody>
                </Card>
            </div>
        )
    }

    if (statoPagina === STATO_SELEZIONA_IDENTITA) {
        return (
            <div className="contenitoreLogin">
                <Card className="main-card mb-3 larghezzaCardLogin">
                    <CardBody> 
                        <Logo/>                        
                        <CardTitle>Seleziona</CardTitle>
                        <div style={{flexDirection:'column', flex:1, justifyContent: 'center'}}>
                            <div style={{
                                    marginLeft:30, 
                                    marginRight:30, 
                                    justifyContent: 'center',                                     
                                }}>
                                {identitaElenco.map((x,i) => 
                                    <div 
                                        key={i} 
                                        style={{
                                            width:"100%",         
                                            border: "1px solid #B0B0B0",
                                            marginBottom:5,
                                            padding:5,
                                            backgroundColor:"#F9F9F9",
                                            alignContent: 'center',
                                            cursor:"pointer"}} 
                                        onClick={() => selezionaIdentita(x)}>
                                    <b>{x.cognome} {x.nome}</b>
                                    <br/>
                                    Tessera: {x.numero_tessera!==""?x.numero_tessera:"n.d."}
                                    <br/>
                                    {x.societa}
                                    </div>
                                )}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }

    if (statoPagina === STATO_RECUPERO_PASSWORD) {
        return (
            <div className="contenitoreLogin">
                <Card className="main-card mb-3 larghezzaCardLogin">
                    <CardBody>
                        <CardTitle>Recupero password</CardTitle>
                        <Form>
                            <Row form>  
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input type="text" name="email" id="email" placeholder="inserisci qui la tua email"
                                            onChange={(e)=>{setInsEmail(document.getElementById("email").value);}}/>                                           
                                    </FormGroup>
                                </Col>
                            </Row>
                                
                            <Button color="primary" className="mt-2" onClick={() => recuperoPassword()}>Recupera password</Button>
                        </Form>
                        <br/>
                        <div style={{textDecoration:'underline', cursor:'pointer'}} onClick={() => setStatoPagina(STATO_LOGIN)}>Clicca qui per tornare alla pagina di accesso all'applicazione</div>

                    </CardBody>
                </Card>
            </div>
        )
    }

    if (statoPagina === STATO_CAMBIO_PASSWORD) {
        return (
            <div className="contenitoreLogin">
                <Card className="main-card mb-3 larghezzaCardLogin">
                    <CardBody>
                        <CardTitle>La password deve essere cambiata</CardTitle>
                        <Form>
                            <Row form>  
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="email">Inserisci la nuova password</Label>
                                        <Input type="password" name="email" id="nuovaPassword" placeholder="inserisci qui la tua nuova password"/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="password">Inserisci di nuovo la nuova password</Label>
                                        <Input type="password" name="password" id="nuovaPassword2" placeholder="inserisci qui la tua nuova password"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                                
                            <Button color="primary" className="mt-2" onClick={() => cambioPassword()}>Memorizza la nuova password</Button>
                        </Form>

                    </CardBody>
                </Card>
            </div>
        )
    }

    if (statoPagina === STATO_RICARICA_APP) {
        return (
        <div className="contenitoreLogin">
                <Card className="main-card mb-3 larghezzaCardLogin">
                    <CardBody> 
                        <Logo/>                        
                        <CardTitle>Aggiornamento applicazione</CardTitle>
                        L'applicazione è stata aggiornata, clicca sul bottone qui sotto per caricare la nuova versione.
                        <br/><br/>
                        <div style={{textAlign:"center"}}>
                            <Button color="primary" className="mt-2" onClick={() => window.location.reload(true)}>OK</Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }

    // default
    return (<div className="contenitoreLogin"><Clessidra loading={true}/></div>)

};

export default PaginaInserimentoCredenziali;