import React, {Fragment,useState,useEffect,useRef,Suspense} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button } from 'reactstrap';
import Parametri from '../../parametri';

import FormGridFormRow from '../SchedaAbbonato/FormGridFormRow';

import TabellaRicerca from '../tabellaricerca.js';
import ModaleRicerca from '../ModaleRicerca.js';

import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import Clessidra from '../Clessidra.js';

import { calcolaAnnoOggi } from '../../funzioni.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
const STATO_VAI_IN_ELIMINA= 5;
const STATO_VAI_IN_INSERISCI= 6;


function TabellaAbbonati(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno
    
    const operazione = "elencoAbbonati";

    const  dataPartenza = useRef();

    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);
    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);
    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');
      
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');
    
    const [idModifica,setIdModifica]=useState('');

    const [flagModaleRicercaAvanzata, setFlagModaleRicercaAvanzata] = useState(false);
    const defaultSelezioneRicercaAvanzata = [
      {"campoDatabase":"", "criterio":"contiene", "dato":""},
      {"campoDatabase":"", "criterio":"contiene", "dato":""},
      {"campoDatabase":"", "criterio":"contiene", "dato":""}
    ];
    const [selezioneRicercaAvanzata, setSelezioneRicercaAvanzata] = useState(defaultSelezioneRicercaAvanzata);

    const coloreScadutiRigaPari="#FFFF60";
    const coloreScadutiRigaDispari="#F0F060";

    const coloreBlu="#0000FF";
    const coloreArancio="#b87e14";
    const coloreViola="#800080";

    const [filtroSoloAttivi,setFiltroSoloAttivi]=useState(1);
    const [filtroSoloScaduti,setFiltroSoloScaduti]=useState(0);

    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Cognome',
        accessor: 'COGNOME'
      },
      {
        etichetta: 'Nome',
        accessor: 'NOME'
      },
      {
        etichetta: 'Numero Tessera',
        accessor: 'NUMERO_TESSERA'
      },
      {
        etichetta: 'Nome Abbonamento',
        accessor: 'NOMEABBONAMENTO'
      },
      {
        etichetta: 'Classe Abbonamento',
        accessor: 'CLASSE_ABBONAMENTO'
      },
      {
        etichetta: 'Data Inizio',
        accessor: 'DATA_INIZIO',
      },
      {
        etichetta: 'Data Fine',
        accessor: 'DATA_FINE',
      },
      {
        etichetta: 'Numero Accessi Residui',
        accessor: 'ACCESSI_RESIDUI',
      },
      {
        etichetta: 'Saldo Q.Ass.',
        accessor: 'QASS',
      },
      {
        etichetta: 'Saldo Q.Abb.',
        accessor: 'QABB',
      },
      {
        etichetta: 'Saldo Totale Quote',
        accessor: 'QTOT',
      },
    ];

    const arrayTipiDati=[];
    arrayTipiDati[6]="data";
    arrayTipiDati[7]="data";
    arrayTipiDati[8]="accessi";

    const campiRicercaAvanzata = [
      { etichetta: 'Cognome', campoDatabase: 'COGNOME', tipo: 'alfanumerico' },
      { etichetta: 'Nome', campoDatabase: 'NOME', tipo: 'alfanumerico' },
      { etichetta: 'Numero Tessera', campoDatabase: 'NUMERO_TESSERA', tipo: 'alfanumerico' },
      { etichetta: 'Nome Abbonamento', campoDatabase: 'NOMEABBONAMENTO', tipo: 'alfanumerico' },
      { etichetta: 'Data Inizio', campoDatabase: 'DATA_INIZIO', tipo: 'data' },
      { etichetta: 'Data Fine', campoDatabase: 'DATA_FINE', tipo: 'data' },
      { etichetta: 'Numero Accessi Residui', campoDatabase: 'ACCESSI_RESIDUI', tipo: 'alfanumerico' }
    ]
                          
    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function VaiElimina(id) {
      setStatoPagina(STATO_VAI_IN_ELIMINA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }

    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      //resetto tutte le var di stato:
      setRisultatoServer(['']);
      setStatoPagina(STATO_RICERCA_DATI);    
    },[dataPartenzaDefault])

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,filtro_solo_attivi:filtroSoloAttivi,filtro_solo_scaduti:filtroSoloScaduti,selezioneRicercaAvanzata:selezioneRicercaAvanzata,dataPartenza:dataPartenzaDefault,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {
            if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
              alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
            }
            if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
            if(valoreDiRitorno.risultatoOperazione===1){
            setRisultatoServer(valoreDiRitorno.risultatoRitorno);
            setInizioPeriodo(valoreDiRitorno.periodo[0].DATA_INIZIO_PERIODO);
            setFinePeriodo(valoreDiRitorno.periodo[0].DATA_FINE_PERIODO);
            setStatoPagina(STATO_OPERAZIONE_CORRETTA);
          }
        },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])


    function ModaleRicercaAvanzata() {
      return (
        <ModaleRicerca        
          campiRicercaAvanzata={campiRicercaAvanzata}
          criteriIniziali = {selezioneRicercaAvanzata}
          onConferma={(criteri) => { 
            setFlagModaleRicercaAvanzata(false); 
            setSelezioneRicercaAvanzata(criteri);
            setStatoPagina(STATO_RICERCA_DATI);
          }}
          onAnnulla={() => { setFlagModaleRicercaAvanzata(false); setSelezioneRicercaAvanzata(defaultSelezioneRicercaAvanzata);setStatoPagina(STATO_RICERCA_DATI);}}
  
          callbackConferma = {
            (v) => {
              var indiceCampoSelezionato=-1;
              for (var i=0; i<campiRicercaAvanzata.length; i++) {                      
                if (campiRicercaAvanzata[i].campoDatabase==v[0].campoDatabase) {
                  indiceCampoSelezionato=i;
                  break;
                }
              }
              if (indiceCampoSelezionato<0) {
                setSelezioneRicercaAvanzata(defaultSelezioneRicercaAvanzata);
              } else {
                if (campiRicercaAvanzata[indiceCampoSelezionato].tipo==="data") {
                  // verifica correttezza della data
                  var dataOk = false;
                  var x = v[0].dato.split("/");
                  if (x.length==3) {
                    if (!isNaN(x[0])&&!isNaN(x[1])&&!isNaN(x[2])) {
                      if (x[0]>0 && x[0]<32 && x[1]>0 && x[1]<13 && x[2]>0 && x[2] < 2100) {
                        var dataOk = true;
                      }
                    }
                  }
                  if (!dataOk) {
                    alert("Data non corretta. Inserisci la data scrivendo giorno/mese/anno. Ad esempio: 1/1/2024");
                    return;
                  }
                }
                setSelezioneRicercaAvanzata(v);
              }
              setFlagModaleRicercaAvanzata(false);
              setStatoPagina(STATO_RICERCA_DATI);
            }
          }    
        /> 
      )
    }

  return (
      <div>

      {flagModaleRicercaAvanzata ? <ModaleRicercaAvanzata /> : <></>}

      {statoPagina === STATO_VAI_IN_INSERISCI ? 
        <div>
        <Suspense fallback={
          <div className="loader-container">
          <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda abbonato in corso...</h6></div>
          </div>
        }>
        <FormGridFormRow {...props} ope={"inserisci"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
        </Suspense>
      </div>
      :""} 

      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda abbonato in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_VAI_IN_ELIMINA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda abbonato in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"cancella"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_Abb</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ?<div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
          <CardBody>
            <CardTitle>
              {filtroSoloAttivi=== 0 && filtroSoloScaduti=== 0? <Fragment>Elenco Abbonati nell'anno&nbsp;</Fragment>
              : filtroSoloAttivi=== 1 ? <Fragment>Elenco Abbonati Attivi nell'anno&nbsp;</Fragment>
              : <Fragment>Elenco Abbonati Scaduti nell'anno&nbsp;</Fragment>}

              <select name="dataPartenza" id="dataPartenza" innerRef={dataPartenza} onChange={(e)=>{setDataPartenzaDefault(e.target.value)}}>
                {parseInt(dataPartenzaDefault) === annoOggi ? <option selected value={annoOggi}>{annoOggi}</option> : <option value={annoOggi}>{annoOggi}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-1 ? <option selected value={annoOggi-1}>{annoOggi-1}</option> : <option value={annoOggi-1}>{annoOggi-1}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-2 ? <option selected value={annoOggi-2}>{annoOggi-2}</option> : <option value={annoOggi-2}>{annoOggi-2}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-3 ? <option selected value={annoOggi-3}>{annoOggi-3}</option> : <option value={annoOggi-3}>{annoOggi-3}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-4 ? <option selected value={annoOggi-4}>{annoOggi-4}</option> : <option value={annoOggi-4}>{annoOggi-4}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-5 ? <option selected value={annoOggi-5}>{annoOggi-5}</option> : <option value={annoOggi-5}>{annoOggi-5}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-6 ? <option selected value={annoOggi-6}>{annoOggi-6}</option> : <option value={annoOggi-6}>{annoOggi-6}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-7 ? <option selected value={annoOggi-7}>{annoOggi-7}</option> : <option value={annoOggi-7}>{annoOggi-7}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-8 ? <option selected value={annoOggi-8}>{annoOggi-8}</option> : <option value={annoOggi-8}>{annoOggi-8}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-9 ? <option selected value={annoOggi-9}>{annoOggi-9}</option> : <option value={annoOggi-9}>{annoOggi-9}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-10 ? <option selected value={annoOggi-10}>{annoOggi-10}</option> : <option value={annoOggi-10}>{annoOggi-10}</option>}
              </select>    
              &nbsp;dal <TrasformaDateAUmano data={inizioPeriodo}/> al <TrasformaDateAUmano data={finePeriodo}/>
            </CardTitle>
            <Button color="success" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo</Button>
          
            {filtroSoloAttivi=== 0 && filtroSoloScaduti=== 0?
                <>
                <Button className="ml-2" color="success" onClick={() => {setFiltroSoloAttivi(1);setStatoPagina(STATO_RICERCA_DATI)}}>Visualizza solo Attivi</Button>
                <Button className="ml-2" color="success" onClick={() => {setFiltroSoloScaduti(1);setStatoPagina(STATO_RICERCA_DATI)}}>Visualizza solo Scaduti</Button>
                </>
            :
                <Button className="ml-2" color="success" onClick={() => {setFiltroSoloAttivi(0);setFiltroSoloScaduti(0);setStatoPagina(STATO_RICERCA_DATI)}}>Visualizza Attivi e Scaduti</Button>
            }
            <Button color="success" className="ml-2" onClick={() => setFlagModaleRicercaAvanzata(true)}>Ricerca avanzata</Button>

            &nbsp;&nbsp;Legenda:
            <span style={{whiteSpace:"pre",marginLeft:5,paddingLeft:5,paddingRight:5,border:"1px solid black"}}>attivi</span>
            <span style={{whiteSpace:"pre",backgroundColor:coloreScadutiRigaPari,marginLeft:5,paddingLeft:5,paddingRight:5,border:"1px solid black"}}>Scaduti</span>

            <span style={{ whiteSpace: "pre", marginLeft: 5, paddingLeft: 5, paddingRight: 5 }}>
            <span style={{ display: "inline-block", width: 10, height: 10, backgroundColor: coloreBlu, borderRadius: "50%", marginRight: 5 }}></span>
              Insolventi sulle Quote Associative
            </span>
            <span style={{ whiteSpace: "pre", marginLeft: 5, paddingLeft: 5, paddingRight: 5 }}>
            <span style={{ display: "inline-block", width: 10, height: 10, backgroundColor: coloreArancio, borderRadius: "50%", marginRight: 5 }}></span>
              Insolventi sulle Quote Abbonamento
            </span>
            <span style={{ whiteSpace: "pre", marginLeft: 5, paddingLeft: 5, paddingRight: 5 }}>
            <span style={{ display: "inline-block", width: 10, height: 10, backgroundColor: coloreViola, borderRadius: "50%", marginRight: 5 }}></span>
              Insolventi sul Totale Quote (Associative + Abbonamento)
            </span>

            {(selezioneRicercaAvanzata[0].campoDatabase!=="" || selezioneRicercaAvanzata[1].campoDatabase!=="" || selezioneRicercaAvanzata[2].campoDatabase!=="") &&
              <div 
                onClick={() => setFlagModaleRicercaAvanzata(true)} 
                style={{
                  marginLeft:0, 
                  marginTop:10, 
                  backgroundColor:"#FFFFFF", 
                  paddingLeft:5, 
                  paddingRight:5, 
                  paddingTop:5, 
                  paddingBottom:5, 
                  cursor:"pointer"
                }}>
                <b>Filtro</b>:&nbsp;
                {selezioneRicercaAvanzata.map((condizione, indiceCondizione) => 
                  <span key={indiceCondizione}>
                    {condizione.campoDatabase !== "" && 
                      <>
                        {indiceCondizione > 0 && <>,&nbsp;</>}
                        {campiRicercaAvanzata.map((x,i)=> {if(x.campoDatabase==condizione.campoDatabase) return x.etichetta; })}
                        &nbsp;{condizione.criterio} {condizione.dato}
                      </>
                    }
                  </span>
                )}
              </div>
            }

          </CardBody>
          {risultatoServer !== undefined && risultatoServer !== "" ?
            <TabellaRicerca 
            columns={colonne} 
            data={risultatoServer} 
            arrayTipiDati={arrayTipiDati} 
            coloriPersonalizzati={[
              {"campo":"7","condizione":"<","valore":dataOggi,"carattereRigaPari":"#000000","sfondoRigaPari":coloreScadutiRigaPari,"carattereRigaDispari":" #000000","sfondoRigaDispari":coloreScadutiRigaDispari},
              {"campo":"8","condizione":"==","valore":'0',"carattereRigaPari":"#000000","sfondoRigaPari":coloreScadutiRigaPari,"carattereRigaDispari":" #000000","sfondoRigaDispari":coloreScadutiRigaDispari}
            ]}
            palliniColorati={[
              {"campo":"9","condizione":"<","valore":'0',"colorePallinoRigaPari":coloreBlu,"colorePallinoRigaDispari":coloreBlu},
              {"campo":"10","condizione":"<","valore":'0',"colorePallinoRigaPari":coloreArancio,"colorePallinoRigaDispari":coloreArancio},
              {"campo":"11","condizione":"<","valore":'0',"colorePallinoRigaPari":coloreViola,"colorePallinoRigaDispari":coloreViola}
            ]}
            campoChiave={0} 
            campoOrdinamento={1} 
            nascondiColonne={[0,9,10,11]}
            callbackSelezionaElemento={VaiModifica} 
            callbackEliminaElemento={VaiElimina}/>            
          : 
            <Table responsive bordered hover striped className="mb-0">
            <thead>
              <tr>
                <th>Nessun dato presente</th>
              </tr>
            </thead>
            </Table>
          }
        </div>        
      :""}
    </div>
   );
}
export default TabellaAbbonati;