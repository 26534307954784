
function Parametri(parametro, identitaSelezionata=[]) {
const versione=244
const urlbackendPrimario = 'https://ema1.gestionesportiva.it/link_back_front.php';
    //const urlbackendPrimario = 'https://ema1.gestionesportiva.it/link_back_front.php';//produzione

    switch (parametro) {       
        case 'serverURL': 
            if (identitaSelezionata.urlbackend != undefined) {
                if (identitaSelezionata.urlbackend != "primario") return identitaSelezionata.urlbackend;
            }           
            return urlbackendPrimario;
        break;

        case 'serverURLPrimario': 
            return urlbackendPrimario;
        break;
        
case 'release': return "P-"+versione; break;
    }
    return "";
}

export default Parametri;