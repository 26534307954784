function FormattaImportoDb(input) {
  const errore="E";

  // rimuovo eventuali spazi all'interno della variabile input (non solo all'inizio e alla fine ma anche in mezzo, ad es. "- 3" è accettato come -3)
  // \s indica white spaces, cioè spazi, tab, a capo, ecc.
  // /g indica occorrenze multiple, cioè sostituisci TUTTI gli spazi in questo caso
  input = input.replace(/\s/g, "")

  if (input.length==0) return errore;

  // controllo se l'input inizia con un segno "+" o "-"
  let segno = "";
  if (input[0] === "+" || input[0] === "-") {
      segno = input[0];
      input = input.slice(1); // elimino il segno dall'input
  }

  if (input.length==0) return errore; // poteva esserci solo il segno, per questo ricontrollo qui

  // separo parte intera da parte decimale
  const parti=input.split(",");
  if (parti.length>2) return errore; // c'è più di una virgola

  /*
  L'espressione regolare ^[\d\.]+$ corrisponde a una stringa che inizia (^) e termina ($) con uno o più (+) caratteri che sono 
  numeri o punti. La \d corrisponde a una cifra numerica e il punto . viene scritto come \. poiché il punto ha un significato
  speciale nelle espressioni regolari.
  */
  // controllo caratteri nella parte intera: numeri e punti
  if (!/^[\d\.]+$/.test(parti[0])) return errore;
  // controllo caratteri nella parte decimale, se c'è: solo numeri
  if (parti.length==2) if (!/^[\d]+$/.test(parti[1])) return errore;
  
  const sezioniParteIntera=parti[0].split(".");
  if (sezioniParteIntera.length>1) {
      // ci sono punti di separazione migliaia: devono essere ogni 3 caratteri
      for (let n=0; n<sezioniParteIntera.length; n++) {
          if (n==0) {
              if (sezioniParteIntera[n].length>3) return errore;
          } else {
              if (sezioniParteIntera[n].length!==3) return errore;
          }
      }
      // valore inserito correttamente e ha punti di separazione migliaia, restituisco il valore eliminandoli
      return (segno+input.replace(/\./g, "").replace(",","."));
  }

  // valore inserito correttamente e non ha punti di separazione migliaia, restituisco il valore così com'è
  return (segno+input).replace(",",".");
}

function FormattaImportoDb_old(importo) {
  let importoOutput='';
  let intermedio='';
  //da formato 23.45689 a 23,46 quindi 2 cifre decimali e virgola in luogo del punto
  if(importo){
      intermedio = importo.replace(".", "");
      importoOutput = intermedio.replace(",", ".");
  }else importoOutput = '';

  return importoOutput;
}


export default FormattaImportoDb;
