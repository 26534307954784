
import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,
    Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';

//https://fullcalendar.io/demos utile per dimostrazioni e manuale

import FullCalendar, { joinSpans } from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import itLocale from '@fullcalendar/core/locales/it';//serve per lingua italiana
import timeGridPlugin from '@fullcalendar/timegrid';

import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';



const STATO_INDEFINITO = 0;
const STATO_RICERCA_NUOVI_DATI = 1
const STATO_RICERCA_DATI_CORRETTA = 2;
const STATO_MEMORIZZAZIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_ERRORE_CONNESSIONE = 5;

function TablesScadenziario(props) {

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [adempimentiFullcalendar,setAdempimentiFullcalendar]=useState([]);//json con tutti gli adempimenti (formato utile per il fullcalendar)
    
    const [modalIsOpen, setIsOpen] = React.useState(false);//per la finestra modale relativa all'inserimento dei dati dell'adempimento
    const [modaleConfermaCanc, setModaleConfermaCanc] = React.useState(false);//per la finestra modale relativa alla cancellazione massiva
    const [modaleConfermaCancUnoSolo, setModaleConfermaCancUnoSolo] = React.useState(false);//per la finestra modale relativa alla cancellazione di un solo adempimento
    
    const [flagModaleNumeroAdempimentiElevato, setFlagModaleNumeroAdempimentiElevato] = React.useState(false);
    const [flagModaleIntervalloTroppoElevato, setFlagModaleIntervalloTroppoElevato] = React.useState(false);
    const [flagModaleDateErrate, setFlagModaleDateErrate] = React.useState(false);
    const [flagModaleDescrizioneNonValorizzata,setFlagModaleDescrizioneNonValorizzata]=React.useState(false);
    const [flagModaleDataInizioNonValorizzata,setFlagModaleDataInizioNonValorizzata]=React.useState(false);
    const [flagModaleDataFineNonValorizzata,setFlagModaleDataFineNonValorizzata]=React.useState(false);
    const [flagModaleUnitaTempoNonValorizzata,setFlagModaleUnitaTempoNonValorizzata]=React.useState(false);
    const [flagModaleUnitaTempoErrata,setFlagModaleUnitaTempoErrata]=React.useState(false);
    
    const [flagAttivaModaleInserisciModifica,setFlagAttivaModaleInserisciModifica]=React.useState(false);
    const [flagAttivaModaleCancellazione,setFlagAttivaModaleCancellazione]=React.useState(false);
    const [flagAttivaModaleCancellazioneUnoSolo,setFlagAttivaModaleCancellazioneUnoSolo]=React.useState(false);
    
    const [operazione,setOperazione]=useState('');

    const [dataPartenza,setDataPartenza]=useState('');//questa verrà settata con il click sulla data: verrà suggerita poi come data di inizio evento
    const [inserisciNuovo,setInserisciNuovo]=useState(0);//verrà settata ad 1 quando l'utente clicca su una data

    //const [giornoEvento,setGiornoEvento]=useState(0);
 
    const [dataSelezionata,setDataSelezionata]=useState("");
    //cariabili con i colori degli adempimenti a seconda se sono stati eseguiti o meno:
    const [coloreEseguito,setColoreEseguito]=useState("");
    const [coloreDaEseguire,setColoreDaEseguire]=useState("");

    const calendarRef = useRef();

    const [bottoniNavigazione,setBottoniNavigazione]=useState("");


    var today = new Date();

    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;
    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno+'T00:00:00';

    const todayMenounMese= subtractMonths(today, 1);

    let sMeseMenounMese = String(todayMenounMese.getMonth()+1);
    if(sMeseMenounMese.length === 1) sMese = "0"+sMeseMenounMese;
    let sGiornoMenounMese = String(todayMenounMese.getDate());
    if(sGiornoMenounMese.length === 1) sGiornoMenounMese = "0"+sGiornoMenounMese;
    const dataOggiMenounMese = todayMenounMese.getFullYear()+'-'+sMeseMenounMese+'-'+sGiornoMenounMese+'T00:00:00';
   
    //periodo considerato per fare la richiesta dati tramite fetch al backend:
    const [dataInizioPeriodo,setDataInizioPeriodo]=useState('');
    const [dataFinePeriodo,setDataFinePeriodo]=useState('');

    //periodo totale considerato per fare la richiesta dati tramite fetch al backend:
    const [dataInizioPeriodoTot,setDataInizioPeriodoTot]=useState('');
    const [dataFinePeriodoTot,setDataFinePeriodoTot]=useState('');

    //escursione massima ammessa di vibilità del calendario:
    let numeroAnniVisibiliDopoOggi=10;
    let numeroAnniVisibiliPrimaOggi=10;

    let annoInizioRange = today.getFullYear()-numeroAnniVisibiliPrimaOggi;
    let meseInizioRange = today.getMonth()+1;
    let giornoInizioRange = today.getDate();

    if(meseInizioRange<10) meseInizioRange="0"+meseInizioRange;
    if(giornoInizioRange<10) giornoInizioRange="0"+giornoInizioRange;

    let annoFineRange = today.getFullYear()+numeroAnniVisibiliDopoOggi;
    let meseFineRange = today.getMonth()+1;
    let giornoFineRange = today.getDate();

    if(meseFineRange<10) meseFineRange="0"+meseFineRange;
    if(giornoFineRange<10) giornoFineRange="0"+giornoFineRange;

    const dataInizioRange = annoInizioRange+'-'+meseInizioRange+'-'+giornoInizioRange;//parto dalla giornata di oggi e tolgo un anno
    const dataFineRange = annoFineRange+'-'+meseFineRange+'-'+giornoFineRange;//vedo un anno da oggi

    //dati relativi alla scheda adempimento:

    const descrizione   = useRef();
    const dataInizio   = useRef();
    const dataFine   = useRef();
    const periodicita   = useRef();

    const [idAdempimento,setIdAdempimento]=useState(0);

    const [descrizioneDefault,setDescrizioneDefault] = useState('');
    const [dataInizioDefault,setDataInizioDefault]=useState('');
    const [dataAdempimentoDefault,setDataAdempimentoDefault]=useState('');
    const [dataFineDefault,setDataFineDefault]=useState('');
    const [defaultUnitaTempo,setDefaultUnitaTempo]=useState("Mesi");
    const [insUnitaTempoEdi,setInsUnitaTempoEdi]=useState("Mesi");
    const [periodicitaDefault,setPeriodicitaDefault] = useState(1);
    const [statoDefault,setStatoDefault] = useState('0');
    const [risultatoServerDatiUnitaTempo,setRisultatoServerDatiUnitaTempo]=useState('');
    //const [statoTendinaUnitaTempo,setStatoTendinaUnitaTempo]=useState(false);

    //const [flagLoading,setFlagLoading]=useState(0);

    const [loading,setLoading]=useState(0);
    

    useEffect(() => {
        //console.log("RICHIAMO USEEEFFECT INIZIALE");
        //al primo giro prendo 3 mesi come periodo di richiesta disponibilità e prenotazioni per cui:
        setStatoPagina(STATO_MEMORIZZAZIONE);

        setDataInizioPeriodo(dataOggiMenounMese);
        setDataInizioPeriodoTot(dataOggiMenounMese);
        calcolaPeriodoAnalisiDisponibilitaUp(dataOggiMenounMese);
        setInserisciNuovo(0);
        setDataPartenza();
        //setFlagLoading(0);
        setDataSelezionata(dataOggi);//al primo giro la data selezionata è la data di oggi
    },[]);

    useEffect(() => {
        if(statoPagina  === STATO_RICERCA_NUOVI_DATI) setBottoniNavigazione('custom2');
        if(statoPagina  === STATO_RICERCA_DATI_CORRETTA) setBottoniNavigazione('custom1,prev,next');
    },[statoPagina]);

    useEffect(() => {
        //console.log("INI"+dataInizioPeriodo+"FIN"+dataFinePeriodo);
        if(dataInizioPeriodo!=='' && dataFinePeriodo!==''){
            let ricalcolo = 0;
            if(dataFinePeriodo>dataFinePeriodoTot){
                ricalcolo = 1;
                setDataFinePeriodoTot(dataFinePeriodo);
                //console.log("CERCO from"+dataInizioPeriodoTot+"to"+dataFinePeriodo)
            } 
            if(dataInizioPeriodo<dataInizioPeriodoTot){ 
                ricalcolo = 1;             
                setDataInizioPeriodoTot(dataInizioPeriodo);
                //console.log("CERCO from"+dataInizioPeriodo+"to"+dataFinePeriodoTot)
            } 
            if(ricalcolo === 1){
                //console.log("CALCOLO");
                //console.log("dataInizioPeriodo"+dataInizioPeriodo);
                //console.log("dataFinePeriodo"+dataFinePeriodo);
                ricercaAdempimenti();
            } 
        }
    },[dataFinePeriodo,dataInizioPeriodo]);
 
    //useEffect per gestire l'inserimento di un nuovo adempimento:
    useEffect(() => {
        if(inserisciNuovo===1){
            setInserisciNuovo(0);
            setDescrizioneDefault('');
            setDataAdempimentoDefault('');
            setDefaultUnitaTempo("Mesi");
            setInsUnitaTempoEdi("Mesi");
            setPeriodicitaDefault(1);
            setStatoDefault('0');
            setIsOpen(true);
            setFlagAttivaModaleInserisciModifica(true);
            nuovoEvento()
        }
    },[dataPartenza]);

    useEffect(() => {
        if(operazione === "modifica"){
            setIsOpen(true);
            setFlagAttivaModaleInserisciModifica(true);
        } 
    },[operazione]);

    function subtractMonths(date, months) {
        date.setMonth(date.getMonth() - months);
        return date;
      }

    function ricercaAdempimenti(modalita="",periodo=0){
        //ricerco gli adempimenti già in calendario:
        //con periodo = 0 eseguo la ricerca solo sul periodo dataInizioPeriodo,dataFinePeriodo
        //invece con periodo = 1 estendo al ricerca su tutto il periodo dataInizioPeriodoTot,dataFinePeriodoTot (utilizzato in caaso di modifica di un adempimento: devo rifare la fetch completa perchè potrei non essere nell'ultimo periodo fetchato)

        // nota:
        // dataInizio nella forma YYYY-MM-DDThh:mm:ss (dove ore, minuti e secondi sono ininfluenti)
        // dataFine nella forma YYYY-MM-DDThh:mm:ss (dove ore, minuti e secondi sono ininfluenti)

        let dIni='';
        let dFine='';

        if(periodo===1){
            dIni=dataInizioPeriodoTot;
            dFine=dataFinePeriodoTot;
        }else{
            dIni=dataInizioPeriodo;
            dFine=dataFinePeriodo;
        }
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"adempimentiRicerca",
            outputDisponibilita:"fullcalendar",
            dataInizio:dIni,
            dataFine:dFine,
            emak: props.emak,
            sessionId:props.sessionId})
        };
          
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then( 
                (valoreDiRitorno) => {
                    setLoading(false);
                    //console.log("eccolo")
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                        //torno al calendario con i dati aggiornati all'ultima modifica:
//console.log(periodo+"faccio fetch da"+dIni+" a "+dFine+" con modalità:"+modalita);
                        if(dataFinePeriodo>dataFinePeriodoTot) setDataFinePeriodoTot(dataFinePeriodo);
                        if(dataInizioPeriodo<dataInizioPeriodoTot) setDataInizioPeriodoTot(dataInizioPeriodo);

                        if (modalita==="sostituisci") {
                            setAdempimentiFullcalendar(valoreDiRitorno.risultatoRitorno);

                            
                            //setDataFinePeriodoTot(dataFinePeriodo);  
//console.log("1 per cui ottengo:"+valoreDiRitorno.risultatoRitorno.length);
//console.log("con id:"+valoreDiRitorno.risultatoRitorno[0].id);
//console.log("con data:"+valoreDiRitorno.risultatoRitorno[0].dataadempimento);
                        }else{
                            if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                            else setAdempimentiFullcalendar([...adempimentiFullcalendar ,...valoreDiRitorno.risultatoRitorno]);
                        }
//console.log("coloro "+valoreDiRitorno.coloreeseguito);
                        setColoreEseguito(valoreDiRitorno.coloreeseguito);
                        setColoreDaEseguire(valoreDiRitorno.coloredaeseguire);                       
                        //setFlagLoading(0);
                        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                },
                (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
    }
    function callbackSetValoreTendEditabileUnitaTempo(valore){
        setInsUnitaTempoEdi(valore);
        setDefaultUnitaTempo(valore);
    }
    function ricercaUnitaTempo() {
        //ricerco le unità di tempo che mi servono per popolare la tendina:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoUnitaTempo",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                setRisultatoServerDatiUnitaTempo(valoreDiRitorno.risultatoRitorno);
                //setStatoTendinaUnitaTempo(true);
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function tornaVisioneCalendario() {
        setIsOpen(false);
        setFlagAttivaModaleInserisciModifica(false);
        setModaleConfermaCanc(false);
        setFlagAttivaModaleCancellazione(false);
        setModaleConfermaCancUnoSolo(false);
        setFlagAttivaModaleCancellazioneUnoSolo(false);
        setFlagModaleNumeroAdempimentiElevato(false);
        setFlagModaleIntervalloTroppoElevato(false);
        setFlagModaleDateErrate(false);
        setFlagModaleDescrizioneNonValorizzata(false);
        setFlagModaleDataInizioNonValorizzata(false);
        setFlagModaleDataFineNonValorizzata(false);
        setFlagModaleUnitaTempoNonValorizzata(false);
        setFlagModaleUnitaTempoErrata(false);
        setVariazioneCampi(0);
        setInserisciNuovo(0);
        setIdAdempimento(0);
        setDataPartenza("");
        setOperazione("");
    }

    function callbackSetVariazioneCampi(variazioneCampi){
        setVariazioneCampi(variazioneCampi);
    }
  

    function vaiDataOggi(){
        let calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(dataOggi); // va al giorno di oggi
//console.log("vaiDataOggi con "+dataOggi);
        setDataSelezionata(dataOggi);
    }

    function settaDataPartenza(arg){
        let calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(arg.dateStr); // va al giorno
        //setto i default delle date di Prima Esecuzione e Ultima Esecuzione con la data cliccata:
        setDataInizioDefault(arg.dateStr.substr(0, 10));
        setDataFineDefault(arg.dateStr.substr(0, 10));

        //setGiornoEvento(arg.dateStr.substr(0, 10)); //YYYY-MM-DDThh:mm:ss
        ricercaUnitaTempo();
        setInserisciNuovo(1);
//console.log("setto data partenza con "+arg.dateStr);
        setDataSelezionata(arg.dateStr);
        setDataPartenza(arg.dateStr.substr(0, 16)); 
    }

    function evidenziaDataSelezionata() {
        if (dataSelezionata==="" || dataSelezionata===undefined || dataSelezionata==='') return;     
        if (calendarRef==="" || calendarRef===undefined  || calendarRef.current==='') return;
        if (calendarRef.current===undefined) return;
        let calendarApi = calendarRef.current.getApi();
        //let vista = calendarApi.getView("name"); // recupera la vista attuale
        //console.log("la vista vale"+calendarApi.getView("name"));
        calendarApi.select(dataSelezionata.substr(0, 10)+"T00:00:00"); // colora di azzurro il giorno                                              
    }
    function nuovoEvento(){
        setOperazione("inserisci");
    }

    function modificaEvento(arg){ // bind with an arrow function
        //alert('View: ' + arg.view.type);
        ricercaUnitaTempo();
        //let calendarApi = calendarRef.current.getApi();
        //calendarApi.fullCalendar('unselect');

        //if(arg.view.type!="dayGridMonth"){
            //setGiornoEvento(arg.event.startStr.substr(0, 10)); //YYYY-MM-DDThh:mm:ss
        //}

        //setto le var di default relativamente al record in esame:
        setDescrizioneDefault(arg.event.title);
        
        setIdAdempimento(arg.event.id);

//console.log("idadempimento cliccato="+arg.event.id);
        //console.log("elementi in idAdempimentiFullcalendar ="+idAdempimentiFullcalendar.length);
        
        //console.log("chiave->"+idAdempimentiFullcalendar["C"+arg.event.id]);

        //let posizione = idAdempimentiFullcalendar[arg.event.id];
        //let posizione = 0;
        
        //console.log("posizione"+posizione);
        //console.log("eccolo qua"+adempimentiFullcalendar[posizione].dataadempimento);
        //recupero quindi gli altri dati:
//console.log("quanti sono"+adempimentiFullcalendar.length);

        for (let i=0; i<adempimentiFullcalendar.length ; i++) {
            //console.log("confronto "+adempimentiFullcalendar[i].id+" con "+arg.event.id);          
            if(adempimentiFullcalendar[i].id===arg.event.id){

                //ho trovato il mio corso: ne recupero i dati:
                setDataInizioDefault(adempimentiFullcalendar[i].primaesecuzione);
                setDataFineDefault(adempimentiFullcalendar[i].ultimaesecuzione);
                setDataAdempimentoDefault(adempimentiFullcalendar[i].dataadempimento);
                setInsUnitaTempoEdi(adempimentiFullcalendar[i].unita);
                setDefaultUnitaTempo(adempimentiFullcalendar[i].unita);
                setPeriodicitaDefault(adempimentiFullcalendar[i].periodicita);
                setStatoDefault(adempimentiFullcalendar[i].stato);
                break;
            }
        }
        setOperazione("modifica");
    }
    
    function closeModal() {
        setIsOpen(false);
        setFlagAttivaModaleInserisciModifica(false);
    }

    function openModalCancellazione() {
        setModaleConfermaCanc(true);
        setFlagAttivaModaleCancellazione(true);
    }

    function openModalCancellazioneUnoSolo() {
        setModaleConfermaCancUnoSolo(true);
        setFlagAttivaModaleCancellazioneUnoSolo(true);
    }

    function chiudiModaleNumeroAdempimentiElevato(){
        setFlagModaleNumeroAdempimentiElevato(false);
    }

    function chiudiModaleIntervalloTroppoElevato(){
        setFlagModaleIntervalloTroppoElevato(false);
    }

    function chiudiModaleDateErrate(){
        setFlagModaleDateErrate(false);
    }
    
    function chiudiModaleDescrizioneNonValorizzata(){
        setFlagModaleDescrizioneNonValorizzata(false);
    }
    function chiudiModaleDataInizioNonValorizzata(){
        setFlagModaleDataInizioNonValorizzata(false);
    }
    function chiudiModaleDataFineNonValorizzata(){
        setFlagModaleDataFineNonValorizzata(false);
    }
    function chiudiModaleUnitaTempoNonValorizzata(){
        setFlagModaleUnitaTempoNonValorizzata(false);
    } 
    function chiudiModaleUnitaTempoErrata(){
        setFlagModaleUnitaTempoErrata(false);
    } 

    function verificaPeriodoVisualizzazione(dataI,dataF) {
        //controllo che la data inizio e fine del periodo di visualizzazione del calendario stiano nel periodo richiesco delle disponibilità:
        //se così non fosse faccio nuova fetch:
        let dInizioVisual= dataI.substr(0,19);
        let dFineVisual= dataF.substr(0,19);

        if(dFineVisual>dataFinePeriodo){
            if(dataFinePeriodoTot && dFineVisual>dataFinePeriodoTot){
//console.log("sono uscito dal range in avanti!");
            //mi sono spostato oltre al periodo che avevo chiesto al backend per cui calcolo la fine del nuovo periodo:
            setStatoPagina(STATO_RICERCA_NUOVI_DATI);
            calcolaPeriodoAnalisiDisponibilitaUp(dataFinePeriodoTot,1);
            }
        } 
        if(dInizioVisual<dataInizioPeriodo){
            if(dataInizioPeriodoTot && dInizioVisual<dataInizioPeriodoTot){
//console.log("sono uscito dal range in dietro!"+dInizioVisual+" "+dataInizioPeriodoTot);
            //mi sono spostato oltre al periodo che avevo chiesto al backend per cui calcolo l'inizio del nuovo periodo:
            setStatoPagina(STATO_RICERCA_NUOVI_DATI);
            calcolaPeriodoAnalisiDisponibilitaDown(dataInizioPeriodoTot,1);
            }
        } 
    }

    function calcolaPeriodoAnalisiDisponibilitaUp(dataPartenza,partenzaGiornoSuccessivo=0) {
 //console.log("UP-> partendo da "+dataPartenza);       
        //calcolo la data fine periodo:
        let mesiDurataPeriodo = 12;//periodo sul quale viene eseguita la richiesta di disponibilità 

        let annoDataPartenza = (dataPartenza.substr(0, 4));
        let meseDataPartenza = (dataPartenza.substr(5, 2));

        let meseArrivo = parseInt(meseDataPartenza) + parseInt(mesiDurataPeriodo) +1;//arriverò al primo giorno del mese successivo al mese indicato in mesiDurataPeriodo
        let annoArrivo = parseInt(annoDataPartenza);
        //casi di cambio anno:
        if(parseInt(meseArrivo) > 12){
            meseArrivo = parseInt(meseArrivo) - 12;
            annoArrivo = parseInt(annoArrivo) + 1;
        }
        //per cui èsetto la nuova la data di fine periodo da considerare:
        if(parseInt(meseArrivo)<10) meseArrivo="0"+parseInt(meseArrivo);
        setDataFinePeriodo(annoArrivo+'-'+meseArrivo+'-01T'+"00:00:00");//arrivo al primo giorno del mese successivo 
        if(partenzaGiornoSuccessivo===1){
            setDataInizioPeriodo(annoDataPartenza+'-'+meseDataPartenza+'-02T'+"00:00:00");
//console.log("UP 1_New "+annoDataPartenza+'-'+meseDataPartenza+'-02T'+"00:00:00");
        }else{
            setDataInizioPeriodo(dataPartenza);
//console.log("UP 2_New "+dataPartenza);
        }
    }
    function calcolaPeriodoAnalisiDisponibilitaDown(dataArrivo,arrivoGiornoPrecedente=0) {
//console.log("DOWN-> partendo da "+dataArrivo);       
        //calcolo la data fine periodo:
        let mesiDurataPeriodo = 12;//periodo sul quale viene eseguita la richiesta di disponibilità 

        let annoDataArrivo = (dataArrivo.substr(0, 4));
        let meseDataArrivo = (dataArrivo.substr(5, 2));
        let giornoDataArrivo = (dataArrivo.substr(8, 2));
        let giornoDataArrivoMenoUno = parseInt(giornoDataArrivo) - 1;


        let mesePartenza = parseInt(meseDataArrivo) - parseInt(mesiDurataPeriodo);//arriverò al primo giorno del mese successivo al mese indicato in mesiDurataPeriodo
        let annoPartenza = parseInt(annoDataArrivo);
        //casi di cambio anno:
        //console.log("il mese partenza mi dà:"+mesePartenza);
//console.log("arri"+mesePartenza);
        if(parseInt(mesePartenza) <1){
            mesePartenza = 12 + parseInt(mesePartenza);
            annoPartenza = parseInt(annoPartenza) - 1;
        }

        //per cui èsetto la nuova la data di fine periodo da considerare:
        if(parseInt(mesePartenza)<10) mesePartenza="0"+parseInt(mesePartenza);
        setDataInizioPeriodo(annoPartenza+'-'+mesePartenza+'-01T'+"00:00:00");
//console.log("DOWN setto inizio "+annoPartenza+'-'+mesePartenza+'-01T'+"00:00:00");
   
        if(giornoDataArrivoMenoUno<1){
            //ricalcolo:
            var nuovaData = new Date(annoDataArrivo,meseDataArrivo-1,giornoDataArrivoMenoUno);

            let sMeseNuovaData = String(nuovaData.getMonth()+1);
            if(sMeseNuovaData.length === 1) sMeseNuovaData = "0"+sMeseNuovaData;
   
            let sGiornoNuovaData = String(nuovaData.getDate());
            if(sGiornoNuovaData.length === 1) sGiornoNuovaData = "0"+sGiornoNuovaData;

            annoDataArrivo = nuovaData.getFullYear();
            meseDataArrivo = sMeseNuovaData;
            giornoDataArrivoMenoUno= sGiornoNuovaData;
        }
        if(arrivoGiornoPrecedente===1){
            setDataFinePeriodo(annoDataArrivo+'-'+meseDataArrivo+'-'+giornoDataArrivoMenoUno+'-T'+"00:00:00");
//console.log("DOWN 1_New "+annoDataArrivo+'-'+meseDataArrivo+'-'+giornoDataArrivoMenoUno+'-T'+"00:00:00");
        }else{
            setDataFinePeriodo(dataArrivo);
//console.log("DOWN 2_New "+dataArrivo);
        }
        
    }

    function fetchOperazioneServer(operazione,bypassaControlli=0) {
        let controlli = 0;
        let intervallo_max_timestamp=1827*86400;//365*5=1825  poi aggiungo +2 per bisestili -> 1827 (nota stesso valore è nel backend)

        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            //controllo correttezza date
            if(dataInizio.current.value>dataFine.current.value){
                controlli = 1;
                setFlagModaleDateErrate(true);
            }
            //console.log("inizio in timestamp"+Date.parse(dataInizio.current.value)/1000);
            //console.log("fine in timestamp"+Date.parse(dataFine.current.value)/1000);
            let differenza = Date.parse(dataFine.current.value)/1000 - Date.parse(dataInizio.current.value)/1000;
            //console.log("differenza"+differenza);
            //console.log("intervallo_max_timestamp"+intervallo_max_timestamp);

            if(differenza>intervallo_max_timestamp){
                //console.log("BLOCCO");
                controlli = 1;
                setFlagModaleIntervalloTroppoElevato(true);
            }
            if(descrizione.current.value === ''){
                controlli = 1;
                setFlagModaleDescrizioneNonValorizzata(true);
            }
            if(dataInizio.current.value === ''){
                controlli = 1;
                setFlagModaleDataInizioNonValorizzata(true);
            }
            if(dataFine.current.value === ''){
                controlli = 1;
                setFlagModaleDataFineNonValorizzata(true);
            }
            if(insUnitaTempoEdi === ''){
                controlli = 1;
                setFlagModaleUnitaTempoNonValorizzata(true);
            }
        }
        if(controlli===0){ 
            setModaleConfermaCanc(false);
            setFlagAttivaModaleCancellazione(false);
            setModaleConfermaCancUnoSolo(false);
            setFlagAttivaModaleCancellazioneUnoSolo(false);
            setFlagModaleNumeroAdempimentiElevato(false);
            setFlagModaleIntervalloTroppoElevato(false);
            setFlagModaleDateErrate(false);
            setFlagModaleDescrizioneNonValorizzata(false);
            setFlagModaleDataInizioNonValorizzata(false);
            setFlagModaleDataFineNonValorizzata(false);
            setFlagModaleUnitaTempoNonValorizzata(false);
            setFlagModaleUnitaTempoErrata(false);
            //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
            
            if(operazione==='modifica'){
                //in realtà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaVisioneCalendario();
            }else{
                // parametri per inviare i dati al server:
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: operazione!=='cancella' ?  
                        JSON.stringify({op:operazione,
                        descrizione:descrizione.current.value,
                        dataInizio:dataInizio.current.value,
                        dataFine:dataFine.current.value,
                        unitaTempo:insUnitaTempoEdi,
                        periodicita:periodicita.current.value,
                        idElemento:idAdempimento,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:idAdempimento,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
                

                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione===1){
                            //torno al calendario con i dati aggiornati all'ultima modifica:
                            ricercaAdempimenti("sostituisci",1);//questa eventualmente rimetterà la pagina in STATO_RICERCA_DATI_CORRETTA
                            setIsOpen(false);
                            setFlagAttivaModaleInserisciModifica(false);
                            setVariazioneCampi(0);
                            setInserisciNuovo(0);
                            setDataPartenza();
                            setIdAdempimento(0);
                            setOperazione("");
                            //setOrarioInizio("");
                            //setOrarioFine("");

                            //setStatoPagina(STATO_RICERCA_DATI_CORRETTA); 
                        }
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){ 
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Numero di adempimenti troppo elevato") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagModaleNumeroAdempimentiElevato(true);
                                }
                                if(valoreDiRitorno.mess.indexOf("Intervallo troppo elevato") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagModaleIntervalloTroppoElevato(true);
                                }
                                if(valoreDiRitorno.mess.indexOf("Date errate") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagModaleDateErrate(true);
                                }
                                if(valoreDiRitorno.mess.indexOf("Descrizione non valorizzata") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagModaleDescrizioneNonValorizzata(true);
                                }
                                if(valoreDiRitorno.mess.indexOf("Data prima esecuzione non valorizzata") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagModaleDataInizioNonValorizzata(true);
                                }
                                if(valoreDiRitorno.mess.indexOf("Data ultima esecuzione non valorizzata") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagModaleDataFineNonValorizzata(true);
                                }
                                if(valoreDiRitorno.mess.indexOf("Unita di tempo non valorizzata") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagModaleUnitaTempoNonValorizzata(true);
                                }
                                if(valoreDiRitorno.mess.indexOf("Unita di tempo errata") !== -1){
                                    setInsUnitaTempoEdi();
                                    setDefaultUnitaTempo();
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagModaleUnitaTempoErrata(true);
                                }
                            }
                            //altrimenti vado in errore generico
                            if(!valoreDiRitorno.mess) setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    function chiudiModaleConfermaCanc(){
        setModaleConfermaCanc(false);
        setFlagAttivaModaleCancellazione(false);
    }

    function chiudiModaleConfermaCancUnoSolo(){
        setModaleConfermaCancUnoSolo(false);
        setFlagAttivaModaleCancellazioneUnoSolo(false);
    }

    

    return(
        <Fragment>
 
        
        <div className="app-main">
            {/*<div>
                <AppSidebar/>
    </div>*/}

            {statoPagina === STATO_MEMORIZZAZIONE ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
            {statoPagina === STATO_ERRORE_CONNESSIONE ? <div className="app-main__outer">Errore: 1Scad {error.message}</div> :""}
            {statoPagina === STATO_OPERAZIONE_FALLITA ? <div className="app-main__outer">Errore per operazione errata</div> :""}
     
            {statoPagina === STATO_RICERCA_DATI_CORRETTA || statoPagina === STATO_RICERCA_NUOVI_DATI ?

            <div className="app-main__outer">
                
                <Card className="main-card mb-3"> 
                            <CardBody>
                                
                                <Clessidra loading={loading}/>   
                                <CardTitle>Scadenziario Adempimenti</CardTitle>
                                <div style={{color: coloreEseguito }}> Adempimenti Eseguiti</div>
                                <div style={{color: coloreDaEseguire }}> Adempimenti da Eseguire</div>

                                {/*
                                <CardTitle>data inizio Periodo {dataInizioPeriodo} data fine Periodo {dataFinePeriodo}</CardTitle>
                                <CardTitle>dataInizioPeriodoTot {dataInizioPeriodoTot} dataFinePeriodoTot {dataFinePeriodoTot}</CardTitle>
            <CardTitle>gli adempimenti sono {adempimentiFullcalendar.length}</CardTitle>*/}
                                
                                <Row form>
                                    <Col md={12}>
                                        <FullCalendar
                                           customButtons={{
                                            custom1: {
                                              text: 'Oggi',
                                              click: function() {
                                                vaiDataOggi();
                                              },
                                            },
                                            custom2: {
                                                text: 'Attendere...',
                                            },
                                          }}
                                            ref ={calendarRef}
                                            height={500}//determina l'altezza della pagine
                                            initialDate={dataSelezionata}
                    
                                            //firstDay={3}//se metti 0 parte da domenica, 1 da lunedì ecc.. nella vista week
                                           // weekNumbers={true}//ti indica nel giorno il numero della settimana
                                           scrollTimeReset={false}//serve per evitare che vada alle 6:00:00 ad ogni click
                                           //slotDuration = '00:20:00'//serve a determinare il campionamento: il default è 30 minuti
                                           
                                            plugins={[ dayGridPlugin, interactionPlugin ,timeGridPlugin ]}
                                            validRange= {{
                                                start: dataInizioRange,
                                                //start:'2021-05-01',
                                                end: dataFineRange,
                                            }}//escursione massima permessa di visibilità del calendario: oggi +- 10 anni
                                            
                                            //https://fullcalendar.io/docs/datesSet dice:
                                            //datesSet is called after the new date range has been rendered. 
                                            //However, you should avoid relying on this callback to manipulate rendered dates, 
                                            //because some dates might still be in view from the previous render, 
                                            //and you don’t want to “double render” them. 
                                            //It’s much better to rely on hooks that manipulate the rendering of individual dates when they change. 
                                            //See the day header, day cell, or slot render hooks.

                                            /*datesSet={ (data => {
                                                //console.log("dates set: data.endStr="+data.endStr+ " dataSelezionata="+dataSelezionata); 
                                                console.log("dates set-> dataSelezionata="+dataSelezionata); 
                                                console.log("dates set-> chiamo evidenziaDataSelezionata"); 
                                                evidenziaDataSelezionata();
                                                verificaPeriodoVisualizzazione(data.endStr);})
                                            }*/

                                            //https://fullcalendar.io/docs/datesSet
                                            datesSet={ (data => {
                                                //console.log("dates set-> data selezionata "+dataSelezionata);
                                                //settaDataPartenza();
                                                //settaDataGiorno(data.start);
                                                evidenziaDataSelezionata();//quando scommento questo -> funge l'evidenziare la selezione ma non funza più  eventClick
                                                verificaPeriodoVisualizzazione(data.startStr,data.endStr);})
                                                
                                            }

                                        
                                            allDaySlot={false} //togli la cella iniziale che individua tutto il giorno

                                            //disegno la toolbar:
                                            
                                            headerToolbar={{
                                              start: 'title', // will normally be on the left. if RTL, will be on the right
                                              center: '',//
                                              //center: 'today,timeGridWeek,timeGridDay',
                                              //end: 'custom1,prev,next', // will normally be on the right. if RTL, will be on the left
                                              end: bottoniNavigazione, // will normally be on the right. if RTL, will be on the left
                                            }}
                                            //a seconda di che vista ho scelto tramite bottoni la propongo:
                                            initialView='dayGridMonth'//ti parte facendoti vedere la settimana e le ore a sx se metti ad es 'timeGridWeek'
                                            locale={itLocale} //serve per lingua italiana
                                            editable={false}//permette di trascinare l'evento se true
                                            eventOverlap={true} //true-> eventi sovrapponibili, false -> eventi non sovrapponibili
                                            selectable={false}//rende il giorno selezionabile
                                            dragScroll={true}//rende l'evento trascinabile
                                            eventMinHeight="20"//altezza minima di ogni evento (default = 15)
                                            weekends={true}//inserisce anche i weekends nel calendario
                                            displayEventEnd={false}//se true fà vedere l'ora di fine evento
                                            events={adempimentiFullcalendar}//inserisco i dati degli adempimenti attualmente già presenti
                                            
                                            eventClick={modificaEvento}//gestisce il click sull'evento (per modifica ad esempio)
                                            //dateClick={inserisciEvento}
                                            selectOverlap={true}//di default è cmq true
                                            //selectMinDistance="1" // seguendo quanto detto in https://github.com/fullcalendar/fullcalendar/issues/4590 ma non funza
                                            //unselectAuto={true}

                                            //eventClick={modificaEvento}//gestisce il click sull'evento (per modifica ad esempio)
                                            
                                            //eventClick={ (data => {modificaEvento(data);})}
                                            //dayClick={inserisciEvento}//gestisce il click sull'evento (per modifica ad esempio)
                                            
                                            //dayClick={evidenziaDataSelezionata()}
                                            //dayClick={ (data => {
                                            //    controllaClickSuEventoBackground(jsEvent);})
                                            //}

                                            displayEventTime={false}//se false -> toglie la visione di inizio e fine eventi (orari nascosti)
                                            dateClick={settaDataPartenza}

                                            slotLabelFormat={[
                                                {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12:false
                                                }
                                                ]}

                                            //slotDuration='24:00'

                                            //dateClick={ (data => {settaDataPartenza;evidenziaDataSelezionata();})}

                                            /*dateClick={ (data => {
                                                //console.log("dates set: data.endStr="+data.endStr+ " dataSelezionata="+dataSelezionata); 
                                                console.log("date click-> dataSelezionata="+dataSelezionata); 
                                                console.log("date click-> chiamo evidenziaDataSelezionata"); 
                                                settaDataPartenza;evidenziaDataSelezionata();})//perchè non fà settaDataPartenza?
                                            }*/
                                        />

                                        {flagAttivaModaleInserisciModifica === true ?
                                        <Modale 
                                                titolo= {operazione === "modifica"? "Modifica adempimento" : "Inserisci adempimento"}
                                                larghezza = "xl"
                                                flagErrore={false}
                                                contenuto={<div>
                                                <FormGroup>
                                                Descrizione*
                                                <Input maxLength={200} type="text" name="descrizione" id="descrizione"
                                                            innerRef={descrizione} 
                                                            placeholder=""
                                                            defaultValue={descrizioneDefault}
                                                        onInput={(e)=>{setDescrizioneDefault(descrizione.current.value)}}/> 
                                                </FormGroup><FormGroup>
                                                Data Prima Esecuzione*
                                                <Input maxLength={10} type="date" name="dataInizio" id="dataInizio"
                                                            innerRef={dataInizio} 
                                                            placeholder=""
                                                            defaultValue={dataInizioDefault}
                                                            onInput={(e)=>{setDataInizioDefault(dataInizio.current.value)}}/> 
                                                </FormGroup><FormGroup>
                                                Data Ultima Esecuzione*
                                                <Input maxLength={10} type="date" name="dataFine" id="dataFine"
                                                            innerRef={dataFine} 
                                                            placeholder=""
                                                            defaultValue={dataFineDefault}
                                                            onInput={(e)=>{setDataFineDefault(dataFine.current.value)}}/> 
                                                </FormGroup>
                                                <Row><Col>Ripeti ogni 
                                                <Input maxLength={10} type="text" name="periodicita" id="periodicita"
                                                            innerRef={periodicita} 
                                                            placeholder=""
                                                            defaultValue={periodicitaDefault}
                                                        onInput={(e)=>{setPeriodicitaDefault(periodicita.current.value)}}/> 
                                                </Col><Col>
                                                Unità di tempo*
                                                <TendinaEditabile
                                                            //titolo={"Unità di tempo*"}
                                                            nomeCampo="DESCRIZIONE_UNITA_TEMPO"
                                                            valoreDefault={defaultUnitaTempo}
                                                            arrayValori={risultatoServerDatiUnitaTempo}
                                                            bloccoInserimento = "1"
                                                            callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                                            callbackSetValoreTendEditabile={callbackSetValoreTendEditabileUnitaTempo}
                                                ></TendinaEditabile>
                                                </Col></Row>
                                                </div>}
                                                bottoni={
                                                    operazione === "inserisci"?
                                                    [
                                                        {
                                                            "etichetta":"Inserisci",
                                                            "tipo":"success",
                                                            callback:() => {fetchOperazioneServer("adempimentiIns")}
                                                        },  
                                                        {
                                                            "etichetta":"Torna al calendario",
                                                            "tipo":"primary",
                                                            callback:() => {tornaVisioneCalendario()}
                                                        },  
                                                    ]
                                                    :
                                                        statoDefault === '0'?
                                                        [
                                                            {
                                                                "etichetta":"Eseguito e Cancella",
                                                                "tipo":"success",
                                                                callback:() => {openModalCancellazioneUnoSolo()}
                                                            },
                                                            {
                                                                "etichetta":"Eseguito e Conserva",
                                                                "tipo":"success",
                                                                callback:() => {fetchOperazioneServer("adempimentiMod2")}
                                                            },
                                                            {
                                                                "etichetta":"Memorizza Modifiche",
                                                                "tipo":"warning",
                                                                callback:() => {fetchOperazioneServer("adempimentiMod")}
                                                            },  
                                                            {
                                                                "etichetta":"Torna al calendario",
                                                                "tipo":"primary",
                                                                callback:() => {tornaVisioneCalendario()}
                                                            },  
                                                            {
                                                                "etichetta":"Elimina adempimento e tutti quelli ad esso legati",
                                                                "tipo":"danger",
                                                                callback:() => {openModalCancellazione()}
                                                            }, 
                                                        ]
                                                        :
                                                        [
                                                        {
                                                                "etichetta":"Non Eseguito",
                                                                "tipo":"success",
                                                                callback:() => {fetchOperazioneServer("adempimentiMod3")}
                                                        },
                                                        {
                                                            "etichetta":"Memorizza Modifiche",
                                                            "tipo":"warning",
                                                            callback:() => {fetchOperazioneServer("adempimentiMod")}
                                                        },  
                                                        {
                                                            "etichetta":"Torna al calendario",
                                                            "tipo":"primary",
                                                            callback:() => {tornaVisioneCalendario()}
                                                        }, 
                                                        {
                                                            "etichetta":"Elimina adempimento e tutti quelli ad esso legati",
                                                            "tipo":"danger",
                                                            callback:() => {openModalCancellazione()}
                                                        },  
                                                        ]
                                                    
                                                }
                                            />
                                        :""}

                                        {/*{flagAttivaModaleInserisciModifica === true && operazione === "cancella"?
                                        <Modale 
                                                titolo="Cancella adempimento"
                                                flagErrore={false}
                                                contenuto={<div>
                                                Descrizione*: {descrizioneDefault}
                                                Data Prima Esecuzione*: <TrasformaDateAUmano data={dataInizioDefault}></TrasformaDateAUmano>
                                                Data Ultima Esecuzione*: <TrasformaDateAUmano data={dataFineDefault}></TrasformaDateAUmano>
                                                Ripeti ogni: {periodicitaDefault}
                                                Unità di tempo {insUnitaTempoEdi}
                                                    
                                                </div>}
                                                bottoni={[
                                                    {
                                                        "etichetta":"OK",
                                                        "tipo":"primary",
                                                        callback:() => {fetchOperazioneServer("adempimentiCanc")}
                                                    },  
                                                    {
                                                        "etichetta":"Annulla",
                                                        "tipo":"primary",
                                                        callback:() => {chiudiModaleConfermaCanc()}
                                                    },  
                                                ]}
                                            />
                                            :""}*/}

{/*
                                        {flagAttivaModaleInserisciModifica === true ?
                                        <Modal
                                            isOpen={modalIsOpen}
                                            onRequestClose={closeModal}
                                            contentLabel="Modale di inserimento o modifica adempimento"
                                            size="lg" style={{maxWidth: '700px', width: '100%'}}
                                        >
                                        <Card className="main-card mb-3"> 
                                            <CardBody>
                                            {operazione === "modifica" ? <CardTitle>Modifica Adempimento del <TrasformaDateAUmano data={dataAdempimentoDefault}></TrasformaDateAUmano>:</CardTitle> :""}
                                            {operazione === "inserisci" ? <CardTitle>Inserisci Adempimento:</CardTitle> :""}
                                        <Form>
                                            <Row form>  
                                                <Col md={12}>
                                                    <FormGroup>
                                                    <Label for="descrizione">Descrizione*</Label>
                                                        {operazione === "inserisci" || operazione === "modifica" ?
                                                            <Input maxLength={200} type="text" name="descrizione" id="descrizione"
                                                            innerRef={descrizione} 
                                                            placeholder=""
                                                            defaultValue={descrizioneDefault}
                                                        onInput={(e)=>{setDescrizioneDefault(descrizione.current.value)}}/> 
                                                        :""}
                                                        {operazione === "cancella" ? <Label>: {descrizioneDefault}</Label> : ""}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row form>  
                                            <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="dataInizio">Data Prima Esecuzione*</Label>
                                                        {operazione === "inserisci" || operazione === "modifica" ?
                                                        <Input maxLength={10} type="date" name="dataInizio" id="dataInizio"
                                                            innerRef={dataInizio} 
                                                            placeholder=""
                                                            defaultValue={dataInizioDefault}
                                                            onInput={(e)=>{setDataInizioDefault(dataInizio.current.value)}}/> 
                                                        :""}
                                                        {operazione === "cancella" ? <Label>: <TrasformaDateAUmano data={dataInizioDefault}></TrasformaDateAUmano></Label>  : ""}
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="dataFine">Data Ultima Esecuzione*</Label>
                                                        {operazione === "inserisci" || operazione === "modifica" ?
                                                        <Input maxLength={10} type="date" name="dataFine" id="dataFine"
                                                            innerRef={dataFine} 
                                                            placeholder=""
                                                            defaultValue={dataFineDefault}
                                                            onInput={(e)=>{setDataFineDefault(dataFine.current.value)}}/> 
                                                        :""}

                                                        {operazione === "cancella" ? <Label>: <TrasformaDateAUmano data={dataFineDefault}></TrasformaDateAUmano></Label>  : ""}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row form>  
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label for="periodicita">Ripeti ogni 
                                                        {operazione === "inserisci" || operazione === "modifica" ?
                                                            <Input maxLength={10} type="text" name="periodicita" id="periodicita"
                                                            innerRef={periodicita} 
                                                            placeholder=""
                                                            defaultValue={periodicitaDefault}
                                                        onInput={(e)=>{setPeriodicitaDefault(periodicita.current.value)}}/> 
                                                        :""}</Label>
                                                        {operazione === "cancella" ? <Label>: {periodicitaDefault}</Label> : ""}
                                                    </FormGroup>
                                                </Col>
                                                <Col md={9}>
                                                    <FormGroup>
                                                        {operazione === "inserisci" || operazione === "modifica" ?
                                                            <TendinaEditabile
                                                            titolo={"Unità di tempo*"}
                                                            nomeCampo="DESCRIZIONE_UNITA_TEMPO"
                                                            valoreDefault={defaultUnitaTempo}
                                                            arrayValori={risultatoServerDatiUnitaTempo}
                                                            bloccoInserimento = "1"
                                                            callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                                            callbackSetValoreTendEditabile={callbackSetValoreTendEditabileUnitaTempo}
                                                        ></TendinaEditabile>
                                                        :""}
                                                        {operazione === "cancella" ? <Label>Unità di tempo {insUnitaTempoEdi}</Label> : ""}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            
                                        <Table responsive>
                                        <tr>  
                                        {operazione === "inserisci"  ? <th><center><Button color="success" className="mt-2" 
                                        onClick={() => fetchOperazioneServer("adempimentiIns")}>Inserisci</Button></center></th> :""}

                                        {operazione === "modifica"  ? <th><center><Button color="warning" className="mt-2" 
                                        onClick={() => fetchOperazioneServer("adempimentiMod")}>Memorizza Modifiche</Button></center></th>:""}

                                        {operazione === "modifica" && statoDefault === 0 ? <th><center><Button color="warning" className="mt-2" 
                                        onClick={() => openModalCancellazioneUnoSolo()}>Eseguito e Cancella</Button></center></th>:""}

                                        {operazione === "modifica" && statoDefault === 0 ? <th><center><Button color="warning" className="mt-2" 
                                        onClick={() => fetchOperazioneServer("adempimentiMod2")}>Eseguito e Conserva</Button></center></th>:""}

                                        {operazione === "modifica" && statoDefault === 1 ? <th><center><Button color="warning" className="mt-2" 
                                        onClick={() => fetchOperazioneServer("adempimentiMod3")}>Non Eseguito</Button></center></th>:""}

                                        {operazione === "modifica" ? 
                                        <th><center><Button color="danger" className="mt-2" onClick={() => openModalCancellazione()} >
                                        <center>Elimina adempimento e tutti quelli ad esso legati</center>
                                        </Button></center></th> 
                                        :"" }

                                        <th><center><Button color="primary" className="mt-2" onClick={() => tornaVisioneCalendario()} >
                                        <center>Annulla</center>
                                        </Button></center></th>   
                                        
                                        
                                        
                                        </tr>

                                        </Table>
                                        </Form> 
                                        </CardBody>
                                        </Card>
                                        </Modal>
                                        :""}
                                        */}
                                        {flagAttivaModaleCancellazione === true ?
                                            <Modale 
                                                titolo="Conferma Cancellazione"
                                                flagErrore={true}
                                                contenuto={<div>Vuoi davvero cancellare gli adempimenti selezionati?</div>}
                                                bottoni={[
                                                    {
                                                        "etichetta":"OK",
                                                        "tipo":"primary",
                                                        callback:() => {fetchOperazioneServer("adempimentiCanc")}
                                                    },  
                                                    {
                                                        "etichetta":"Annulla",
                                                        "tipo":"primary",
                                                        callback:() => {chiudiModaleConfermaCanc()}
                                                    },  
                                                ]}
                                            />
                                        :""}

                                        {flagAttivaModaleCancellazioneUnoSolo === true ?
                                            <Modale 
                                                titolo="Conferma Cancellazione un solo adempimento"
                                                flagErrore={true}
                                                contenuto={<div>Vuoi davvero cancellare l'adempimento selezionato?</div>}
                                                bottoni={[
                                                    {
                                                        "etichetta":"OK",
                                                        "tipo":"primary",
                                                        callback:() => {fetchOperazioneServer("adempimentiMod1")}
                                                    },  
                                                    {
                                                        "etichetta":"Annulla",
                                                        "tipo":"primary",
                                                        callback:() => {chiudiModaleConfermaCancUnoSolo()}
                                                    },  
                                                ]}
                                            />
                                        :""}

                                        {flagModaleNumeroAdempimentiElevato === true ?
                                            <Modale 
                                                titolo="Informativa Numero Adempimenti Elevato"
                                                flagErrore={true}
                                                contenuto={<div>Il numero di adempimenti è troppo elevato. Ridurre l'intervallo tra la data di prima esecuzione ed ultima esecuzione oppure diminuirne la periodicità</div>}
                                                bottoni={[ 
                                                    {
                                                        "etichetta":"Ok",
                                                        "tipo":"primary",
                                                        callback:() => {chiudiModaleNumeroAdempimentiElevato()}
                                                    },  
                                                ]}
                                            />
                                        :""}

                                        {flagModaleIntervalloTroppoElevato === true ?
                                            <Modale 
                                                titolo="Informativa intervallo troppo elevato"
                                                flagErrore={true}
                                                contenuto={<div>L'intervallo tra data di prima ed ultima esecuzione è troppo elevato (massimo accettato: 5 anni)</div>}
                                                bottoni={[ 
                                                    {
                                                        "etichetta":"Ok",
                                                        "tipo":"primary",
                                                        callback:() => {chiudiModaleIntervalloTroppoElevato()}
                                                    },  
                                                ]}
                                            />
                                        :""}

                                        {flagModaleDateErrate === true ?
                                            <Modale 
                                                titolo="Informativa date errate"
                                                flagErrore={true}
                                                contenuto={<div>La data di ultima esecuzione è precedente la data di prima esecuzione</div>}
                                                bottoni={[ 
                                                    {
                                                        "etichetta":"Ok",
                                                        "tipo":"primary",
                                                        callback:() => {chiudiModaleDateErrate()}
                                                    },  
                                                ]}
                                            />
                                        :""}

                                        {flagModaleUnitaTempoNonValorizzata === true ?
                                            <Modale 
                                                titolo="Informativa unità di tempo non valorizzata"
                                                flagErrore={true}
                                                contenuto={<div>Non è stata indicata l'unità di tempo che è necessaria</div>}
                                                bottoni={[ 
                                                    {
                                                        "etichetta":"Ok",
                                                        "tipo":"primary",
                                                        callback:() => {chiudiModaleUnitaTempoNonValorizzata()}
                                                    },  
                                                ]}
                                            />
                                        :""}

                                        {flagModaleUnitaTempoErrata === true ?
                                            <Modale 
                                                titolo="Informativa unità di tempo errata"
                                                flagErrore={true}
                                                contenuto={<div>L'unità di tempo non è stata valorizzata in modo corretto: scegliere uno dei valori proposti in tendina</div>}
                                                bottoni={[ 
                                                    {
                                                        "etichetta":"Ok",
                                                        "tipo":"primary",
                                                        callback:() => {chiudiModaleUnitaTempoNonValorizzata()}
                                                    },  
                                                ]}
                                            />
                                        :""}

                                        {flagModaleDescrizioneNonValorizzata === true ?
                                            <Modale 
                                                titolo="Informativa descrizione non valorizzata"
                                                flagErrore={true}
                                                contenuto={<div>Non è stata indicata la descrizione, che è necessaria</div>}
                                                bottoni={[ 
                                                    {
                                                        "etichetta":"Ok",
                                                        "tipo":"primary",
                                                        callback:() => {chiudiModaleDescrizioneNonValorizzata()}
                                                    },  
                                                ]}
                                            />
                                        :""}

                                        {flagModaleDataInizioNonValorizzata === true ?
                                            <Modale 
                                                titolo="Informativa data prima esecuzione non valorizzata"
                                                flagErrore={true}
                                                contenuto={<div>Non è stata indicata la data prima esecuzione, che è necessaria</div>}
                                                bottoni={[ 
                                                    {
                                                        "etichetta":"Ok",
                                                        "tipo":"primary",
                                                        callback:() => {chiudiModaleDataInizioNonValorizzata()}
                                                    },  
                                                ]}
                                            />
                                        :""}

                                        {flagModaleDataFineNonValorizzata === true ?
                                            <Modale 
                                                titolo="Informativa data ultima esecuzione non valorizzata"
                                                flagErrore={true}
                                                contenuto={<div>Non è stata indicata la data ultima esecuzione, che è necessaria</div>}
                                                bottoni={[ 
                                                    {
                                                        "etichetta":"Ok",
                                                        "tipo":"primary",
                                                        callback:() => {chiudiModaleDataFineNonValorizzata()}
                                                    },  
                                                ]}
                                            />
                                        :""}

                                        </Col>
                                    </Row> 
                                               
                            </CardBody>
                        </Card>
                {/*<AppFooter/>*/}
            </div>
            :""}
        </div>
    </Fragment>
    );
};

export default TablesScadenziario;