/* FinestraDialogo.js 1.0 */

import React, {useState} from 'react';
import { Button, Card, CardBody, CardTitle, Input } from 'reactstrap';

function FinestraDialogo(props) {  
    const [casellaConferma, setCasellaConferma] = useState(false);

    return (
        <>
        <div style={{flex:1}}></div>
        <div className="spazioFinestraDialogo">
            <div className="larghezzaFinestraDialogo" isOpen={true} contentLabel={props.titolo}>
                <CardTitle style={{backgroundColor:props.flagErrore ? "#D00000" : ""}}>
                    {props.titolo}
                </CardTitle>
                <CardBody style={{textAlign:'center', paddingLeft:40, paddingRight:40}}>            
                    {props.contenuto}
                    {props.testoCasellaConferma ? 
                        <div style={{marginTop:"1em"}}>
                            <Input type="checkbox" onInput={()=>{setCasellaConferma(!casellaConferma)}}/>
                            {props.testoCasellaConferma}
                        </div>
                    :
                        <></>
                    }
                    <div style={{marginTop:20}}>
                        {props.bottoni.map((bottone, indice) =>
                            <div key={indice} style={{display:"inline-block",marginLeft:10, marginRight:10}}>
                                <Button 
                                    disabled={bottone.necessariaCasellaConferma && !casellaConferma} 
                                    color={bottone.tipo} 
                                    onClick={bottone.callback}
                                >
                                    {bottone.etichetta}
                                </Button>                            
                            </div>
                        )}
                    </div>                
                </CardBody>                        
            </div>
        </div>
        <div style={{flex:1}}></div>
        </>
    )
}

export default FinestraDialogo;