
function FormattaNumeroDb(numero) {
  let numeroOutput='';
  //da formato 23.45689 a 23,46 quindi 2 cifre decimali e virgola in luogo del punto
  if(numero){
      numeroOutput = numero.replace(",", ".");
  }else numeroOutput = '';

  return numeroOutput;
}


export default FormattaNumeroDb;
