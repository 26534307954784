import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';

import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input, Modal
} from 'reactstrap';
import Parametri from '../../parametri';

import TendinaEditabile from '../TendinaEditabile.js';

//import html2canvas from "html2canvas";
//const pdfConverter = require("jspdf");

import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js'

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import Cestino from "../../immagini/cestino.png";
import FinestraDialogo from '../FinestraDialogo.js';

import Esercizio from '../EserciziSchedeAllenamento.js';


//nota: richiede installazione di : npm i html2canvas e npm i jspdf seguendo le indicazioni di:
//https://stackoverflow.com/questions/59737202/is-there-a-way-to-convert-react-chartjs-2-chart-to-pdf

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record

const STATO_SCARICA_PDF = 8;
const STATO_PREPARA_PDF = 9;

function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);
    //const [arrayEsercizi,setArrayEsercizi]=useState([]);

    const [flagAttivaModaleSchedaNonCancellabile, setFlagAttivaModaleSchedaNonCancellabile] = useState(0);
    const [flagAttivaModaleSchedaNonModificabile, setFlagAttivaModaleSchedaNonModificabile] = useState(0);
    const [flagAttivaModaleSchedaNonInseribile, setFlagAttivaModaleSchedaNonInseribile] = useState(0);
    const [flagAttivaModaleInserisciDescrizione, setFlagAttivaModaleInserisciDescrizione] = useState(0);

    const [arrayEserciziScheda,setArrayEserciziScheda]=useState([]);
    const [arraySerieScheda,setArraySerieScheda]=useState([]);
    const [arrayRipetizioniScheda,setArrayRipetizioniScheda]=useState([]);
    const [arrayRecuperoTraSerieScheda,setArrayRecuperoTraSerieScheda]=useState([]);
    const [arrayNoteEsercizioScheda,setArrayNoteEsercizioScheda]=useState([]);
    const [arrayClasseScheda,setArrayClasseScheda]=useState([]);
    const [arraySpiegazioneScheda,setArraySpiegazioneScheda]=useState([]);
    const [arrayFotoScheda,setArrayFotoScheda]=useState([]);
    const [typeFoto,setTypeFoto]=useState([]);
    const [arrayNomeFotoScheda,setArrayNomeFotoScheda]=useState([]);

    //const [arrayEserciziSchedaDefault,setArrayEserciziSchedaDefault]=useState([]);
    const [arrayClasseSchedaDefault,setArrayClasseSchedaDefault]=useState([]);

    const descrizione   = useRef();
    const note   = useRef();

    const serie   = useRef([]);
    const ripetizioni   = useRef([]);
    const recupero   = useRef([]);
    //const noteEse   = useRef({});
    const dataInizio   = useRef();
    const dataFine   = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    //const [statoTendinaEserciziDefault,setStatoTendinaEserciziDefault]=useState(false);
    const [statoTendinaEsercizi,setStatoTendinaEsercizi]=useState(false);
    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');
    const [risultatoServerDatiTuttiEsercizi,setRisultatoServerDatiTuttiEsercizi]=useState('');
    //const risultatoServerDatiTuttiEserciziLimitato=[];
    const [risultatoServerDatiTuttiEserciziLimitato,setRisultatoServerDatiTuttiEserciziLimitato]=useState([]);
    
    const [aggiungiEsercizio,setAggiungiEsercizio]=useState(0);

    //const [rivisualizza,setRivisualizza]=useState(0);
    
    const [descrizioneDefault,setDescrizioneDefault]=useState('');
    const [noteDefault,setNoteDefault]=useState('');
    const [dataInizioDefault,setDataInizioDefault]=useState('');
    const [dataFineDefault,setDataFineDefault]=useState('');

    //per le classi di esercizi:
    const [risultatoServerDatiTutteClassiEsercizi,setRisultatoServerDatiTutteClassiEsercizi]=useState('');
    //const [arrayClassiEsercizi,setArrayClassiEsercizi]=useState([]);
    const [statoTendinaClassiEsercizi,setStatoTendinaClassiEsercizi]=useState(false);             
      
    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        //setStatoTendinaEserciziDefault(false);
        setStatoTendinaEsercizi(false);
        setAggiungiEsercizio(0);
        scaricaLogo();
    },[]);

    useEffect(() => {
        if(statoTendinaEsercizi === true && statoTendinaClassiEsercizi === true && ricercaLogo===1){
            for (let i=0; i<arrayClasseScheda.length ; i++) {
                callbackSetValoreTendEditabileClassiEsercizi(arrayClasseScheda[i],i,0);   
            }
            if(props.stato==="preparaPdf"){
                setStatoPagina(STATO_PREPARA_PDF);
            }else{
                setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
            }
        }
    },[statoTendinaEsercizi,statoTendinaClassiEsercizi,ricercaLogo]);

    useEffect(() => {
        if(statoPagina === STATO_PREPARA_PDF){
            setStatoPagina(STATO_SCARICA_PDF);
        }
      },[statoPagina]);

    useEffect(() => {
        //if(aggiungiEsercizio==1){
            let ese = [...arrayEserciziScheda];//popolo l'array locale con i dati dell'array di stato
            ese.push("");//aggiungo un elemento (per ora vuoto) all'array locale
            setArrayEserciziScheda(ese);//aggiorno nuovamente la var di stato
            //console.log("useEffect su aggiungiEsercizio " + aggiungiEsercizio);

            //NE AGGIUNGO ANCHE LE CARATTERISTICHE:
            let serie = [...arraySerieScheda];//popolo l'array locale con i dati dell'array di stato
            serie.push("");//aggiungo un elemento (per ora vuoto) all'array locale
            setArraySerieScheda(serie);//aggiorno nuovamente la var di stato

            let rip = [...arrayRipetizioniScheda];//popolo l'array locale con i dati dell'array di stato
            rip.push("");//aggiungo un elemento (per ora vuoto) all'array locale
            setArrayRipetizioniScheda(rip);//aggiorno nuovamente la var di stato
         
            let rec = [...arrayRecuperoTraSerieScheda];//popolo l'array locale con i dati dell'array di stato
            rec.push("");//aggiungo un elemento (per ora vuoto) all'array locale
            setArrayRecuperoTraSerieScheda(rec);//aggiorno nuovamente la var di stato
        
            let note = [...arrayNoteEsercizioScheda];//popolo l'array locale con i dati dell'array di stato
            note.push("");//aggiungo un elemento (per ora vuoto) all'array locale
            setArrayNoteEsercizioScheda(note);//aggiorno nuovamente la var di stato

            let spi = [...arraySpiegazioneScheda];//popolo l'array locale con i dati dell'array di stato
            spi.push("");//aggiungo un elemento (per ora vuoto) all'array locale
            setArraySpiegazioneScheda(spi);

            let foto = [...arrayFotoScheda];//popolo l'array locale con i dati dell'array di stato
            foto.push("");//aggiungo un elemento (per ora vuoto) all'array locale
            setArrayFotoScheda(foto);

            let nFoto = [...arrayNomeFotoScheda];//popolo l'array locale con i dati dell'array di stato
            nFoto.push("");//aggiungo un elemento (per ora vuoto) all'array locale
            setArrayNomeFotoScheda(nFoto);

            let tFoto = [...typeFoto];//popolo l'array locale con i dati dell'array di stato
            tFoto.push("");//aggiungo un elemento (per ora vuoto) all'array locale
            setTypeFoto(tFoto);
         
            let cla = [...arrayClasseScheda];//popolo l'array locale con i dati dell'array di stato
            cla.push("");//aggiungo un elemento (per ora vuoto) all'array locale
            setArrayClasseScheda(cla);

        //}
    },[aggiungiEsercizio]);

    /*useEffect(() => {
        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[rivisualizza])*/




    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("schedaAllenamentoIns");
        if(props.ope==='modifica') setOperazione("schedaAllenamentoMod");
        if(props.ope==='cancella') setOperazione("schedaAllenamentoCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"elencoEserciziScheda",
                    id:props.id,
                    visioneFoto:1,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){

                    
                    setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                    setNoteDefault(valoreDiRitorno.risultatoRitorno[0].NOTE_SCHEDA);
                    setDataInizioDefault(valoreDiRitorno.risultatoRitorno[0].DATA_INIZIO);
                    setDataFineDefault(valoreDiRitorno.risultatoRitorno[0].DATA_FINE);

                    let arrLocaleEsercizi=[];
                    let arrLocaleSerie=[];
                    let arrLocaleRipetizioni=[];
                    let arrLocaleRecupero=[];
                    let arrLocaleNote=[];
                    let arrLocaleClasse=[];
                    let arrLocaleSpiegazione=[];
                    let arrLocaleFoto=[];
                    let arrLocaleNomeFoto=[];
                    let arrTypeFoto=[];

                    for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                        arrLocaleEsercizi[i]=valoreDiRitorno.risultatoRitorno[i].ESERCIZIO;
                        arrLocaleSerie[i]=valoreDiRitorno.risultatoRitorno[i].SERIE;
                        arrLocaleRipetizioni[i]=valoreDiRitorno.risultatoRitorno[i].RIPETIZIONI;
                        arrLocaleRecupero[i]=valoreDiRitorno.risultatoRitorno[i].RECUPERO_TRA_SERIE;
                        arrLocaleNote[i]=valoreDiRitorno.risultatoRitorno[i].NOTE_ESERCIZIO;
                        arrLocaleClasse[i]=valoreDiRitorno.risultatoRitorno[i].CLASSE;
                        arrLocaleSpiegazione[i]=valoreDiRitorno.risultatoRitorno[i].SPIEGAZIONE;
                        arrLocaleFoto[i]=valoreDiRitorno.risultatoRitorno[i].FILE;
                        arrLocaleNomeFoto[i]=valoreDiRitorno.risultatoRitorno[i].FOTO;
                        arrTypeFoto[i]=valoreDiRitorno.risultatoRitorno[i].TYPE;
                        //console.log("pos"+i+"ese"+valoreDiRitorno.risultatoRitorno[i].ESERCIZIO)
                    }
                    if (valoreDiRitorno.risultatoRitorno.length === 0) {
                        //console.log("Nessun esercizio, ne aggiungo uno vuoto");
                        arrLocaleEsercizi[0]="";
                        arrLocaleSerie[0]="";
                        arrLocaleRipetizioni[0]="";
                        arrLocaleRecupero[0]="";
                        arrLocaleNote[0]="";
                        arrLocaleClasse[0]="";
                        arrLocaleSpiegazione[0]="";
                        arrLocaleFoto[0]="";
                        arrLocaleNomeFoto[0]="";
                        arrTypeFoto[0]="";
                    }  
               
                
                    setArrayEserciziScheda(arrLocaleEsercizi);
                    setArraySerieScheda(arrLocaleSerie);
                    setArrayRipetizioniScheda(arrLocaleRipetizioni);
                    setArrayRecuperoTraSerieScheda(arrLocaleRecupero);
                    setArrayNoteEsercizioScheda(arrLocaleNote);
                    setArrayClasseScheda(arrLocaleClasse);
                    setArraySpiegazioneScheda(arrLocaleSpiegazione);
                    setArrayFotoScheda(arrLocaleFoto);
                    setArrayNomeFotoScheda(arrLocaleNomeFoto);
                    setTypeFoto(arrTypeFoto);

                    //setArrayEserciziSchedaDefault(arrLocaleEsercizi);
                    setArrayClasseSchedaDefault(arrLocaleClasse);

                    //setStatoTendinaEserciziDefault(true);
                    ricercaDatiTuttiEsercizi();
                    ricercaDatiTutteClassiEsercizi();
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else{
            setStatoPagina(STATO_RICERCA_DATI);
            ricercaDatiTuttiEsercizi();
            ricercaDatiTutteClassiEsercizi();
        }
    },[props.ope]);

    function scaricaLogo() {
        //console.log("scaricaLogo");
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {          
              if(valoreDiRitorno.risultatoOperazione===1){
                  if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                    setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                    setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                    setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                  }
                  setRicercaLogo(1);
              }
          },
          (error) => {console.log("Errore connessione");}
        );        
      }

    function ricercaDatiTuttiEsercizi() {
        //ricerco tutti gli esercizi disponibili che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoEsercizi",elencoCompleto:'1',recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiTuttiEsercizi(valoreDiRitorno.risultatoRitorno);
                //console.log(valoreDiRitorno.risultatoRitorno)
                //setArrayEsercizi(valoreDiRitorno.risultatoRitorno);      
                setStatoTendinaEsercizi(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaDatiTutteClassiEsercizi() {
        //ricerco tutte le calssi di esercizi disponibili che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoClassiEsercizi",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiTutteClassiEsercizi(valoreDiRitorno.risultatoRitorno);
                //setArrayClassiEsercizi(valoreDiRitorno.risultatoRitorno);         
                setStatoTendinaClassiEsercizi(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function tornaScheda() {
        setFlagAttivaModaleSchedaNonCancellabile(0);
        setFlagAttivaModaleSchedaNonModificabile(0);
        setFlagAttivaModaleSchedaNonInseribile(0);
        setFlagAttivaModaleInserisciDescrizione(0);
    }

    function handleCambiamentoMod(event) {
        setVariazioneCampi(1);
    }
    function callbackSetVariazioneCampi(variazioneCampi){
        setVariazioneCampi(variazioneCampi);
    }

    function callbackSetValoreTendEditabileClassiEsercizi(valore,index,res){
        //quando scelgo una classe di esercizi, la tendina degli esercizi si limita ai soli esercizi appartenetei alla classe prescelta:
        //per cui in posizione index passo da un array con tutti gli elementi (risultatoServerDatiTuttiEsercizi) ad uno con solo gli esercizi di una data classe
        // ovvero risultatoServerDatiTuttiEserciziLimitato[index]:

        let dati = [];
        let elementi = risultatoServerDatiTuttiEsercizi.length;
        let indice = 0;
        //console.log("elementi sono"+elementi)
        if(elementi === 0 || valore === '') {
            for (let i = 0; i < elementi; i++) {
                dati.splice(indice,1,risultatoServerDatiTuttiEsercizi[i]);//);
                indice = indice +1;
            }
        }else{
            for (let i = 0; i < elementi; i++) {
                //console.log("*"+risultatoServerDatiTuttiEsercizi[i].CLASSE+"-"+valore)
                //controllo se la classe è pari a valore enel caso inserisco l'esercizio nell'array 'limitato':
                //console.log("confronto"+risultatoServerDatiTuttiEsercizi[i].CLASSE+"con"+valore)
                if(risultatoServerDatiTuttiEsercizi[i].CLASSE===valore){
                    //console.log("OK")
                    dati.splice(indice,1,risultatoServerDatiTuttiEsercizi[i]);//);
                    indice = indice +1;
                }
            }
        }
 //console.log("Limit"+index+"su"+valore);
        risultatoServerDatiTuttiEserciziLimitato[index] = [...dati];

        if(res===1){
            let datiese = [...arrayEserciziScheda];//popolo l'array locale con i dati dell'array di stato
            datiese.splice(index,1,'');//);
            setArrayEserciziScheda(datiese);//aggiorno nuovamente la var di stato
            //inoltre devo andare a cancellare tutti i dati eventualmente già presenti per il record in esame:
            
            aggiornaSerie(index,'');
            aggiornaRipetizioni(index,'');
            aggiornaRecupero(index,'');
            aggiornaNote(index,'');
            aggiornaSpiegazione(index,'');
            aggiornaFoto(index,'');
            aggiornaNomeFoto(index,'');
            aggiornaTypeFoto(index,'');
            //console.log("scelgo"+valore);
            aggiornaClasse(index,valore,0);//l'unica cosa che metto è la classe
        }
        
    }

    function callbackSetValoreTendEditabile(valore,index,valoreNonVisibile){
        if (index === undefined) return;
        let dati = [...arrayEserciziScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,valore);//);
        setArrayEserciziScheda(dati);//aggiorno nuovamente la var di stato
        //console.log("callbackSetValoreTendEditabile("+valore+","+index+")");
        //recupero i restanti dati relativi all'esercizio in modo da mettere i default nei vari campi:
       
        if(valore === ''){
            //azzero solo i valori relativi alle caratteristiche dell'esercizio mantenendone la classe:
            aggiornaSerie(index,'');
            aggiornaRipetizioni(index,'');
            aggiornaRecupero(index,'');
            aggiornaNote(index,'');
            aggiornaSpiegazione(index,'');
            aggiornaFoto(index,'');
            aggiornaNomeFoto(index,'');
            aggiornaTypeFoto(index,'');
        }else{

            for (let i = 0; i < risultatoServerDatiTuttiEsercizi.length; i++) {
                if(risultatoServerDatiTuttiEsercizi[i].DESCRIZIONE===valore){
                    aggiornaSerie(index,risultatoServerDatiTuttiEsercizi[i].SERIE_STD);
                    aggiornaRipetizioni(index,risultatoServerDatiTuttiEsercizi[i].RIPETIZIONI_STD);
                    aggiornaRecupero(index,risultatoServerDatiTuttiEsercizi[i].RECUPERO_TRA_SERIE_STD);
                    aggiornaNote(index,risultatoServerDatiTuttiEsercizi[i].NOTE_ESERCIZIO_STD);
                    if(arrayClasseScheda[index]!==risultatoServerDatiTuttiEsercizi[i].CLASSE) aggiornaClasse(index,"",1); //
                    aggiornaSpiegazione(index,risultatoServerDatiTuttiEsercizi[i].SPIEGAZIONE);
                    aggiornaFoto(index,risultatoServerDatiTuttiEsercizi[i].FILE);
                    aggiornaNomeFoto(index,risultatoServerDatiTuttiEsercizi[i].FOTO);
                    aggiornaTypeFoto(index,risultatoServerDatiTuttiEsercizi[i].TYPE);
                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                }
            }
        }
    }

    function aggiornaSerie(index,dato){
        let dati = [...arraySerieScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArraySerieScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRipetizioni(index,dato){
        let dati = [...arrayRipetizioniScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayRipetizioniScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRecupero(index,dato){
        let dati = [...arrayRecuperoTraSerieScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayRecuperoTraSerieScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaNote(index,dato){
        let dati = [...arrayNoteEsercizioScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayNoteEsercizioScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaClasse(index,dato,tipoAggiornamento){
        //se tipoAggiornamento == 0 -> agggiorno solo ArrayClasseScheda
        //se tipoAggiornamento == 1 -> agggiorno ArrayClasseScheda e ArrayClasseSchedaDefault
        let dati = [...arrayClasseScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayClasseScheda(dati);//aggiorno nuovamente la var di stato
        if(tipoAggiornamento === 1){
            setArrayClasseSchedaDefault(dati);//aggiorno nuovamente la var di sta
        }
    }
    function aggiornaSpiegazione(index,dato){
        let dati = [...arraySpiegazioneScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArraySpiegazioneScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaFoto(index,dato){
        let dati = [...arrayFotoScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayFotoScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaNomeFoto(index,dato){
        let dati = [...arrayNomeFotoScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayNomeFotoScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaTypeFoto(index,dato){
        let dati = [...typeFoto];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setTypeFoto(dati);//aggiorno nuovamente la var di stato
    }

    function eliminaEsercizio(posizione) {

        /*
        let risCla = [...risultatoServerDatiTutteClassiEsercizi];
        risCla.splice(posizione, 1);//cancello l'elemento desiderato
        setRisultatoServerDatiTutteClassiEsercizi(risCla);//aggiorno nuovamente la var di stato
     */
    
        let ris = [...risultatoServerDatiTuttiEserciziLimitato];
        ris.splice(posizione, 1);//cancello l'elemento desiderato
        setRisultatoServerDatiTuttiEserciziLimitato(ris);//aggiorno nuovamente la var di stato

        let ese = [...arrayEserciziScheda];//popolo l'array locale con i dati dell'array di stato
        ese.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayEserciziScheda(ese);//aggiorno nuovamente la var di stato
        
        //la stessa cosa è da fare per tutti gli array legati all'esercizio
        let ser = [...arraySerieScheda];//popolo l'array locale con i dati dell'array di stato
        ser.splice(posizione, 1);//cancello l'elemento desiderato
        setArraySerieScheda(ser);//aggiorno nuovamente la var di stato

        let rip = [...arrayRipetizioniScheda];//popolo l'array locale con i dati dell'array di stato
        rip.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRipetizioniScheda(rip);//aggiorno nuovamente la var di stato

        let rec = [...arrayRecuperoTraSerieScheda];//popolo l'array locale con i dati dell'array di stato
        rec.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRecuperoTraSerieScheda(rec);//aggiorno nuovamente la var di stato

        let note = [...arrayNoteEsercizioScheda];//popolo l'array locale con i dati dell'array di stato
        note.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayNoteEsercizioScheda(note);//aggiorno nuovamente la var di stato

        let cla = [...arrayClasseScheda];//popolo l'array locale con i dati dell'array di stato
        cla.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayClasseScheda(cla);//aggiorno nuovamente la var di stato

        let spi = [...arraySpiegazioneScheda];//popolo l'array locale con i dati dell'array di stato
        spi.splice(posizione, 1);//cancello l'elemento desiderato
        setArraySpiegazioneScheda(spi);//aggiorno nuovamente la var di stato

        let fot= [...arrayFotoScheda];//popolo l'array locale con i dati dell'array di stato
        fot.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayFotoScheda(fot);//aggiorno nuovamente la var di stato

        let nomeFot= [...arrayNomeFotoScheda];//popolo l'array locale con i dati dell'array di stato
        nomeFot.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayNomeFotoScheda(nomeFot);//aggiorno nuovamente la var di stato

        let typeFot= [...typeFoto];//popolo l'array locale con i dati dell'array di stato
        typeFot.splice(posizione, 1);//cancello l'elemento desiderato
        setTypeFoto(typeFot);//aggiorno nuovamente la var di stato

        //setRivisualizza(rivisualizza+1);

    }

    function spostaEsercizio(posizione,spostamento){

        const toIndex = parseInt(posizione)+spostamento;

        if(toIndex>=0){
            const fromIndex = parseInt(posizione);
            
            /*
            let risCla = [...risultatoServerDatiTutteClassiEsercizi];
            const elementRisCla = risCla.splice(fromIndex, 1)[0];
            risCla.splice(toIndex, 0, elementRisCla);
            setRisultatoServerDatiTutteClassiEsercizi(risCla);//aggiorno nuovamente la var di stato
*/

            let ris = [...risultatoServerDatiTuttiEserciziLimitato];
            const elementRis = ris.splice(fromIndex, 1)[0];
            ris.splice(toIndex, 0, elementRis);
            setRisultatoServerDatiTuttiEserciziLimitato(ris);//aggiorno nuovamente la var di stato

            let ese = [...arrayEserciziScheda];//popolo l'array locale con i dati dell'array di stato
            const elementEse = ese.splice(fromIndex, 1)[0];
            ese.splice(toIndex, 0, elementEse);
            setArrayEserciziScheda(ese);//aggiorno nuovamente la var di stato
            
            let ser = [...arraySerieScheda];//popolo l'array locale con i dati dell'array di stato
            const elementSer = ser.splice(fromIndex, 1)[0];
            ser.splice(toIndex, 0, elementSer);
            setArraySerieScheda(ser);//aggiorno nuovamente la var di stato

            let rip = [...arrayRipetizioniScheda];//popolo l'array locale con i dati dell'array di stato
            const elementRip = rip.splice(fromIndex, 1)[0];
            rip.splice(toIndex, 0, elementRip);
            setArrayRipetizioniScheda(rip);//aggiorno nuovamente la var di stato

            let rec = [...arrayRecuperoTraSerieScheda];//popolo l'array locale con i dati dell'array di stato
            const elementRec = rec.splice(fromIndex, 1)[0];
            rec.splice(toIndex, 0, elementRec);
            setArrayRecuperoTraSerieScheda(rec);//aggiorno nuovamente la var di stato

            let note = [...arrayNoteEsercizioScheda];//popolo l'array locale con i dati dell'array di stato
            const elementNote = note.splice(fromIndex, 1)[0];
            note.splice(toIndex, 0, elementNote);
            setArrayNoteEsercizioScheda(note);//aggiorno nuovamente la var di stato

            let cla = [...arrayClasseScheda];//popolo l'array locale con i dati dell'array di stato
            const elementCla = cla.splice(fromIndex, 1)[0];
            cla.splice(toIndex, 0, elementCla);
            setArrayClasseScheda(cla);//aggiorno nuovamente la var di stato

            let spi = [...arraySpiegazioneScheda];//popolo l'array locale con i dati dell'array di stato
            const elementSpi = spi.splice(fromIndex, 1)[0];
            spi.splice(toIndex, 0, elementSpi);
            setArraySpiegazioneScheda(spi);//aggiorno nuovamente la var di stato

            let fot= [...arrayFotoScheda];//popolo l'array locale con i dati dell'array di stato
            const elementFot = fot.splice(fromIndex, 1)[0];
            fot.splice(toIndex, 0, elementFot);
            setArrayFotoScheda(fot);//aggiorno nuovamente la var di stato

            let nomeFot= [...arrayNomeFotoScheda];//popolo l'array locale con i dati dell'array di stato
            const elementNomeFot = nomeFot.splice(fromIndex, 1)[0];
            nomeFot.splice(toIndex, 0, elementNomeFot);
            setArrayNomeFotoScheda(nomeFot);//aggiorno nuovamente la var di stato

            let typeFot= [...typeFoto];//popolo l'array locale con i dati dell'array di stato
            const elementTypeFot = typeFot.splice(fromIndex, 1)[0];
            typeFot.splice(toIndex, 0, elementTypeFot);
            setTypeFoto(typeFot);//aggiorno nuovamente la var di stato
        }
        //setRivisualizza(rivisualizza+1);
    }
    

    function fetchOperazioneServer(permessoCanc=0,bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            //controllo lunghezza campo text:
           
            if(note.current.value.length>1000){
                controlli = 1;
                alert("Il campo Note contiene troppi caratteri (massimo accettato 1000)");
                setDisabilitaBottoni(false);
            }
        }
     
        let dataInizioDaPassare = '';
        let dataFineDaPassare = '';
        if(controlli===0){ 

            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                if (props.tipo === "assegnata" && dataInizio.current)  dataInizioDaPassare = dataInizio.current.value;
                else dataInizioDaPassare = '';
                if (props.tipo === "assegnata" && dataFine.current) dataFineDaPassare = dataFine.current.value;
                else dataFineDaPassare = '';
                
                // parametri per inviare i dati al server:
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ? JSON.stringify({op:operazione,
                        descrizione:descrizione.current.value,
                        eserciziScheda:arrayEserciziScheda,
                        serieEserciziScheda:arraySerieScheda,
                        ripetizioniEserciziScheda:arrayRipetizioniScheda,
                        recuperoEserciziScheda:arrayRecuperoTraSerieScheda,
                        noteEserciziScheda:arrayNoteEsercizioScheda,
                        note:note.current.value,
                        dataInizio:dataInizioDaPassare,
                        dataFine:dataFineDaPassare,
                        idElemento:props.id,
                        tipo:props.tipo,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        permessoCanc:permessoCanc,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };

                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2) {
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Descrizione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDescrizione(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dato non cancellabile: presente in schede_allenamenti_persone") !== -1){
                                    setFlagAttivaModaleSchedaNonCancellabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dato non modificabile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleSchedaNonModificabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dato non inseribile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleSchedaNonInseribile(1);
                                }
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    function loadImageAsDataURL(url) {
        return new Promise((resolve, reject) => {
          var img = new Image();
          img.onload = function () {
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            resolve(canvas.toDataURL());
          }
          img.onerror = reject;
          img.src = url;
        });
      }

    //JSON.stringify(risultatoServer)
        return (
            <div>

                {statoPagina === STATO_SCARICA_PDF ? 
                        <div>
                        <PDFViewer style={styles.viewer}>
                            <Document>
                            <Page size="A4" style={styles.page}>
                                <View style={{width:"100%"}} >
                                    <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                                        <View wrap={false} style={styles.tableRowIntestazione}> 
                                            <View style={styles.tableColIntestazioneSx}> 
                                                <View style={styles.tableCell}> 
                                                {/*<Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />*/}
                                                    {fileLogo !== "" ? 
                                                        <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />
                                                    :<Text></Text>}  
                                                </View>
                                            </View> 
                                            <View style={styles.tableColIntestazioneDx}>   
                                                <View style={styles.tableCellIntestazione}> 
                                                    {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                                                    <Text style={styles.titolo}>Scheda di Allenamento</Text>
                                                </View> 
                                                <View style={styles.tableCellIntestazione}>
                                                {props.persona ? <Text style={styles.titolo}>di {props.persona}</Text> 
                                                        : ""}
                                                </View>
                                            </View> 
                                        </View> 
                                    </View> {/*chiude table intestazione*/}

                                    <View style={styles.tableNoBordo}>{/*apre table dati*/}
                                        <View wrap={false}> 
                                            <View style={styles.tableCol1NoBordo}> 
                                                <View style={{width:"100%"}} ><Text style={styles.testoMargine2SxGrassetto}>{descrizioneDefault}</Text></View>  
                                            </View>
                                        </View>
                                        {props.persona && dataInizioDefault!==''? 
                                            <View wrap={false} style={styles.tableRow}> 
                                                <View style={styles.tableCol1NoBordo}> 
                                                    <View style={{width:"100%"}} ><Text style={styles.testoMargine2SxGrassetto}>Da Eseguire dal Giorno: <TrasformaDateAUmano data={dataInizioDefault}></TrasformaDateAUmano></Text></View>
                                                </View>
                                            </View>
                                        :""}
                                        {props.persona && dataFineDefault!=='' ?
                                            <View wrap={false} style={styles.tableRow}> 
                                                <View style={styles.tableCol1NoBordo}> 
                                                    <View style={{width:"100%"}} ><Text style={styles.testoMargine2SxGrassetto}>Da Eseguire fino al Giorno: <TrasformaDateAUmano data={dataFineDefault}></TrasformaDateAUmano></Text></View>
                                                </View>
                                            </View>
                                        : ""}
                                    </View>
                                    
                                    {/*lista esercizi scheda */}
                                    {arrayEserciziScheda !== undefined && arrayEserciziScheda.length>0 ?
                                        arrayEserciziScheda.map((elemento,index) => 
                                        <View key={index} wrap={false} style={[{display:'flex',flexDirection: "row"}]}>
                                            {index%2 !== 1 ?<>
                                            <View style={{flex:1}}>
                                            <Esercizio 
                                                    numeroDato={index} 
                                                    arrayEserciziScheda={arrayEserciziScheda} 
                                                    arrayClasseScheda={arrayClasseScheda} 
                                                    arraySpiegazioneScheda={arraySpiegazioneScheda}
                                                    arrayFotoScheda={arrayFotoScheda}
                                                    typeFoto={typeFoto}
                                                    arraySerieScheda={arraySerieScheda}
                                                    arrayRipetizioniScheda={arrayRipetizioniScheda}
                                                    arrayRecuperoTraSerieScheda={arrayRecuperoTraSerieScheda}
                                                    arrayNoteEsercizioScheda={arrayNoteEsercizioScheda}
                                            />
                                            </View>
                                           <View style={{flex:1,paddingLeft:10}}>
                                            {arrayEserciziScheda[index+1] ?
                                            <Esercizio 
                                                    numeroDato={index+1} 
                                                    arrayEserciziScheda={arrayEserciziScheda} 
                                                    arrayClasseScheda={arrayClasseScheda} 
                                                    arraySpiegazioneScheda={arraySpiegazioneScheda}
                                                    arrayFotoScheda={arrayFotoScheda}
                                                    typeFoto={typeFoto}
                                                    arraySerieScheda={arraySerieScheda}
                                                    arrayRipetizioniScheda={arrayRipetizioniScheda}
                                                    arrayRecuperoTraSerieScheda={arrayRecuperoTraSerieScheda}
                                                    arrayNoteEsercizioScheda={arrayNoteEsercizioScheda}
                                            />
                                            :""}
                                            </View>
                                           </>
                                           :""}
                                        </View> 
                                        )
                                    :""}

                                    <View style={styles.tableColNoBordo}> 
                                        <View style={styles.tableNoBordo}>
                                            <View wrap={false}> 
                                                {noteDefault ? 
                                                    <View wrap={false} style={styles.tableRow}> 
                                                        <View style={styles.tableCol1NoBordo}> 
                                                            <Text style={styles.testoGrassetto}>Note scheda: {noteDefault}</Text>
                                                        </View>
                                                    </View>
                                                :""}
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                            </Page>
                        </Document>
                        </PDFViewer>
                    </div>

                :""}


                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1ScheAll {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_PREPARA_PDF ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA || statoPagina === STATO_SCARICA_PDF ||statoPagina === STATO_PREPARA_PDF ?
                <div style={{display:statoPagina === STATO_SCARICA_PDF?"none":"block"}}>
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleInserisciDescrizione === 1 ?
                        <Modale 
                            titolo="Richiesta nome della scheda"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato il nome della scheda, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />                    
                    :""}

                    {flagAttivaModaleSchedaNonCancellabile === 1 ?
                        <Modale 
                            titolo="Informativa scheda non cancellabile"
                            flagErrore={true}
                            contenuto={<div>La scheda di allenamento risulta associata ad uno o più persone. 
                                Procedendo con la cancellazione, la scheda verrà rimossa anche dall'associazione alle persone.</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);fetchOperazioneServer(1)}
                                },
                                {
                                    "etichetta":"Annulla",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }     
                            ]}
                        />                    
                    :""}

                    {flagAttivaModaleSchedaNonModificabile === 1 ?
                        <Modale 
                            titolo="Informativa scheda non modificabile"
                            flagErrore={true}
                            contenuto={<div>Il nome della scheda di allenamento non è modificabile in {descrizione.current.value} poichè nel database è già presente una scheda di allenamento con lo stesso nome</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />                    
                    :""}

      
                    {flagAttivaModaleSchedaNonInseribile=== 1 && props.tipo === "standard"?
                        <Modale 
                            titolo="Informativa scheda non inseribile"
                            flagErrore={true}
                            contenuto={<div>La scheda di allenamento {descrizione.current.value} non è inseribile in poichè nel database è già presente una scheda di allenamento con la stessa denominazione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />  
                    :""}


                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" && props.tipo === "standard"? <CardTitle>Inserimento Scheda Allenamento Predefinita</CardTitle> :""}
                            {props.ope === "modifica" && props.tipo === "standard" ? <CardTitle>Modifica Scheda Allenamento Predefinita</CardTitle> :""}
                            {props.ope === "modifica" && props.tipo === "assegnata" ? <CardTitle>Modifica Scheda Allenamento assegnata a {props.persona}</CardTitle> :""}
                         
                          
                                <Clessidra loading={loading}/>


                                {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione della scheda
                                        {props.tipo === "standard" ? " predefinita " :""}
                                        {props.tipo === "assegnata" ? " assegnata a "+props.persona+" "  :""}
                                         <b>{descrizioneDefault}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :

                                <div id="schedaAllenamento">
                                {props.tipo === "assegnata" ?
                                    <Row form>  
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="dataInizio">Scheda da eseguire dal giorno</Label>
                                                {(props.ope === "inserisci" || props.ope === "modifica") ?
                                                <Input maxLength={10} type="date" name="dataInizio" id="dataInizio"  defaultValue={dataInizioDefault} innerRef={dataInizio}
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setDataInizioDefault(dataInizio.current.value)}}/> 
                                                    :""}
                                                {props.ope === "cancella" && dataInizioDefault ? <Label>: <TrasformaDateAUmano data={dataInizioDefault}></TrasformaDateAUmano></Label> : ""}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="dataFine">al giorno</Label>
                                                {(props.ope === "inserisci" || props.ope === "modifica") ?
                                                <Input maxLength={10} type="date" name="dataFine" id="dataFine"  defaultValue={dataFineDefault} innerRef={dataFine}
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setDataFineDefault(dataFine.current.value)}}/> 
                                                    :""}
                                                {props.ope === "cancella" && dataFineDefault ? <Label>: <TrasformaDateAUmano data={dataFineDefault}></TrasformaDateAUmano></Label> : ""}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                :""}
                                <Row form>  
                                    <Col md={12}>
                                        {/*{serie[0].current.value != undefined ? <CardTitle> valore {serie[0].current.value} </CardTitle> :""}*/}
                                        <FormGroup>
                                            
                                            <Label for="descrizione">Nome della Scheda*</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                            <Input maxLength={200} type="text" name="descrizione" id="descrizione" defaultValue={descrizioneDefault} innerRef={descrizione} 
                                                   placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value)}}/> 
                                                   :""}
                                            {props.ope === "cancella" ? <Label>: {descrizioneDefault}</Label> : ""}
                                            
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <div className='schedaSottotitolo'>Esercizi</div>
                                <Row form> 
                                    <Col md={12}>
                           
                                
                                    <table>
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <td style={{paddingLeft:2,paddingRight:2}}></td>
                                            <td style={{width: '25%',paddingRight:2}}>Classificazione</td>
                                            <td style={{width: '25%',paddingRight:2}}>Esercizio</td>
                                            <td style={{width: '5%',paddingRight:2}}>N. serie</td>
                                            <td style={{width: '5%',paddingRight:2}}>Ripetizioni</td>
                                            <td style={{width: '10%',paddingRight:2}}>Recupero tra serie</td>
                                            <td style={{width: '20%',paddingRight:2}}>Note esercizio</td>
                                            <td></td>
                                        </tr>
                                    </thead>

                                    

                                    {arrayEserciziScheda !== undefined && arrayEserciziScheda.length>0 ?
                                        arrayEserciziScheda.map((elemento,index) => <tr key={index}>
                                        {props.ope === "inserisci" || props.ope === "modifica" ?
                                            <>
                                            <td><img onClick={(e) => { handleCambiamentoMod(1); eliminaEsercizio(index)}} src={Cestino}/></td>
                                            <td style={{paddingLeft:2,paddingRight:2,textAlign:'right'}}>{index+1}</td>
                                            </>
                                        :""}
                       
                                        <td>
                         
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                            <>
                                            {/*{arrayClasseSchedaDefault[index]}*/}
                                            <select                   
                                                    className="form-control"  
                                                    onInput={(e)=>{
                                                    callbackSetVariazioneCampi(1);
                                                    callbackSetValoreTendEditabileClassiEsercizi(e.target.value,index,1);}}>
<option value=''></option>
                                                {risultatoServerDatiTutteClassiEsercizi.map((elementoP,indexP) =>
arrayClasseScheda[index] === risultatoServerDatiTutteClassiEsercizi[indexP]['DESCRIZIONE'] ? 
<option key={indexP} value={risultatoServerDatiTutteClassiEsercizi[indexP]['DESCRIZIONE']} selected>{risultatoServerDatiTutteClassiEsercizi[indexP]['DESCRIZIONE']}</option> : 
<option key={indexP} value={risultatoServerDatiTutteClassiEsercizi[indexP]['DESCRIZIONE']}>{risultatoServerDatiTutteClassiEsercizi[indexP]['DESCRIZIONE']}</option>
                                                )};
                                            </select>
                                            </>
                                                
                                            :""}
                                            {props.ope === "cancella" ? 
                                                <Label>: {arrayClasseSchedaDefault[index]}</Label> 
                                            : ""}
                                        </td>

                                        <td>
                                        
                                        {props.ope === "inserisci" || props.ope === "modifica" ?
                                         risultatoServerDatiTuttiEserciziLimitato[index] ?
                                        
                                         <>{/*{arrayEserciziScheda[index]}*/}
                                            <select                  
                                                    className="form-control"  
                                                    onInput={(e)=>{
                                                    callbackSetVariazioneCampi(1);
                                                    callbackSetValoreTendEditabile(e.target.value,index,);}}>
<option value=''></option>
                                                {risultatoServerDatiTuttiEserciziLimitato[index].map((elementoPossibile,indexPossibile) =>

arrayEserciziScheda[index] === risultatoServerDatiTuttiEserciziLimitato[index][indexPossibile]['DESCRIZIONE'] ? 
<option key={indexPossibile} value={risultatoServerDatiTuttiEserciziLimitato[index][indexPossibile]['DESCRIZIONE']} selected>{risultatoServerDatiTuttiEserciziLimitato[index][indexPossibile]['DESCRIZIONE']}</option> : 
<option key={indexPossibile} value={risultatoServerDatiTuttiEserciziLimitato[index][indexPossibile]['DESCRIZIONE']}>{risultatoServerDatiTuttiEserciziLimitato[index][indexPossibile]['DESCRIZIONE']}</option>
                     
                                                )};
                                            </select>
                                            </>
                                            :
                                            <select                    
                                                    className="form-control"  
                                                    onInput={(e)=>{
                                                    callbackSetVariazioneCampi(1);
                                                    callbackSetValoreTendEditabile(e.target.value,index);}}>
<option value=''></option>
                                                {risultatoServerDatiTuttiEsercizi.map((elementoPossibile,indexPossibile) =>

arrayEserciziScheda[index] === risultatoServerDatiTuttiEsercizi[indexPossibile]['DESCRIZIONE'] ? 
<option key={indexPossibile} value={risultatoServerDatiTuttiEsercizi[indexPossibile]['DESCRIZIONE']} selected>{risultatoServerDatiTuttiEsercizi[indexPossibile]['DESCRIZIONE']}</option> : 
<option key={indexPossibile} value={risultatoServerDatiTuttiEsercizi[indexPossibile]['DESCRIZIONE']}>{risultatoServerDatiTuttiEsercizi[indexPossibile]['DESCRIZIONE']}</option>
                                                    
                                            
                                                )};
                                            </select>
                                      
                                            
                                            :""}
                                            {props.ope === "cancella" ? <Label>: {arrayEserciziScheda[index]}</Label> : ""}
                                        </td>
                                       
                                        <td>
                                        {/*<Label for="serie">Serie</Label>*/}
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                            <Input maxLength={11} type="text" name="serie[index]" id="serie[index]" value={arraySerieScheda[index]} innerRef={serie[index]} 
                                                   placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaSerie(index,e.target.value)}}/> 
                                                   :""}
                                            {props.ope === "cancella" ? <Label>: {arraySerieScheda[index]}</Label> : ""}
                                        </td>
                                        <td>
                                        {/*<Label for="ripetizioni">Ripetizioni</Label>*/}
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                            <Input maxLength={11} type="text" name="ripetizioni[index]" id="ripetizioni[index]" value={arrayRipetizioniScheda[index]} innerRef={ripetizioni[index]} 
                                                   placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaRipetizioni(index,e.target.value)}}/> 
                                                   :""}
                                            {props.ope === "cancella" ? <Label>: {arrayRipetizioniScheda[index]}</Label> : ""}
                                        </td>
                                        <td>
                                        {/*<Label for="recupero">Recupero</Label>*/}
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                            <Input maxLength={200} type="text" name="recupero[index]" id="recupero[index]" value={arrayRecuperoTraSerieScheda[index]} innerRef={recupero[index]} 
                                                   placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaRecupero(index,e.target.value)}}/> 
                                                   :""}
                                            {props.ope === "cancella" ? <Label>: {arrayRecuperoTraSerieScheda[index]}</Label> : ""}
                                        </td>

                                        <td>
                                            {/*<Label for="noteEse">Note</Label>*/}
                                            {props.ope === "inserisci" || props.ope === "modifica" ?

                                            <Input maxLength={200} type="text"  value={arrayNoteEsercizioScheda[index]} 
                                                   placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaNote(index,e.target.value)}}/> 
                                                   :""}
                                            {props.ope === "cancella" ? <Label>: {arrayNoteEsercizioScheda[index]}</Label> : ""}
                                        </td>
                                            
                                            

                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <td style={{paddingLeft:2}}><center><Button color="primary" style={{marginBottom : 5}} onClick={() => {
                                                //setStatoPagina(STATO_MEMORIZZAZIONE);
                                                handleCambiamentoMod(1);
                                                spostaEsercizio(index,-1)}}>Su</Button></center></td>
                                            :""}
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <td style={{paddingLeft:2}}><center><Button color="primary" style={{marginBottom : 5}} onClick={() => {
                                                //setStatoPagina(STATO_MEMORIZZAZIONE);
                                                handleCambiamentoMod(1);
                                                spostaEsercizio(index,1)}}>Giù</Button></center></td>
                                            :""}

                                            </tr>)    
                                        :""}
                                        
                                        </table>
                           

                                        {props.ope === "inserisci" || props.ope === "modifica" ?
                                            <Button color="primary" className="mt-3 mb-3" onClick={() => {
                                            handleCambiamentoMod(1);
                                            setAggiungiEsercizio(aggiungiEsercizio+1)}} 
                                            >Aggiungi Esercizio</Button>
                                        :""}

                    
                                       
                                    </Col>
                                        </Row>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>                                       
                                            <Label for="note">Note aggiuntive della Scheda</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                            <Input maxLength={200} type="textarea" name="note" id="note" defaultValue={noteDefault} innerRef={note} 
                                                   placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setNoteDefault(note.current.value)}}/> 
                                                   :""}
                                            {props.ope === "cancella" ? <Label>: {noteDefault}</Label> : ""}                                        
                                        </FormGroup>
                                    </Col>
                                </Row>
                                </div>
                                }
                             

                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                                
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>

                                {props.ope === "inserisci" || props.ope === "modifica" ?
                                    <Button color="success" className="mt-2" onClick={(e)=>{setStatoPagina(STATO_PREPARA_PDF);}}>Esporta in formato PDF</Button>
                                :""}

                                </div>

                </div>
                </div>
                :""}
            </div>
        );
}
export default FormGridFormRow;
