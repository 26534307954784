import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input, Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import VisualizzaAllegato from '../VisualizzaAllegato.js';

import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import Resizer from "react-image-file-resizer";
import FinestraDialogo from '../FinestraDialogo.js';

import FormattaImportoDb from '../FormattaImportoDb.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagModaleInfoFoto, setFlagModaleInfoFoto] = React.useState(false);
    const [flagModaleConfermaCancFoto, setFlagModaleConfermaCancFoto] = React.useState(false);
    const [flagAttivaModaleEsercizioNonCancellabile, setFlagAttivaModaleEsercizioNonCancellabile] = useState(0);
    const [flagAttivaModaleEsercizioNonModificabile, setFlagAttivaModaleEsercizioNonModificabile] = useState(0);
    const [flagAttivaModaleEsercizioNonInseribile, setFlagAttivaModaleEsercizioNonInseribile] = useState(0);
    const [flagAttivaModaleInserisciDescrizione, setFlagAttivaModaleInserisciDescrizione] = useState(0);
    const [flagAttivaModaleDimensioneFoto, setFlagAttivaModaleDimensioneFoto] = useState(0);

    const [flagAttivaModaleSerieErrato, setFlagAttivaModaleSerieErrato] = useState(0);
    const [flagAttivaModaleRipetizioniErrato, setFlagAttivaModaleRipetizioniErrato] = useState(0);

    const [fineSpazio, setFineSpazio] = useState(0);

    const [numeroSchedeContenentiEsercizio,setNumeroSchedeContenentiEsercizio]=useState(0);
    
    const [contenutoFile,setContenutoFile]=useState('');
    const [dimensioneFoto,setDimensioneFoto]=useState('');
    const [dimensioneMassimaFoto,setDimensioneMassimaFoto]=useState('');
    const [estensioneFileFoto, setEstensioneFileFoto] = useState('');
    const [flagFotoModificata, setFlagFotoModificata] = useState(false);
    

    const descrizione   = useRef();
    const spiegazione   = useRef();
    const serieStd   = useRef();
    const ripetizioniStd   = useRef();
    const recuperoStd   = useRef();
    const noteStd   = useRef();
    const flagEliminaFotoRif   = useRef();
    
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [statoTendinaClasse,setStatoTendinaClasse]=useState(false);

    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');

    const [risultatoServerDatiClasse,setRisultatoServerDatiClasse]=useState('');
    //const [classeDefault,setClasseDefault]=useState('');
    const [descrizioneDefault,setDescrizioneDefault]=useState('');
    const [spiegazioneDefault,setSpiegazioneDefault]=useState('');
    const [fotoDefault,setFotoDefault]=useState('');

    const [typeFoto,setTypeFoto]=useState('');
    const [flagEliminaFoto,setFlagEliminaFoto]=useState('');
    
    const [serieStdDefault,setSerieStdDefault]=useState('');
    const [ripetizioniStdDefault,setRipetizioniStdDefault]=useState('');
    const [recuperoStdDefault,setRecuperoStdDefault]=useState('');
    const [noteStdDefault,setNoteStdDefault]=useState('');

    const [insClasseEdi,setInsClasseEdi]=useState('');
    const [sceltaInsClasseEdi,setSceltaInsClasseEdi]=useState(0);

    const [anteprima,setAnteprima]=useState('');
    const [typeAnteprima,setTypeAnteprima]=useState('');

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaClasse(false);
    },[]);

    useEffect(() => {
        if(statoTendinaClasse === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaClasse]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("esercizioIns");
        if(props.ope==='modifica') setOperazione("esercizioMod");
        if(props.ope==='cancella') setOperazione("esercizioCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"esercizioRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    setInsClasseEdi(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE_CLASSE);//serve per settare la var di stato se non cambi valore nella tendina in caso di modifica
                    setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                    setSpiegazioneDefault(valoreDiRitorno.risultatoRitorno[0].SPIEGAZIONE);
                    setFotoDefault(valoreDiRitorno.risultatoRitorno[0].FOTO);
                    setEstensioneFileFoto(valoreDiRitorno.risultatoRitorno[0].ESTENSIONE_FOTO);
                    setContenutoFile(valoreDiRitorno.risultatoRitorno[0].FILE);
                    setTypeFoto(valoreDiRitorno.risultatoRitorno[0].TYPE);
                    setSerieStdDefault(valoreDiRitorno.risultatoRitorno[0].SERIE_STD);
                    setRipetizioniStdDefault(valoreDiRitorno.risultatoRitorno[0].RIPETIZIONI_STD);
                    setRecuperoStdDefault(valoreDiRitorno.risultatoRitorno[0].RECUPERO_TRA_SERIE_STD);
                    setNoteStdDefault(valoreDiRitorno.risultatoRitorno[0].NOTE_ESERCIZIO_STD);
                    ricercaDatiClasse(props.ope);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else{
            setStatoPagina(STATO_RICERCA_DATI);
            ricercaDatiClasse(props.ope);
        }
    },[props.ope]);

    useEffect(() => { 
        if(flagEliminaFoto === 'on') setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[flagEliminaFoto]);

    function scaricaFile(nomeFile, contenuto) {
        const link = document.createElement('a');
        link.href = contenuto;
        link.setAttribute('download',nomeFile);     
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();      
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }
    function aggiornaFile(valore){
        setVariazioneCampi(1);
        let typeFile = '';
        if (valore.type.substring(0, 5) === "image") {
            var nuovoTipo = valore.type.substring(6);
            if (nuovoTipo.toUpperCase()!="JPG" && nuovoTipo.toUpperCase()!="JPEG" && nuovoTipo.toUpperCase()!="PNG") {
                nuovoTipo = "JPEG";
                typeFile = "image/jpeg";
            }else{
                typeFile = valore.type;
            }

            try {
                Resizer.imageFileResizer(
                    valore,
                    600,
                    600,
                    nuovoTipo,
                    100,
                    0,
                    (uri) => {
                        setContenutoFile(uri);
                    },
                    "base64"
                );                
            } catch (err) {
                // console.log(err);
                alert("Si è verificato un errore nel ridimensionamento dell'immagine, contattare l'assistenza tecnica");
            }
        } else {            
            setContenutoFile(valore)
        }
        const estensione = valore.name.split('.').pop();
        setFlagFotoModificata(true); setFlagEliminaFoto(""); setTypeFoto(typeFile); setEstensioneFileFoto(estensione);
    }

    function funzioneEliminaFoto(valore){
        //setVariazioneCampi(1);
        handleCambiamentoMod();
        setFlagEliminaFoto(valore);
        setTypeFoto('');
        setContenutoFile('');
    }

    /*function aggiornaFile(valore){
        setVariazioneCampi(1);
        setContenutoFile(valore);
        setFotoDefault("");
        setTypeFoto('');
        setFlagEliminaFoto("");
        setAnteprima(URL.createObjectURL(valore));
        setTypeAnteprima(valore.type);
    }
    function funzioneEliminaFoto(valore){
        setFlagEliminaFoto(valore);
        setContenutoFile('');
        setTypeFoto('');
        setFotoDefault('');
        setContenutoFile('');
        setAnteprima('');
        setTypeAnteprima('');
        setStatoPagina(STATO_MEMORIZZAZIONE);
    }*/

    function ricercaDatiClasse(operazione) {
        //ricerco le classi di esercizi che mi servono per popolare le tendine:
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoClassiEsercizi",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiClasse(valoreDiRitorno.risultatoRitorno);
                
                setStatoTendinaClasse(true);
                }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function tornaScheda() {
        setFlagModaleInfoFoto(false);
        setFlagModaleConfermaCancFoto(false);
        setFlagAttivaModaleEsercizioNonCancellabile(0);
        setFlagAttivaModaleEsercizioNonModificabile(0);
        setFlagAttivaModaleEsercizioNonInseribile(0);
        setFlagAttivaModaleInserisciDescrizione(0);
        setFlagAttivaModaleDimensioneFoto(0);
        setFlagAttivaModaleSerieErrato(0);
        setFlagAttivaModaleRipetizioniErrato(0);

        setNumeroSchedeContenentiEsercizio(0);
        setFineSpazio(0);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function callbackSetValoreTendEditabileClasse(valore){
        setInsClasseEdi(valore);
        //setClasseDefault(valore);
        setSceltaInsClasseEdi(1);
    }
    

    function fetchOperazioneServer(permessoCanc=0,bypassaControlli=0,tipoCancellazione=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){

            if(descrizione.current.value.length>1000){
                controlli = 1;
                alert("Il campo Descrizione contiene troppi caratteri (massimo accettato 1000)");
                setDisabilitaBottoni(false);
            }
        
            if(descrizione.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDescrizione(1);
            }

            if(!Number.isInteger(Number(serieStd.current.value)) && serieStd.current.value!==''){
                controlli = 1;
                setFlagAttivaModaleSerieErrato(1);
            }
            if(!Number.isInteger(Number(ripetizioniStd.current.value)) && ripetizioniStd.current.value!==''){
                controlli = 1;
                setFlagAttivaModaleRipetizioniErrato(1);
            }
        }
        

        if(controlli===0){ 
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{

                // parametri per inviare i dati al server:

                setLoading(true);
                //aggiorno con post senza json:
                const formData = new FormData();
                if (props.ope!=='cancella'){
                    formData.append('op', operazione);
                    formData.append('descrizione',descrizione.current.value);
                    formData.append('spiegazione',spiegazione.current.value);

                    formData.append('serieStd',serieStd.current.value);
                    formData.append('ripetizioniStd',ripetizioniStd.current.value);
                    formData.append('recuperoStd',recuperoStd.current.value);
                    formData.append('noteStd',noteStd.current.value);

                    formData.append('classe', insClasseEdi);
                    formData.append('sceltaClasse', sceltaInsClasseEdi);

                    if (flagFotoModificata) {
                        formData.append('contenutoFileFoto', contenutoFile);
                        formData.append('estensioneFileFoto', estensioneFileFoto);
                        formData.append('typeFileFoto', typeFoto);
                    }

                    //formData.append('contenutoFile', contenutoFile);
                    formData.append('flagEliminaFoto', flagEliminaFoto);
                    
                    formData.append('idElemento', props.id);
                    formData.append('emak', props.emak);
                    
                    formData.append('sessionId', props.sessionId);
                    formData.append('session_id',props.sessionId);
                }else{
                    formData.append('op', operazione);
                    formData.append('idElemento', props.id);
                    formData.append('permessoCanc', permessoCanc);
                    if(tipoCancellazione === 1) formData.append('eliminaEsercizioDalleSchede',1);
                    if(tipoCancellazione === 2) formData.append('eliminaSchedeConEsercizio',1);
                    formData.append('emak', props.emak);
                    formData.append('sessionId', props.sessionId);
                    formData.append('session_id',props.sessionId);
                }

                const requestOptions = {
                    method: 'POST',
                    body: formData
                }

                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Dato non cancellabile: presente in schede allenamento") !== -1){
                                    setNumeroSchedeContenentiEsercizio(valoreDiRitorno.numSchede);
                                    setFlagAttivaModaleEsercizioNonCancellabile(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Dato non modificabile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleEsercizioNonModificabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dato non inseribile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleEsercizioNonInseribile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Descrizione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDescrizione(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Spazio Terminato") !== -1){
                                    setFineSpazio(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dimensione foto esercizio troppo elevata") !== -1){
                                    setFlagAttivaModaleDimensioneFoto(1);
                                    setDimensioneFoto(valoreDiRitorno.dimFoto);
                                    setDimensioneMassimaFoto(valoreDiRitorno.dimMaxFoto);
                                    setContenutoFile('');
                                    setTypeFoto('');
                                    setFotoDefault('');
                                    setContenutoFile('');
                                    setAnteprima('');
                                    setTypeAnteprima('');
                                }
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
           
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1Ese {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleSerieErrato === 1 ?
                        <Modale 
                        titolo="Informativa numero di serie errato"
                        flagErrore={true}
                        contenuto={<div>Il numero di serie non è un numero: completare il campo con un valore numerico</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleRipetizioniErrato === 1 ?
                        <Modale 
                        titolo="Informativa numero di ripetizioni errato"
                        flagErrore={true}
                        contenuto={<div>Il numero di ripetizioni non è un numero: completare il campo con un valore numerico</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {fineSpazio === 1 ?
                        <Modale 
                        titolo="Informativa spazio terminato"
                        flagErrore={true}
                        contenuto={<div>Lo spazio dati è terminato</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleDimensioneFoto === 1 ?
                        <Modale 
                            titolo="Dimensione eccessiva foto"
                            flagErrore={true}
                            contenuto={<div>La foto ha una dimensione troppo elevata:<br/>rilevata {dimensioneFoto} KB, massima accettata {dimensioneMassimaFoto} KB</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciDescrizione===1 ?
                        <Modale 
                            titolo="Richiesta descrizione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la descrizione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleEsercizioNonCancellabile===1 ?
                        <Modale 
                            titolo="Informativa esercizio non cancellabile"
                            flagErrore={true}
                            contenuto={<div>
                            
                           
                            {numeroSchedeContenentiEsercizio === 1 ? <>L'esercizio selezionato risulta associato ad una scheda di allenamento.
                            Procedendo con la cancellazione del solo esercizio dalla scheda, l'esercizio verrà cancellato anche dalla scheda di allenamento nella quale è inserito.
                            Procedendo con la cancellazione globale si procederà all'eliminazione sia dell'esercizio che della scheda che lo contiene.
                            <br/></>:""}
                            {numeroSchedeContenentiEsercizio > 1 ? <>L'esercizio selezionato risulta associato a {numeroSchedeContenentiEsercizio} schede di allenamento.
                            Procedendo con la cancellazione del solo esercizio dalle schede, l'esercizio verrà cancellato anche dalle schede di allenamento nelle quali è inserito.
                            Procedendo invece con la cancellazione globale si procederà all'eliminazione sia dell'esercizio che delle {numeroSchedeContenentiEsercizio} schede che lo contientengono.
                            </>:""}
                            </div>}
                            bottoni={[
                                {
                                    "etichetta":"Cancella solo esercizio",
                                    "tipo":"danger",
                                    callback:() => {setDisabilitaBottoni(false);fetchOperazioneServer(1,0,1)}
                                },
                                {
                                    "etichetta":"Cancellazione globale",
                                    "tipo":"danger",
                                    callback:() => {setDisabilitaBottoni(false);fetchOperazioneServer(1,0,2)}
                                },  
                                {
                                    "etichetta":"Annulla",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                },      
                            ]}
                        />
                    :""}
                    
                    {flagAttivaModaleEsercizioNonModificabile===1 ?
                        <Modale 
                            titolo="Informativa esercizio non modificabile"
                            flagErrore={true}
                            contenuto={<div>La denominazione dell'esercizio non è modificabile in {descrizione.current.value} poichè nel database è già presente un esercizio con la stessa denominazione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleEsercizioNonInseribile===1 ?
                        <Modale 
                            titolo="Informativa esercizio non inseribile"
                            flagErrore={true}
                            contenuto={<div>La denominazione dell'esercizio non è inseribile in {descrizione.current.value} poichè nel database è già presente un esercizio con la stessa denominazione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}
                    {flagModaleInfoFoto === true ?
                        <Modale 
                            titolo="Foto dell'esercizio"
                            flagErrore={false}
                            contenuto="La foto può essere inserita in formato: jpg, jpeg, gif, png, webp"
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagModaleConfermaCancFoto === true ?
                    <Modale 
                        titolo="Conferma eliminazione"
                        flagErrore={false}
                        contenuto="Confermi l'eliminazione della foto?"
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setFlagModaleConfermaCancFoto(false); funzioneEliminaFoto("on")}
                            },
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback: () => {setDisabilitaBottoni(false);tornaScheda()}
                            }
                        ]}
                    />
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Esercizio</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Esercizio</CardTitle> :""}
                           
                    
                                <Clessidra loading={loading}/>

                                {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione dell' esercizio <b>{descrizioneDefault}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :
                                <>
                                {/* div contenente dati esercizio e foto */}
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    {/* div contenente dati esercizio */}
                                    <div style={{flex:1}}>
                                        <Row form>  
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="descrizione">Denominazione*</Label>
                                                        <Input maxLength={200} type="text" name="descrizione" id="descrizione"
                                                        innerRef={descrizione} 
                                                        placeholder=""
                                                        defaultValue={descrizioneDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value)}}/> 
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>  
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="spiegazione">Descrizione</Label>
                                                        <Input type="text" name="spiegazione" id="spiegazione"
                                                        innerRef={spiegazione} 
                                                        placeholder=""
                                                        defaultValue={spiegazioneDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setSpiegazioneDefault(spiegazione.current.value)}}/> 
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>  
                                        <Col md={12}><FormGroup>
                                        <Label>Classificazione</Label>
                                        <TendinaEditabile
                                            //titolo={"Classe esercizio"}
                                            nomeCampo="DESCRIZIONE"
                                            labelCampoVuoto=""
                                            valoreDefault={insClasseEdi}
                                            arrayValori={risultatoServerDatiClasse}
                                            callbackSetVariazioneCampi={setVariazioneCampi}
                                            callbackSetValoreTendEditabile={callbackSetValoreTendEditabileClasse}
                                        ></TendinaEditabile>
                                        </FormGroup></Col>
                                        </Row>
                                        <Row form>  
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="serie">N. serie</Label>
                                                    <Input maxLength={11} type="text" name="serieStd" id="serieStd" defaultValue={serieStdDefault} innerRef={serieStd}
                                                        placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setSerieStdDefault(serieStd.current.value)}}/> 
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="ripetizioniStd">Ripetizioni</Label>
                                                    <Input maxLength={11} type="text" name="ripetizioniStd" id="ripetizioniStd" defaultValue={ripetizioniStdDefault} innerRef={ripetizioniStd}
                                                        placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setRipetizioniStdDefault(ripetizioniStd.current.value)}}/> 
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="recuperoStd">Recupero tra serie</Label>
                                                    <Input maxLength={200} type="textarea" name="recuperoStd" id="recuperoStd"  defaultValue={recuperoStdDefault} innerRef={recuperoStd}
                                                        placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setRecuperoStdDefault(recuperoStd.current.value)}}/> 
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="noteStd">Note esercizio</Label>
                                                    <Input maxLength={200} type="textarea" name="noteStd" id="noteStd"  defaultValue={noteStdDefault} innerRef={noteStd}
                                                        placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setNoteStdDefault(noteStd.current.value)}}/> 
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        </div> {/* fine div contenente dati esercizio */}

                                        <div style={{paddingLeft:10}}> {/* div contenente foto*/}
                                                <Label for="foto">
                                                    Foto Esercizio
                                                    <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoFoto(true)}/>
                                                </Label>
                                                <VisualizzaAllegato
                                                    cssContenitore="btnimg"
                                                    cssImmagine="immagineAnagrafica"
                                                    cssPdf="pdfAnagrafica"
                                                    cssTestoEstensione="testoEstensioneAllegatoAnagrafica"
                                                    scalaPdf={props.dimensioniApp.x/3000}
                                                    contenutoFile={contenutoFile}
                                                    tipoFile={typeFoto}
                                                    estensioneFile={estensioneFileFoto}
                                                    estensioniAccettate={".jpg,.jpeg,.gif,.png,.webp"}
                                                    flagContenutoModificato={flagFotoModificata}
                                                    callbackAggiornaFile={(e) => {handleCambiamentoMod(e);aggiornaFile(e.target.files[0]);}}
                                                    callbackScarica={() => scaricaFile("Foto." + estensioneFileFoto,contenutoFile)}
                                                    callbackElimina={() => setFlagModaleConfermaCancFoto(true)}
                                                /> 
                                        </div>
                                    </div>  
{/*
                                    <Row form>
                                            <Col md={12}>
                                            <div class="wrapper">
                                                <Fragment>
                                                <div class="btnimg">
                                                    
                                                    {typeFoto === "application/pdf" || typeFoto === "text/plain" ? 
                                                        <embed className="immagineM" src={"data:" + typeFoto + ";base64," + contenutoFile}  type={typeFoto}/>  
                                                    :""}
                                                    {typeFoto.substring(0, 5) === "image" ?
                                                        <img className="immagineM" src={"data:" + typeFoto + ";base64," + contenutoFile} />
                                                    :""}
                                                    
                                                    {typeAnteprima.substring(0, 5) === "image" ?
                                                        <img className="immagineM" src={anteprima}/>
                                                    :""}
                                                    {typeAnteprima === "application/pdf" || typeAnteprima === "text/plain" ? 
                                                        <embed className="immagineM" src={anteprima}/>
                                                    :""}
                                                </div>
                                                <Label for="foto">(formati ammessi: jpg, jpeg, gif, png, webp)</Label>
                                                <input type='file' className='bottone' accept=".jpg,.jpeg,.gif,.png,.webp" 
                                                    
                                                    onChange={(e) => {handleCambiamentoMod(e);aggiornaFile(e.target.files[0]);}}/>
                                                </Fragment>
                                            </div>
                                            </Col>
                                        </Row> 
                                        <Row form> 
                                            {fotoDefault !== "" ? 
                                                <Col md={6}>
                                                <a download={"Esercizio " + descrizioneDefault} href={"data:" + typeFoto + ";base64," + contenutoFile}>Scarica file</a>
                                                </Col>
                                            :""}
                                        
                                            <Col md={6}>
                                                {fotoDefault !== '' || contenutoFile!=='' ?
                                                <Fragment>
                                                <Input className="ml-2 mt-2" type="checkbox" name="flagEliminaFoto" id="flagEliminaFoto"
                                                    innerRef={flagEliminaFotoRif}                                 
                                                    placeholder=""
                                                    onInput={(e)=>{handleCambiamentoMod(e);funzioneEliminaFoto(e.target.value)}}/>
                                                    <Label className="ml-4 mt-2">Elimina foto</Label></Fragment>
                                                :""}
                                            </Col>
                                            </Row>   
                                    </Col>
                                </Row>}*/}
                                </>
                                }
                        
                                 
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                               
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica")  ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
