import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input , Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';
import Cestino from "../../immagini/cestino.png";
import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import FormattaImportoVideo from '../FormattaImportoVideo.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');

    const [cognome,setCognome] = useState('');
    const [nome,setNome] = useState('');
    const [numeroTessera,setNumeroTessera] = useState('');
    const [tipo,setTipo] = useState('');
    const [email,setEmail] = useState('');
    const [cell,setCell] = useState('');
    
    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [arrayComunicazioni, setArrayComunicazioni] = useState([]);//conterra tutte le cominicazioni fatte dal / al contattato

    const [arrayEsitiPossibili, setArrayEsitiPossibili] = useState(['Positivo','Neutro','Negativo']);

    const [risultatoServerDatiMezziComunicazione,setRisultatoServerDatiMezziComunicazione]=useState('');
    const [statoTendinaMezziComunicazione,setStatoTendinaMezziComunicazione]=useState(false);
    const [ricercaComunicazioni,setRicercaComunicazioni]=useState(false);


    const [azioneDefault, setAzioneDefault] = useState('');
    const [dataAzioneDefault, setDataAzioneDefault] = useState('');
    const dataAzione  = useRef();
    const azione  = useRef();
    //per i punteggi:
    const [percRispostePos,setRispostePos] = useState(0);
    const [percRisposteNeu,setRisposteNeu] = useState(0);
    const [percRisposteNeg,setRisposteNeg] = useState(0);
    const [puntRisp,setPuntRisp] = useState(0);
    const [puntAbbo,setPuntAbbo] = useState(0);
    const [puntEve,setPuntEve] = useState(0);

    const [percRispostePosMediaAltri,setRispostePosMediaAltri] = useState(0);
    const [percRisposteNeuMediaAltri,setRisposteNeuMediaAltri] = useState(0);
    const [percRisposteNegMediaAltri,setRisposteNegMediaAltri] = useState(0);
    const [puntRispMediaAltri,setPuntRispMediaAltri] = useState(0);
    const [puntAbboMediaAltri,setPuntAbboMediaAltri] = useState(0);
    const [puntEveMediaAltri,setPuntEveMediaAltri] = useState(0);

    const [ricercaValoriCrm,setRicercaValoriCrm]=useState(false);

    const [modaleNoData,setModaleNoData]=useState(false);
    const [modaleNoMezzo,setModaleNoMezzo]=useState(false);
    const [modaleInfoDestinatari,setModaleInfoDestinatari]=useState(false);
    const [modaleInfoParametri,setModaleInfoParametri]=useState(false);

    const [expandedIndexContenuto, setExpandedIndexContenuto] = useState(null);
    const [expandedIndexRisposta, setExpandedIndexRisposta] = useState(null);

    const handleMouseEnterContenuto = (index) => {setExpandedIndexContenuto(index);};
    const handleMouseLeaveContenuto = () => {setExpandedIndexContenuto(null);};
    const textareaStyleContenuto = (index) => ({
        // Aggiungi la logica di stile condizionale qui
        fontFamily: 'inherit',
        fontSize: 'inherit',
        color: 'inherit',
        lineHeight: 'inherit',
        width: '100%', // La textarea riempie tutta la larghezza del <td>
        height: index === expandedIndexContenuto ? '200px' : '50px', // Adatta l'altezza in base all'espansione
        transition: 'height 0.3s ease',
    });

    const handleMouseEnterRisposta = (index) => {setExpandedIndexRisposta(index);};
    const handleMouseLeaveRisposta = () => {setExpandedIndexRisposta(null);};
    const textareaStyleRisposta = (index) => ({
        // Aggiungi la logica di stile condizionale qui
        fontFamily: 'inherit',
        fontSize: 'inherit',
        color: 'inherit',
        lineHeight: 'inherit',
        width: '100%', // La textarea riempie tutta la larghezza del <td>
        height: index === expandedIndexRisposta ? '200px' : '50px', // Adatta l'altezza in base all'espansione
        transition: 'height 0.3s ease',
    });

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaMezziComunicazione(false);
        setRicercaComunicazioni(false);
        setRicercaValoriCrm(false);
        ricercaMezziComunicazione();
        ricercaValCrm();
    },[]);

    useEffect(() => {
        if(statoTendinaMezziComunicazione === true && ricercaComunicazioni === true && ricercaValoriCrm === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaMezziComunicazione,ricercaComunicazioni,ricercaValoriCrm]);

    useEffect(() => {
        if(props.ope==='modifica') setOperazione("storicoContattatoModCrm");
        if(props.ope==='cancella') setOperazione("storicoContattatoCancCrm");

        if(props.ope==="modifica" || props.ope==="cancella"){
          
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"storicoContattatoRecuperaDatiCrm",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    setCognome(valoreDiRitorno.datiContattato[0].COGNOME);
                    setNome(valoreDiRitorno.datiContattato[0].NOME);
                    setNumeroTessera(valoreDiRitorno.datiContattato[0].NUMERO_TESSERA);
                    setTipo(valoreDiRitorno.datiContattato[0].TIPO);
                    setEmail(valoreDiRitorno.datiContattato[0].EMAIL);
                    setCell(valoreDiRitorno.datiContattato[0].CELLULARE);

                    let arrLocale = [];

                    valoreDiRitorno.risultatoRitorno.forEach(item => {
                        if (item.ID !== '') {
                            arrLocale.push({
                                ID: item.ID,  // Aggiungi il campo ID qui
                                DATA: item.DATA,
                                MEZZO: item.MEZZO,
                                ESITO: item.ESITO,
                                CONTENUTO: item.CONTENUTO,
                                RISPOSTA: item.RISPOSTA
                            });
                        }
                    });
                    aggiungiElementoVuotoIniziale(arrLocale);

                    setArrayComunicazioni(arrLocale);
                    setRicercaComunicazioni(true);
                    
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[props.ope]);

    function aggiungiElementoVuotoIniziale(arrLocale) {
        arrLocale.push({
            ID: `new-${Date.now()}`,// Campo vuoto per consentire l'inserimento di un nuovo ID ((ne metto uno casuale in modo da renderlo univoco))
            DATA: '',      // Campo vuoto per consentire l'inserimento di una nuova data
            MEZZO: '',     // Campo vuoto per consentire l'inserimento di un nuovo mezzo
            ESITO: '',     // Campo vuoto per consentire l'inserimento di un nuovo esito
            CONTENUTO: '',  // Campo vuoto per consentire l'inserimento di un nuovo contenuto
            RISPOSTA: ''  // Campo vuoto per consentire l'inserimento di un nuovo risposta
        });
    }

    function ricercaMezziComunicazione() {
        //ricerco tra i mezzi di comunicazione con i quali si possono mandare le comunicazioni:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoMezziComunicazCrm",emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    setRisultatoServerDatiMezziComunicazione(valoreDiRitorno.risultatoRitorno);
                    setStatoTendinaMezziComunicazione(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaValCrm() {
        //ricerco i valori crm del contattato:
    
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"datiCrmRecuperaDati",idCom:props.id,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    if(valoreDiRitorno.risultatoRitorno[0]){
                        setRispostePos(valoreDiRitorno.risultatoRitorno[0].PERC_RISP_POSITIVE);
                        setRisposteNeu(valoreDiRitorno.risultatoRitorno[0].PERC_RISP_NEUTRE);
                        setRisposteNeg(valoreDiRitorno.risultatoRitorno[0].PERC_RISP_NEGATIVE);
                        setPuntRisp(valoreDiRitorno.risultatoRitorno[0].PUNTEGGIO_RISPOSTE);
                        setPuntAbbo(valoreDiRitorno.risultatoRitorno[0].PUNTEGGIO_ABBONAMENTI);
                        setPuntEve(valoreDiRitorno.risultatoRitorno[0].PUNTEGGIO_PART_EVENTI);

                        setRispostePosMediaAltri(valoreDiRitorno.datiAltri[0].MEDIA_PERC_RISP_POSITIVE);
                        setRisposteNeuMediaAltri(valoreDiRitorno.datiAltri[0].MEDIA_PERC_RISP_NEUTRE);
                        setRisposteNegMediaAltri(valoreDiRitorno.datiAltri[0].MEDIA_PERC_RISP_NEGATIVE);
                        setPuntRispMediaAltri(valoreDiRitorno.datiAltri[0].MEDIA_PUNTEGGIO_RISPOSTE);
                        setPuntAbboMediaAltri(valoreDiRitorno.datiAltri[0].MEDIA_PUNTEGGIO_ABBONAMENTI);
                        setPuntEveMediaAltri(valoreDiRitorno.datiAltri[0].MEDIA_PUNTEGGIO_PART_EVENTI);

                        setDataAzioneDefault(valoreDiRitorno.risultatoRitorno[0].DATA_AZIONE);
                        setAzioneDefault(valoreDiRitorno.risultatoRitorno[0].AZIONE);
                    }
                    setRicercaValoriCrm(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function tornaElenco() {
        props.callbackTermineModifica();
    }

    function tornaElenco() {
        props.callbackTermineModifica();
    }

    function tornaScheda(valore) {
        //setFlagAttivaModaleRisorsaNonCancellabile(0);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }
    const modificaEsito = (index, newValue) => {
        const nuovoArray = [...arrayComunicazioni];
        nuovoArray[index].ESITO = newValue;
    
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') {
            aggiungiElementoVuoto(nuovoArray);
        }
    
        setArrayComunicazioni(nuovoArray);
    };
    const modificaMezzo = (index, newValue) => {
        const nuovoArray = [...arrayComunicazioni];
        nuovoArray[index].MEZZO = newValue;
    
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') {
            aggiungiElementoVuoto(nuovoArray);
        }
    
        setArrayComunicazioni(nuovoArray);
    };
    const modificaContenuto = (index, newValue) => {
        const nuovoArray = [...arrayComunicazioni];
        nuovoArray[index].CONTENUTO = newValue;
    
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') {
            aggiungiElementoVuoto(nuovoArray);
        }
    
        setArrayComunicazioni(nuovoArray);
    };
    const modificaRisposta = (index, newValue) => {
        const nuovoArray = [...arrayComunicazioni];
        nuovoArray[index].RISPOSTA = newValue;
    
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') {
            aggiungiElementoVuoto(nuovoArray);
        }
    
        setArrayComunicazioni(nuovoArray);
    };
    const modificaData = (index, newValue) => {
        const nuovoArray = [...arrayComunicazioni];
        nuovoArray[index].DATA = newValue;
        
        // Controlla se è l'ultima riga e se è necessario aggiungere una nuova riga
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') {
            aggiungiElementoVuoto(nuovoArray);
        }
    
        setArrayComunicazioni(nuovoArray);
    };

    const eliminaComunicazione = (elemento) => {
        const nuovoArray = arrayComunicazioni.filter(comm => comm.ID !== elemento.ID);
        setArrayComunicazioni(nuovoArray);
        handleCambiamentoMod(); 
    };

    const aggiungiElementoVuoto = (arr) => {
        arr.push({
            ID: `new-${Date.now()}`,//casuale in modo da renderlo univoco
            DATA: '',
            MEZZO: '',
            ESITO: '',
            CONTENUTO: '',
            RISPOSTA: ''
        });
        return arr;
    };

    const aggiungiRigaVuota = () => {
        const nuovoArray = [...arrayComunicazioni];
    
        // Aggiunge una nuova riga vuota
        nuovoArray.push({
            ID: `new-${Date.now()}`,//casuale in modo da renderlo univoco
            DATA: '',
            MEZZO: '',
            ESITO: '',
            CONTENUTO: '',
            RISPOSTA: ''
        });
    
        // Aggiorna lo stato
        setArrayComunicazioni(nuovoArray);
    };

    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;

        //controllo che i campi siano stati completati in modo corretto:
        arrayComunicazioni.forEach(item => {
            if (item.ID !== '') {
                // Controllo se DATA è vuota:
                if (item.ESITO==''){
                    //accetto che non metti esito e metto 'Neutro':
                    item.ESITO = 'Neutro';
                } 
            }
        });

        if(controlli===0){ 

            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                
                setLoading(true);
                // parametri per inviare i dati al server:
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ?  
                        JSON.stringify({op:operazione,
                        arrayComunicazioni:arrayComunicazioni,
                        azione:azioneDefault,
                        dataAzione:dataAzioneDefault,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Data Mancante") !== -1){
                                    setModaleNoData(true);
                                }   
                                if(valoreDiRitorno.mess.indexOf("Mezzo Mancante") !== -1){
                                    setModaleNoMezzo(true);
                                }   
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }
   
    
        return (
            <>
                
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <Card className="main-card mb-3"><Clessidra loading={true}/></Card> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1Sto_Cont {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <Card className="main-card mb-3"><Clessidra loading={true}/></Card> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            
                            {props.ope === "modifica" ? <CardTitle>Storico Comunicazioni con {cognome} {nome} 
                                {numeroTessera != ""? <> {numeroTessera} </> :""}
                                - {tipo}
                            </CardTitle> :""}

                            {modaleInfoParametri===true ?
                            <Modale 
                                titolo="Informativa Parametri"
                                flagErrore={false}
                                contenuto={<div>I valori riportati tra parentesi sono la media dei valori degli altri iscritti o degli altri contatti/prospect a seconda dell'appartenza del destinatario</div>}
                                bottoni={[
                                    {
                                        "etichetta":"OK",
                                        "tipo":"primary",
                                        callback:() => {setDisabilitaBottoni(false);setModaleInfoParametri(false)}
                                    }    
                                ]}
                            />
                            :""}


                            {modaleInfoDestinatari===true ?
                            <Modale 
                                titolo="Informativa dati di contatto destinatari"
                                flagErrore={false}
                                contenuto={<div>Nel caso in cui all'iscritto o al prospect venga assegnato un riferimento con flag referente acceso, i dati di contatto saranno quelli del riferimento</div>}
                                bottoni={[
                                    {
                                        "etichetta":"OK",
                                        "tipo":"primary",
                                        callback:() => {setDisabilitaBottoni(false);setModaleInfoDestinatari(false)}
                                    }    
                                ]}
                            />
                            :""}
                           
                            
                            <Clessidra loading={loading}/>
                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione dai contattati cancellandone tutte le comunicazioni?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :
                                <>

                                {modaleNoMezzo===true ?
                                    <Modale 
                                        titolo="Richiesto Mezzo di Comunicazione"
                                        flagErrore={true}
                                        contenuto={<div>Non è stato indicato il mezzo di comunicazione, che è necessario</div>}
                                        bottoni={[
                                            {
                                                "etichetta":"OK",
                                                "tipo":"primary",
                                                callback:() => {setDisabilitaBottoni(false);setModaleNoMezzo(false)}
                                            }    
                                        ]}
                                    />
                                :""}    

                                {modaleNoData===true ?
                                    <Modale 
                                        titolo="Richiesta Data"
                                        flagErrore={true}
                                        contenuto={<div>Non è stata indicata la data, che è necessaria</div>}
                                        bottoni={[
                                            {
                                                "etichetta":"OK",
                                                "tipo":"primary",
                                                callback:() => {setDisabilitaBottoni(false);setModaleNoData(false)}
                                            }    
                                        ]}
                                    />
                                :""}

                                {/*numero comunicazioni {arrayComunicazioni.length}*/}
                                <Row form> 
                                    <Col md={12}>
                                    <div className='schedaSottotitolo'>
                                        Dati di Contatto<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoDestinatari(true)}/>
                                    </div>
                                </Col>
                                </Row>
                                <Row form>
                                    <Col md={6}>
                                    Cellulare : <b>{cell}</b>
                                    </Col>
                                    <Col md={6}>
                                    Email : <b>{email}</b>
                                    </Col>  
                                </Row>
                                <Row form><Col md={12}><div className='schedaSottotitolo'>Parametri<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoParametri(true)}/></div></Col></Row>
                                <Row form>
                                <Col md={6}>Punteggio su Risposte : <b style={{ color: Number(puntRisp) >= Number(puntRispMediaAltri) ? 'green' : 'red' }}>{FormattaImportoVideo(puntRisp)}</b> ({FormattaImportoVideo(puntRispMediaAltri)})</Col>
                                    <Col md={6}>Risposte esito Positivo : <b style={{ color: Number(percRispostePos) >= Number(percRispostePosMediaAltri) ? 'green' : 'red' }}>{FormattaImportoVideo(percRispostePos)}%</b> ({FormattaImportoVideo(percRispostePosMediaAltri)}%)</Col>
                                    
                                </Row>
                                

                                <Row form>
                                <Col md={6}>Risposte esito Neutro : <b>{FormattaImportoVideo(percRisposteNeu)}%</b> ({FormattaImportoVideo(percRisposteNeuMediaAltri)}%)</Col>
                                <Col md={6}>Risposte esito Negativo : <b style={{ color: Number(percRisposteNeg) > Number(percRisposteNegMediaAltri) ? 'red' : 'green' }}>{FormattaImportoVideo(percRisposteNeg)}%</b> ({FormattaImportoVideo(percRisposteNegMediaAltri)}%)</Col>
                                    {/*<Col md={4}>Punteggio su Abbonamenti : <b style={{ color: Number(puntAbbo) >= Number(puntAbboMediaAltri) ? 'green' : 'red' }}>{FormattaImportoVideo(puntAbbo)}</b> ({FormattaImportoVideo(puntAbboMediaAltri)})</Col>
                                    <Col md={4}>Punteggio su Eventi : <b style={{ color: Number(puntEve) >= Number(puntEveMediaAltri) ? 'green' : 'red' }}>{FormattaImportoVideo(puntEve)}</b> ({FormattaImportoVideo(puntEveMediaAltri)})</Col>
                                */}
                                </Row>

                                <Row form><Col md={12}><div className='schedaSottotitolo'>Prossima azione da intraprendere nei confronti del contattato</div></Col></Row>
                                <Row form>
                                    <Col md={2}> 
                                    <FormGroup>
                                        <Label for="testo">Data</Label>
                                                <Input maxLength={10} type="date" name="dataAzione" id="dataAzione"
                                                        innerRef={dataAzione} 
                                                        placeholder=""
                                                        defaultValue={dataAzioneDefault}
                                                        value={dataAzioneDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setDataAzioneDefault(dataAzione.current.value);}}/> 
    
                                        </FormGroup>
                                    </Col>
                                    <Col md={10}>
                                        <FormGroup>
                                            <Label for="testo">Azione</Label>
                                                <Input maxLength={200} type="textarea" name="azione" id="azione"  defaultValue={azioneDefault} innerRef={azione}
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setAzioneDefault(azione.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row> 

                                <Row form> 
                                    <Col md={12}>
                                    <div className='schedaSottotitolo'>
                                        Comunicazioni
                                    </div>
                                </Col>
                                </Row>
                                <Row form> 
                                    <Col md={12}>
                                    <table>
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <td style={{paddingLeft:2,paddingRight:2}}></td>
                                            <td style={{width: '5%',paddingRight:2}}>Data*</td>
                                            <td style={{width: '20%',paddingRight:2}}>Mezzo di Comunicazione*</td>
                                            <td style={{width: '10%',paddingRight:2}}>Esito</td>
                                            <td style={{width: '40%',paddingRight:2}}>Testo</td>
                                            <td style={{width: '40%',paddingRight:2}}>Risposta</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    {arrayComunicazioni !== undefined && arrayComunicazioni.length > 0 ? 
                                        arrayComunicazioni.map((elemento, index) => 
                                            <tr key={index}>
                                                <td><img onClick={() => { handleCambiamentoMod(1); eliminaComunicazione(elemento)}} src={Cestino}/></td>
                                                <td style={{paddingLeft:2, paddingRight:2, textAlign:'right'}}>{index + 1}</td>
                                                <td>
                                                    <Input 
                                                        maxLength={200} 
                                                        type="date"  
                                                        value={elemento.DATA} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaData(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> 
                                                </td>
                                                <td>
                                                <select
                                                    className="form-control"
                                                    style={{ width: '100%' }}
                                                    value={elemento.MEZZO} 
                                                    onChange={(e) => {
                                                        modificaMezzo(index, e.target.value);
                                                        handleCambiamentoMod(e)
                                                    }}
                                                >
                                                    <option></option>
                                                    {risultatoServerDatiMezziComunicazione.map((elementoMezzo, indexMezzo) => (
                                                        <option 
                                                            key={indexMezzo} 
                                                            value={risultatoServerDatiMezziComunicazione[indexMezzo].DESCRIZIONE}>
                                                            {risultatoServerDatiMezziComunicazione[indexMezzo].DESCRIZIONE}
                                                        </option>
                                                    ))}
                                                </select>
                                                
                                                </td>
                                                <td>
                                                <select
                                                    className="form-control"
                                                    style={{ width: '100%' }}
                                                    value={elemento.ESITO} 
                                                    onChange={(e) => {
                                                        modificaEsito(index, e.target.value);
                                                        handleCambiamentoMod(e)
                                                    }}
                                                >
                                                    <option></option>
                                                    {arrayEsitiPossibili.map((elementoPossibile, indexPossibile) => (
                                                        <option 
                                                            key={indexPossibile} 
                                                            value={arrayEsitiPossibili[indexPossibile]}>
                                                            {arrayEsitiPossibili[indexPossibile]}
                                                        </option>
                                                    ))}
                                                </select>
                                                </td>
                                                <td>
                                                    {/*<Input 
                                                        maxLength={200} 
                                                        type="text"  
                                                        value={elemento.CONTENUTO} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaContenuto(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> */}
                                                   <textarea
                                                        key={index}
                                                        maxLength={200}
                                                        value={elemento.CONTENUTO}
                                                        placeholder=""
                                                        style={textareaStyleContenuto(index)} // Applica lo stile condizionale
                                                        onChange={(e) => {
                                                            modificaContenuto(index, e.target.value);
                                                            handleCambiamentoMod(e);
                                                        }}
                                                        onMouseEnter={() => handleMouseEnterContenuto(index)}
                                                        onMouseLeave={handleMouseLeaveContenuto}
                                                    />
                                                </td>
                                                <td>
                                                    {/*<Input 
                                                        maxLength={200} 
                                                        type="text"  
                                                        value={elemento.RISPOSTA} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaRisposta(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> */}

                                                    <textarea
                                                        key={index}
                                                        maxLength={200}
                                                        value={elemento.RISPOSTA}
                                                        placeholder=""
                                                        style={textareaStyleRisposta(index)} // Applica lo stile condizionale
                                                        onChange={(e) => {
                                                            modificaRisposta(index, e.target.value);
                                                            handleCambiamentoMod(e);
                                                        }}
                                                        onMouseEnter={() => handleMouseEnterRisposta(index)}
                                                        onMouseLeave={handleMouseLeaveRisposta}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    : ""}
                                        
                                    </table>
                        
                                    </Col>
                                </Row>
                                </>
                            }
                                
                                 
                                </div>

                                <div className="schedaSpazioInferiore">
                                <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={aggiungiRigaVuota}>Aggiungi Comunicazione</Button>
                                {/*<Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);setMostraRigaAggiungi(true)}}>Aggiungi Comunicazione</Button>   */}
                              
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                           
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                            
                </div>
                :""}
            </>
        );
}
export default FormGridFormRow;
