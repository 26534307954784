import React, {Fragment,useState,useEffect,useRef} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button,FormGroup,Label,Input,Modal } from 'reactstrap';

import Parametri from '../../parametri';
import arrayColoriGrafico from '../../coloriGrafici';

import FormattaImportoVideo from '../FormattaImportoVideo.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

//per grafici:
//import {Bar} from 'react-chartjs-2';
//import {Pie} from 'react-chartjs-2';

//possibili grafici(possible exports: Bar, Bubble, Chart, Doughnut, Line, Pie, PolarArea, Radar, Scatter, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent)


import {Bar} from 'react-chartjs-2';//istogramma a barre verticali
import {Doughnut} from 'react-chartjs-2';//un grafico a torta con stile ciambella
//import {HorizontalBar} from 'react-chartjs-2';//istogramma a barre orizzontali
import {Line} from 'react-chartjs-2';//grafico con linea su piano cartesiano
import {Pie} from 'react-chartjs-2';//la classica torta
import {PolarArea} from 'react-chartjs-2';//tipo torta ma con un aspetto grafico più ricercato e tecnologico
import {Radar} from 'react-chartjs-2';//tipo radar con ai vertici le voci; è possibile rappresentare più dataset
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import styles from '../stiliPdf.js'

import { calcolaAnnoOggi } from '../../funzioni.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;

const STATO_SCARICA_PDF = 8;
const STATO_PREPARA_PDF = 9;


const COLORE_TITOLI_REPORT="#a5a5a4";

function TabellaReportSponsor(props) {

  const sponsorizzazioniGraficoNettiCanvas = useRef();
  const sponsorizzazioniGraficoIvaCanvas = useRef();
  const sponsorizzazioniGraficoCanvas = useRef();

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const [ricercaCompletata,setRicercaCompletata]=useState(0);

    const [necessitaRicalcolo,setNecessitaRicalcolo]=useState(0);
    const [topDefault,setTopDefault]=useState('10');
    const top = useRef();

    const coloriGraficoSfondo=arrayColoriGrafico;
    const coloriGraficoSfondoIva=arrayColoriGrafico;
    const coloriGraficoSfondoNetti=arrayColoriGrafico;

    const coloriGraficoBordo='rgb(0,0,0)';
    const coloriGraficoBordoIva='rgb(0,0,0)';
    const coloriGraficoBordoNetti='rgb(0,0,0)';

    const larghezzaBordoGrafico=1;
 
    const larghColonnaDati=1;
    const [larghColonnaSponsor,setLarghColonnaSponsor]=useState(9);
    

    const flagVisualizzaImportiNetti   = useRef();
    const flagVisualizzaImportiIva   = useRef();
    const flagVisualizzaImportiLordi   = useRef();
    const [flagVisualizzaImportiNettiDefault,setflagVisualizzaImportiNettiDefault]=useState(1);
    const [flagVisualizzaImportiIvaDefault,setflagVisualizzaImportiIvaDefault]=useState(1);
    const [flagVisualizzaImportiLordiDefault,setflagVisualizzaImportiLordiDefault]=useState(1);

    const  radioPeriodo = useRef();
    const [radioPeriodoDefault,setRadioPeriodoDefault]=useState("1");
 
    //const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno;

    const operazione = "reportSponsor";

    //const  intervallo = useRef();
    const  dataPartenza = useRef();
    
    const [risultatoServer,setRisultatoServer]=useState('');
    //const [entiScadenza,setEntiScadenza]=useState('');
    
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);

    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);
    //const [dataPartenzaDefault,setDataPartenzaDefault]=useState(today.getFullYear());
  
    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');

    //per grafici:
    const [dataSponsorizzazioniNetti,setDataSponsorizzazioniNetti]=useState([]);
    const [dataSponsorizzazioniIva,setDataSponsorizzazioniIva]=useState([]);
    const [dataSponsorizzazioni,setDataSponsorizzazioni]=useState([]);

    //DATI DA VISIONARE:
    const [arraySponsor,setArraySponsor]=useState([]);
    const [arraySponsorizzazioniImporti,setArraySponsorizzazioniImporti]=useState([]);
    const [arraySponsorizzazioniImportiNetti,setArraySponsorizzazioniImportiNetti]=useState([]);
    const [arraySponsorizzazioniImportiIva,setArraySponsorizzazioniImportiIva]=useState([]);

    const [statoTotaleSponsorizzazioniAnno,setStatoTotaleSponsorizzazioniAnno]=useState(0);
    const [statoTotaleSponsorizzazioniNettiAnno,setStatoTotaleSponsorizzazioniNettiAnno]=useState(0);
    const [statoTotaleSponsorizzazioniIvaAnno,setStatoTotaleSponsorizzazioniIvaAnno]=useState(0);

    const [arraySponsorTopImporti,setArraySponsorTopImporti]=useState([]);
    const [arraySponsorTopImportiNetti,setArraySponsorTopImportiNetti]=useState([]);
    const [arraySponsorTopIva,setArraySponsorTopIva]=useState([]);
    const [arraySponsorizzazioniTopIva,setArraySponsorizzazioniTopIva]=useState([]);
    const [arraySponsorizzazioniTopImportiNetti,setArraySponsorizzazioniTopImportiNetti]=useState([]);
    const [arraySponsorizzazioniTopImporti,setArraySponsorizzazioniTopImporti]=useState([]);

    const [visualizzaAltro,setVisualizzaAltro]=useState(0);
    const  visualizzaAltroRif = useRef();
    //consensi visualizzazione grafici:
    const [visualizzaSponsorizzazioni,setVisualizzaSponsorizzazioni]=useState(1);

    //tipologie di grafici per ogni sezione:
    const [tipoGraficoSponsorizzazioni,setTipoGraficoSponsorizzazioni]=useState("Bar");

    const [graficoImporti,setGraficoImporti]=useState(0);
    const [graficoIva,setGraficoIva]=useState(0);
    const [graficoNetti,setGraficoNetti]=useState(0);

    //consensi visualizzazione tabelle:
    const [visualizzaTab,setVisualizzaTab]=useState(1);

    //nomi dei tipi di grafici: (se cambi vai a modificare anche le select dei tipi che ora sono in ordine alfabetico secondo quete deciture)
    let descriDoughnut = "Ciambella";
    let descriLine = "Funzione";
    //let descriHorizontalBar = "Istogramma orizzontale";
    let descriBar = "Istogramma verticale";
    let descriPolarArea = "Polare";
    let descriRadar = "Radar";
    let descriPie =  "Torta";

    const  TGSponsorizzazioni = useRef();

    //modale per attivar le impostazioni:
    const [modaleImpostazioni, setModaleImpostazioni] = React.useState(false);

    const [tableColStato,setTableColStato]=useState(0);
    /*const styles = StyleSheet.create({
      page: {flexDirection: 'row',paddingBottom: 50,paddingTop: 50,},
      titolo: {fontFamily: 'Helvetica-Bold',marginLeft: 1, fontSize: 18},
      titoloPiccolo: {fontFamily: 'Helvetica-Bold',marginLeft: 10, fontSize: 10},
      image: {height: 150,width: 200},
      logo: {height: 60,width: 80,objectFit: 'contain'},
      nomesocieta: {fontFamily: 'Helvetica-Bold',marginLeft: 1,marginBottom: 5, fontSize: 22,},
      viewer: {height: '100vh',width: '150vh'},

      table: {display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0,margin: 10, }, 
      tableIntestazione: { display: "table", width: "auto", borderRightWidth: 0, borderBottomWidth: 0,margin: 10,}, 
      tableRowGrigia: { margin: "auto", flexDirection: "row",height: "auto",backgroundColor : "silver",}, 
      tableRow: { margin: "auto", flexDirection: "row",height: "auto"}, 
      tableRowIntestazione: { margin: "auto", flexDirection: "row",height: 60,}, 
      tableRowBlu: { margin: "auto", flexDirection: "row",backgroundColor : "#99cbff",height: "auto",}, 
      tableColIntestazioneSx: { width: 100/3+"%", borderLeftWidth: 0, borderTopWidth: 0},
      tableColIntestazioneDx: { width: 2*100/3+"%", borderLeftWidth: 0, borderTopWidth: 0 },
      tableCol: { width: 100/4+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
      tableCell: { margin: "auto", fontSize: 10, },
      tableCellIntestazione: { marginLeft: 0,fontSize: 10},
      tableCellGrassettoAllineatoSinistra: {fontFamily: 'Helvetica-Bold',textAlign: 'left',marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellGrassettoAllineatoDestra: {fontFamily: 'Helvetica-Bold',textAlign: 'right',marginRight: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellAllineatoSinistra: {textAlign: 'left',marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellAllineatoDestra: {textAlign: 'right',marginRight: 5, fontSize: 10,marginTop: 3,marginBottom: 3},

      grafici: {width: 300,height: 300,},
      graficiLunghi: {width: 400,height: 200,},
      pageNumber:{position: "absolute",fontSize: 12,bottom:30,left:0,right:0,textAlign:'center',color:"gray"}
    });*/

    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    const [cambiamento,setCambiamento] = useState(0);
    const [ricercaImpostazioniReport,setRicercaImpostazioniReport] = useState(0);

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    useEffect(() => {
      let sommaColonne=1;
      let larghezzaColonnaSponsorLocale = 12;
      if(flagVisualizzaImportiNettiDefault === 1){
        sommaColonne = sommaColonne + 1;
        larghezzaColonnaSponsorLocale = larghezzaColonnaSponsorLocale - 1;
      } 
      if(flagVisualizzaImportiIvaDefault === 1){
        sommaColonne = sommaColonne + 1;
        larghezzaColonnaSponsorLocale = larghezzaColonnaSponsorLocale - 1;
      } 
      if(flagVisualizzaImportiLordiDefault === 1){
        sommaColonne = sommaColonne + 1;
        larghezzaColonnaSponsorLocale = larghezzaColonnaSponsorLocale - 1;
      } 
      setTableColStato(sommaColonne);
      setLarghColonnaSponsor(larghezzaColonnaSponsorLocale);
      if(statoPagina === STATO_PREPARA_PDF){
        setStatoPagina(STATO_SCARICA_PDF);
      }
    },[statoPagina,flagVisualizzaImportiNettiDefault,flagVisualizzaImportiIvaDefault,flagVisualizzaImportiLordiDefault]);

    useEffect(() => {
      if(ricercaCompletata === 1
        && dataSponsorizzazioniNetti
        && dataSponsorizzazioniIva
        && dataSponsorizzazioni
        && ricercaLogo === 1
        && ricercaImpostazioniReport === 1
        ){
        setStatoPagina(STATO_OPERAZIONE_CORRETTA);
        setRicercaCompletata(0);
      }
    },[ricercaCompletata,ricercaLogo,ricercaImpostazioniReport])

    useEffect(() => { 
      //grafico Sponsorizzazioni
      setDataSponsorizzazioniNetti({labels: [''],
          datasets: [{label: 'Sponsorizzazioni (Imponibili)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataSponsorizzazioniIva({labels: [''],
          datasets: [{label: 'Sponsorizzazioni (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataSponsorizzazioni({labels: [''],
          datasets: [{label: 'Sponsorizzazioni (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      scaricaLogo();
      trovaImpostazioniReport();
      },[])

    useEffect(() => {
      //grafico Sponsorizzazioni Importi Netti
      if(arraySponsorTopImportiNetti.length>0){
      setDataSponsorizzazioniNetti({labels: arraySponsorTopImportiNetti,
      datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondoNetti,
          borderColor: coloriGraficoBordoNetti,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arraySponsorizzazioniTopImportiNetti
      },
      ]});
    }
    },[arraySponsorizzazioniTopImportiNetti]);

    useEffect(() => {
      //grafico Sponsorizzazioni Importi Iva
      if(arraySponsorTopIva.length>0){
      setDataSponsorizzazioniIva({labels: arraySponsorTopIva,
      datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondoIva,
          borderColor: coloriGraficoBordoIva,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arraySponsorizzazioniTopIva
      },
      ]});
    }
    },[arraySponsorizzazioniTopIva]);

    useEffect(() => {
      //grafico Sponsorizzazioni Importi Lordi
      if(arraySponsorTopImporti.length>0){
      setDataSponsorizzazioni({labels: arraySponsorTopImporti,
      datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondo,
          borderColor: coloriGraficoBordo,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arraySponsorizzazioniTopImporti
      },
      ]});
    }
    },[arraySponsorizzazioniTopImporti]);

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI && ricercaImpostazioniReport===1){
        setGraficoImporti(0);
        setGraficoNetti(0);
        setGraficoIva(0);
        
        // parametri per inviare i dati al server:
        //console.log("RICERCA"+visualizzaAltro);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            op:operazione,
            visualizzaAltro:visualizzaAltro,
            sogliaTop:topDefault,
            dataPartenza:dataPartenzaDefault,
            radioPeriodo:radioPeriodoDefault,
            emak: props.emak,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              
              //console.log("RISULTATO"+valoreDiRitorno.risultatoOperazione);
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                let arrLocale1=[];//Sponsorizzazioni -> sponsor
                let arrLocale2=[];//Sponsorizzazioni -> importi lordi
                let arrLocale3=[];//Sponsorizzazioni -> importi netti
                let arrLocale4=[];//Sponsorizzazioni -> importi iva

                let totaleSponsorizzazioniAnno = 0;
                let totaleSponsorizzazioniNettiAnno = 0;
                let totaleSponsorizzazioniIvaAnno = 0;

                let arrLocale1TopImporti=[];
                let arrLocale5TopImporti=[];
                let arrLocale1TopIva=[];
                let arrLocale5TopIva=[];
                let arrLocale1TopImportiNetti=[];
                let arrLocale5TopImportiNetti=[];
      
                for (let i=0; i<valoreDiRitorno.risultatoRitorno[0].dati.length ; i++) {
                  arrLocale1[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].SPONSOR;
                  setArraySponsor(arrLocale1);
                  arrLocale2[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_SPONSORIZZAZIONI;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_SPONSORIZZAZIONI) !== 0) setGraficoImporti(1);
                  setArraySponsorizzazioniImporti(arrLocale2);
                  arrLocale3[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_NETTO_SPONSORIZZAZIONI;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_NETTO_SPONSORIZZAZIONI) !== 0) setGraficoNetti(1);
                  setArraySponsorizzazioniImportiNetti(arrLocale3);
                  arrLocale4[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IVA_SPONSORIZZAZIONI;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IVA_SPONSORIZZAZIONI) !== 0) setGraficoIva(1);
                  setArraySponsorizzazioniImportiIva(arrLocale4);
                  //totali:
                  totaleSponsorizzazioniAnno = totaleSponsorizzazioniAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_SPONSORIZZAZIONI);
                  totaleSponsorizzazioniNettiAnno = totaleSponsorizzazioniNettiAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_NETTO_SPONSORIZZAZIONI);
                  totaleSponsorizzazioniIvaAnno = totaleSponsorizzazioniIvaAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IVA_SPONSORIZZAZIONI);
                }

                for (let i1=0; i1<valoreDiRitorno.risultatoRitorno[1].topImporti.length ; i1++) {
                  arrLocale1TopImporti[i1]=valoreDiRitorno.risultatoRitorno[1].topImporti[i1].SPONSOR;
                  setArraySponsorTopImporti(arrLocale1TopImporti);
                  arrLocale5TopImporti[i1]=parseFloat(valoreDiRitorno.risultatoRitorno[1].topImporti[i1].IMPORTI);
                  setArraySponsorizzazioniTopImporti(arrLocale5TopImporti);
                }
                for (let i2=0; i2<valoreDiRitorno.risultatoRitorno[2].topIva.length ; i2++) {
                  arrLocale1TopIva[i2]=valoreDiRitorno.risultatoRitorno[2].topIva[i2].SPONSOR;
                  setArraySponsorTopIva(arrLocale1TopIva);
                  arrLocale5TopIva[i2]=parseFloat(valoreDiRitorno.risultatoRitorno[2].topIva[i2].IVA);
                  setArraySponsorizzazioniTopIva(arrLocale5TopIva);
                }
                for (let i3=0; i3<valoreDiRitorno.risultatoRitorno[3].topImportiNetti.length ; i3++) {
                  arrLocale1TopImportiNetti[i3]=valoreDiRitorno.risultatoRitorno[3].topImportiNetti[i3].SPONSOR;
                  setArraySponsorTopImportiNetti(arrLocale1TopImportiNetti);
                  arrLocale5TopImportiNetti[i3]=parseFloat(valoreDiRitorno.risultatoRitorno[3].topImportiNetti[i3].IMPORTI);
                  setArraySponsorizzazioniTopImportiNetti(arrLocale5TopImportiNetti);
                }

                setInizioPeriodo(valoreDiRitorno.risultatoRitorno[4].periodo[0].DATA_INIZIO_PERIODO);
                setFinePeriodo(valoreDiRitorno.risultatoRitorno[4].periodo[0].DATA_FINE_PERIODO);
                
                //ora setto le var di stato dei totali:
                setStatoTotaleSponsorizzazioniAnno(totaleSponsorizzazioniAnno);
                setStatoTotaleSponsorizzazioniNettiAnno(totaleSponsorizzazioniNettiAnno);
                setStatoTotaleSponsorizzazioniIvaAnno(totaleSponsorizzazioniIvaAnno);

                setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                //setStatoPagina(STATO_OPERAZIONE_CORRETTA);
                setRicercaCompletata(1);
              }
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina,ricercaImpostazioniReport])
    //},[props.numPagina])

    useEffect(() => {
      if(necessitaRicalcolo===1){
        //resetto tutte le var di stato:
        setArraySponsor([]);
        setArraySponsorizzazioniImporti([]);
        setArraySponsorizzazioniImportiNetti([]);
        setArraySponsorizzazioniImportiIva([]);
        setStatoTotaleSponsorizzazioniAnno(0);
        setStatoTotaleSponsorizzazioniNettiAnno(0);
        setStatoTotaleSponsorizzazioniIvaAnno(0);
        setStatoPagina(STATO_RICERCA_DATI); 
        setNecessitaRicalcolo(0);   
      }
    },[necessitaRicalcolo])

    function scaricaLogo() {
      //console.log("scaricaLogo");
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({op:"ricercaLogo",emak: props.emak, sessionId: props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          
            if(valoreDiRitorno.risultatoOperazione===1){
                if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                  setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                  setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                  setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                  setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                  setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                  setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                  setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                  setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
                }
                setRicercaLogo(1);
            }
        },
        (error) => {console.log("Errore connessione");}
      );        
    }

    function chiudiModaleImpostazioni() {
      setModaleImpostazioni(false);
      //setStatoPagina(STATO_OPERAZIONE_CORRETTA);
      if(necessitaRicalcolo === 2) setNecessitaRicalcolo(1);
      if(cambiamento === 1) aggiornaImpostazioniReport();
      
    }
    function ResettaImpostazioni() {
      setTipoGraficoSponsorizzazioni("Bar");
    }

    function funzioneTrasformaDateAUmano(data) {
      var dataOutput='';
      //da formato aaaa-mm-gg a gg/mm/aaaa
      if(data){
          const arrdata = data.split('-');
          let anno = arrdata[0];
          let mese = arrdata[1];
          let giorno = arrdata[2];
          dataOutput = giorno+"/"+mese+"/"+anno;
      }else dataOutput = '';
  
      return dataOutput;
   
  }

  function aggiornaVistaImporti(tipo){

    if(tipo === 'netti'){
        if(flagVisualizzaImportiNettiDefault === 1){
          setflagVisualizzaImportiNettiDefault(0); 
          setLarghColonnaSponsor(larghColonnaSponsor+3);  
        }  
        if(flagVisualizzaImportiNettiDefault === 0){
          setflagVisualizzaImportiNettiDefault(1); 
          setLarghColonnaSponsor(larghColonnaSponsor-3);  
        } 
    }
    if(tipo === 'iva'){
      if(flagVisualizzaImportiIvaDefault === 1){
        setflagVisualizzaImportiIvaDefault(0); 
        setLarghColonnaSponsor(larghColonnaSponsor+3); 
      } 
      if(flagVisualizzaImportiIvaDefault === 0){
        setflagVisualizzaImportiIvaDefault(1); 
        setLarghColonnaSponsor(larghColonnaSponsor-3);
      } 
    }
    if(tipo === 'lordi'){
      if(flagVisualizzaImportiLordiDefault === 1){
        setflagVisualizzaImportiLordiDefault(0);
        setLarghColonnaSponsor(larghColonnaSponsor+3); 
      }  
      if(flagVisualizzaImportiLordiDefault === 0){
        setflagVisualizzaImportiLordiDefault(1);
        setLarghColonnaSponsor(larghColonnaSponsor-3);
      }
    }
  }
  function trovaImpostazioniReport() {
    //vado a fare la fetch di ricerca delle impostazioni del report per la persona in esame:
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        op:"impostazioniReport",
        ricercaImpostazioni : 1,
        report : 2,
        emak: props.emak,
        sessionId: props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1){
              setVisualizzaTab(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB));
              setVisualizzaSponsorizzazioni(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_SPONSORIZZAZIONI));
              setTipoGraficoSponsorizzazioni(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_SPONSORIZZAZIONI);
              setflagVisualizzaImportiNettiDefault(parseInt(valoreDiRitorno.risultatoRitorno[0].IMPORTI_NETTI));
              setflagVisualizzaImportiIvaDefault(parseInt(valoreDiRitorno.risultatoRitorno[0].IMPORTI_IVA));
              setflagVisualizzaImportiLordiDefault(parseInt(valoreDiRitorno.risultatoRitorno[0].IMPORTI_LORDI));
              setRadioPeriodoDefault(valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO);
              if (valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO==2) setDataPartenzaDefault(new Date().getFullYear()); else setDataPartenzaDefault(annoOggi);
              setTopDefault(valoreDiRitorno.risultatoRitorno[0].TOP_DEFAULT);
              setVisualizzaAltro(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ALTRO);
              setRicercaImpostazioniReport(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    ); 
  }
  function aggiornaImpostazioniReport() {
    setCambiamento(0);
    //vado a fare la fetch di aggiornamento delle impostazioni del report per la persona in esame:
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        op:"impostazioniReport",
        modificaImpostazioni : 1,
        report : 2,
        visualizzaTabella:visualizzaTab,
        visualizzaGrafici:visualizzaSponsorizzazioni,
        tipoGrafici:tipoGraficoSponsorizzazioni,
        importiNetti:flagVisualizzaImportiNettiDefault,
        importiIva:flagVisualizzaImportiIvaDefault,
        importiLordi:flagVisualizzaImportiLordiDefault,
        periodoConsiderato:radioPeriodoDefault,
        elementiSignificativi:topDefault,
        visualizzaAltro:visualizzaAltro,
        emak: props.emak,
        sessionId: props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1 && valoreDiRitorno.risultatoRitorno){
              setVisualizzaTab(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB);
              setVisualizzaSponsorizzazioni(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_SPONSORIZZAZIONI);
              setTipoGraficoSponsorizzazioni(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_SPONSORIZZAZIONI);
              setflagVisualizzaImportiNettiDefault(valoreDiRitorno.risultatoRitorno[0].IMPORTI_NETTI);
              setflagVisualizzaImportiIvaDefault(valoreDiRitorno.risultatoRitorno[0].IMPORTI_IVA);
              setflagVisualizzaImportiLordiDefault(valoreDiRitorno.risultatoRitorno[0].IMPORTI_LORDI);
              setRadioPeriodoDefault(valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO);
              if (valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO==2) setDataPartenzaDefault(new Date().getFullYear()); else setDataPartenzaDefault(annoOggi);
              setTopDefault(valoreDiRitorno.risultatoRitorno[0].TOP_DEFAULT);
              setVisualizzaAltro(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ALTRO);
            
              //setRicercaImpostazioniReport(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    );
  }
 

  return (
    <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}>

      {statoPagina === STATO_SCARICA_PDF ? 
        <div>
          {/*<PDFViewer style={styles.viewer}>*/}
          <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
          <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
            <Document>
              <Page size="A4" style={styles.page}>
                <View style={{width:"100%"}} >
                <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                    <View wrap={false} style={styles.tableRowIntestazione}> 
                      <View style={styles.tableColIntestazioneSx}> 
                        <View style={styles.tableCell}> 
                          {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}
                          
                        </View> 
                      </View> 
                      <View style={styles.tableColIntestazioneDx}>   
                        <View style={styles.tableCellIntestazione}> 
                          {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                          {indirizzoSocieta !== "" ?
                          <Text style={styles.datisocieta}>{indirizzoSocieta}
                          {cittaSocieta !== "" ?
                          capSocieta !== "" ?
                          " "+capSocieta+" "+cittaSocieta
                          :" "+cittaSocieta
                          :""}
                          </Text>
                          :<Text></Text>}
                          {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                          {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                          <Text style={styles.titolo}>
                            Report Sponsor {dataPartenzaDefault}
                          </Text>                           
                        </View> 
                      </View> 
                    </View> 
                  </View> {/*chiude table intestazione*/}

                  <View>
                    <Text style={styles.titoloPiccolo}>
                      Periodo dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano> 
                    </Text>
                  </View>

                  {visualizzaTab === 1 ?
                  <View style={styles.table}>{/*apre table dati*/}
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Sponsor</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo Netto</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>IVA</Text> 
                        </View> 
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo Lordo</Text> 
                        </View> 
                      :<Text></Text>}
                    </View>

                    {arraySponsor.map((elemento,index) => <View wrap={true} style={styles.tableRow} key={index}>
                        {/*<View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,flexDirection: 'row', flexWrap: 'wrap'}}>*/}                   
                            
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0}}>    
                            {/*<Text style={styles.tableCellAllineatoSinistra}>{arraySponsor[index].substr(0,45)}</Text>   e' il limite per tagliare       
                            <Text style={styles.tableCellAllineatoSinistra}>{arraySponsor[index].substr(0,40)}{"\n"}{arraySponsor[index].substr(40,46)}</Text>
                            <Text style={styles.tableCellAllineatoSinistra}>{arraySponsor[index]}</Text>     
                            <Text style={{ display:'flex',flexDirection:'row',flexWrap: 'wrap',marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3 }}>{arraySponsor[index]}</Text>       
                            <Text style={{overflowWrap: "break-word",marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3 }}>{arraySponsor[index]}</Text>
                            <Text style={{maxWidth:230,marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3 }}>{arraySponsor[index]}</Text>*/}
                            <Text style={{marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3 }}>{arraySponsor[index]}</Text>
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(arraySponsorizzazioniImportiNetti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(arraySponsorizzazioniImportiIva[index])}</Text>
                        </View> 
                        :<Text></Text>} 
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(arraySponsorizzazioniImporti[index])}</Text>
                          </View>  
                        :<Text></Text>}                    
                    </View>)}
           

                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleSponsorizzazioniNettiAnno)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleSponsorizzazioniIvaAnno)}</Text>
                          </View> 
                        :<Text></Text>}   
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleSponsorizzazioniAnno)}</Text>
                          </View> 
                        :<Text></Text>}       
                    </View> 
                  </View>
                  :""}

                  {flagVisualizzaImportiNettiDefault === 1 && sponsorizzazioniGraficoNettiCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoSponsorizzazioni === "Line" || tipoGraficoSponsorizzazioni === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + sponsorizzazioniGraficoNettiCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {flagVisualizzaImportiIvaDefault === 1 && sponsorizzazioniGraficoIvaCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoSponsorizzazioni === "Line" || tipoGraficoSponsorizzazioni === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + sponsorizzazioniGraficoIvaCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {flagVisualizzaImportiLordiDefault === 1 && sponsorizzazioniGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoSponsorizzazioni === "Line" || tipoGraficoSponsorizzazioni === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + sponsorizzazioniGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
              </Page>
            </Document>
        </PDFViewer>
      </div>

      :""}

     
    
      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_Rep_Spo</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? 
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
          </div>
        </div> :""}
      {statoPagina === STATO_PREPARA_PDF ?
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
            <Clessidra loading={true}/>
          </div> 
        </div> :""}
   

      {statoPagina === STATO_OPERAZIONE_CORRETTA || statoPagina === STATO_SCARICA_PDF ||statoPagina === STATO_PREPARA_PDF ?
        <div className="schedaSpazioCentrale" style={{display:statoPagina === STATO_SCARICA_PDF?"none":"block"}}>
            
            <Row>
              <Col lg="12">
                    <Card className="main-card mb-3">
                    <CardBody>
                            <FormGroup>
                            <div id="reportIntestazione">
                            <CardTitle>
                            Report Sponsor &nbsp;
                            <select name="dataPartenza" id="dataPartenza" innerRef={dataPartenza} value={parseInt(dataPartenzaDefault)} onChange={(e)=>{setDataPartenzaDefault(e.target.value);setNecessitaRicalcolo(1);}}>
                              {[...Array(11)].map((elemento, indice) => (
                                <option key={indice} value={annoOggi + 1 - indice}>
                                  {annoOggi + 1 - indice}
                                </option>
                              ))}                              
                            </select>  
                               
                            &nbsp; dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano>                  
                            </CardTitle>
                            <Button color="success" className="bottoneMargineASinistra" onClick={() => {setModaleImpostazioni(true)}}>Impostazioni</Button>
                              <Button color="success" className="bottoneMargineASinistra" onClick={(e)=>{setStatoPagina(STATO_PREPARA_PDF);}}>Esporta il PDF</Button>
                            

                            <div id='idDivGenerazionePdf' style={{display:'none'}}>Generazione PDF in corso...</div>

                            {modaleImpostazioni === true ?
                            <Modale 
                                larghezza = "lg"
                                titolo="Impostazioni layout report Sponsor"
                                flagErrore={false}
                                contenuto={<div>
                                  <CardBody>
                                <CardTitle>Impostazioni layout report Sponsor
                                </CardTitle> 
                               
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center></center></b></Col>  
                                <Col><b><center>Tabella</center></b></Col>
                                <Col><b><center>Grafici</center></b></Col>
                                <Col><b><center>Tipo Grafici</center></b></Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Sponsorizzazioni</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaTab" 
                                      defaultChecked = {visualizzaTab === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaTab === 1 ? setVisualizzaTab(0) : setVisualizzaTab(1);setCambiamento(1)}}/></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaSponsorizzazioni" 
                                      defaultChecked = {visualizzaSponsorizzazioni === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaSponsorizzazioni === 1 ? setVisualizzaSponsorizzazioni(0) : setVisualizzaSponsorizzazioni(1);setCambiamento(1)}}/></center></Col>
                                <Col>
                                <center><select style={{marginTop:10}} name="TGSponsorizzazioni" id="TGSponsorizzazioni" innerRef={TGSponsorizzazioni} onChange={(e)=>{setTipoGraficoSponsorizzazioni(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoSponsorizzazioni === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoSponsorizzazioni === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoSponsorizzazioni === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoSponsorizzazioni === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoSponsorizzazioni === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoSponsorizzazioni === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center> 
                                </Col>
                                </Row>
                                </CardBody>

                                <CardBody>
                                {/*settaggio importi da visionare*/}
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center>Importi da visionare</center></b></Col>
                                </Row>
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center> 
                                <Input type="checkbox" name="flagVisualizzaImportiNetti" id="flagVisualizzaImportiNetti"
                                defaultChecked = {flagVisualizzaImportiNettiDefault === 1 ?  'checked' :""}
                                innerRef={flagVisualizzaImportiNetti}                                 
                                placeholder=""
                                onInput={(e)=>{aggiornaVistaImporti("netti");setCambiamento(1)}}/>
                                <Label for="flagVisualizzaImportiNetti">Visualizza Importi Netti</Label>
                                </center> </Col>
                                <Col><center> 
                                <Input type="checkbox" name="flagVisualizzaImportiIva" id="flagVisualizzaImportiIva"
                                defaultChecked = {flagVisualizzaImportiIvaDefault === 1 ?  'checked' :""}
                                innerRef={flagVisualizzaImportiIva}                                 
                                placeholder=""
                                onInput={(e)=>{aggiornaVistaImporti("iva");setCambiamento(1)}}/>
                                <Label for="flagVisualizzaImportiIva">Visualizza IVA</Label>
                                </center> </Col>
                                <Col><center> 
                                <Input type="checkbox" name="flagVisualizzaImportiLordi" id="flagVisualizzaImportiLordi"
                                defaultChecked = {flagVisualizzaImportiLordiDefault === 1 ?  'checked' :""}
                                innerRef={flagVisualizzaImportiLordi}                                 
                                placeholder=""
                                onInput={(e)=>{aggiornaVistaImporti("lordi");setCambiamento(1)}}/>
                                <Label for="flagVisualizzaImportiLordi">Visualizza Importi Lordi</Label>
                                </center> </Col>
                                </Row>
                                </CardBody>

                                <CardBody>
                                {/*settaggio tipo periodo*/}
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center>Periodo Considerato</center></b></Col>
                                </Row>
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center>
                                <FormGroup check>
                                  <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "1" ?  'checked' :""}
                                            value="1"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1);setDataPartenzaDefault(annoOggi)}}/> {'Apertura/Chiusura Esercizio'}
                                  </Label>
                                </FormGroup>
                                </center></Col>
                                <Col><center>
                                <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "2" ?  'checked' :""}
                                            value="2"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1);setDataPartenzaDefault(new Date().getFullYear())}}/> {'Anno Solare'}
                                  </Label>
                                </FormGroup>
                                </center></Col>
                                </Row>
                                </CardBody>
                                <CardBody>
                                {/*settaggio Top N*/}
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center>Nei grafici visualizza i <Input type="select" name="top" id="top" innerRef={top}  onInput={(e)=>{setTopDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1)}}>
                                  {topDefault === '5' ? <option selected value="5">5</option> : <option value="5">5</option> }
                                  {topDefault === '10' || topDefault === '' ? <option selected value="10">10</option> : <option value="10">10</option> }
                                  {topDefault === '20' ? <option selected value="20">20</option> : <option value="20">20</option> }
                                  {topDefault === '30' ? <option selected value="30">30</option> : <option value="30">30</option> }
                                  {topDefault === '40' ? <option selected value="40">40</option> : <option value="40">40</option> }
                                  {topDefault === '50' ? <option selected value="50">50</option> : <option value="50">50</option> }
                                  {topDefault === '60' ? <option selected value="60">60</option> : <option value="60">60</option> }
                                  {topDefault === '70' ? <option selected value="70">70</option> : <option value="70">70</option> }
                                  {topDefault === '80' ? <option selected value="80">80</option> : <option value="80">80</option> }
                                  {topDefault === '90' ? <option selected value="90">90</option> : <option value="90">90</option> }
                                  {topDefault === '100' ? <option selected value="100">100</option> : <option value="100">100</option> }
           
                                </Input> elementi significativi  
                                  
                                </center></Col>
                                <Col>
                                  <Label>Nei grafici visualizza anche dati relativi ad 'Altro'</Label>
                                    <Input className="ml-2 mt-2" type="checkbox" name="visualizzaAltro" id="visualizzaAltro"
                                      defaultChecked = {visualizzaAltro === "1" ?  'checked' :""}
                                      innerRef={visualizzaAltroRif}                                 
                                      placeholder=""
                                      onClick={(e)=>{visualizzaAltro === '1' ? setVisualizzaAltro('0') : setVisualizzaAltro('1');setNecessitaRicalcolo(2);setCambiamento(1)}}/>
                                </Col>
                                </Row>
                                </CardBody>
                                </div>}
                                bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {chiudiModaleImpostazioni()}
                                },    
                                {
                                  "etichetta":"Resetta Impostazioni Grafici",
                                  "tipo":"danger",
                                  callback:() => {ResettaImpostazioni();setCambiamento(1)}
                              },  
                            ]}
                            />
                        :""}
            
                            </div>
                            </FormGroup>

                            <div id="titolo" style={{display:"none"}}>
                            Report Sponsor {dataPartenzaDefault} calcolato dal {funzioneTrasformaDateAUmano(inizioPeriodo)} al {funzioneTrasformaDateAUmano(finePeriodo)} 
                            </div>

                            {risultatoServer !== undefined && risultatoServer !== "" ?
                            <div id="reportSponsor">  
                                <div id="reportSponsorizzazioni"  style={{width:'100%'}}>
                                {visualizzaTab === 1 || visualizzaSponsorizzazioni === 1 ?
                                 <Row><Col><CardTitle>Riepilogo</CardTitle></Col></Row>
                                :""}
                                </div>
                                <div id="Tabella"  style={{width:'100%'}}>  
                                  {((arraySponsorizzazioniImporti.length>0 || arraySponsorizzazioniImportiNetti.length>0 || arraySponsorizzazioniImportiIva.length>0))
                                  ?
                                    visualizzaTab === 1 ?
                                    <Fragment>
                                    <Row form className="border"><Col><b>Sponsor</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>Importo Netto</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>IVA</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>Importo Lordo</b></Col> :""}
                                    </Row>

                                    {arraySponsor.map((elemento,index) => <Row form className="border" key={index} >
                                      {arraySponsor[index]!=='' ? 
                                        <Col>{arraySponsor[index]}</Col>
                                      : <Col>Non specificato</Col>}
                                      
                                      {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arraySponsorizzazioniImportiNetti[index])}</Col> :""} 
                                      {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arraySponsorizzazioniImportiIva[index])}</Col> :""}
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arraySponsorizzazioniImporti[index])}</Col> :""}
                                      </Row>)
                                    }
                                    {/*riga dei totali in fondo alla tabella*/}
                                    <Row form className="border">
                                    <Col><b>TOTALE</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleSponsorizzazioniNettiAnno)}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleSponsorizzazioniIvaAnno)}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleSponsorizzazioniAnno)}</b></Col> :""}
                                    </Row>

                                    </Fragment>
                                    :""
                                  : ""}
                                  
                                  </div>

                                  {flagVisualizzaImportiNettiDefault === 1 && graficoNetti === 1 && tipoGraficoSponsorizzazioni==="Bar" && arraySponsorizzazioniTopImportiNetti.length>0  && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataSponsorizzazioniNetti} ref={sponsorizzazioniGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Netto (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoNetti === 1 && tipoGraficoSponsorizzazioni==="Doughnut" && arraySponsorizzazioniTopImportiNetti.length>0 && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataSponsorizzazioniNetti} ref={sponsorizzazioniGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Netto (Top '+topDefault+')'}},}} /></div> : ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoNetti === 1 && tipoGraficoSponsorizzazioni==="Line" && arraySponsorizzazioniTopImportiNetti.length>0 && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataSponsorizzazioniNetti} ref={sponsorizzazioniGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Netto (Top '+topDefault+')'}},}} /></div> : ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoNetti === 1 && tipoGraficoSponsorizzazioni==="Pie" && arraySponsorizzazioniTopImportiNetti.length>0 && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataSponsorizzazioniNetti} ref={sponsorizzazioniGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Netto (Top '+topDefault+')'}},}} /></div> : ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoNetti === 1 && tipoGraficoSponsorizzazioni==="PolarArea" && arraySponsorizzazioniTopImportiNetti.length>0  && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataSponsorizzazioniNetti} ref={sponsorizzazioniGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Netto (Top '+topDefault+')'}},}} /></div> : ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoNetti === 1 && tipoGraficoSponsorizzazioni==="Radar" && arraySponsorizzazioniTopImportiNetti.length>0 && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataSponsorizzazioniNetti} ref={sponsorizzazioniGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Netto (Top '+topDefault+')'}},}} /></div> : ""}

                                  {flagVisualizzaImportiIvaDefault === 1 && graficoIva === 1 && tipoGraficoSponsorizzazioni==="Bar" && arraySponsorizzazioniTopIva.length>0  && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataSponsorizzazioniIva} ref={sponsorizzazioniGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoIva === 1 && tipoGraficoSponsorizzazioni==="Doughnut" && arraySponsorizzazioniTopIva.length>0 && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataSponsorizzazioniIva} ref={sponsorizzazioniGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA (Top '+topDefault+')'}},}} /></div> : ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoIva === 1 && tipoGraficoSponsorizzazioni==="Line" && arraySponsorizzazioniTopIva.length>0 && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataSponsorizzazioniIva} ref={sponsorizzazioniGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA (Top '+topDefault+')'}},}}  /></div> : ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoIva === 1 && tipoGraficoSponsorizzazioni==="Pie" && arraySponsorizzazioniTopIva.length>0 && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataSponsorizzazioniIva} ref={sponsorizzazioniGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA (Top '+topDefault+')'}},}} /></div> : ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoIva === 1 && tipoGraficoSponsorizzazioni==="PolarArea" && arraySponsorizzazioniTopIva.length>0  && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataSponsorizzazioniIva} ref={sponsorizzazioniGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA (Top '+topDefault+')'}},}} /></div> : ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoIva === 1 && tipoGraficoSponsorizzazioni==="Radar" && arraySponsorizzazioniTopIva.length>0 && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataSponsorizzazioniIva} ref={sponsorizzazioniGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA (Top '+topDefault+')'}},}}  /></div> : ""}
                               
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoImporti === 1 && tipoGraficoSponsorizzazioni==="Bar" && arraySponsorizzazioniTopImporti.length>0  && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataSponsorizzazioni} ref={sponsorizzazioniGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Lordo (Top '+topDefault+')'}},}}  /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoImporti === 1 && tipoGraficoSponsorizzazioni==="Doughnut" && arraySponsorizzazioniTopImporti.length>0 && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataSponsorizzazioni} ref={sponsorizzazioniGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Lordo (Top '+topDefault+')'}},}} /></div> : ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoImporti === 1 && tipoGraficoSponsorizzazioni==="Line" && arraySponsorizzazioniTopImporti.length>0 && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataSponsorizzazioni} ref={sponsorizzazioniGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Lordo (Top '+topDefault+')'}},}} /></div> : ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoImporti === 1 && tipoGraficoSponsorizzazioni==="Pie" && arraySponsorizzazioniTopImporti.length>0 && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataSponsorizzazioni} ref={sponsorizzazioniGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Lordo (Top '+topDefault+')'}},}} /></div> : ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoImporti === 1 && tipoGraficoSponsorizzazioni==="PolarArea" && arraySponsorizzazioniTopImporti.length>0  && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataSponsorizzazioni} ref={sponsorizzazioniGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Lordo (Top '+topDefault+')'}},}} /></div> : ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoImporti === 1 && tipoGraficoSponsorizzazioni==="Radar" && arraySponsorizzazioniTopImporti.length>0 && visualizzaSponsorizzazioni === 1?
                                    <div id="sponsorizzazioniGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataSponsorizzazioni} ref={sponsorizzazioniGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Lordo (Top '+topDefault+')'}},}} /></div> : ""}
                  
                              </div>
                            : 
                              <Table responsive bordered hover striped className="mb-0">
                              <thead>
                                <tr>
                                  <th>Nessun dato presente</th>
                                </tr>
                              </thead>
                              </Table>
                          }
                      </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
   
      :""}
    </div>
   );
}
export default TabellaReportSponsor;