import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input , Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';
import Cestino from "../../immagini/cestino.png";
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setvariazioneCampi]=useState(0);
    
    const [flagAttivaModaleInserisciCognome, setFlagAttivaModaleInserisciCognome] = useState(0);

    const cognome   = useRef();
    const nome   = useRef();
    const codFiscale   = useRef();
    const sesso   = useRef();
    const citta   = useRef();
    const indirizzo   = useRef();
    const provincia   = useRef();
    const provinciaNascita   = useRef();
    const comuneNascita   = useRef();
    const dataNascita   = useRef();
    const email   = useRef();
    const cellulare   = useRef();
    const telefonoFisso   = useRef();
    const note   = useRef();
    const sitoWeb   = useRef();
    const cap   = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');

    const [cognomeDefault,setCognomeDefault] = useState('');
    const [nomeDefault,setNomeDefault] = useState('');
    const [codFiscaleDefault,setCodFiscaleDefault] = useState('');
    const [sessoDefault,setSessoDefault] = useState('');
    const [cittaDefault,setCittaDefault] = useState('');
    const [indirizzoDefault,setIndirizzoDefault] = useState('');
    const [provinciaDefault,setProvinciaDefault] = useState('');
    const [provinciaNascitaDefault,setProvinciaNascitaDefault] = useState('');
    const [comuneNascitaDefault,setComuneNascitaDefault] = useState('');
    const [dataNascitaDefault,setDataNascitaDefault] = useState('');
    const [emailDefault,setEmailDefault] = useState('');
    const [cellulareDefault,setCellulareDefault] = useState('');
    const [telefonoFissoDefault,setTelefonoFissoDefault] = useState('');
    const [noteDefault,setNoteDefault] = useState('');
    const [sitoWebDefault,setSitoWebDefault] = useState('');
    const [capDefault,setCapDefault] = useState('');
    const [modaleInfoDestinatari,setModaleInfoDestinatari]=useState(false);

    const [insSesso,setSesso]=useState('');

    const [sposta,setSposta] = useState(false);

    //const [risultatoServerDatiRisorse,setRisultatoServerDatiRisorse]=useState('');
   
    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);
   
    const [arrayRiferimenti,setArrayRiferimenti]=useState([]);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setSesso(1);
    },[]);

    

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("prospectCrmIns");
        if(props.ope==='modifica') setOperazione("prospectCrmMod");
        if(props.ope==='cancella') setOperazione("prospectCrmCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"prospectCrmRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    setCognomeDefault(valoreDiRitorno.risultatoRitorno[0].COGNOME);
                    setNomeDefault(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setCodFiscaleDefault(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
                    setSessoDefault(valoreDiRitorno.risultatoRitorno[0].SESSO);
                    setCittaDefault(valoreDiRitorno.risultatoRitorno[0].CITTA);
                    setIndirizzoDefault(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                    setProvinciaDefault(valoreDiRitorno.risultatoRitorno[0].PROVINCIA);
                    setProvinciaNascitaDefault(valoreDiRitorno.risultatoRitorno[0].PROVINCIA_NASCITA);
                    setComuneNascitaDefault(valoreDiRitorno.risultatoRitorno[0].COMUNE_NASCITA);
                    setDataNascitaDefault(valoreDiRitorno.risultatoRitorno[0].DATA_NASCITA);
                    setEmailDefault(valoreDiRitorno.risultatoRitorno[0].EMAIL);
                    setCellulareDefault(valoreDiRitorno.risultatoRitorno[0].CELLULARE);
                    setTelefonoFissoDefault(valoreDiRitorno.risultatoRitorno[0].TELEFONO);
                    setNoteDefault(valoreDiRitorno.risultatoRitorno[0].NOTE);
                    setSitoWebDefault(valoreDiRitorno.risultatoRitorno[0].SITO_WEB);
                    setCapDefault(valoreDiRitorno.risultatoRitorno[0].CAP);

                    ricercaRiferimenti(valoreDiRitorno.risultatoRitorno[0].ID);
                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else{
            let arrLocale = [];
            aggiungiElementoVuotoIniziale(arrLocale);
            setArrayRiferimenti(arrLocale);
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        } 
    },[props.ope]);


    function ricercaRiferimenti(idProspect) {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"ricercaRiferimentiCrm",idProspect:idProspect,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    let arrLocale = [];
                    let contatore = 0;

                    valoreDiRitorno.risultatoRitorno.forEach(item => {
                        if (item.ID !== '') {
                            contatore = contatore + 1;
                            arrLocale.push({
                                ID: contatore,
                                COGNOME: item.COGNOME,
                                NOME: item.NOME,
                                CODICE_FISCALE: item.CODICE_FISCALE,
                                CELLULARE: item.CELLULARE,
                                NOTE: item.NOTE,
                                ID_PROSPECT: item.ID_PROSPECT,
                                EMAIL: item.EMAIL,
                                INDIRIZZO: item.INDIRIZZO,
                                CITTA: item.CITTA,
                                PROVINCIA: item.PROVINCIA,
                                CAP: item.CAP,
                                LUOGO_NASCITA: item.LUOGO_NASCITA,
                                DATA_NASCITA: item.DATA_NASCITA,
                                REFERENTE: item.REFERENTE,
                            });
                        }
                    });
                    aggiungiElementoVuotoIniziale(arrLocale);
                    setArrayRiferimenti(arrLocale);
                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function aggiungiElementoVuotoIniziale(arrLocale) {
        arrLocale.push({
            ID: `new-${Date.now()}`,// Campo vuoto per consentire l'inserimento di un nuovo ID ((ne metto uno casuale in modo da renderlo univoco))
            DATA: '', 
            COGNOME:  '', 
            NOME: '', 
            CODICE_FISCALE: '', 
            CELLULARE: '', 
            NOTE: '', 
            ID_PROSPECT: '', 
            EMAIL: '', 
            INDIRIZZO: '', 
            CITTA: '', 
            PROVINCIA: '', 
            CAP: '', 
            LUOGO_NASCITA: '', 
            DATA_NASCITA: '',
            REFERENTE: '0' 
        });
    }

    const aggiungiRigaVuota = () => {
        const nuovoArray = [...arrayRiferimenti];
    
        // Aggiunge una nuova riga vuota
        nuovoArray.push({
            ID: `new-${Date.now()}`,// Campo vuoto per consentire l'inserimento di un nuovo ID ((ne metto uno casuale in modo da renderlo univoco))
            DATA: '', 
            COGNOME:  '', 
            NOME: '', 
            CODICE_FISCALE: '', 
            CELLULARE: '', 
            NOTE: '', 
            ID_PROSPECT: '', 
            EMAIL: '', 
            INDIRIZZO: '', 
            CITTA: '', 
            PROVINCIA: '', 
            CAP: '', 
            LUOGO_NASCITA: '', 
            DATA_NASCITA: '',
            REFERENTE: '0'
        });
    
        // Aggiorna lo stato
        setArrayRiferimenti(nuovoArray);
    };
    const aggiungiElementoVuoto = (arr) => {
        arr.push({
            ID: `new-${Date.now()}`,// Campo vuoto per consentire l'inserimento di un nuovo ID ((ne metto uno casuale in modo da renderlo univoco))
            DATA: '', 
            COGNOME:  '', 
            NOME: '', 
            CODICE_FISCALE: '', 
            CELLULARE: '', 
            NOTE: '', 
            ID_PROSPECT: '', 
            EMAIL: '', 
            INDIRIZZO: '', 
            CITTA: '', 
            PROVINCIA: '', 
            CAP: '', 
            LUOGO_NASCITA: '',
            DATA_NASCITA: '',
            REFERENTE: '0'
        });
        return arr;
    };

    const modificaCognome = (index, newValue) => {
        const nuovoArray = [...arrayRiferimenti];
        nuovoArray[index].COGNOME = newValue;
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') aggiungiElementoVuoto(nuovoArray);
        setArrayRiferimenti(nuovoArray);
    };
    const modificaNome = (index, newValue) => {
        const nuovoArray = [...arrayRiferimenti];
        nuovoArray[index].NOME = newValue;
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') aggiungiElementoVuoto(nuovoArray);
        setArrayRiferimenti(nuovoArray);
    };
    const modificaCodiceFiscale = (index, newValue) => {
        const nuovoArray = [...arrayRiferimenti];
        nuovoArray[index].CODICE_FISCALE = newValue;
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') aggiungiElementoVuoto(nuovoArray);
        setArrayRiferimenti(nuovoArray);
    };
    const modificaCellulare = (index, newValue) => {
        const nuovoArray = [...arrayRiferimenti];
        nuovoArray[index].CELLULARE = newValue;
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') aggiungiElementoVuoto(nuovoArray);
        setArrayRiferimenti(nuovoArray);
    };
    const modificaEmail = (index, newValue) => {
        const nuovoArray = [...arrayRiferimenti];
        nuovoArray[index].EMAIL = newValue;
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') aggiungiElementoVuoto(nuovoArray);
        setArrayRiferimenti(nuovoArray);
    };
    const modificaIndirizzo = (index, newValue) => {
        const nuovoArray = [...arrayRiferimenti];
        nuovoArray[index].INDIRIZZO = newValue;
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') aggiungiElementoVuoto(nuovoArray);
        setArrayRiferimenti(nuovoArray);
    };
    const modificaCitta = (index, newValue) => {
        const nuovoArray = [...arrayRiferimenti];
        nuovoArray[index].CITTA = newValue;
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') aggiungiElementoVuoto(nuovoArray);
        setArrayRiferimenti(nuovoArray);
    };
    const modificaProvincia = (index, newValue) => {
        const nuovoArray = [...arrayRiferimenti];
        nuovoArray[index].PROVINCIA = newValue;
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') aggiungiElementoVuoto(nuovoArray);
        setArrayRiferimenti(nuovoArray);
    };
    const modificaCap = (index, newValue) => {
        const nuovoArray = [...arrayRiferimenti];
        nuovoArray[index].CAP = newValue;
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') aggiungiElementoVuoto(nuovoArray);
        setArrayRiferimenti(nuovoArray);
    };
    const modificaLuogoNascita = (index, newValue) => {
        const nuovoArray = [...arrayRiferimenti];
        nuovoArray[index].LUOGO_NASCITA = newValue;
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') aggiungiElementoVuoto(nuovoArray);
        setArrayRiferimenti(nuovoArray);
    };
    const modificaDataNascita = (index, newValue) => {
        const nuovoArray = [...arrayRiferimenti];
        nuovoArray[index].DATA_NASCITA = newValue;
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') aggiungiElementoVuoto(nuovoArray);
        setArrayRiferimenti(nuovoArray);
    };
    const modificaReferente = (index, newValue) => {
        const nuovoArray = [...arrayRiferimenti];
        nuovoArray[index].REFERENTE = newValue ? 1 : 0; // Converti true/false in 1/0
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') {
            aggiungiElementoVuoto(nuovoArray);
        }
        setArrayRiferimenti(nuovoArray);
    };
    const modificaNote = (index, newValue) => {
        const nuovoArray = [...arrayRiferimenti];
        nuovoArray[index].NOTE = newValue;
        if (index === nuovoArray.length - 1 && newValue.trim() !== '') aggiungiElementoVuoto(nuovoArray);
        setArrayRiferimenti(nuovoArray);
    };

    const eliminaRiferimento = (elemento) => {
        const nuovoArray = arrayRiferimenti.filter(comm => comm.ID !== elemento.ID);
        setArrayRiferimenti(nuovoArray);
        handleCambiamentoMod(); 
    };

    function tornaElenco() {
        setSposta(false);
        setModaleInfoDestinatari(false);
        props.callbackTermineModifica();
    }

    function tornaScheda(valore) {
        setFlagAttivaModaleInserisciCognome(0);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setvariazioneCampi(1);
    }


    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(cognome.current) {
                if(cognome.current.value=== ''){
                    controlli = 1;
                    setFlagAttivaModaleInserisciCognome(1);
                }
            }
        }
        let spostamento = 0;
        if(sposta === true) spostamento = 1;
    
        if(controlli===0){ 

            if(props.ope==='modifica' && variazioneCampi===0 && spostamento == 0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                setLoading(true);
                // parametri per inviare i dati al server:
                if(spostamento == 1){}

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ?  
                    JSON.stringify({op:operazione,
                        spostaTraIscritti:spostamento,
                        cognome:cognomeDefault,
                        nome:nomeDefault,
                        codFiscale:codFiscaleDefault,
                        sesso:insSesso,
                        citta:cittaDefault,
                        indirizzo:indirizzoDefault,
                        provincia:provinciaDefault,
                        provinciaNascita:provinciaNascitaDefault,
                        comuneNascita:comuneNascitaDefault,
                        dataNascita:dataNascitaDefault,
                        email:emailDefault,
                        cellulare:cellulareDefault,
                        telefonoFisso:telefonoFissoDefault,
                        note:noteDefault,
                        sitoWeb:sitoWebDefault,
                        cap:capDefault,
                        arrayRiferimenti:arrayRiferimenti,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 

                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId

                        })
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Cognome non valorizzato") !== -1){
                                    setFlagAttivaModaleInserisciCognome(1);
                                }
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    
        return (
            <>
                
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <Card className="main-card mb-3"><Clessidra loading={true}/></Card> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1crm_pro {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <Card className="main-card mb-3"><Clessidra loading={true}/></Card> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                    {modaleInfoDestinatari===true ?
                        <Modale 
                            titolo="Informativa Riferimenti"
                            flagErrore={true}
                            contenuto={<div>Nel caso in cui al prospect venga assegnato un riferimento con flag referente acceso, i dati di contatto saranno quelli del riferimento</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);setModaleInfoDestinatari(false)}
                                }    
                            ]}
                        />
                        :""}

                    {flagAttivaModaleInserisciCognome===1 ?
                        <Modale 
                            titolo="Richiesta Cognome"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato il Cognome, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Contatto / Prospect</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Contatto / Prospect</CardTitle> :""}
                           
                            
                            <Clessidra loading={loading}/>
                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione del contatto / prospect <b>{cognomeDefault}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :
                                <>
                                {sposta === true ?
                                    <FinestraDialogo 
                                        titolo="Conferma spostamento contatto / prospect in iscritti"
                                        flagErrore={true}                                
                                        contenuto={<div>
                                            Confermi lo spostamento del contatto / prospect <b>{cognomeDefault}</b> negli iscritti ?<br/>                                    
                                        </div>}
                                        bottoni={[
                                            {
                                                "etichetta":"Conferma",
                                                "tipo":"primary",
                                                callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                            },
                                            {
                                                "etichetta":"Annulla",
                                                "tipo":"primary",
                                                callback: () => {tornaElenco()}
                                            }
                                        ]}
                                    />                        

                                :



                                <>
                                <Row form>  
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="cognome">Cognome*</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={50} type="text" name="cognome" id="cognome"
                                                innerRef={cognome} 
                                                placeholder=""
                                                defaultValue={cognomeDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setCognomeDefault(cognome.current.value)}}/> 
                                            :""}
                                         
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="nome">Nome</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={50} type="text" name="nome" id="nome"
                                                innerRef={nome} 
                                                placeholder=""
                                                defaultValue={nomeDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setNomeDefault(nome.current.value)}}/> 
                                            :""}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={100} type="text" name="email" id="email"
                                                innerRef={email} 
                                                placeholder=""
                                                defaultValue={emailDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setEmailDefault(email.current.value)}}/> 
                                            :""}
                                         
                                        </FormGroup>
                                    </Col>
                                </Row>


                                <Row form>  
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="cellulare">Telefono Cellulare</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={30} type="text" name="cellulare" id="cellulare"
                                                innerRef={cellulare} 
                                                placeholder=""
                                                defaultValue={cellulareDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setCellulareDefault(cellulare.current.value)}}/> 
                                            :""}
                                         
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="telefonoFisso">Telefono Fisso</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={30} type="text" name="telefonoFisso" id="telefonoFisso"
                                                innerRef={telefonoFisso} 
                                                placeholder=""
                                                defaultValue={telefonoFissoDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setTelefonoFissoDefault(telefonoFisso.current.value)}}/> 
                                            :""}
                                         
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={1}>
                                        <Label for="sesso">Sesso</Label>
                                        <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="sesso" 
                                            innerRef={sesso} 
                                            defaultValue={sessoDefault}
                                            defaultChecked = {props.ope === "inserisci" || sessoDefault === "1" ?  'checked' :""}
                                            value="1"
                                            onInput={(e)=>{setSesso(e.target.value);handleCambiamentoMod(e);}}/> {'Maschio'}
                                        </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="sesso" 
                                            innerRef={sesso} 
                                            defaultValue={sessoDefault}
                                            defaultChecked = {sessoDefault === "2" ?  'checked' :""}
                                            value="2"
                                            onInput={(e)=>{setSesso(e.target.value);handleCambiamentoMod(e);}}/> {'Femmina'}
                                        </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={5}>
                                        <FormGroup>
                                            <Label for="citta">Comune</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={50} type="text" name="citta" id="emacittail"
                                                innerRef={citta} 
                                                placeholder=""
                                                defaultValue={cittaDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setCittaDefault(citta.current.value)}}/> 
                                            :""}
                                         
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="sitoWeb">Sito Web</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={100} type="text" name="sitoWeb" id="sitoWeb"
                                                innerRef={sitoWeb} 
                                                placeholder=""
                                                defaultValue={sitoWebDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setSitoWebDefault(sitoWeb.current.value)}}/> 
                                            :""}
                                         
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="indirizzo">Indirizzo</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={255} type="text" name="indirizzo" id="indirizzo"
                                                innerRef={indirizzo} 
                                                placeholder=""
                                                defaultValue={indirizzoDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setIndirizzoDefault(indirizzo.current.value)}}/> 
                                            :""}
                                         
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cap">CAP</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={10} type="text" name="cap" id="cap"
                                                innerRef={cap} 
                                                placeholder=""
                                                defaultValue={capDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setCapDefault(cap.current.value)}}/> 
                                            :""}
                                         
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="provincia">Provincia o Stato Estero</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={50} type="text" name="provincia" id="provincia"
                                                innerRef={provincia} 
                                                placeholder=""
                                                defaultValue={provinciaDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setProvinciaDefault(provincia.current.value)}}/> 
                                            :""}
                                         
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="codFiscale">Codice Fiscale</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={20} type="text" name="codFiscale" id="codFiscale"
                                                innerRef={codFiscale} 
                                                placeholder=""
                                                defaultValue={codFiscaleDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setCodFiscaleDefault(codFiscale.current.value)}}/> 
                                            :""}
                                         
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={4}>
                                        <FormGroup>
                                            <FormGroup>
                                            <Label for="dataNascita">Data di Nascita</Label>
                                            <Input maxLength={10} type="date" name="dataNascita" id="dataNascita" defaultValue={dataNascitaDefault} innerRef={dataNascita}
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setDataNascitaDefault(dataNascita.current.value)}}/> 
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="comuneNascita">Comune di Nascita</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={50} type="text" name="comuneNascita" id="comuneNascita"
                                                innerRef={comuneNascita} 
                                                placeholder=""
                                                defaultValue={comuneNascitaDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setComuneNascitaDefault(comuneNascita.current.value)}}/> 
                                            :""}
                                         
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="provinciaNascita">Provincia o Stato Estero di Nascita</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={50} type="text" name="provinciaNascita" id="provinciaNascita"
                                                innerRef={provinciaNascita} 
                                                placeholder=""
                                                defaultValue={provinciaNascitaDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setProvinciaNascitaDefault(provinciaNascita.current.value)}}/> 
                                            :""}
                                         
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="note">Note</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={200} type="text" name="note" id="note"
                                                innerRef={note} 
                                                placeholder=""
                                                defaultValue={noteDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setNoteDefault(note.current.value)}}/> 
                                            :""}
                                         
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form> 
                                    <Col md={12}>
                                    <div className='schedaSottotitolo'>
                                        Riferimenti
                                    </div>
                                </Col>
                                </Row>
                                <Row form> 
                                    <Col md={12}>
                                    <table style={{width: '100%'}}>
                                    {arrayRiferimenti !== undefined && arrayRiferimenti.length > 0 ? 
                                        arrayRiferimenti.map((elemento, index) => 
                                            <tr key={index}>
                                                <td><img onClick={() => { handleCambiamentoMod(1); eliminaRiferimento(elemento)}} src={Cestino}/></td>
                                                <td style={{paddingLeft:2, paddingRight:2, textAlign:'right'}}>{index + 1}</td>
                                                <td style={{padding:5}}>
                                                    
                                                <div style={{ display:"flex", flexDirection:"row"}}>
                                                <div style={{flex:2,marginRight: '10px'}}>
                                                <Row form> 
                                                <Col md={3}>
                                                    Cognome
                                                    <Input 
                                                        maxLength={50} 
                                                        type="text"  
                                                        value={elemento.COGNOME} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaCognome(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> 
                                                </Col>
                                                <Col md={3}>
                                                    Nome
                                                    <Input 
                                                        maxLength={50} 
                                                        type="text"  
                                                        value={elemento.NOME} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaNome(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> 
                                                </Col>
                                                <Col md={3}>
                                                    Codice Fiscale
                                                    <Input 
                                                        maxLength={20} 
                                                        type="text"  
                                                        value={elemento.CODICE_FISCALE} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaCodiceFiscale(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> 
                                                </Col>
                                                <Col md={3}>
                                                    Telefono
                                                    <Input 
                                                        maxLength={30} 
                                                        type="text"  
                                                        value={elemento.CELLULARE} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaCellulare(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> 
                                                </Col>
                                                </Row>
                                                <Row form> 
                                                <Col md={3}>
                                                    Email
                                                    <Input 
                                                        maxLength={255} 
                                                        type="text"  
                                                        value={elemento.EMAIL} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaEmail(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> 
                                                </Col>
                                                <Col md={3}>
                                                    Indirizzo
                                                    <Input 
                                                        maxLength={255} 
                                                        type="text"  
                                                        value={elemento.INDIRIZZO} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaIndirizzo(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> 
                                                </Col>
                                                <Col md={3}>
                                                    Comune
                                                    <Input 
                                                        maxLength={50} 
                                                        type="text"  
                                                        value={elemento.CITTA} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaCitta(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> 
                                                </Col>
                                                <Col md={3}>
                                                    Provincia
                                                    <Input 
                                                        maxLength={50} 
                                                        type="text"  
                                                        value={elemento.PROVINCIA} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaProvincia(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> 
                                                </Col>
                                                </Row>

                                                <Row form> 
                                                <Col md={3}>
                                                    CAP
                                                    <Input 
                                                        maxLength={20} 
                                                        type="text"  
                                                        value={elemento.CAP} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaCap(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> 
                                                </Col>
                                                <Col md={3}>
                                                    Data di nascita
                                                    <Input 
                                                        maxLength={10} 
                                                        type="date"  
                                                        value={elemento.DATA_NASCITA} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaDataNascita(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> 
                                               </Col>
                                               <Col md={3}>
                                                    Luogo di nascita
                                                    <Input 
                                                        maxLength={200} 
                                                        type="text"  
                                                        value={elemento.LUOGO_NASCITA} 
                                                        placeholder="" 
                                                        onInput={(e) => {modificaLuogoNascita(index, e.target.value);handleCambiamentoMod(e)}} 
                                                    /> 
                                                </Col>
                                                <Col md={3}>
                                                    <Input
                                                        className="ml-2 mt-3"
                                                        type="checkbox"
                                                        id="IDRiferimentiPersonaReferente"
                                                        checked={elemento.REFERENTE==1}
                                                        onChange={(e) => {
                                                            handleCambiamentoMod(e);
                                                            modificaReferente(index,e.target.checked,);
                                                        }}
                                                        />
                                                    <Label className="ml-4 mt-2">Referente <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoDestinatari(true)}/></Label>
                                                </Col>
                                                </Row>
                                                </div>

                                                <div style={{flex: 1,marginRight: '10px'}}>
                                                <Row form> 
                                                <Col md={12}>
                                                        Note
                                                        <textarea
                                                            key={index}
                                                            maxLength={200}
                                                            value={elemento.NOTE}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                modificaNote(index, e.target.value);
                                                                handleCambiamentoMod(e);
                                                            }}
                                                            style={{ 
                                                                width: '100%',  // Larghezza del textarea
                                                                height: '100%'  // Altezza del textarea
                                                              }}
                                                        />
                                                    </Col>
                                                    </Row>
                                                </div>
                                                </div>
                                                </td>
                                            </tr>
                                        )
                                    : ""}
                                    </table>
                                </Col>
                                </Row>
                                </>
                                }
                            </>
                            }
                                
                                 
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                                {props.ope === "modifica" && variazioneCampi === 0? <Button disabled={disabilitaBottoni} color="danger" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);setSposta(true)}}>Sposta tra gli Iscritti</Button>:""}
                                
                                <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={aggiungiRigaVuota}>Aggiungi Riferimento</Button>
                                
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                            
                </div>
                :""}
            </>
        );
}
export default FormGridFormRow;
