import React, {useState} from 'react';
import { useEffect } from 'react';
import { Button } from 'reactstrap';
import Parametri from '../../parametri';
import TrasformaDateAUmano from '../TrasformaDateAUmano';
import FormattaImportoVideo from '../FormattaImportoVideo';
import Clessidra from '../Clessidra.js';

import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const STATO_VISUALIZZA = 0;
const STATO_ERRORE_CONNESSIONE = 1;
const STATO_OPERAZIONE_FALLITA = 2;
const STATO_RICERCA_DATI = 3;
const STATO_ACQUISTA_RINNOVO = 4;
const STATO_ACQUISTA_AGGIUNTIVO = 5;
const STATO_CONFERMA_ORDINE = 6;

function PayPalButton(props) {    
    const [errorMessage, setErrorMessage] = useState('');
    const [flagPagamentoCompletato, setFlagPagamentoCompletato] = useState(false);
  
    const createOrder = async () => {
      try {
        const response = await fetch(Parametri("serverURL"), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            op: 'paypalOrder',
            emak: props.emak,
            sessionId:props.sessionId,
            cart: [
              {
                id: props.numeroOrdine,
                quantity: '1',
              },
            ],
          }),
        });
  
        const orderData = await response.json();
  
        if (orderData) {
          if (orderData.id) {
            return orderData.id;
          } else {
            const errorDetail = orderData?.details?.[0];
            const errorMessage = errorDetail
              ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
              : JSON.stringify(orderData);
    
            throw new Error(errorMessage);
          }
        } else {
          // Gestisci il caso in cui 'id' non è presente nell'oggetto o orderData è null
          throw new Error("orderData not found or invalid data");
        }
  
      } catch (error) {
        console.error(error);
        //setErrorMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
        setErrorMessage("Impossibile procedere al checkout Paypal");
        props.setFlagPagamentoFallito(true);
      }
    };
  
    const onApprove = async (data, actions) => {
      try {
        const response = await fetch(Parametri("serverURL"), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            op: 'paypalCapture',
            emak: props.emak,
            sessionId:props.sessionId,
            orderID: data.orderID
          }),
        });
  
        const orderData = await response.json();
  
        const errorDetail = orderData?.details?.[0];
  
        if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
          return actions.restart();
        } else if (errorDetail) {
          throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
        } else if (!orderData.purchase_units) {
          throw new Error(JSON.stringify(orderData));
        } else {
          const transaction =
            orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
            orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
          setErrorMessage(
            `Transazione ${transaction.status}: ${transaction.id}`,
          );
          if (transaction.status == "COMPLETED") {
            setFlagPagamentoCompletato(true);
            props.setFlagPagamentoCompletato(true);
            props.setPaypalTransactionId(transaction.id);
          }
          console.log(
            'Capture result',
            orderData,
            JSON.stringify(orderData, null, 2),
          );
        }
      } catch (error) {
        console.error(error);
        // setErrorMessage(`Sorry, your transaction could not be processed...<br><br>${error}`);
        props.setFlagPagamentoFallito(true);
        setErrorMessage("Transazione Paypal FALLITA");
      }
    };
  
    return (
      <div>
        {!flagPagamentoCompletato && <PayPalButtons          
          createOrder={createOrder}
          onApprove={onApprove}
        />}
        <div id="result-message" dangerouslySetInnerHTML={{ __html: errorMessage }} />
      </div>
    );
  }

function FormGridFormRow(props) {
    const [statoPagina,setStatoPagina]=useState(STATO_VISUALIZZA);
    const [datiAbbonamento, setDatiAbbonamento] = useState([]);
    const [listino, setListino] = useState([]);
    const [pacchettoSelezionato, setPacchettoSelezionato] = useState("");
    const [pacchettiAggiuntivi, setPacchettiAggiuntivi] = useState("");
    const [ordini, setOrdini] = useState([]);
    const [numeroOrdine, setNumeroOrdine] = useState("");
    const [importoDaPagare, setImportoDaPagare] = useState("");
    const [flagAggiuntivoAcquistabile, setFlagAggiuntivoAcquistabile] = useState(false);
    const [flagRinnovoAcquistabile, setFlagRinnovoAcquistabile] = useState(false);

    const [flagPagamentoCompletato, setFlagPagamentoCompletato] = useState(false);
    const [flagPagamentoFallito, setFlagPagamentoFallito] = useState(false);
    const [paypalTransactionId, setPaypalTransactionId] = useState("");
  
    useEffect(() => {
        mioAbbonamentoRecuperaDati();
    },[]);

    function mioAbbonamentoRecuperaDati() {
        setStatoPagina(STATO_RICERCA_DATI);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: 
                JSON.stringify({op:"mioAbbonamentoRecuperaDati",
                emak: props.emak,
                sessionId:props.sessionId
            })       
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione<=0) {
                    alert("Impossibile scaricare i dati dell'abbonamento, riprovare o contattare l'assistenza tecnica");
                    setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    return;
                }            
                if(valoreDiRitorno.risultatoOperazione===2) {
                    props.setPagina(props.PAGINE.tornaLogin);
                    return;
                }
                setDatiAbbonamento(valoreDiRitorno.risultatoRitorno[0]);
                setOrdini(valoreDiRitorno.ordini);
                if (valoreDiRitorno.aggiuntivoAcquistabile==0) setFlagAggiuntivoAcquistabile(false); else setFlagAggiuntivoAcquistabile(true);
                if (valoreDiRitorno.rinnovoAcquistabile==0) setFlagRinnovoAcquistabile(false); else setFlagRinnovoAcquistabile(true);
                setStatoPagina(STATO_VISUALIZZA);
            },
            (error) => {
                alert("Impossibile scaricare i dati dell'abbonamento, riprovare o contattare l'assistenza tecnica");
                setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );
    }

    function mioAbbonamentoElencoArticoli(tipo) {
        setStatoPagina(STATO_RICERCA_DATI);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: 
                JSON.stringify({op:"mioAbbonamentoElencoArticoli",
                tipo:tipo,
                emak:props.emak,
                sessionId:props.sessionId
            })       
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione<=0) {
                    alert("Impossibile scaricare l'elenco dei profili acquistabili, riprovare o contattare l'assistenza tecnica");
                    setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    return;
                }            
                if(valoreDiRitorno.risultatoOperazione===2) {
                    props.setPagina(props.PAGINE.tornaLogin);
                    return;
                }
                setListino(valoreDiRitorno.articoli);
                if (tipo=="rinnovo") setStatoPagina(STATO_ACQUISTA_RINNOVO); else setStatoPagina(STATO_ACQUISTA_AGGIUNTIVO);
            },
            (error) => {
                alert("Impossibile scaricare l'elenco dei profili acquistabili, riprovare o contattare l'assistenza tecnica");
                setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );
    }

    function calcolaImportoAggiuntivo(pacchettoSelezionato, dataFineAttualeAbbonamento) {
        var costoMensileNettoCentesimi = 0;
        var costoMensileIvaCentesimi = 0;
        for (var n=0; n<listino.length; n++) {
            if (listino[n].CODICE_ARTICOLO == pacchettoSelezionato.CODICE_ARTICOLO) {
                // devo calcolare il netto e l'iva e solo alla fine il lordo, per fare i conti come fa il backend e non dare importi diversi per qualche cent
                if (listino[n].DURATA_UNITA_MISURA == "giorni") {
                    costoMensileNettoCentesimi = 100 * 30 * listino[n].IMPORTO_NETTO / listino[n].DURATA_VALORE;
                    costoMensileIvaCentesimi = 100 * 30 * listino[n].IVA / listino[n].DURATA_VALORE;
                }
                if (listino[n].DURATA_UNITA_MISURA == "mesi") {
                    costoMensileNettoCentesimi = 100 * listino[n].IMPORTO_NETTO / listino[n].DURATA_VALORE;
                    costoMensileIvaCentesimi = 100 * listino[n].IVA / listino[n].DURATA_VALORE;
                }
                if (listino[n].DURATA_UNITA_MISURA == "anni") {
                    costoMensileNettoCentesimi = 100 * listino[n].IMPORTO_NETTO / (listino[n].DURATA_VALORE * 12);
                    costoMensileIvaCentesimi = 100 * listino[n].IVA / (listino[n].DURATA_VALORE * 12);
                }

                // Ottieni la data di oggi
                const oggi = new Date();
                const annoOggi = oggi.getFullYear();
                const meseOggi = oggi.getMonth() + 1; // Gennaio = 0, quindi aggiungiamo 1

                // Dividi la stringa della data in anno e mese
                const [anno, mese] = dataFineAttualeAbbonamento.split('-').map(Number);

                // Calcola il numero di mesi tra le due date
                const differenzaAnni = anno - annoOggi;
                const differenzaMesi = differenzaAnni * 12 + (mese - meseOggi);

                // Arrotonda per eccesso
                const mesiArrotondati = Math.ceil(differenzaMesi);

                console.log("IVA", Math.ceil(mesiArrotondati * costoMensileIvaCentesimi)/100);
                console.log("NETTO", Math.ceil(mesiArrotondati * costoMensileNettoCentesimi)/100);

                return Math.ceil(mesiArrotondati * costoMensileNettoCentesimi)/100 + Math.ceil(mesiArrotondati * costoMensileIvaCentesimi)/100;
            }
        }
        return 0; // non ho trovato l'articolo
    }

    function mioAbbonamentoAcquista(tipo) {
        setStatoPagina(STATO_RICERCA_DATI);
        var p = pacchettoSelezionato;
        if (pacchettiAggiuntivi != "") p = p + ";" + pacchettiAggiuntivi;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: 
                JSON.stringify({op:"mioAbbonamentoAcquista",
                codiceArticolo:p,
                tipo:tipo,
                emak:props.emak,
                sessionId:props.sessionId
            })       
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione<=0) {
                    alert("Impossibile memorizzare l'ordine, riprovare o contattare l'assistenza tecnica");
                    setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    return;
                }            
                if(valoreDiRitorno.risultatoOperazione===2) {
                    props.setPagina(props.PAGINE.tornaLogin);
                    return;
                }
                setNumeroOrdine(valoreDiRitorno.numeroOrdine);
                setImportoDaPagare(valoreDiRitorno.importo);
                setStatoPagina(STATO_CONFERMA_ORDINE);
            },
            (error) => {
                alert("Impossibile memorizzare l'ordine, riprovare o contattare l'assistenza tecnica");
                setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );
    }

    // funzione presente in SchedaMioAbbonamento di EMA e Acquista di EMASITO
    function verificaSelezionePacchettoAggiuntivo(p) {
        var ar = pacchettiAggiuntivi.split(';');
        for (var n=0; n<ar.length; n++) if (ar[n] == p) return true;
        return false;
    }

    // funzione presente in SchedaMioAbbonamento di EMA e Acquista di EMASITO
    function aggiungiRimuoviPacchettoAggiuntivo(p) {
        var aggiungi = true;
        var ar = pacchettiAggiuntivi.split(';');
        for (var n=0; n<ar.length; n++) {
            if (ar[n] == p) {
                aggiungi = false;
                break;
            }
        }
        if (aggiungi) {
            var pv = pacchettiAggiuntivi;
            if (pv != "") pv = pv + ";";
            pv = pv + p;            
        } else {
            var pv = "";
            for (var n=0; n<ar.length; n++) {
                if (ar[n] != p) {
                    if (pv != "") pv = pv + ";";
                    pv = pv + ar[n];
                }
            }        
        }
        setPacchettiAggiuntivi(pv);
    }

    // funzione presente in SchedaMioAbbonamento di EMA e Acquista di EMASITO
    function ottieniImportoPacchetto(p) {
        for (var n=0; n<listino.length; n++) {
            if (listino[n].CODICE_ARTICOLO == p) return listino[n].IMPORTO_LORDO;
        }
        return 0;
    }

    // funzione presente in SchedaMioAbbonamento di EMA e Acquista di EMASITO
    function calcolaTotaleImportoPacchettiSelezionati() {
        var ar = pacchettiAggiuntivi.split(';');
        var i = parseFloat(ottieniImportoPacchetto(pacchettoSelezionato));
        for (var n=0; n<ar.length; n++) i = i + parseFloat(ottieniImportoPacchetto(ar[n]));
        return FormattaImportoVideo(i);
    }

    function EspandiDescrizione(d) {
        // stampo una stringa del tipo A;B;C;D mettendo i valori uno sotto l'altro (separati dal <br/>).
        // Per farlo, con lo split trasformo la stringa con i valori separati da punto e virgola in un array
        // e poi con il map stampo un valore alla volta, mettendo il <br/> davanti a tutti i valori tranne il primo
        return (
            <>{d.split(';').map((e,i) => <span key={i}>{i>0 && <br/>}{e}</span>)}</>
        )
    }
  
    if (statoPagina===STATO_RICERCA_DATI) {
        return (<Clessidra loading={true}/>)
    }

    if (statoPagina===STATO_ERRORE_CONNESSIONE || statoPagina===STATO_OPERAZIONE_FALLITA) {
        return (<div></div>)
    }

    if (statoPagina==STATO_VISUALIZZA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div className='schedaSottotitolo'>
                        Abbonamento: Situazione Attuale
                    </div>                
                    <b>{datiAbbonamento.DESCRIZIONE}</b><br/>
                    <b>Limite Iscritti</b>: {datiAbbonamento.LIMITE_ISCRITTI}<br/>
                    <b>Limite MB</b>: {datiAbbonamento.LIMITE_MB}<br/>
                    <b>Scadenza abbonamento</b>: <TrasformaDateAUmano data={datiAbbonamento.DATA_FINE}/><br/>
                    <div style={{color:"#FFFFFF", padding:3, marginTop:5, backgroundColor:"#A0A0A0", fontWeight:'bold'}}>
                        Per rinnovare l'abbonamento o aumentare i limiti, clicca i bottoni in fondo alla pagina.
                    </div>

                    <div className='schedaSottotitolo'>
                        Dettaglio Ordini
                    </div> 
                    {ordini.length > 0 ? 
                        <table style={{width:"100%"}}>
                            <tr className="rigaTitoloTabellaReport">
                                <td className="titoloTabellaReport">Ordine</td>
                                <td className="titoloTabellaReport">Oggetto</td>
                                <td className="titoloTabellaReport">Importo dovuto</td>
                                <td className="titoloTabellaReport">Importo pagato</td>
                                <td className="titoloTabellaReport">Note</td>
                            </tr>
                        { ordini.map((elemento, indice) => 
                            <tr key={indice} className="rigaTabellaReport">                                
                                <td>{elemento.NUMEROORDINE}<br/>del <TrasformaDateAUmano data={elemento.DATAORDINE}/></td>
                                <td>
                                    <b>{EspandiDescrizione(elemento.DESCRIZIONE)}</b><br/>
                                    {elemento.LIMITE_ISCRITTI} iscritti + {elemento.LIMITE_MB} MB<br/>
                                    {elemento.CONSEGNATO==1 ? 
                                        <>                                        
                                        {elemento.TIPOORDINE=="aggiuntivo" ? 
                                            <>fino</>
                                        : 
                                            <>dal&nbsp;<TrasformaDateAUmano data={elemento.DATA_INIZIO_SERVIZIO}/></>
                                        }
                                        &nbsp;al&nbsp;
                                        <TrasformaDateAUmano data={elemento.DATA_FINE_SERVIZIO}/>
                                        </>
                                    :
                                        ""
                                    }
                                </td>
                                <td>
                                    {FormattaImportoVideo(elemento.IMPORTO_LORDO)} euro{elemento.IMPORTO_NETTO+elemento.IMPORTO_IVA>0 ? <><br/>({FormattaImportoVideo(elemento.IMPORTO_NETTO)} euro + {FormattaImportoVideo(elemento.IMPORTO_IVA)} IVA)</>:""}
                                </td>
                                <td>
                                    {FormattaImportoVideo(elemento.IMPORTO_PAGATO)} euro
                                    <br/>
                                    <TrasformaDateAUmano data={elemento.DATA_PAGAMENTO}/>
                                </td>
                                <td>
                                    {elemento.CONSEGNATO==1 ? "" : elemento.DATA_PAGAMENTO=="" ? "attesa pagamento" : "in fase di attivazione"}
                                </td>
                            </tr>
    
                        )}
                        </table>
                    :
                    "dati non disponibili"}
                </div>

                <div style={{color:"#FFFFFF", padding:3, marginTop:5, backgroundColor:"#A0A0A0", fontWeight:'bold'}}>
                    Per assistenza sugli ordini, scrivi all'indirizzo assistenza@gestionesportiva.it
                </div>

                <div className="schedaSpazioInferiore">
                    {flagRinnovoAcquistabile && <Button className="mt-2" color="primary" onClick={() => { mioAbbonamentoElencoArticoli("rinnovo") }}>Rinnova</Button>}
                    &nbsp;&nbsp;
                    {flagAggiuntivoAcquistabile && <Button className="mt-2" color="primary" onClick={() => { mioAbbonamentoElencoArticoli("aggiuntivo") }}>Acquista aggiuntivo</Button>}
                    {!flagRinnovoAcquistabile && !flagAggiuntivoAcquistabile && <span>In questo momento, non puoi rinnovare nè aggiungere pacchetti secondo le offerte standard, se desideri un'offerta personalizzata contattaci all'indirizzo qui sopra indicato.</span>}
                </div>
            </div>             
        )
    }        

    if ((statoPagina==STATO_ACQUISTA_RINNOVO || statoPagina==STATO_ACQUISTA_AGGIUNTIVO) && listino.length==0) {
        return (
            <div>Dati acquisti non disponibili, contattare l'assistenza tecnica</div>
        )
    }

    if (statoPagina==STATO_ACQUISTA_AGGIUNTIVO && listino.length>0) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div className='schedaSottotitolo'>
                        Acquista
                    </div>
                    <div style={{display:"flex",flexDirection:"column", marginTop:5, marginBottom:10}}>
                        <span>Seleziona un pacchetto da aggiungere a quelli già acquistati, quindi sarà valido fino al <TrasformaDateAUmano data={datiAbbonamento.DATA_FINE}/>:</span>
                        <br/>
                        {listino.map((elemento, indice) => 
                        <div key={indice} style={{display:'flex'}}>
                            <label>
                                <input type='checkbox' name='pacchetto' value={elemento.CODICE_ARTICOLO} checked={pacchettoSelezionato === elemento.CODICE_ARTICOLO} onChange={e => { setPacchettoSelezionato(e.target.value)}}/>
                                <span style={{marginLeft:10, cursor:'pointer'}}>
                                    {elemento.DESCRIZIONE}
                                    , iscritti aggiuntivi: {elemento.LIMITE_ISCRITTI}
                                    , spazio aggiuntivo: {elemento.LIMITE_MB} {"MB"}
                                    , importo: {FormattaImportoVideo(calcolaImportoAggiuntivo(elemento,datiAbbonamento.DATA_FINE))} euro
                                </span>
                            </label>                            
                        </div>
                        )}
                    </div>
                    <Button className="mt-2" color="primary" onClick={() => { if (pacchettoSelezionato!="") mioAbbonamentoAcquista("aggiuntivo") }}>Conferma ordine</Button>                    
                    &nbsp;&nbsp;
                    <Button className="mt-2" color="primary" onClick={() => { setStatoPagina(STATO_VISUALIZZA) }}>Annulla</Button>                    
                </div>
            </div>             
        )
    }    
    
    if (statoPagina==STATO_ACQUISTA_RINNOVO && listino.length>0) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div className='schedaSottotitolo'>
                        Acquista
                    </div>
                    <div style={{display:"flex",flexDirection:"column", marginTop:5, marginBottom:10}}>
                        <span>Seleziona i pacchetti da attivare <b>alla scadenza dell'attuale abbonamento</b>, quindi saranno attivi dal <TrasformaDateAUmano data={datiAbbonamento.DATA_FINE}/>:</span>
                        <br/>

                        <span>Seleziona <b>uno</b> tra i seguenti <b>pacchetti base</b>:</span>
                        {listino.map((elemento, indice) => (elemento.ACQUISTABILE==1 || elemento.ACQUISTABILE==2) &&                        
                            <div key={indice} style={{display:'flex'}}>                            
                                <label>
                                    <input type='checkbox' 
                                        name='pacchettobase' 
                                        value={elemento.CODICE_ARTICOLO} 
                                        checked={pacchettoSelezionato === elemento.CODICE_ARTICOLO}                                         
                                        onChange={e => { setPacchettoSelezionato(e.target.value)}}
                                    />
                                    <span style={{marginLeft:10, cursor:'pointer'}}>
                                        {elemento.DESCRIZIONE}
                                        , durata: {elemento.DURATA_VALORE+" "+elemento.DURATA_UNITA_MISURA}
                                        , iscritti: {elemento.LIMITE_ISCRITTI}
                                        , spazio: {elemento.LIMITE_MB} {"MB"}
                                        , importo: {FormattaImportoVideo(elemento.IMPORTO_LORDO)} euro
                                    </span>
                                </label>                            
                            </div>                        
                        )}

                        <br/>

                        <span>Seleziona, se vuoi, <b>pacchetti aggiuntivi</b> tra i seguenti, che avranno la stessa durata del pacchetto base selezionato:</span>
                        {listino.map((elemento, indice) => elemento.ACQUISTABILE==3 &&                        
                            <div key={indice} style={{display:'flex'}}>                            
                                <label>
                                    <input type='checkbox' 
                                        name='pacchettiaggiuntivi' 
                                        value={elemento.CODICE_ARTICOLO} 
                                        checked={verificaSelezionePacchettoAggiuntivo(elemento.CODICE_ARTICOLO)}  
                                        onChange={e => { aggiungiRimuoviPacchettoAggiuntivo(e.target.value)}}
                                    />
                                    <span style={{marginLeft:10, cursor:'pointer'}}>
                                        {elemento.DESCRIZIONE}
                                        , iscritti aggiuntivi: {elemento.LIMITE_ISCRITTI}
                                        , spazio aggiuntivo: {elemento.LIMITE_MB} {"MB"}
                                        , importo: {FormattaImportoVideo(elemento.IMPORTO_LORDO)} euro
                                    </span>
                                </label>                            
                            </div>                        
                        )}             

                        <br/>

                        <span style={{fontSize:20}}>Importo totale: {calcolaTotaleImportoPacchettiSelezionati()} IVA inclusa.</span>           
                    </div>
                    <Button className="mt-2" color="primary" onClick={() => { if (pacchettoSelezionato!="") mioAbbonamentoAcquista("rinnovo") }}>Conferma ordine</Button>                    
                    &nbsp;&nbsp;
                    <Button className="mt-2" color="primary" onClick={() => { setStatoPagina(STATO_VISUALIZZA) }}>Annulla</Button>                    

                </div>
            </div>             
        )
    }        

    if (statoPagina==STATO_CONFERMA_ORDINE) {
        var importo = 0;
        if (listino.length == 0) {
            // non dovrebbe verificarsi mai, ma non posso proseguire senza listino perché non saprei l'importo
            return (
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                    <div className="schedaSpazioCentrale">
                        <div className='schedaSottotitolo'>
                            Conferma Ordine
                        </div>
                        Grazie per aver effettuato la richiesta di acquisto, è stato generato l'ordine <span style={{fontSize:20, fontWeight:'bold'}}>{numeroOrdine}</span>.<br/>
                        <br/>
                        Si è verificato un problema con il calcolo dell'importo. Cortesemente contatta l'assistenza tecnica indicando il numero d'ordine {numeroOrdine}.                        
                    </div>
                </div>             
            )
        }
        if (importoDaPagare === 0) {
            // anche questo caso non dovrebbe verificarsi, importo nullo?
            return (
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                    <div className="schedaSpazioCentrale">
                        <div className='schedaSottotitolo'>
                            Conferma Ordine
                        </div>
                        Grazie per aver effettuato la richiesta di acquisto, è stato generato l'ordine <span style={{fontSize:20, fontWeight:'bold'}}>{numeroOrdine}</span>.<br/>
                        <br/>
                        Si è verificato un problema con il calcolo dell'importo. Cortesemente contatta l'assistenza tecnica indicando il numero d'ordine {numeroOrdine}.                        
                    </div>
                </div>             
            )            
        }
        // per sandbox:
        // <PayPalScriptProvider options={{ 'client-id': 'AaDR0vR9aLP0_c5FqLpBPQz2UwpK4OdNhJ1KQSPzpL0GiD1rYcfQOIEbsvJBJkWfLrSGwCKfeWcsgGHY' }}>
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div className='schedaSottotitolo'>
                        Conferma Ordine
                    </div>

                    { flagPagamentoCompletato ?
                        <>
                            Grazie per aver effettuato e pagato l'ordine {numeroOrdine}, Transaction ID Paypal: {paypalTransactionId}
                            <br/>
                            Gli articoli ordinati verranno attivati a breve.
                            <br/>
                            <Button className="mt-2" color="primary" onClick={() => { mioAbbonamentoRecuperaDati() }}>OK</Button>
                        </>
                    :
                        <>
                            Grazie per aver effettuato la richiesta di acquisto, è stato generato l'ordine <span style={{fontSize:20, fontWeight:'bold'}}>{numeroOrdine}</span>.<br/>
                            <br/>
                            {flagPagamentoFallito && 
                                <div style={{backgroundColor:"#FF2020", color:"#FFFF00", width:"100%", paddingTop:5, paddingBottom:5, marginTop: 5, marginBottom:5}}><b>PAGAMENTO PAYPAL FALLITO</b></div>
                            }
                            Per attivare il servizio ordinato, procedi al pagamento di
                            <div style={{fontSize:20, fontWeight:'bold'}}>{FormattaImportoVideo(importoDaPagare)} euro</div>
                            utilizzando uno dei seguenti metodi:<br/><br/>                

                            <PayPalScriptProvider options={{ 'client-id': 'AQPplAdeAWZxZW-t4uJym90JrTEJiw6LcnYxcYapA9ogTBnvkaScuCDOIvFcPFAEgC05YeX29d0o6qpK', 'currency':'EUR' }}>
                                <PayPalButton 
                                    emak={props.emak}
                                    sessionId={props.sessionId}
                                    numeroOrdine={numeroOrdine} 
                                    setFlagPagamentoCompletato={setFlagPagamentoCompletato} 
                                    setFlagPagamentoFallito={setFlagPagamentoFallito}
                                    setPaypalTransactionId={setPaypalTransactionId}
                                />
                            </PayPalScriptProvider>

                            <li>Bonifico bancario all'<span style={{fontSize:20, fontWeight:'bold'}}>IBAN IT67G0301503200000003931391</span>, intestato ad Azienda Informatica Genova Srl, indicando nella causale l'ordine {numeroOrdine}</li>
                            <br/>
                            <li>Pagamento PayPal all'indirizzo email <span style={{fontSize:20, fontWeight:'bold'}}>info@aigenova.it</span> indicando nella causale l'ordine {numeroOrdine}</li>
                            <br/>
                        </>
                    }
                    
                </div>
            </div>             
        )
    }    

    return (
        <div>Errore 1 MioAbbonamento</div>
    )
}
export default FormGridFormRow;
