import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';

import FormattaImportoVideo from '../FormattaImportoVideo.js';
import FormattaImportoDb from '../FormattaImportoDb.js';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';

import Interrogativo from '../../immagini/interrogativo-azzurro.png';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record

function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);
    const [flagAttivaModaleAbbNonCancellabile, setFlagAttivaModaleAbbNonCancellabile] = useState(0);
    const [flagAttivaModaleAbbNonModificabile, setFlagAttivaModaleAbbNonModificabile] = useState(0);
    const [flagAttivaModaleAbbNonInseribile, setFlagAttivaModaleAbbNonInseribile] = useState(0);
    const [flagAttivaModaleInserisciNome, setFlagAttivaModaleInserisciNome] = useState(0);
    const [flagAttivaModaleImponibileErrato, setFlagAttivaModaleImponibileErrato] = useState(0);
    const [flagAttivaModaleIvaErrata, setFlagAttivaModaleIvaErrata] = useState(0);
    const [flagAttivaModaleImportoModificato, setFlagAttivaModaleImportoModificato] = useState(0);
    const [flagAttivaModaleInfoBuoni, setFlagAttivaModaleInfoBuoni] = useState(0);
    const [flagAttivaModaleAccessi, setFlagAttivaModaleAccessi] = useState(0);
    const [modaleClasseAbbonamento, setModaleClasseAbbonamento] = useState(false);

    /*
        accessor: 'NOMECORSO' tendina
        accessor: 'DESCRIZIONE'
        accessor: 'NOME'
        accessor: 'IMPORTO'
        accessor: 'PERIODICITA' tendina fissa
    */

    const nome   = useRef();
    const classe   = useRef();
    const importo   = useRef();
    const durata   = useRef();
    const accessi  = useRef();
    const avviso  = useRef();
    const iva   = useRef();
    //const periodicita   = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);

    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');

    //const [risultatoServerDatiCorso,setRisultatoServerDatiCorso]=useState('');

    //const [insPeriodicitaEdi,setInsPeriodicitaEdi]=useState('');
    const [insUnitaDurataEdi,setInsUnitaDurataEdi]=useState(0);

    /*let nomeDefault = "";
    let importoDefault  = "";
    let periodicitaDefault  = "";*/

    const [nomeDefault,setNomeDefault]=useState('');
    const [classeDefault,setClasseDefault]=useState('');
    const [importoDefault,setImportoDefault]=useState('');
    const [durataDefault,setDurataDefault]=useState('');
    const [avvisoDefault,setAvvisoDefault]=useState(0);

    const [accessiDefault,setAccessiDefault]=useState('');

    //const [ivaDefault,setIvaDefault]=useState('');
    //const [periodicitaDefault,setPeriodicitaDefault]=useState('');

    const [flagArchiviato,setFlagArchiviato]=useState(0);
    const [numeroSottoscrizioni, setNumeroSottoscrizioni]=useState(0);
    const [impostazioneModificaImporto, setImpostazioneModificaImporto]=useState(0);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [copiaCampo,setCopiaCampo]= useState(true);
    //let periodicitaPossibili=["Nessuna","Giornaliero","Settimanale","Quindicinale","Mensile","Bimestrale","Trimestrale","Quadrimestrale","Semestrale","Annuale"];

    const [unitaDurataPossibili,setUnitaDurataPossibili]=useState([]);

    const [termineRicercaDati,setTermineRicercaDati]=useState(0);

    const [unitaAttuale,setUnitaAttuale]=useState('');

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setCopiaCampo(true);
        //setInsPeriodicitaEdi("Annuale");//dafault utile in inesrimento se l'utente non sceglie nulla
    },[]);

    useEffect(() => {
        if(termineRicercaDati === 1){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[termineRicercaDati]);

    // Sincronizzo il campo "classe" con il campo "nome" finché copiaCampo è true
    useEffect(() => {
        if (copiaCampo && props.ope!=='modifica') {
        setClasseDefault(nomeDefault);
        }
    }, [nomeDefault, copiaCampo]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("abbonamentiIns");
        if(props.ope==='modifica') setOperazione("abbonamentiMod");
        if(props.ope==='cancella') setOperazione("abbonamentiCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"abbonamentiRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
//console.log(valoreDiRitorno.risultatoRitorno[0]);
                    //setInsPeriodicitaEdi(valoreDiRitorno.risultatoRitorno[0].PERIODICITA);
                    setNomeDefault(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setClasseDefault(valoreDiRitorno.risultatoRitorno[0].CLASSE_ABBONAMENTO);
                    setImportoDefault(valoreDiRitorno.risultatoRitorno[0].IMPORTO);
                    //setIvaDefault(valoreDiRitorno.risultatoRitorno[0].IVA);
                    //setPeriodicitaDefault(valoreDiRitorno.risultatoRitorno[0].PERIODICITA);
                    setFlagArchiviato(parseInt(valoreDiRitorno.risultatoRitorno[0].FLAG_ARCHIVIATO));

                    setDurataDefault(valoreDiRitorno.risultatoRitorno[0].DURATA_PREDEFINITA);
                    setAccessiDefault(valoreDiRitorno.risultatoRitorno[0].ACCESSI);
                    setAvvisoDefault(valoreDiRitorno.risultatoRitorno[0].GIORNI_AVVISO_SCADENZA);
                    setInsUnitaDurataEdi(valoreDiRitorno.risultatoRitorno[0].DURATA_PREDEFINITA_UNITA_MISURA);
                    
                    ricercaUnitaMisura(valoreDiRitorno.risultatoRitorno[0].DURATA_PREDEFINITA_UNITA_MISURA);
                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        } else {
            ricercaUnitaMisura(0);
            //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[props.ope]);

    // Funzione per gestire il cambiamento nel campo "classe"
    const handleClasseChange = (e) => {
        const nuovoValore = e.target.value;
      
        // Se il valore scritto è diverso da quello attuale o ci sono cambiamenti, disabilita la copia
        if (nuovoValore !== nomeDefault) {
          setCopiaCampo(false);
        }
      
        // Aggiorna lo stato con il nuovo valore
        setClasseDefault(nuovoValore);
        setVariazioneCampi(1);
      };

    function isInt(n) {
        return n % 1 === 0;
     }

    function ricercaUnitaMisura(valoreAttuale) {
        //ricerco tutte le unità di misura del tempo possibili che mi servono per popolare la tendina:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoUnitaTempo",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){  
                    setUnitaDurataPossibili(valoreDiRitorno.risultatoRitorno);   

                    if(insUnitaDurataEdi === 0) setInsUnitaDurataEdi(valoreDiRitorno.risultatoRitorno[0].ID);//in inserimento, se non cambi nulla in tendina passerà il primo valore della tendina

                    if(parseInt(valoreAttuale) !== 0){
                        for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                            if(parseInt(valoreDiRitorno.risultatoRitorno[i].ID) === parseInt(valoreAttuale)){
                                setUnitaAttuale(valoreDiRitorno.risultatoRitorno[i].DESCRIZIONE_UNITA_TEMPO);
                                break;
                            } 
                        }
                    }
                    setTermineRicercaDati(1);
                    
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }


    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function tornaScheda() {
        setFlagAttivaModaleImportoModificato(0);
        setFlagAttivaModaleAbbNonCancellabile(0);
        setFlagAttivaModaleAbbNonModificabile(0);
        setFlagAttivaModaleAbbNonInseribile(0);
        setFlagAttivaModaleInserisciNome(0);
        setFlagAttivaModaleImponibileErrato(0);
        setFlagAttivaModaleIvaErrata(0);
        setFlagAttivaModaleInfoBuoni(0);
        setFlagAttivaModaleAccessi(0);
        setModaleClasseAbbonamento(false);
    }


    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }
   
    function fetchOperazioneServer(archivia=0,bypassaControlli=0) {

        let controlli=0;
        let durataLocale = 0;
        let accessiLocale = -1;
        let avvisoLocale = 0;

        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(nomeDefault=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciNome(1);
            }
            if(isNaN(FormattaImportoDb(importo.current.value))){
                controlli = 1;
                setFlagAttivaModaleImponibileErrato(1);
            }
            
            if(durata.current !== undefined) durataLocale = durata.current.value;

            if(isInt(durataLocale)===false){
                alert("Valorizzare la Durata con un valore intero");
                controlli = 1;
                setDisabilitaBottoni(false);
            }

            if(accessi.current !== undefined) accessiLocale = accessi.current.value;

            if(isInt(accessiLocale)===false){
                alert("Valorizzare il numero di Accessi con un valore intero");
                controlli = 1;
                setDisabilitaBottoni(false);
            }

            if(avviso.current !== undefined) avvisoLocale = avviso.current.value;

            if(isInt(avvisoLocale)===false){
                alert("Valorizzare i Giorni di Preavviso Scadenza Abbonamento per gli iscritti con un valore intero");
                controlli = 1;
                setDisabilitaBottoni(false);
            }
            
        }

        if(controlli===0){

            //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        
            if(props.ope==='modifica' && variazioneCampi===0 && archivia===0){
                //in realtà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco(); 
            }else{
                // parametri per inviare i dati al server:
                setLoading(true);

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope !=='cancella' ? 
                        JSON.stringify({op:operazione,
                        nome:nomeDefault,
                        classe:classeDefault,
                        archivia:archivia,
                        importo:FormattaImportoDb(importo.current.value),
                        importoModificato:importoDefault==FormattaImportoDb(importo.current.value)?0:1,
                        impostazioneModificaImporto:impostazioneModificaImporto,
                        //periodicita:insPeriodicitaEdi,
                        durata:durataLocale,
                        accessi:accessiLocale,
                        avviso:avvisoLocale,
                        unitaDurata:insUnitaDurataEdi,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                        : 
                        JSON.stringify({op:operazione,
                        archivia:archivia,
                        archiviato:flagArchiviato,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Abb non cancellabile: presente in abbonati") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                               
                                    setFlagAttivaModaleAbbNonCancellabile(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Abb non modificabile: presente altro record uguale") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                
                                    setFlagAttivaModaleAbbNonModificabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Abb non inseribile: presente altro record uguale") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                               
                                    setFlagAttivaModaleAbbNonInseribile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Nome non valorizzato") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                  
                                    setFlagAttivaModaleInserisciNome(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Importo errato") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                  
                                    setFlagAttivaModaleImponibileErrato(1);
                                }

                                if(valoreDiRitorno.mess=="modifica importo su abb sottoscritto"){
                                    setFlagAttivaModaleImportoModificato(1);
                                    setNumeroSottoscrizioni(valoreDiRitorno.sottoscrizioni);
                                }
                                /*if(valoreDiRitorno.mess.indexOf("Iva errata") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setModaleIvaErrata(true);
                                    setFlagAttivaModaleIvaErrata(1);
                                }*/
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    const dt = new Date();
    dt.setDate(dt.getDate() - 1);
    var dg = dt.getDate();
    var dm = dt.getMonth()+1;
    var da = dt.getFullYear();
    const dataIeri = dg+"/"+dm+"/"+da;

    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
         
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1Abb {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleImportoModificato===1 ?
                        <Modale 
                        titolo="Richiesta conferma"
                        flagErrore={true}
                        contenuto={
                            <div>
                                Hai modificato l'importo su un abbonamento che è stato già sottoscritto {numeroSottoscrizioni} {numeroSottoscrizioni==1?"volta":"volte"}. Scegli cosa fare:<br/><br/>
                                <div style={{textAlign:'left'}}>
                                    <Input className="ml-2 mt-2" type="checkbox" checked={impostazioneModificaImporto === 1} onInput={(e)=>{setImpostazioneModificaImporto(1);}}/>  
                                    <Label className="ml-4 mt-1">
                                        mantieni l'importo precedente di {FormattaImportoVideo(importoDefault,1)} euro a chi ha sottoscritto finora questo abbonamento 
                                        e applica il nuovo importo di {importo.current.value} euro <b>a chi lo sottoscriverà in futuro</b>.
                                        Per fare questo, il nome dell'abbonamento finora sottoscritto cambierà in "{nomeDefault} fino al {dataIeri}".
                                    </Label>

                                    {/*<br/>

                                    <Input className="ml-2 mt-2" type="checkbox" onInput={(e)=>{setImpostazioneModificaImporto(2);}}/>  
                                    <Label className="ml-4 mt-1">
                                        mantieni l'importo precedente di {FormattaImportoVideo(importoDefault,1)} euro a chi ha sottoscritto finora questo abbonamento 
                                        e applica il nuovo importo di {importo.current.value} euro <b>solo a chi lo sottoscriverà in futuro</b>.
                                        Chi lo ha già sottoscritto continuerà ad avere il vecchio importo anche nei prossimi rinnovi.
                                        Per fare questo, il nome dell'abbonamento finora sottoscritto cambierà in "{nomeDefault} fino al {dataIeri}".
                                    </Label>*/}

                                    <br/>

                                    <Input className="ml-2 mt-2" type="checkbox" checked={impostazioneModificaImporto === 3} onInput={(e)=>{setImpostazioneModificaImporto(3);}}/>  
                                    <Label className="ml-4 mt-1">
                                        l'importo precedente di {FormattaImportoVideo(importoDefault,1)} euro era sbagliato, quindi modifica l'importo e 
                                        assegna {importo.current.value} euro <b>a tutti</b> quelli che hanno sottoscritto questo abbonamento in passato.
                                    </Label>
                                </div>

                            </div>
                        }
                        bottoni={[
                            {
                                "etichetta":"Conferma",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);fetchOperazioneServer()}
                            },
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""} 

                    {flagAttivaModaleInserisciNome===1 ?
                        <Modale 
                        titolo="Richiesta nome"
                        flagErrore={true}
                        contenuto={<div>Non è stato indicato il nome, che è necessario</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""} 

                    {flagAttivaModaleAbbNonCancellabile=== 1 ?
                        <Modale 
                        titolo="Informativa archiviazione abbonamento"
                        flagErrore={true}
                        contenuto={<div>L'abbonamento selezionato risulta associato ad uno o più abbonati, per questo non si può cancellare, ma si può archiviare. 
                        Vuoi archiviarlo? Se sì, verrà tolto dagli abbonamenti disponibili e comparirà solo premendo il bottone 'visualizza abbonamenti archiviati'</div>}
                        bottoni={[
                            {
                                "etichetta":"Archivia",
                                "tipo":"danger",
                                callback:() => {setDisabilitaBottoni(false);fetchOperazioneServer(1)}
                            },   
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}
                    {flagAttivaModaleAbbNonModificabile=== 1 ?
                        <Modale 
                        titolo="Informativa abbonamento non modificabile"
                        flagErrore={true}
                        contenuto={<div>Il nome dell'abbonamento non è modificabile in {nome.current.value} poichè è già presente un abbonamento con lo stesso nome</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}
                    {flagAttivaModaleAbbNonInseribile=== 1 ?
                        <Modale 
                        titolo="Informativa abbonamento non inseribile"
                        flagErrore={true}
                        contenuto={<div>L'abbonamento {nome.current.value} non è inseribile in poichè è già presente un abbonamento con lo stesso nome</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}

                    {flagAttivaModaleImponibileErrato=== 1 ?
                        <Modale 
                        titolo="Informativa imponibile errato"
                        flagErrore={true}
                        contenuto={<div>L'importo inserito non è un numero: completare il campo con un valore numerico</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}

                    {flagAttivaModaleIvaErrata=== 1 ?
                        <Modale 
                        titolo="Informativa iva errata"
                        flagErrore={true}
                        contenuto={<div>L'importo inserito per l'iva non è un numero: completare il campo con un valore numerico</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}
                    {flagAttivaModaleInfoBuoni=== 1 ?
                        <Modale 
                        titolo="Informativa gestione buoni abbonamento"
                        flagErrore={true}
                        contenuto={<div>Per inserire un Abbonamento Gratuito, un Abbonamento 'Buono Regalo' o simili è possibile valorizzare l'importo a 0</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}
                    {modaleClasseAbbonamento === true ?
                        <Modale 
                        titolo="Informativa campo Classe Abbonamento"
                        flagErrore={true}
                        contenuto={<div>La classe abbonamento permette di riunire diversi abbonamenti in un gruppo. Inizialmente coincidente con l'abbonamanto stesso, la classe può essere variata andando a modificarne il nome. Indicando lo stesso nome di classe in più abbonamenti, questi verranno quindi raggruppati sotto un'unica classe. Ciò sarà utile ad esempio nella <b>Registrazione Manuale Accessi</b>, dove indicando la classe si otterra l'elenco di tutti gli iscritti agli abbonamenti di quella classe. Oppure nelle <b>Prenotazioni</b>, dove si potrà vincolare la possibilità di prenotazione di una prestazione / attrezzatura al possesso di un abbonamento di una determinata classe.</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}

                    {flagAttivaModaleAccessi=== 1 ?
                        <Modale 
                        titolo="Informativa Numero Accessi"
                        flagErrore={true}
                        contenuto={<div>Se il controllo accessi é stato attivato, è possibile indicare il valore massimo di accessi consentiti. Lasciando il campo vuoto verrà previsto un numero di accessi illimitato</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}

                    <div className="schedaSpazioCentrale">
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Abbonamento</CardTitle> :""}
                            {props.ope === "modifica" ? 
                                flagArchiviato === 1 ? <CardTitle>Scheda Abbonamento Archiviato</CardTitle> : <CardTitle>Modifica Abbonamento</CardTitle>
                            :""}
                            
                            
                            
                            {/*{props.ope === "modifica" ? 
                                    <Row form><Col md={12}><FormGroup><Label>Nome Abbonamento: {nomeDefault}</Label><FormGroup></Col></Row>
                                    <Row form><Col md={12}><FormGroup><Label>Importo: {FormattaImportoVideo(importoDefault)}</Label><FormGroup></Col></Row>
                                    <Row form><Col md={12}><FormGroup><Label>Periodicità: {insPeriodicitaEdi}</Label><FormGroup></Col></Row>
                    :""}*/}

                        
                            <Clessidra loading={loading}/>
                                {props.ope === "cancella" ?
                                    <FinestraDialogo 
                                        titolo="Conferma eliminazione" 
                                        flagErrore={true}                                
                                        contenuto={<div>
                                            Confermi l'eliminazione dell'abbonamento <b>{nomeDefault}</b> ?<br/> 
                                            {flagArchiviato === 1 ?      
                                            <b>Attenzione: trattandosi di un abbonamento archiviato, procedendo con la cancellazione si andranno ad eliminare anche tutti i dati relativi agli abbonati ad esso connessi</b>
                                            :""}
                                        </div>}
                                        bottoni={[
                                            {
                                                "etichetta":"Conferma",
                                                "tipo":"primary",
                                                callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                            },
                                            {
                                                "etichetta":"Annulla",
                                                "tipo":"primary",
                                                callback: () => {tornaElenco()}
                                            }
                                        ]}
                                    />                        

                                :

                                <>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                        <Label for="nome">Nome Abbonamento*</Label>
                                        {(props.ope === "inserisci" || props.ope === "modifica") && flagArchiviato !== 1 ? (
                                            <Input
                                            maxLength={100}
                                            type="text"
                                            name="nome"
                                            id="nome"
                                            innerRef={nome}
                                            placeholder=""
                                            value={nomeDefault}
                                            onInput={(e) => {
                                                setNomeDefault(e.target.value);  // Aggiorna il valore del campo nome
                                            }}
                                            />
                                        ) : (
                                            <Label for="nome">: {nomeDefault}</Label>
                                        )}
                                        {props.ope === "cancella" ? <Label>: {nomeDefault}</Label> : ""}
                                        </FormGroup>
                                    </Col>
                                    </Row>
                                    <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                        <Label for="classe">Classe Abbonamento<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleClasseAbbonamento(true)}/></Label>
                                        {(props.ope === "inserisci" || props.ope === "modifica") && flagArchiviato !== 1 ? (
                                            <Input
                                            maxLength={100}
                                            type="text"
                                            name="classe"
                                            id="classe"
                                            innerRef={classe}
                                            placeholder=""
                                            value={classeDefault}  // Stato aggiornato dinamicamente
                                            onInput={handleClasseChange}  // Gestione delle modifiche manuali
                                            />
                                        ) : (
                                            <Label for="nome">: {classeDefault}</Label>
                                        )}
                                        {props.ope === "cancella" ? <Label>: {classeDefault}</Label> : ""}
                                        </FormGroup>
                                    </Col>
                                    </Row>


                                    <Row form> 
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="importo">Importo<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagAttivaModaleInfoBuoni(1)}/></Label>
                                                {(props.ope === "inserisci" || props.ope === "modifica") && flagArchiviato!==1 ?
                                                    <Input maxLength={12} type="text" name="importo" id="importo"
                                                    innerRef={importo} placeholder="" defaultValue={FormattaImportoVideo(importoDefault,1)}
                                                    onInput={(e)=>{handleCambiamentoMod(e);}}/> 
                                                :""}
                                                {(props.ope === "inserisci" || props.ope === "modifica") && flagArchiviato===1 ?
                                                    <Input maxLength={12} type="text" name="importo" id="importo" readOnly
                                                    innerRef={importo} placeholder="" defaultValue={FormattaImportoVideo(importoDefault,1)}
                                                    onInput={(e)=>{handleCambiamentoMod(e);}}/> 
                                                :""}
                                                {props.ope === "cancella" ? <Label>: {FormattaImportoVideo(importoDefault)}</Label> : ""}
                                            </FormGroup>
                                        </Col>

                                        {/*<Col md={6}>
                                            <FormGroup>
                                            {(props.ope === "inserisci") && flagArchiviato!==1 ? <Label for="periodicita">Periodicità*</Label>:""}
                                            {((props.ope === "inserisci" || props.ope === "modifica") && flagArchiviato===1) 
                                                || ((props.ope === "modifica") && flagArchiviato!==1) ? 
                                                <Label for="periodicita">Periodicità*</Label>
                                            :""}
                                                {(props.ope === "inserisci") && flagArchiviato!==1 ?
                                                    <select defaultValue={insPeriodicitaEdi} className="form-control"  onInput={(e)=>{setInsPeriodicitaEdi(e.target.value);handleCambiamentoMod(e);}}>
                                                        {periodicitaPossibili.map(periodicitaPossibili =>
                                                            <option key={periodicitaPossibili} value={periodicitaPossibili} >
                                                                {periodicitaPossibili}
                                                        </option>                                                   
                                                    )};
                                                    </select>
                                                :""}

                                                
                                                {((props.ope === "inserisci" || props.ope === "modifica") && flagArchiviato===1) 
                                                || ((props.ope === "modifica") && flagArchiviato!==1) ?
                                                    <Label>: {periodicitaDefault}</Label>
                                                :""}
                                                {props.ope === "cancella"? <Label>: {periodicitaDefault}</Label> : ""}
                                            </FormGroup> 
                                        </Col>*/}
                                    </Row>  
                                    
                                                     
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="accessi">Numero accessi<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagAttivaModaleAccessi(1)}/></Label>
                                                    {(props.ope === "inserisci") && flagArchiviato!==1 ?
                                                        <Input maxLength={12} type="text" name="accessi" id="accessi"
                                                        innerRef={accessi} placeholder="" defaultValue={accessiDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);}}/> 
                                                    :""}
                                                    {(props.ope === "inserisci" && flagArchiviato===1) || props.ope === "modifica" || props.ope === "cancella"?
                                                        accessiDefault == -1 ?<Label>: Illimitato</Label> : <Label>: {accessiDefault}</Label>
                                                    :""}
                                                  
                                                </FormGroup>
                                            </Col>

                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="durata">Durata</Label>
                                                    {(props.ope === "inserisci") && flagArchiviato!==1 ?
                                                        <Input maxLength={12} type="text" name="durata" id="durata"
                                                        innerRef={durata} placeholder="" defaultValue={durataDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);}}/> 
                                                    :""}
                                                    {(props.ope === "inserisci" && flagArchiviato===1) || props.ope === "modifica" ?
                                                        <Label>: {durataDefault}</Label>
                                                    :""}
                                                    {props.ope === "cancella" ? <Label>: {durataDefault}</Label> : ""}
                                                </FormGroup>
                                            </Col>

                                            <Col md={4}>
                                                <FormGroup>
                                            
                                
                                                <Label for="unitaDurata">Unità di Misura Durata</Label>
                                                

                                                {(props.ope === "inserisci") && flagArchiviato!==1 ?
                                                        <select defaultValue={insUnitaDurataEdi} className="form-control"  onInput={(e)=>{setInsUnitaDurataEdi(e.target.value);handleCambiamentoMod(e);}}>
                                                            {unitaDurataPossibili.map(unitaDurataPossibili =>
                                                            <option key={unitaDurataPossibili} value={unitaDurataPossibili.ID}>{unitaDurataPossibili.DESCRIZIONE_UNITA_TEMPO}</option>  
                                                            )}; 
                                                        </select>
                                                :""}

                                                    
                                                {((props.ope === "inserisci" && flagArchiviato===1) || props.ope === "modifica")?     
                                                    <Label>: {unitaAttuale}</Label>
                                                :""}

                                                {props.ope === "cancella"? <Label>: {insUnitaDurataEdi}</Label> : ""}
                                                </FormGroup> 
                                                            </Col>
                                        </Row> 
                                    

                                    <Row> 
                                        <Col md={12}>
                                            <FormGroup>
                                                
                                                <Label for="avviso">Giorni di Preavviso Scadenza Abbonamento per gli Iscritti</Label>

                                                {(props.ope === "inserisci"|| props.ope === "modifica") && flagArchiviato!==1 ?
                                                    <Input maxLength={12} type="text" name="avviso" id="avviso"
                                                    innerRef={avviso} placeholder="" defaultValue={avvisoDefault}
                                                    onInput={(e)=>{handleCambiamentoMod(e);}}/> 
                                                :<Label>: {avvisoDefault}</Label>}
                                            </FormGroup>
                                        </Col>
                                    </Row> 
                                  
                                </>
                                }
                                
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? 
                                    <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {
                                        /*
                                        if (importoDefault!=FormattaImportoDb(importo.current.value)) {
                                            alert("Importo modificato: default="+importoDefault+" attuale="+importo.current.value);
                                        }
                                        */
                                        setDisabilitaBottoni(true);
                                        fetchOperazioneServer();
                                    }}>Memorizza Modifiche</Button>
                                :
                                    ""
                                }
                                
                                {/*<Fragment> flagArchiviato: {flagArchiviato} </Fragment>*/}
                                
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                {props.ope === "modifica" && flagArchiviato !== 1 ?
                                    <Button disabled={disabilitaBottoni} color="danger" className="mt-2" onClick={(e) => {fetchOperazioneServer(1)}} >
                                        <Fragment>Archivia</Fragment>
                                    </Button>
                                :""}
                                {props.ope === "modifica" && flagArchiviato === 1 ?
                                    <Button disabled={disabilitaBottoni} color="danger" className="mt-2" onClick={(e) => {fetchOperazioneServer(2)}} >
                                        <Fragment>Riporta nei Disponibili</Fragment>
                                    </Button>
                                :""}
                                </div>
                                
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
