import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input , Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import TendinaEditabile from '../TendinaEditabile.js';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno

    /*
    var d = new Date();
    d.setMonth(d.getMonth() + 1);

    let sMeseMaggiorato = String(d.getMonth()+1);
    if(sMeseMaggiorato.length === 1) sMeseMaggiorato = "0"+sMeseMaggiorato;
    let sGiornoMaggiorato = String(d.getDate());
    if(sGiornoMaggiorato.length === 1) sGiornoMaggiorato = "0"+sGiornoMaggiorato;
    const dataOggiMaggiorataUnMese = d.getFullYear()+'-'+sMeseMaggiorato+'-'+sGiornoMaggiorato*/

    
    const [operazione,setOperazione] = useState('');
    
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagAttivaModaleAdempimentoNonModificabile, setFlagAttivaModaleAdempimentoNonModificabile] = useState(0);
    const [flagAttivaModaleAdempimentoNonInseribile, setFlagAttivaModaleAdempimentoNonInseribile] = useState(0);
    const [flagAttivaModaleInserisciDescrizione, setFlagAttivaModaleInserisciDescrizione] = useState(0);
    const [flagAttivaModaleInserisciDataInizio, setFlagAttivaModaleInserisciDataInizio] = useState(0);
    const [flagAttivaModaleInserisciDataFine, setFlagAttivaModaleInserisciDataFine] = useState(0);
    const [flagAttivaModaleInserisciUnitaTempo, setFlagAttivaModaleInserisciUnitaTempo] = useState(0);
    const [flagAttivaModaleDateDistanti, setFlagAttivaModaleDateDistanti] = useState(0);
    const [flagAttivaModaleDateErrate, setFlagAttivaModaleDateErrate] = useState(0);
    const [flagAttivaModaleUnitaTempoErrata, setFlagAttivaModaleUnitaTempoErrata] = useState(0);

    //const [flagAttivaModaleInserisciUnitaTempo, setFlagAttivaModaleInserisciUnitaTempo] = useState(0);

    const [risultatoServerDatiUnitaTempo,setRisultatoServerDatiUnitaTempo]=useState('');
    const [statoTendinaUnitaTempo,setStatoTendinaUnitaTempo]=useState(false);

    const descrizione   = useRef();
    const dataInizio   = useRef();
    const dataFine   = useRef();
    const periodicita   = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    
    //let descrizioneDefault = "";
    const [descrizioneDefault,setDescrizioneDefault] = useState('');
    const [dataInizioDefault,setDataInizioDefault]=useState(dataOggi);
    const [dataFineDefault,setDataFineDefault]=useState(dataOggi);
    const [periodicitaDefault,setPeriodicitaDefault] = useState(0);

    const [insUnitaTempoEdi,setInsUnitaTempoEdi]=useState('');

    //const [ritornaScheda,setRitornaScheda]=useState(0);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaUnitaTempo(false);
    },[]);

    useEffect(() => {
        if(statoTendinaUnitaTempo === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaUnitaTempo]);

    /*useEffect(() => {
        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[ritornaScheda]);*/

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("adempimentiIns");
        if(props.ope==='modifica') setOperazione("adempimentiMod");
        if(props.ope==='cancella') setOperazione("adempimentiCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"adempimentiRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            //setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    setLoading(false);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                  
                    ricercaUnitaTempo(props.ope);
                    setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                    setDataInizioDefault(valoreDiRitorno.risultatoRitorno[0].DATA_PRIMA_ESECUZIONE);
                    setDataFineDefault(valoreDiRitorno.risultatoRitorno[0].DATA_ULTIMA_ESECUZIONE);
                    setInsUnitaTempoEdi(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE_UNITA_TEMPO);
                    setPeriodicitaDefault(valoreDiRitorno.risultatoRitorno[0].PERIODICITA);
                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    },
                (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else{
            setStatoPagina(STATO_RICERCA_DATI);
            ricercaUnitaTempo(props.ope);
        }
    },[props.ope]);

    function ricercaUnitaTempo(operazione) {
        //ricerco le unità di tempo che mi servono per popolare la tendina:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoUnitaTempo",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiUnitaTempo(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaUnitaTempo(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }


    function tornaElenco() {
        props.callbackTermineModifica();
    }

    function tornaScheda() {
        setFlagAttivaModaleAdempimentoNonModificabile(0);
        setFlagAttivaModaleAdempimentoNonInseribile(0);
        setFlagAttivaModaleInserisciDescrizione(0);
        setFlagAttivaModaleInserisciDataInizio(0);
        setFlagAttivaModaleInserisciDataFine(0);
        setFlagAttivaModaleInserisciUnitaTempo(0);
        setFlagAttivaModaleDateDistanti(0);
        setFlagAttivaModaleDateErrate(0);
        setFlagAttivaModaleUnitaTempoErrata(0);

    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(descrizione.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDescrizione(1);
            }
            if(dataInizio.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDataInizio(1);
            }
            if(dataFine.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDataFine(1);
            }
            if(insUnitaTempoEdi=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciUnitaTempo(1);
            }
            let presenteUnitaTempo = -1;
            //if(insAbbonamentoEdi != ''){
                presenteUnitaTempo = 0;
                //controllo se l'unità di tempo non è presente nell'elenco attuale:
                for (let i=0; i<risultatoServerDatiUnitaTempo.length ; i++) {
                    if(risultatoServerDatiUnitaTempo[i]["DESCRIZIONE_UNITA_TEMPO"] === insUnitaTempoEdi){
                        presenteUnitaTempo=1;
                        break;
                    } 
                }
                if(presenteUnitaTempo === 0){
                    setInsUnitaTempoEdi();     
                    setFlagAttivaModaleUnitaTempoErrata(1);
                    controlli = 1;
                } 
            //}
        }



        if(controlli===0){ 

            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                setLoading(true);
                // parametri per inviare i dati al server:
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ?  
                        JSON.stringify({op:operazione,
                        descrizione:descrizione.current.value,
                        dataInizio:dataInizio.current.value,
                        dataFine:dataFine.current.value,
                        unitaTempo:insUnitaTempoEdi,
                        periodicita:periodicita.current.value,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){

                                if(valoreDiRitorno.mess.indexOf("Adempimento non modificabile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleAdempimentoNonModificabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Adempimento non inseribile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleAdempimentoNonInseribile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Descrizione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDescrizione(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Data prima esecuzione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDataInizio(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Data ultima esecuzione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDataFine(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Intervallo troppo elevato") !== -1){
                                    setFlagAttivaModaleDateDistanti(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Date errate") !== -1){
                                    setFlagAttivaModaleDateErrate(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Unita di tempo non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciUnitaTempo(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Unita di tempo errata") !== -1){
                                    setInsUnitaTempoEdi();
                                    setFlagAttivaModaleUnitaTempoErrata(1);
                                }
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }
/*
    if(statoPagina === STATO_RICERCA_DATI_CORRETTA){
        {risultatoServer != undefined && risultatoServer != "" ? descrizioneDefault= risultatoServer[0].DESCRIZIONE :""}
    }
 */   
    
        return (
            <Fragment>
              
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1Ris {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleInserisciDataInizio===1 ?
                        <Modale 
                        titolo="Richiesta data inizio"
                        flagErrore={true}
                        contenuto={<div>Non è stata indicata la data prima esecuzione, che è necessaria</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleInserisciUnitaTempo===1 ?
                        <Modale 
                        titolo="Richiesta unità tempo"
                        flagErrore={true}
                        contenuto={<div>Non è stata indicata l'unità di tempo, che è necessaria</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleInserisciDataFine===1 ?
                        <Modale 
                        titolo="Richiesta unità tempo"
                        flagErrore={true}
                        contenuto={<div>Non è stata indicata la data ultima esecuzione, che è necessaria</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleDateDistanti===1 ?
                        <Modale 
                        titolo="Informativa periodo esecuzione"
                        flagErrore={true}
                        contenuto={<div>Le date di prima ed ultima esecuzione risultano troppo distanti (intervallo massimo: 5 anni)</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleUnitaTempoErrata===1 ?
                        <Modale 
                        titolo="Informativa unita di tempo errata"
                        flagErrore={true}
                        contenuto={<div>Il campo unità di tempo non è stato valorizzato in modo corretto: scegliere uno dei valori proposti in tendina</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleDateErrate===1 ?
                        <Modale 
                        titolo="Informativa date errate"
                        flagErrore={true}
                        contenuto={<div>La data di prima esecuzione risulta successiva alla data di ultima esecuzione</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleInserisciDescrizione===1 ?
                        <Modale 
                        titolo="Richiesta descrizione"
                        flagErrore={true}
                        contenuto={<div>Non è stata indicata la descrizione, che è necessaria</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleAdempimentoNonModificabile===1 ?
                        <Modale 
                        titolo="Informativa adempimento non modificabile"
                        flagErrore={true}
                        contenuto={<div>La descrizione dell'adempimento non è modificabile in {descrizione.current.value} poichè nel database è già presente un adempimento con la stessa descrizione</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleAdempimentoNonInseribile===1 ?
                        <Modale 
                        titolo="Informativa Adempimento non inseribile"
                        flagErrore={true}
                        contenuto={<div>L'adempimento {descrizione.current.value} non è inseribile in poichè nel database è già presente un adempimento con la stessa descrizione</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Adempimento</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Adempimento</CardTitle> :""}
                
                            
                                <Clessidra loading={loading}/>

                                {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione di tutti gli adempimenti <b>{descrizioneDefault}</b> con data prima esecuzione <b><TrasformaDateAUmano data={dataInizioDefault}></TrasformaDateAUmano></b> e data ultima esecuzione <b><TrasformaDateAUmano data={dataFineDefault}></TrasformaDateAUmano></b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Elimina",
                                            "tipo":"danger",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :

                            
                                <>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="descrizione">Descrizione*</Label>
                                                <Input maxLength={200} type="text" name="descrizione" id="descrizione"
                                                innerRef={descrizione} 
                                                placeholder=""
                                                defaultValue={descrizioneDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                <Col md={6}>
                                        <FormGroup>
                                            <Label for="dataInizio">Data Prima Esecuzione*</Label>
                                            <Input maxLength={10} type="date" name="dataInizio" id="dataInizio"
                                                innerRef={dataInizio} 
                                                placeholder=""
                                                defaultValue={dataInizioDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataInizioDefault(dataInizio.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="dataFine">Data Ultima Esecuzione*</Label>
                                            <Input maxLength={10} type="date" name="dataFine" id="dataFine"
                                                innerRef={dataFine} 
                                                placeholder=""
                                                defaultValue={dataFineDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataFineDefault(dataFine.current.value)}}/> 
                                       
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="periodicita">Ripeti ogni 
                                                <Input maxLength={10} type="text" name="periodicita" id="periodicita"
                                                innerRef={periodicita} 
                                                placeholder=""
                                                defaultValue={periodicitaDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setPeriodicitaDefault(periodicita.current.value)}}/> 
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={9}>
                                        <FormGroup>
                                            <Label>Unità di tempo</Label>
                                
                                                <TendinaEditabile
                                                //titolo={"Unità di tempo*"}
                                                nomeCampo="DESCRIZIONE_UNITA_TEMPO"
                                                valoreDefault={insUnitaTempoEdi}
                                                arrayValori={risultatoServerDatiUnitaTempo}
                                                bloccoInserimento = "1"
                                                callbackSetVariazioneCampi={setVariazioneCampi}
                                                callbackSetValoreTendEditabile={setInsUnitaTempoEdi}
                                            ></TendinaEditabile>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                </>
                                }
                               

                                </div>
                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                               
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica")?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                                
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
