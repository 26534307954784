import React, {useState, useEffect} from 'react';
import imgAnagrafiche from './immagini/anagrafiche.png'
import imgPrenotazioni from './immagini/prenotazioni.png'
import imgMerchandizing from './immagini/merchandizing.png'
import imgTesseramenti from './immagini/tesseramenti.png'
import imgSponsor from './immagini/sponsor.png'
import imgEventi from './immagini/eventi.png'
import imgAllenamenti from './immagini/allenamenti.png'
import imgGestEconomica from './immagini/euro.png'
import imgReport from './immagini/report.png'
import imgDocumenti from './immagini/documenti.png'
import imgAdempimenti from './immagini/calendario.png'
import imgImpostazioni from './immagini/impostazioni.png'
import imgNews from './immagini/news.png'
import imgCrm from './immagini/crm.png'
import imgLogout from './immagini/logout.png'

/*
Permessi: 0b<elenco bit 0 o 1, dove 0=no e 1=sì>
primo bit: flag amministrazione richiesto
secondo bit: flag staff staff richiesto
*/

function Menu(props) {
    var flagControlloAccessi = false;
    switch (props.identitaSelezionata.nome_database) {
      case "EMA18":
      case "EMA48":
        flagControlloAccessi=true;
    }
    var flagCRM = true;
    var flagFattureElettroniche = true;

    const voci = [{
      "voce":"Anagrafiche",
      "icona": imgAnagrafiche,
      "permessi":0b10,
      "sottomenu":[
        {"voce": "Elenco Iscritti", "pagina":props.PAGINE.iscritti, "permessi":0b10},
        {"voce": "Invia Messaggio", "pagina":props.PAGINE.inviaMessaggio, "permessi":0b10},
        flagControlloAccessi && {"voce": "Associa Tessera", "pagina":props.PAGINE.associaTessera, "permessi":0b10},        
        {"voce": "Direttivo", "pagina":props.PAGINE.direttivo, "permessi":0b10},
        {"voce": "Libro Soci", "pagina":props.PAGINE.libroSoci, "permessi":0b10},
        {"voce": "Raggruppamenti", "pagina":props.PAGINE.gruppi, "permessi":0b10},
        {"voce": "Enti Esterni / Fornitori", "pagina":props.PAGINE.clientiFornitori, "permessi":0b10},
        {"voce": "Società Analoghe", "pagina":props.PAGINE.socAnaloghe, "permessi":0b10},
        {"voce": "Contatti Utili", "pagina":props.PAGINE.contattiUtili, "permessi":0b10},
      ]
    }, {
      "voce":"Abbonamenti / Prenotazioni",
      "icona": imgPrenotazioni,
      "permessi":0b11,
      "sottomenu":[
        {"voce": "Abbonati", "pagina":props.PAGINE.abbonati, "permessi":0b10},
        {"voce": "Abbonamenti Disponibili", "pagina":props.PAGINE.abbonamenti, "permessi":0b10},
        {"voce": "Registrazione Manuale Accessi", "pagina":props.PAGINE.accessi, "permessi":0b11},
        {"voce": "Registrazione Accessi con QR Code", "pagina":props.PAGINE.accessiQR, "permessi":0b11},
        {"voce": "Prestazioni / Attrezzature Prenotabili", "pagina":props.PAGINE.corsi, "permessi":0b11},
        {"voce": "Prenotazioni", "pagina":props.PAGINE.prenotazioneCorsi2, "permessi":0b11},
      ]
    },{
      "voce":"Gestione Economica",
      "icona": imgGestEconomica,
      "permessi":0b10,
      "sottomenu":[
        {"voce": "Prima Nota", "pagina":props.PAGINE.primaNota, "permessi":0b10},
        {"voce": "Tabella Quote Associative", "pagina":props.PAGINE.quoteAssociative, "permessi":0b10},
        {"voce": "Incassi Quote Associative", "pagina":props.PAGINE.incassiQuoteAss, "permessi":0b10},
        {"voce": "Incassi Quote Abbonamenti", "pagina":props.PAGINE.incassiQuoteAbb, "permessi":0b10},
        {"voce": "Incassi da Esterni", "pagina":props.PAGINE.incassiDiversi, "permessi":0b10},
        {"voce": "Incassi Diversi da Iscritti", "pagina":props.PAGINE.incassiDiversiIscritti, "permessi":0b10},
        {"voce": "Spese", "pagina":props.PAGINE.spese, "permessi":0b10},
        {"voce": "Rimborsi", "pagina":props.PAGINE.rimborsi, "permessi":0b10},
        {"voce": "Risorse", "pagina":props.PAGINE.risorse, "permessi":0b10},
        {"voce": "Voci di Bilancio", "pagina":props.PAGINE.vociDiBilancio, "permessi":0b10},
        flagFattureElettroniche && {"voce": "Fatture Elettroniche", "pagina":props.PAGINE.fattureElettroniche, "permessi":0b10},
      ]
    },{
      "voce":"Tesseramenti",
      "icona": imgTesseramenti,
      "permessi":0b10,
      "sottomenu":[
        {"voce": "Elenco Tesseramenti", "pagina":props.PAGINE.tesseram, "permessi":0b10},
        {"voce": "Feder. / Enti di Tesseramento", "pagina":props.PAGINE.federEntiTesseram, "permessi":0b10},
        {"voce": "Tipi di Tesseramento", "pagina":props.PAGINE.tipiTesseram, "permessi":0b10},
        {"voce": "Categorie di Tesseramento", "pagina":props.PAGINE.catTesseram, "permessi":0b10},
      ]      
    },{
      "voce":"Adempimenti",
      "icona": imgAdempimenti,
      "permessi":0b10,
      "sottomenu":[
        {"voce": "Scadenzario", "pagina":props.PAGINE.scadenziario, "permessi":0b10},
      ]
    },{      
      "voce":"Bacheca news",
      "icona": imgNews,
      "permessi":0b11,
      "sottomenu":[
        {"voce": "News", "pagina":props.PAGINE.news, "permessi":0b11},
      ]
    },{      
      "voce":"Eventi",
      "icona": imgEventi,
      "permessi":0b01,
      "sottomenu":[
        {"voce": "Elenco Eventi", "pagina":props.PAGINE.eventi, "permessi":0b01},
        {"voce": "Tipi Eventi", "pagina":props.PAGINE.tipiEventi, "permessi":0b01},
      ]
    },{
      "voce":"Allenamenti",
      "icona": imgAllenamenti,
      "permessi":0b01,
      "sottomenu":[
        {"voce": "Esercizi", "pagina":props.PAGINE.esercizi, "permessi":0b01},
        {"voce": "Classificazioni Esercizi", "pagina":props.PAGINE.classiEsercizi, "permessi":0b01},
        {"voce": "Schede Allenamento Predefinite", "pagina":props.PAGINE.schedeAllenamento, "permessi":0b01},
        {"voce": "Schede Allenamento Assegnate", "pagina":props.PAGINE.assegnazioniSchede, "permessi":0b01},
      ]
    },{      
      "voce":"Merchandizing",
      "icona": imgMerchandizing,
      "permessi":0b10,
      "sottomenu":[
        {"voce": "Categorie Articoli", "pagina":props.PAGINE.catArticoli, "permessi":0b10},
        {"voce": "Articoli", "pagina":props.PAGINE.articoli, "permessi":0b10},
      ]
    },{
      "voce":"Sponsor / Convenzioni",
      "icona": imgSponsor,
      "permessi":0b10,
      "sottomenu":[
        {"voce": "Elenco Sponsor", "pagina":props.PAGINE.sponsor, "permessi":0b10},
        {"voce": "Campagne Sponsor", "pagina":props.PAGINE.campagneSponsor, "permessi":0b10},
        {"voce": "Elenco Enti Conv.", "pagina":props.PAGINE.entiConvenzioni, "permessi":0b10},
        {"voce": "Convenzioni", "pagina":props.PAGINE.convenzioni, "permessi":0b10},
      ]
    },{
      "voce":"Report",
      "icona": imgReport,
      "permessi":0b11,
      "sottomenu":[
        {"voce": "Report Elenco Prenotazioni", "pagina":props.PAGINE.reportElencoPrenotazioni, "permessi":0b10},
        {"voce": "Report Prenotazioni", "pagina":props.PAGINE.reportPrenotazioniCorsi, "permessi":0b11},
        {"voce": "Report Insolventi Quote", "pagina":props.PAGINE.insolventi, "permessi":0b10},
        {"voce": "Report Bilancio", "pagina":props.PAGINE.bilancio, "permessi":0b10},
        {"voce": "Scadenze Certificati Medici", "pagina":props.PAGINE.certScad, "permessi":0b10},
        {"voce": "Scadenze Doc. Identità", "pagina":props.PAGINE.docScad, "permessi":0b10},
        {"voce": "Scadenze Doc. Privacy", "pagina":props.PAGINE.privacyScad, "permessi":0b10},
        {"voce": "Scadenze Abbonamenti", "pagina":props.PAGINE.abbonamentiScad, "permessi":0b10},
        {"voce": "Report Scadenze Tesseram.", "pagina":props.PAGINE.reportTesseramenti, "permessi":0b10},
        {"voce": "Report Scadenze Vincoli", "pagina":props.PAGINE.reportVincoli, "permessi":0b10},
        {"voce": "Situazione Economica Iscritti", "pagina":props.PAGINE.reportEconPersone, "permessi":0b10},
        {"voce": "Situazione Economica Esterni", "pagina":props.PAGINE.reportEconEsterni, "permessi":0b10},
        {"voce": "Report Sponsor", "pagina":props.PAGINE.reportSponsor, "permessi":0b10},
        {"voce": "Report Rimborsi", "pagina":props.PAGINE.reportRimborsi, "permessi":0b10},
        {"voce": "Report Eventi", "pagina":props.PAGINE.reportEventi, "permessi":0b11},      
        {"voce": "Report Elenco Articoli", "pagina":props.PAGINE.reportElencoArticoli, "permessi":0b10},
        {"voce": "Report Accessi Struttura", "pagina":props.PAGINE.reportAccessi, "permessi":0b11}
      ]
    },{
      "voce":"Documenti",
      "icona": imgDocumenti,
      "permessi":0b10,
      "sottomenu":[
        //{"voce": "Verbali Assemblee (da togliere?)", "pagina":props.PAGINE.verbaliAssemblee, "permessi":0b10},//
        {"voce": "Documenti", "pagina":props.PAGINE.documenti, "permessi":0b10},
        {"voce": "Tipi Documenti", "pagina":props.PAGINE.tipiDocumenti, "permessi":0b10},
      ]
    },flagCRM && {
      "voce":"CRM",
      "icona": imgCrm,
      "permessi":0b10,
      "sottomenu":[
        //{"voce": "Settaggi", "pagina":props.PAGINE.settaggiCrm, "permessi":0b10},
        false && {"voce": "Mezzi di Comunicazione", "pagina":props.PAGINE.mezziComunicazCrm, "permessi":0b10},
        {"voce": "Comunicazioni", "pagina":props.PAGINE.comunicazioniCrm, "permessi":0b10},
        {"voce": "Contattati", "pagina":props.PAGINE.contattatiCrm, "permessi":0b10},
        {"voce": "Report Distr. Risposte", "pagina":props.PAGINE.datiCrm, "permessi":0b10},
        {"voce": "Contatti / Prospect", "pagina":props.PAGINE.prospectCrm, "permessi":0b10},
        {"voce": "Report Azioni CRM", "pagina":props.PAGINE.reportAzioniCrm, "permessi":0b10},
      ]
    },{      
      "voce":"Impostazioni",
      "icona": imgImpostazioni,
      "permessi":0b11,
      "sottomenu":[
        {"voce": "Modifica Password", "pagina":props.PAGINE.cambioPassword, "permessi":0b11},
        {"voce": "Mio Abbonamento", "pagina":props.PAGINE.mioAbbonamento, "permessi":0b10},
        {"voce": "Società e Layout Tessere", "pagina":props.PAGINE.impostazioniSocieta, "permessi":0b10},
        {"voce": "Giorni di Chiusura Struttura", "pagina":props.PAGINE.giorniChiusura, "permessi":0b10},
        {"voce": "Posizioni in Società", "pagina":props.PAGINE.posizioniSocieta, "permessi":0b10},
        {"voce": "Tipi Documenti Identità", "pagina":props.PAGINE.tipiDocIdentita, "permessi":0b10},
        {"voce": "Importazione Massiva", "pagina":props.PAGINE.importazioneMassiva, "permessi":0b10},
      ]
    //},{      
      //"voce":"Controllo Accessi",
      //"icona": imgImpostazioni,
      //"permessi":0b11,
      //"sottomenu":[
      //  {"voce": "Controllo Accessi", "pagina":props.PAGINE.controlloAccessi, "permessi":0b11}
      //]
    },{
      "voce":"Logout",
      "icona": imgLogout,
      "permessi":0b11,
      "sottomenu":[
        {"voce": "Logout", "pagina":props.PAGINE.tornaLogin, "permessi":0b11},
      ]
    }];

    const sfondoVocePrincipale = "#212a2f";
    const sfondoVocePrincipaleAperta = "#996633";
    const bordoInferiorePrincipale = "#505050";
    const testoVocePrincipale = "#d0d0d0";
    const testoVocePrincipaleAperta = "#f0f0f0";

    const sfondoVoceSecondaria = "#505050";
    const bordoInferioreSecondario = "#707070";
    const testoVoceSecondaria = "#f0f0f0";

    const sfondoVoceSelezionata = "#FFA500";
    const testoVoceSelezionata = "#000000";

    const [aperto, setAperto] = useState([]);
    const [voceSelezionata, setVoceSelezionata] = useState({"i":-1,"j":-1});

    useEffect(() => {
      var fa = [];
      for (var n=0; n<voci.length; n++) fa.push(false);
      setAperto(fa);
    }, [])

    useEffect(() => {
      if (props.chiudiTuttiSottomenu) {
        props.setChiudiTuttiSottomenu(false);
        var fa = [];
        for (var n=0; n<voci.length; n++) fa.push(false);
        setAperto(fa);
      }
    }, [props.chiudiTuttiSottomenu])    
    
    if (!props.flagVisibile) return (<></>)

    return (
    
        <div 
          className="scrollorizzontale"
          onMouseEnter={() => { if (props.flagMenuRidotto) props.setFlagMenuRidotto(false) }}
          onMouseLeave={() => { if (props.dimensioniApp.x<props.xMinimaPerMenuNonRidotto)  props.setFlagMenuRidotto(true) }}
          style={{
            height:props.dimensioniApp.yMain, 
            minWidth: props.flagMenuRidotto ? props.dimensioniApp.yTitolo+5 : "15em", 
            maxWidth: props.flagMenuRidotto ? props.dimensioniApp.yTitolo+15 : 10000,
            backgroundColor:sfondoVocePrincipale,userSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none"
          }}
        >
            {voci.map((x,i) => 
              <div key={i}>
                {(props.identitaSelezionata.flag_amministrazione===1 && parseInt(x.permessi&0b10)!=0)||(props.identitaSelezionata.flag_staff===1 && parseInt(x.permessi&0b01)!=0) ?
                  <>
                    <div 
                      onClick={() => {
                        var fa = [...aperto]; 
                        fa[i]=!fa[i];
                        // la riga seguente chiude gli altri sottomenu aperti:
                        if(fa[i]) for(var n=0; n<fa.length; n++) if (n!=i) fa[n]=false;

                        if (x.pagina!==undefined) {
                          setVoceSelezionata({"i":i,"j":-1});
                            if (x.pagina===props.pagina) {
                              props.setRicarica(true)
                            } else {
                              props.setPagina(x.pagina)
                            }
                        }

                        setAperto(fa);

                      }}
                      style={{
                        backgroundColor: aperto[i] ? sfondoVocePrincipaleAperta : sfondoVocePrincipale,
                        color: aperto[i] ? testoVocePrincipaleAperta : testoVocePrincipale,
                        paddingTop:5,
                        paddingBottom:5,
                        paddingLeft:10,
                        paddingRight:10,
                        whiteSpace: "nowrap",
                        fontSize:"1em",
                        borderBottom: "1px solid " + bordoInferiorePrincipale,
                        cursor: "pointer",
                        display: "flex"
                      }}
                    >
                      {x.icona!==undefined ? <div><img src={x.icona} height="16px" width="16px" alt=""/></div> : <></>}
                      {props.flagMenuRidotto ? "" : <div style={{paddingLeft:"7px"}}>{x.voce}</div>}
                    </div>
                    {aperto[i] && x.sottomenu ? 
                      x.sottomenu.map((y,j) =>
                        <>
                          {(props.identitaSelezionata.flag_amministrazione===1 && parseInt(y.permessi&0b10)!=0)||(props.identitaSelezionata.flag_staff===1 && parseInt(y.permessi&0b01)!=0) ?
                            <div
                              key={j}
                              onClick={() => {
                                setVoceSelezionata({"i":i,"j":j});
                                if (y.pagina===props.pagina) {
                                  props.setRicarica(true)
                                } else {
                                  props.setPagina(y.pagina)
                                }
                              }}
                              style={{
                                backgroundColor: i===voceSelezionata.i && j===voceSelezionata.j ? sfondoVoceSelezionata : sfondoVoceSecondaria,
                                color: i===voceSelezionata.i && j===voceSelezionata.j ? testoVoceSelezionata : testoVoceSecondaria,
                                paddingTop:4,
                                paddingBottom:4,
                                paddingLeft:10,
                                paddingRight:10,
                                whiteSpace: "nowrap",
                                fontSize:"1em",
                                borderBottom: "1px solid " + bordoInferioreSecondario,
                                cursor: "pointer"
            
                              }}
                            >
                              {props.flagMenuRidotto ? y.voce.substring(0,2)+"..." : y.voce}
                            </div>
                          :
                            <></>
                          }
                        </>
                      )
                    :<div></div>}
                  </>
                :<div></div>}
              </div>
            )}
        </div>        
    );
}

export default Menu;
